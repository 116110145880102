// Feldlänge & Stichprobe
export const MODULE_FIELD_LENGTH_AND_SAMPLE = 'field_length_and_sample';

// Branchen & Kunden
export const MODULE_CUSTOMER_AND_INDUSTRY = 'customer_and_industry';

// Kunden & Spezifikation
export const MODULE_CUSTOMER_AND_SPECIFICATION = 'customer_and_specification';

// Image
export const MODULE_IMAGE_DIMENSIONS = 'image_dimensions';
export const MODULE_IMAGE_TOGGLE = 'image_toggle';

// Werbemittel
export const MODULE_ADVERTISING_MATERIAL = 'advertising_material';
export const MODULE_ADVERTISING_MATERIAL_FILES = 'advertising_material_files';
export const MODULE_ADVERTISING_MATERIAL_TOGGLE = 'advertising_material_toggle';

// Werbebeurteilung
export const MODULE_ADVERTISING_EVALUATION = 'advertising_evaluations';
export const MODULE_ADVERTISING_EVALUATION_FILES = 'advertising_evaluation_files';
export const MODULE_ADVERTISING_EVALUATION_TOGGLE = 'advertising_evaluation_toggle';
export const MODULE_ADVERTISING_EVALUATION_DIMENSIONS = 'advertising_evaluation_dimensions';

// Activation
export const MODULE_ACTIVATION_DIMENSIONS = 'activation_dimensions';
export const MODULE_ACTIVATION_TOGGLE = 'activation_toggle';

// Medienabfrage
export const MODULE_MEDIA_RETRIEVAL = 'media_retrieval';
export const MODULE_MEDIA_RETRIEVAL_DIMENSIONS = 'media_retrieval_dimensions';

// Titelspezifikation
export const MODULE_TITLE_SPECIFICATION = 'title_specification';
export const MODULE_TITLE_SPECIFICATION_FILES = 'title_specification_files';

// Editorialspezifikation
export const MODULE_EDITORIAL_SPECIFICATION = 'editorial_specification';
export const MODULE_EDITORIAL_SPECIFICATION_FILES = 'editorial_specification_files';

// Kunden
export const MODULE_CUSTOMERS = 'customers';

// Auswertungsoptionen
export const MODULE_REPORTING_OPTIONS = 'reporting_options';

// Anmerkungen
export const MODULE_NOTES = 'notes';

// Eigene Fragen
export const MODULE_CUSTOM_QUESTIONS = 'custom_questions';

// Eigene Fragen RichText
export const MODULE_CUSTOM_QUESTIONS_RICH_TEXT = 'custom_questions_rich_text';

// Motivspezifikation
export const MODULE_MOTIVE_SPECIFICATION = 'motive_specification';

export const MODULE_FEASIBILITY = 'feasibility';

export const hasModule = (key, modules) => {
  const module = modules.find((module) => module.key === key);

  return module !== undefined;
};

export const findModuleByKey = (key, modules) => {
  const module = modules.find((module) => module.key === key);

  if (module === undefined) {
    return null;
  }

  return module;
};

export const hasValuesByKey = (key, values) => {
  if (Array.isArray(values)) {
    const hasModule = values.find((module) => module.key === key);
    return typeof hasModule !== 'undefined';
  }

  return Object.keys(values).includes(key);
};

export const findValuesByKey = (key, values) => {
  const value = values.find((value) => value.key === key);

  if (value === undefined) {
    return null;
  }

  return value;
};

export const showTitleSpecification = (modules, values) => {
  const hasTitleSpecificationModule = hasModule(MODULE_TITLE_SPECIFICATION_FILES, modules);
  const hasTitleSpecificationValues = hasValuesByKey(MODULE_TITLE_SPECIFICATION_FILES, values);

  return hasTitleSpecificationModule && hasTitleSpecificationValues;
};

export const showEditorialSpecification = (modules, values) => {
  const hasEditorialSpecificationModule = hasModule(MODULE_EDITORIAL_SPECIFICATION_FILES, modules);
  const hasEditorialSpecificationValues = hasValuesByKey(MODULE_EDITORIAL_SPECIFICATION_FILES, values);

  return hasEditorialSpecificationModule && hasEditorialSpecificationValues;
};

export const showMediaRetrievalDimensionsOnly = (modules, values) => {
  const hasMediaRetrievalModule = hasModule(MODULE_MEDIA_RETRIEVAL, modules) === false;
  const hasMediaRetrievalDimensionsModule = hasModule(MODULE_MEDIA_RETRIEVAL_DIMENSIONS, modules);
  const hasMediaRetrievalDimensionsValues = hasValuesByKey(MODULE_MEDIA_RETRIEVAL_DIMENSIONS, values);

  return hasMediaRetrievalModule && hasMediaRetrievalDimensionsModule && hasMediaRetrievalDimensionsValues;
};

export const showMediaRetrievalFromComponents = (modules) => {
  // todo-item-refactor - component `MediaRetrieval` now contains the `MediaRetrievalDimensions` where
  // should remove this hacky hack hack here
  const mediaRetrieval = findModuleByKey(MODULE_MEDIA_RETRIEVAL, modules);
  return mediaRetrieval && Array.isArray(mediaRetrieval.mediaRetrievalDimensions);
};

export const isRetargeting = (type) => type === 'retargeting';

export const isMotiveTest = (type) =>
  type === 'motive_test' || type === 'motive_test_brand_story' || type === 'motive_test_campaign_check';

export class Module {
  /**
   * @var string
   */
  type;

  constructor(module = '') {
    this.type = module;
  }

  getNameByStudyType(studyType, defaultName = 'Sonstige') {
    if (typeof this.NAME_MAP[this.type] === 'string') {
      return this.NAME_MAP[this.type];
    }
    if (typeof this.NAME_MAP[this.type] === 'function') {
      return this.NAME_MAP[this.type](studyType);
    }

    return defaultName;
  }

  getImageNameByStudyType = (type) => {
    // todo 3.0 remove after blueprint
    if (type === 'motive_test_campaign_check') {
      return 'Imagebeurteilung';
    }

    return 'Imagefragen';
  };

  NAME_MAP = {
    [MODULE_FIELD_LENGTH_AND_SAMPLE]: 'Feldlänge & Stichprobe',
    [MODULE_CUSTOMER_AND_INDUSTRY]: 'Kunden & Branchen',
    [MODULE_CUSTOMER_AND_SPECIFICATION]: 'Kunden und Spezifikation',
    [MODULE_IMAGE_DIMENSIONS]: this.getImageNameByStudyType,
    [MODULE_IMAGE_TOGGLE]: this.getImageNameByStudyType,
    [MODULE_MEDIA_RETRIEVAL]: 'Medienabfrage',
    [MODULE_MEDIA_RETRIEVAL_DIMENSIONS]: 'Medienabfrage',
    [MODULE_TITLE_SPECIFICATION_FILES]: 'Titelspezifikation',
    [MODULE_EDITORIAL_SPECIFICATION_FILES]: 'Redaktionelle Einbindung',
    [MODULE_REPORTING_OPTIONS]: 'Auswertungsoptionen',
    [MODULE_NOTES]: 'Anmerkungen',
    [MODULE_ADVERTISING_MATERIAL_FILES]: 'Werbemittel',
    [MODULE_ADVERTISING_MATERIAL_TOGGLE]: 'Werbemittel',
    [MODULE_ADVERTISING_EVALUATION_FILES]: 'Werbemittelbeurteilung',
    [MODULE_ADVERTISING_EVALUATION_TOGGLE]: 'Werbemittelbeurteilung',
    [MODULE_ADVERTISING_EVALUATION_DIMENSIONS]: 'Werbemittelbeurteilung',
    [MODULE_ACTIVATION_TOGGLE]: 'Aktivierung',
    [MODULE_ACTIVATION_DIMENSIONS]: 'Aktivierung',
    [MODULE_CUSTOM_QUESTIONS]: 'Eigene Fragen',
    [MODULE_CUSTOM_QUESTIONS_RICH_TEXT]: 'Eigene Fragen',
    [MODULE_MOTIVE_SPECIFICATION]: 'Motivspezifikation',
  };
}

Module.getName = function getName(module, studyType = '', defaultName = 'Unbekannt') {
  return new Module(module).getNameByStudyType(studyType, defaultName);
};

Module.getComponentName = (module, modules, type, defaultName = 'Unbekannt') => {
  const componentConfiguration = findModuleByKey(module, modules);
  if (componentConfiguration && componentConfiguration.label) {
    return componentConfiguration.label;
  }

  return Module.getName(module, type, defaultName);
};

Module.getAllNamesByStudyType = function getAllNamesByStudyType(studyType, modules) {
  const map = {};
  const names = new Module().NAME_MAP;
  Object.keys(names).forEach((key) => {
    map[key] = Module.getComponentName(key, modules, studyType);
  });

  return map;
};

Module.getUniqueNamesByStudyType = function getUniqueNamesByStudyType(studyType, modules) {
  return [...new Set(Object.values(Module.getAllNamesByStudyType(studyType, modules)))];
};

Module.getMap = () => new Module().NAME_MAP;
