import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Form, Icon, Message } from 'semantic-ui-react';
import { ErrorMessage, Formik } from 'formik';
import * as Yup from 'yup';
import { withHooks } from '@Classic/Hocs/withHooks';
import Client from '../Client';
import CenteredCard from '../Ui/CenteredCard';
import InputError from '../Ui/InputError';

const ResetSchema = Yup.object().shape({
  password: Yup.string().required('Bitte geben Sie Ihr neues Passwort ein.'),
});

class ResetScreen extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      error: false,
    };

    this.password = React.createRef();
    this.request = this.request.bind(this);
  }

  componentDidMount() {
    this.password.current.focus();
    document.title = 'Neues Passwort | Media Monitor Rapid';
  }

  request(values, actions) {
    const { hash } = this.props.params; // eslint-disable-line react/destructuring-assignment
    const { navigate } = this.props;

    const payload = {
      ...values,
      hash: hash,
    };

    Client.post('/passwordchange', payload)
      .then(() => {
        navigate('/login', {
          state: {
            fromReset: true,
          },
        });
      })
      .catch((error) => {
        if (error.networkError) {
          return;
        }
        error.data.forEach((error) => {
          actions.setFieldError(error.property, error.message);
        });

        this.setState({ error: true });
        actions.setSubmitting(false);
      });
  }

  render() {
    const { error } = this.state;

    return (
      <CenteredCard renderHeader headline="Neues Passwort">
        <Formik
          initialValues={{
            password: '',
          }}
          validationSchema={ResetSchema}
          onSubmit={(values, actions) => this.request(values, actions)}
          render={({ errors, touched, values, isSubmitting, handleSubmit, handleChange, handleBlur }) => (
            <Form onSubmit={handleSubmit}>
              <Form.Field fluid error={errors.password && touched.password}>
                <label htmlFor="password">Neues Passwort</label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  ref={this.password}
                />
                <InputError message={<ErrorMessage name="password" />} />
              </Form.Field>
              {error && (
                <Message negative>
                  <Icon name="warning sign" />
                  Beim Erstellen Ihres neuen Passwortes ist ein Fehler aufgetreten. Bitte versuchen Sie es noch einmal
                  oder <Link to="/recovery">fordern Sie ein neues Passwort an</Link>.
                </Message>
              )}
              <Button fluid secondary type="submit" loading={isSubmitting}>
                Neues Passwort erstellen
              </Button>
            </Form>
          )}
        />
      </CenteredCard>
    );
  }
}

export default withHooks(ResetScreen);
