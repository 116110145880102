import React from 'react';
import { Accordion, Form, Icon, Label, Message, Radio, Segment } from 'semantic-ui-react';
import CheckboxAccordion from '../Components/CheckboxAccordion';
import './DimensionsForm.scss';

const summarize = (values) => {
  let sum = 0;

  Object.keys(values).forEach((key) => {
    sum += values[key].length;
  });

  return sum;
};

class DimensionsForm extends React.Component {
  onChangeValues = (name, value) => {
    const { module, values, onChange } = this.props;
    const { key } = module;

    const data = {
      ...values,
      [name]: value,
    };

    console.log('Changing value of DimensionForm: ', key, value);
    onChange(key, data);
  };

  renderCheckboxAccordion = (dimension, active = false) => {
    const { module, values } = this.props;
    const { maxCustomItems } = module;

    console.log('Rendering Checkbox Dimension: ', dimension);
    console.log('Values: ', values);

    return (
      <CheckboxAccordion
        key={dimension.key}
        dimension={dimension}
        values={values}
        onChange={this.onChangeValues}
        maxCustomItems={dimension.max ?? maxCustomItems}
        dataCyName={`form-dimension-${dimension.key}`}
        active={active}
      />
    );
  };

  renderRadioButtons = (dimension) => {
    const { values } = this.props;

    return (
      <div className="ui form" key={dimension.key}>
        <Form.Field>
          <label htmlFor={`dimension-${dimension.key}`}>{dimension.name}</label>
          {dimension.items.map((item, idx) => (
            <Form.Field key={`${dimension.key}-${item}`}>
              <Radio
                key={dimension.key}
                label={dimension.labels[idx]}
                name={`dimension-${dimension.key}`}
                value={item}
                checked={values[dimension.key].includes(item)}
                onChange={() => {
                  this.onChangeValues(dimension.key, [item]);
                }}
              />
            </Form.Field>
          ))}
        </Form.Field>
      </div>
    );
  };

  render = () => {
    const { module, values, studyType, isMediaOpal } = this.props;
    const { dimensions, min, max } = module;

    const inputSetDimensions = dimensions.filter((dimension) => dimension.type === 'inputSet');
    const radioDimensions = dimensions.filter((dimension) => dimension.type === 'radio');
    const checkboxDimensions = dimensions.filter((dimension) => dimension.type === 'checkbox');

    const header = (
      <Segment textAlign="right" className="mb-0">
        Insgesamt ausgewählt
        <Label horizontal circular content={summarize(values)} color="blue" className="ml-10" />
      </Segment>
    );

    const footer = (
      <Message attached="bottom">
        <Icon name="warning sign" />
        {studyType === 'daily_drive'
          ? `Sie dürfen maximal ${max} Items dimensionsübergreifend auswählen.`
          : `Sie müssen mindestens ${min} und dürfen maximal ${max} Items dimensionsübergreifend auswählen.`}
      </Message>
    );

    if (isMediaOpal) {
      return (
        <form className="ui form">
          {radioDimensions.map((dimension) => this.renderRadioButtons(dimension))}
          {checkboxDimensions.map((dimension) => this.renderCheckboxAccordion(dimension))}
          <Accordion fluid styled className="DimensionsForm mt-15">
            {inputSetDimensions.map((dimension) => this.renderCheckboxAccordion(dimension, true))}
          </Accordion>
          {footer}
        </form>
      );
    }

    const infoBoxDailyDrive = (
      <Message>
        <Icon name="warning sign" />
        Die Imagefragen sind nur für PKW-Marken bzw. PKW-Modelle oder in einer Kombination vorgesehen. Sie sind deshalb{' '}
        <strong>nicht</strong> für den Bereich Autohäuser konfigurierbar.
      </Message>
    );

    return (
      <form className="ui form">
        {studyType === 'daily_drive' && infoBoxDailyDrive}
        {header}
        <Accordion fluid styled className="DimensionsForm">
          {dimensions.map((dimension) => this.renderCheckboxAccordion(dimension))}
        </Accordion>
        {footer}
      </form>
    );
  };
}

export default DimensionsForm;
