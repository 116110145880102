const createHistoryHashObserver = (history, timeout = 1000, mainSelector = 'main') => {
  let observer;
  let timeoutId;
  const { location } = history;

  if (!window.MutationObserver) {
    return;
  }

  const reset = () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
      timeoutId = null;
    }

    if (observer) {
      observer.disconnect();
    }
  };

  const createScrollToElement = (id) => () => {
    const element = document.getElementById(id);

    if (element) {
      element.scrollIntoView();
      reset();

      return true;
    }

    return false;
  };

  function scroll(location) {
    if (typeof location.hash !== 'string') {
      return;
    }

    const elementId = location.hash.slice(1);

    if (!elementId) {
      const contentArea = document.getElementById(mainSelector);
      if (contentArea) {
        contentArea.scrollTop = 0;
      }
      return;
    }

    const scrollToElement = createScrollToElement(elementId);

    setTimeout(() => {
      if (scrollToElement()) {
        return;
      }

      observer = new MutationObserver(scrollToElement);

      observer.observe(document, {
        attributes: true,
        childList: true,
        subtree: true,
      });

      timeoutId = setTimeout(reset, timeout);
    });
  }

  history.listen((location) => {
    if (timeoutId) {
      reset();
    }

    scroll(location);
  });

  requestAnimationFrame(() => {
    scroll(location);
  });
};

export default createHistoryHashObserver;
