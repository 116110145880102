import React from 'react';
import { findValuesByKey, Module, MODULE_EDITORIAL_SPECIFICATION_FILES } from '../../Modules';
import ValidationError from '../../Ui/ValidationError';
import FileList from './FileList';
import Section from './Section';

const EditorialSpecification = ({ values, errors, wave, deepLink, activeIndex }) => (
  <Section headline={Module.getName(MODULE_EDITORIAL_SPECIFICATION_FILES, wave.study.type.type)} deepLink={deepLink}>
    <FileList
      files={findValuesByKey(MODULE_EDITORIAL_SPECIFICATION_FILES, values).values.files}
      errors={errors}
      wave={wave}
      activeIndex={activeIndex}
      property={'files[]'}
    />
    <ValidationError errors={errors} property="files" />
  </Section>
);

export default EditorialSpecification;
