import React, { Fragment } from 'react';
import ReactMarkdown from 'react-markdown';
import { Divider } from 'semantic-ui-react';
import {
  findModuleByKey,
  findValuesByKey,
  hasModule,
  Module,
  MODULE_ACTIVATION_DIMENSIONS,
  MODULE_ADVERTISING_EVALUATION,
  MODULE_ADVERTISING_EVALUATION_DIMENSIONS,
  MODULE_ADVERTISING_MATERIAL,
  MODULE_ADVERTISING_MATERIAL_FILES,
  MODULE_CUSTOM_QUESTIONS,
  MODULE_CUSTOM_QUESTIONS_RICH_TEXT,
  MODULE_CUSTOMER_AND_INDUSTRY,
  MODULE_CUSTOMER_AND_SPECIFICATION,
  MODULE_EDITORIAL_SPECIFICATION,
  MODULE_FIELD_LENGTH_AND_SAMPLE,
  MODULE_IMAGE_DIMENSIONS,
  MODULE_MEDIA_RETRIEVAL,
  MODULE_MEDIA_RETRIEVAL_DIMENSIONS,
  MODULE_MOTIVE_SPECIFICATION,
  MODULE_NOTES,
  MODULE_REPORTING_OPTIONS,
  MODULE_TITLE_SPECIFICATION,
  showEditorialSpecification,
  showMediaRetrievalDimensionsOnly,
  showTitleSpecification,
} from '../../Modules';
import { hasRole, ROLE_REPORTING_INTERESTED_PERSON } from '../../Permissions';
import DefinitionList from '../../Ui/DefinitionList';
import InfoMessage from '../../Ui/InfoMessage';
import ValidationError from '../../Ui/ValidationError';
import Activation from './Activation';
import Advertising from './Advertising';
import AdvertisingMaterial from './AdvertisingMaterial';
import CustomerAndIndustry from './CustomerAndIndustry';
import CustomerAndSpecification from './CustomerAndSpecification';
import Date from './Date';
import EditorialSpecification from './EditorialSpecification';
import FieldLengthAndSample from './FieldLengthAndSample';
import Image from './Image';
import List from './List';
import Media from './Media';
import MediaDimension from './MediaDimension';
import Section from './Section';
import Time from './Time';
import TitleSpecification from './TitleSpecification';

const hasDeeplink = (wave, activeIndex) => {
  if (hasRole(ROLE_REPORTING_INTERESTED_PERSON)) {
    return false;
  }

  if (wave.state === 'initial' && activeIndex === 0) {
    return true;
  }

  if (wave.state === 'changed' && activeIndex === 1) {
    return true;
  }

  if (wave.state === 'last_minute' && activeIndex === 0) {
    return true;
  }

  return false;
};

/**
 * @param groupedErrors object
 * @param component Array
 */
const getErrors = (groupedErrors, component) => {
  if (typeof groupedErrors === 'undefined') {
    return [];
  }

  const errors = groupedErrors[component];

  if (Array.isArray(errors)) {
    return errors;
  }

  return [];
};

const Summary = ({ configuration, values, errors, wave, regions, activeIndex }) => (
  <Fragment>
    <Section headline="Details" padded={false}>
      <DefinitionList>
        <DefinitionList.Definition>Erstellt am</DefinitionList.Definition>
        <DefinitionList.Description>
          <Date date={wave.createdAt} /> um <Time date={wave.createdAt} /> Uhr
        </DefinitionList.Description>
      </DefinitionList>
    </Section>
    {hasModule(MODULE_FIELD_LENGTH_AND_SAMPLE, configuration.modules) && (
      <FieldLengthAndSample
        configuration={configuration}
        values={findValuesByKey(MODULE_FIELD_LENGTH_AND_SAMPLE, values)}
        errors={getErrors(errors, MODULE_FIELD_LENGTH_AND_SAMPLE)}
        wave={wave}
        module={findModuleByKey(MODULE_FIELD_LENGTH_AND_SAMPLE, configuration.modules)}
        regions={regions}
        deepLink={hasDeeplink(wave, activeIndex) ? `/wave/${wave.id}/configuration` : undefined}
      />
    )}
    {hasModule(MODULE_MOTIVE_SPECIFICATION, configuration.modules) && (
      <Section
        headline={Module.getComponentName(MODULE_MOTIVE_SPECIFICATION, configuration.modules, wave.study.type.type)}
        deepLink={hasDeeplink(wave, activeIndex) ? `/wave/${wave.id}/configuration/specification` : undefined}
      >
        <DefinitionList>
          <DefinitionList.Definition>Beilageoption</DefinitionList.Definition>
          <DefinitionList.Description>
            <List items={findValuesByKey(MODULE_MOTIVE_SPECIFICATION, values).values.specification} />
          </DefinitionList.Description>
        </DefinitionList>
        <ValidationError errors={getErrors(errors, MODULE_MOTIVE_SPECIFICATION)} property="choiceSetValues" />
        <ValidationError errors={getErrors(errors, MODULE_MOTIVE_SPECIFICATION)} property="textarea" />
      </Section>
    )}
    {hasModule(MODULE_CUSTOMER_AND_SPECIFICATION, configuration.modules) && (
      <CustomerAndSpecification
        configuration={configuration}
        values={findValuesByKey(MODULE_CUSTOMER_AND_SPECIFICATION, values)}
        errors={getErrors(errors, MODULE_CUSTOMER_AND_SPECIFICATION)}
        wave={wave}
        deepLink={
          hasDeeplink(wave, activeIndex) ? `/wave/${wave.id}/configuration/customer-and-specification` : undefined
        }
        activeIndex={activeIndex}
      />
    )}
    {hasModule(MODULE_CUSTOMER_AND_INDUSTRY, configuration.modules) && (
      <CustomerAndIndustry
        configuration={configuration}
        values={findValuesByKey(MODULE_CUSTOMER_AND_INDUSTRY, values)}
        errors={getErrors(errors, MODULE_CUSTOMER_AND_INDUSTRY)}
        wave={wave}
        deepLink={hasDeeplink(wave, activeIndex) ? `/wave/${wave.id}/configuration/customer-and-industry` : undefined}
      />
    )}
    {hasModule(MODULE_IMAGE_DIMENSIONS, configuration.modules) && (
      <Image
        configuration={configuration}
        values={values}
        errors={getErrors(errors, MODULE_IMAGE_DIMENSIONS)}
        wave={wave}
        deepLink={hasDeeplink(wave, activeIndex) ? `/wave/${wave.id}/configuration/image` : undefined}
      />
    )}
    {hasModule(MODULE_MEDIA_RETRIEVAL, configuration.modules) &&
      showMediaRetrievalDimensionsOnly(configuration.modules, values) === false && (
        <Media
          configuration={configuration}
          module={findModuleByKey(MODULE_MEDIA_RETRIEVAL, configuration.modules)}
          values={findValuesByKey(MODULE_MEDIA_RETRIEVAL, values)}
          errors={getErrors(errors, MODULE_MEDIA_RETRIEVAL)}
          wave={wave}
          deepLink={hasDeeplink(wave, activeIndex) ? `/wave/${wave.id}/configuration/media` : undefined}
        />
      )}
    {hasModule(MODULE_MEDIA_RETRIEVAL_DIMENSIONS, configuration.modules) &&
      showMediaRetrievalDimensionsOnly(configuration.modules, values) === true && (
        <MediaDimension
          configuration={configuration}
          module={findModuleByKey(MODULE_MEDIA_RETRIEVAL_DIMENSIONS, configuration.modules)}
          values={findValuesByKey(MODULE_MEDIA_RETRIEVAL_DIMENSIONS, values)}
          errors={getErrors(errors, MODULE_MEDIA_RETRIEVAL)}
          wave={wave}
          deepLink={hasDeeplink(wave, activeIndex) ? `/wave/${wave.id}/configuration/media-dimensions` : undefined}
        />
      )}
    {showTitleSpecification(configuration.modules, values) && (
      <TitleSpecification
        configuration={configuration}
        values={values}
        errors={getErrors(errors, MODULE_TITLE_SPECIFICATION)}
        wave={wave}
        deepLink={
          hasDeeplink(wave, activeIndex) ? `/wave/${wave.id}/configuration/title-specification-files` : undefined
        }
        activeIndex={activeIndex}
      />
    )}
    {showEditorialSpecification(configuration.modules, values) && (
      <EditorialSpecification
        configuration={configuration}
        values={values}
        errors={getErrors(errors, MODULE_EDITORIAL_SPECIFICATION)}
        wave={wave}
        deepLink={
          hasDeeplink(wave, activeIndex) ? `/wave/${wave.id}/configuration/editorial-specification-files` : undefined
        }
        activeIndex={activeIndex}
      />
    )}
    {hasModule(MODULE_ADVERTISING_MATERIAL_FILES, configuration.modules) && (
      <AdvertisingMaterial
        configuration={configuration}
        values={values}
        errors={getErrors(errors, MODULE_ADVERTISING_MATERIAL)}
        wave={wave}
        deepLink={hasDeeplink(wave, activeIndex) ? `/wave/${wave.id}/configuration/advertising-material` : undefined}
        activeIndex={activeIndex}
      />
    )}
    {hasModule(MODULE_ADVERTISING_EVALUATION_DIMENSIONS, configuration.modules) && (
      <Advertising
        configuration={configuration}
        values={values}
        errors={getErrors(errors, MODULE_ADVERTISING_EVALUATION)}
        wave={wave}
        deepLink={hasDeeplink(wave, activeIndex) ? `/wave/${wave.id}/configuration/advertising-evaluation` : undefined}
        activeIndex={activeIndex}
      />
    )}
    {hasModule(MODULE_ACTIVATION_DIMENSIONS, configuration.modules) && (
      <Activation
        configuration={configuration}
        values={values}
        errors={getErrors(errors, MODULE_ACTIVATION_DIMENSIONS)}
        wave={wave}
        deepLink={hasDeeplink(wave, activeIndex) ? `/wave/${wave.id}/configuration/activation` : undefined}
        activeIndex={activeIndex}
      />
    )}
    {hasModule(MODULE_CUSTOM_QUESTIONS, configuration.modules) && (
      <Section
        headline={Module.getComponentName(MODULE_CUSTOM_QUESTIONS, configuration.modules, wave.study.type.type)}
        deepLink={hasDeeplink(wave, activeIndex) ? `/wave/${wave.id}/configuration/custom-questions` : undefined}
      >
        {findValuesByKey(MODULE_CUSTOM_QUESTIONS, values).values.questions.length === 0 ? (
          <InfoMessage>Es wurden keine eigenen Fragen angegeben.</InfoMessage>
        ) : (
          <ul>
            {findValuesByKey(MODULE_CUSTOM_QUESTIONS, values).values.questions.map((question, idx) => (
              <li key={question}>
                {question}
                <ValidationError errors={getErrors(errors, MODULE_CUSTOM_QUESTIONS)} property={`questions[${idx}]`} />
              </li>
            ))}
          </ul>
        )}
        <ValidationError errors={getErrors(errors, MODULE_CUSTOM_QUESTIONS)} property="questions" />
      </Section>
    )}
    {hasModule(MODULE_CUSTOM_QUESTIONS_RICH_TEXT, configuration.modules) && (
      <Section
        headline={Module.getComponentName(
          MODULE_CUSTOM_QUESTIONS_RICH_TEXT,
          configuration.modules,
          wave.study.type.type
        )}
        deepLink={
          hasDeeplink(wave, activeIndex) ? `/wave/${wave.id}/configuration/custom-questions-rich-text` : undefined
        }
      >
        {findValuesByKey(MODULE_CUSTOM_QUESTIONS_RICH_TEXT, values).values.questions.map((question, idx) => {
          const module = findModuleByKey(MODULE_CUSTOM_QUESTIONS_RICH_TEXT, configuration.modules);
          const { title } = module.questions[idx];
          return (
            <React.Fragment key={title}>
              {idx > 0 ? <Divider /> : ''}
              {/* eslint-disable-next-line react/no-danger */}
              <h4 dangerouslySetInnerHTML={{ __html: title }} />
              {question ? (
                <ReactMarkdown children={question} />
              ) : (
                <InfoMessage>Es wurde keine eigene Frage angegeben.</InfoMessage>
              )}
              <ValidationError errors={getErrors(errors, MODULE_CUSTOM_QUESTIONS)} property="questions" />
            </React.Fragment>
          );
        })}
      </Section>
    )}
    {hasModule(MODULE_REPORTING_OPTIONS, configuration.modules) && (
      <Section
        headline={Module.getComponentName(MODULE_REPORTING_OPTIONS, configuration.modules, wave.study.type.type)}
        deepLink={hasDeeplink(wave, activeIndex) ? `/wave/${wave.id}/configuration/reporting-options` : undefined}
      >
        <ul>
          {findValuesByKey(MODULE_REPORTING_OPTIONS, values).values.options.map((option) => (
            <li key={option}>{option}</li>
          ))}
        </ul>
        <ValidationError errors={getErrors(errors, MODULE_REPORTING_OPTIONS)} property="choiceSetValues" />
        <ValidationError errors={getErrors(errors, MODULE_REPORTING_OPTIONS)} property="textarea" />
      </Section>
    )}
    {hasModule(MODULE_NOTES, configuration.modules) && (
      <Section
        headline={Module.getComponentName(MODULE_NOTES, configuration.modules, wave.study.type.type)}
        deepLink={hasDeeplink(wave, activeIndex) ? `/wave/${wave.id}/configuration/notes` : undefined}
      >
        {findValuesByKey(MODULE_NOTES, values).values.notes === '' ? (
          <InfoMessage>Es wurden keine Anmerkungen hinterlegt.</InfoMessage>
        ) : (
          <ReactMarkdown children={findValuesByKey(MODULE_NOTES, values).values.notes} />
        )}
        <ValidationError errors={getErrors(errors, MODULE_NOTES)} property="notes" />
      </Section>
    )}
  </Fragment>
);

export default Summary;
