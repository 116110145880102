import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Checkbox, Icon, Loader, Modal, Table } from 'semantic-ui-react';
import * as Yup from 'yup';
import { withHooks } from '@Classic/Hocs/withHooks';
import { addMessage } from '../Actions/messageActions';
import { store } from '../App';
import Client, { handleRequestError } from '../Client';
import DeleteModal from '../Components/DeleteModal';
import Map from '../Components/Map';
import Screen from '../Components/Screen';
import ScreenHeader from '../Components/ScreenHeader';
import UploadButton from '../Components/UploadButton';
import InputWithSave from '../Forms/InputWithSave';
import OrganizationForm from '../Forms/OrganizationForm';
import { getRoleName, hasRole, ROLE_ADMIN } from '../Permissions';
import { Active } from './UserScreen';

const UpdateOrganizationSchema = Yup.object().shape({
  name: Yup.string().required('Bitte geben Sie einen Namen an.'),
  blueprints: Yup.array().required('Bitte wählen Sie die Studientypen aus.'),
});

class UpdateOrganizationScreen extends React.Component {
  regionMimeTypes = [
    '.csv',
    '.xlsx',
    '.xls',
    'application/vnd.ms-Excel',
    'application/msexcel',
    'application/x-msexcel',
    'application/x-ms-Excel',
    'zz-application/zz-winassoc-xls',

    'application/x-Excel',
    'application/x-dos_ms_Excel',
    'application/xls',
    'application/x-xls',

    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ];

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      organization: null,
      blueprints: [],
      users: [],
      showUsers: false,
      showRegions: false,
      activeRegion: null,
      openMapModal: false,
      openDeleteModal: false,
      deletingRegion: false,
      processingCsv: false,
    };
  }

  componentDidMount() {
    const { navigate, params } = this.props;
    const { uuid } = params;

    if (hasRole(ROLE_ADMIN) === false) {
      this.props.navigate('/', { replace: true });
      store.dispatch(addMessage('Sie haben keine Berechtigung diese Seite aufzurufen.', false, 'error'));
    }

    Promise.all([
      Client.get(`/organization/detail/${uuid}`),
      Client.get('/blueprint/list', { params: { type: 'ROOT' } }),
      Client.get('/user/filter', { params: { organizationId: uuid } }),
    ])
      .then((response) => {
        const [organization, blueprints, users] = response;
        const blueprintsNotInOrganization = this.searchBlueprintsNotInOrganization(
          organization.data.blueprints,
          blueprints.data
        );
        this.setState({
          organization: organization.data,
          blueprints: [...organization.data.blueprints, ...blueprintsNotInOrganization],
          users: users.data,
          loading: false,
        });
      })
      .catch(() => {
        store.dispatch(addMessage('Beim Laden der Daten ist ein Fehler aufgetreten.', true, 'error'));
        this.setState({ loading: false });
      });
  }

  searchBlueprintsNotInOrganization = (blueprintsInOrganization, allBlueprints) =>
    allBlueprints.filter(
      (blueprint) =>
        !blueprintsInOrganization.some((existingBlueprint) => existingBlueprint.studyType === blueprint.studyType)
    );

  showOrganizationList = () => {
    const { navigate } = this.props;
    navigate('/organizations');
  };

  updateOrganization = (values, actions) => {
    // eslint-disable-line class-methods-use-this
    const { uuid } = this.props.params; // eslint-disable-line react/destructuring-assignment
    const { blueprints } = this.state;

    const savedBlueprints = blueprints
      .filter((blueprint) => values.blueprints.some((studyType) => studyType === blueprint.id))
      .map((blueprint) => blueprint.id);

    const data = { id: uuid, ...values, blueprints: savedBlueprints };

    Client.post('/organization/update', data)
      .then(() => {
        actions.setSubmitting(false);
        store.dispatch(addMessage('Die Organisation wurde erfolgreich bearbeitet.'));
      })
      .catch((error) => {
        handleRequestError(error, actions);
      });
  };

  uploadRegion = (file) => {
    const { organization } = this.state;
    this.setState({ processingCsv: true });

    const data = new FormData();
    data.append('file', file);
    data.append('organizationId', organization.id);

    Client.upload('/region/create', data)
      .then((response) => {
        organization.regions = [...organization.regions, response.data];

        this.setState({
          organization: organization,
          processingCsv: false,
        });
        store.dispatch(addMessage('Die Region wurde erfolgreich hochgeladen.'));
      })
      .catch((error) => {
        // Error while processing file in API
        if (error.code === 400 || error.code === 451) {
          this.setState({ processingCsv: false });
          const message = (
            <React.Fragment>
              Beim Upload ist ein Fehler aufgetreten:
              <ul>
                <li>${error.message}</li>
              </ul>
            </React.Fragment>
          );
          store.dispatch(addMessage(message, false, 'error'));

          return;
        }

        if (error.code === 450) {
          this.setState({ processingCsv: false });

          const message = (
            <React.Fragment>
              Beim Upload ist ein Fehler aufgetreten:
              <ul>
                {error.data.map((err) => (
                  <li>{err.message}</li>
                ))}
              </ul>
            </React.Fragment>
          );
          store.dispatch(addMessage(message, false, 'error'));

          return;
        }

        if (error.code === 500) {
          this.setState({ processingCsv: false });
          const message = (
            <React.Fragment>
              Beim Upload ist ein Fehler aufgetreten:
              <ul>
                <li>
                  ${error.message}: ${error.data.message}
                </li>
              </ul>
            </React.Fragment>
          );
          store.dispatch(addMessage(message, false, 'error'));

          return;
        }

        if (error instanceof TypeError) {
          this.setState({ processingCsv: false });
          store.dispatch(addMessage('Internal Server Error 500', false, 'error'));
        }
      });
  };

  updateRegion = (region, name) => {
    if (region.name === name) {
      return;
    }

    const { organization } = this.state;
    Client.post('/region/update', {
      id: region.id,
      name: name,
    }).then((response) => {
      organization.regions = organization.regions.map((r) => {
        if (r.id === response.data.id) {
          return response.data;
        }
        return r;
      });
      this.setState({
        organization: organization,
      });
      store.dispatch(addMessage('Die Region wurde gespeichert.'));
    });
  };

  deleteRegion = () => {
    const { activeRegion, organization } = this.state;

    if (activeRegion === null) {
      return;
    }

    this.setState({
      deletingRegion: true,
    });

    Client.post('/region/delete', {
      id: activeRegion.id,
    }).then(() => {
      organization.regions = organization.regions.filter((r) => r.id !== activeRegion.id);
      this.setState({
        organization: organization,
        openDeleteModal: false,
        activeRegion: null,
        deletingRegion: false,
      });
      store.dispatch(addMessage('Die Region wurde gelöscht.'));
    });
  };

  openDeleteRegionModal = (open, region) => {
    this.setState({
      openDeleteModal: true,
      activeRegion: region === null ? null : { ...region },
    });
  };

  openMapModal = (open, region) => {
    this.setState({
      openMapModal: open,
      activeRegion: region === null ? null : { ...region },
    });
  };

  showUsers(show) {
    this.setState({
      showUsers: show,
    });
  }

  showRegions(show) {
    this.setState({
      showRegions: show,
    });
  }

  renderUserList() {
    const { users, showUsers } = this.state;
    const hasUsers = users.length > 0;

    return (
      <React.Fragment>
        <h3 className="card-title d-flex align-items-center justify-content-space-between">
          <span className="d-flex align-items-center">
            {hasUsers && (
              <Checkbox
                toggle
                onChange={(ev, { checked }) => this.showUsers(checked)}
                checked={showUsers}
                data-cy="user-toggle"
                className="mr-15"
              />
            )}
            Benutzer ({users.length})
          </span>
          <Button icon as={Link} to="/user/create" labelPosition="right" className="mr-0 mt-15 mb-15">
            <Icon link name="plus" className="mr-0" /> Neuen Nutzer erstellen
          </Button>
        </h3>
        {!hasUsers && <p data-cy="user_empty">Keine Benutzer vorhanden.</p>}
        {showUsers && hasUsers && (
          <Table data-cy="user_list" celled striped className="mb-15">
            <thead>
              <tr>
                <th />
                <th>Name</th>
                <th>E-Mail</th>
                <th>Rolle</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <tr key={user.id}>
                  <td>
                    <Active active={user.active} />
                  </td>
                  <td data-cy="user_name">
                    {user.firstName} {user.lastName}
                  </td>
                  <td>{user.email}</td>
                  <td>{getRoleName(user.role)}</td>
                  <td>
                    <Link to={`/user/${user.id}/update`} data-cy="user_edit">
                      <Icon name="edit outline" className="mr-0" />
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </React.Fragment>
    );
  }

  renderRegionList() {
    const { showRegions, organization, processingCsv } = this.state;

    return (
      <React.Fragment>
        <h3 className="card-title d-flex align-items-center justify-content-space-between">
          <span className="d-flex align-items-center">
            <Checkbox
              toggle
              onChange={(ev, { checked }) => this.showRegions(checked)}
              checked={showRegions}
              data-cy="region-toggle"
              className="mr-15"
            />
            Regionen ({organization.regions.length})
          </span>
          <UploadButton
            data-doc="app_region_create"
            dataCyName="custom-region"
            onUpload={this.uploadRegion}
            removeLabel="Region entfernen"
            uploadLabel="Region hochladen"
            isUploading={processingCsv}
            value={null}
            accept={this.regionMimeTypes.join(',')}
            style={{ marginBottom: '5px' }}
            fluid={false}
          />
        </h3>
        {showRegions && (
          <Table data-cy="region_list" celled striped className="mb-15">
            <thead>
              <tr>
                <th>Name</th>
                <th>Aktionen</th>
              </tr>
            </thead>
            <tbody>
              {organization.regions.map((region) => (
                <tr key={region.id} data-cy="region">
                  <td data-cy="region_name">
                    <InputWithSave
                      className="w-100"
                      value={region.name}
                      data-doc="app_region_edit"
                      onSubmit={(value) => this.updateRegion(region, value)}
                    />
                  </td>
                  <td className="collapsing">
                    <Button
                      style={{ textAlign: 'left' }}
                      type="button"
                      content="Karte anzeigen"
                      icon="map outline"
                      labelPosition="right"
                      data-cy="region_map"
                      onClick={() => this.openMapModal(true, region)}
                    />
                    <Button
                      style={{ textAlign: 'left' }}
                      type="button"
                      icon="delete"
                      content="Region löschen"
                      labelPosition="right"
                      data-cy="region_delete"
                      data-doc="app_region_delete"
                      onClick={() => this.openDeleteRegionModal(true, region)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </React.Fragment>
    );
  }

  render() {
    const { loading, blueprints, organization, openDeleteModal, activeRegion, deletingRegion, openMapModal } =
      this.state;

    return (
      <Screen title="Organisation bearbeiten | Media Monitor Rapid" data-doc="app_organization_edit">
        <ScreenHeader title="Organisation bearbeiten" hasBackButton={false} />
        {loading && <Loader active>Organisation wird geladen ...</Loader>}
        {!loading && organization && (
          <React.Fragment>
            <OrganizationForm
              loading={loading}
              blueprints={blueprints}
              validationSchema={UpdateOrganizationSchema}
              onSubmit={this.updateOrganization}
              submitLabel="Änderungen speichern"
              initialValues={{
                name: organization.name,
                customerId: organization.customerId,
                customerExportLabel: organization.customerExportLabel,
                blueprints: organization.blueprints.map((blueprint) => blueprint.id),
              }}
            />
            {this.renderUserList()}
            {this.renderRegionList()}
            {openDeleteModal && activeRegion && (
              <DeleteModal
                open
                loading={deletingRegion}
                headline="Achtung"
                message={
                  <p>
                    Sind Sie sicher, dass Sie die Region <strong>{activeRegion.name}</strong> löschen möchten?
                  </p>
                }
                abortLabel="Nicht löschen"
                confirmLabel="Region löschen"
                onConfirm={this.deleteRegion}
                onClose={() => this.openDeleteRegionModal(true, null)}
              />
            )}
            {openMapModal && activeRegion && (
              <Modal
                closeIcon
                id="map-modal"
                size="fullscreen"
                open={openMapModal}
                onClose={() => this.openMapModal(false, null)}
              >
                <Map zips={activeRegion.zips} />
              </Modal>
            )}
          </React.Fragment>
        )}
      </Screen>
    );
  }
}

export default withHooks(UpdateOrganizationScreen);
