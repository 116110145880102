import * as React from 'react';
import { Link } from 'react-router-dom';
import { Button, Dropdown, Form, Icon, Message } from 'semantic-ui-react';
import { addMessage } from '@Classic/Actions/messageActions.js';
import { store } from '@Classic/App.jsx';
import Client from '../Client';
import Screen from '../Components/Screen';
import ScreenHeader from '../Components/ScreenHeader';
import StudyCalendar from '../Components/StudyCalendar';
import StudyList from '../Components/StudyList';
import {
  hasOneOfRoles,
  ROLE_ADMIN,
  ROLE_MARKET_RESEARCHER,
  ROLE_SERVICE_PROVIDER_PANEL,
  ROLE_SERVICE_PROVIDER_QUESTIONNAIRE,
  ROLE_STUDENT,
} from '../Permissions';

const VIEW_LIST = 'list';
const VIEW_CALENDAR = 'calendar';

const transformModulesForDropdowns = (modules) => {
  const modulesMap = new Map();

  modules.forEach((module) => {
    if (modulesMap.has(module.type)) {
      return;
    }

    modulesMap.set(module.type, module.name.replace(/\(.+\)/, '').trim()); // remove name addons like "(Funke)"
  });

  const ICON_MAP = {
    motive_test: 'image outline',
    motive_test_brand_story: 'image outline',
    motive_test_campaign_check: 'image outline',
    easy_success_measurement: 'chart line',
    daily_sales: 'euro',
    daily_drive: 'car',
    retargeting: 'redo alternate',
    advertisement_copy_test: 'image',
    supplement_copy_test: 'file image',
    motive_test_media_opal: 'image outline',
    quick_poll: 'stopwatch',
  };

  return Array.from(modulesMap).map((module) => ({
    key: module[0],
    value: module[0],
    text: module[1],
    icon: ICON_MAP[module[0]],
  }));
};

class DashboardScreen extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      studies: [],
      organizations: [],
      organizationId: 'all',
      moduleTypes: [],
      moduleType: 'all',
      view: VIEW_LIST,
    };

    this.updateStudy = this.updateStudy.bind(this);
    this.addStudy = this.addStudy.bind(this);
    this.filterStudies = this.filterStudies.bind(this);
    this.removeWave = this.removeWave.bind(this);
    this.updateWaveName = this.updateWaveName.bind(this);
  }

  componentDidMount() {
    if (hasOneOfRoles([ROLE_ADMIN, ROLE_SERVICE_PROVIDER_PANEL, ROLE_SERVICE_PROVIDER_QUESTIONNAIRE, ROLE_STUDENT])) {
      Promise.all([Client.get('/study'), Client.get('/organization/list'), Client.get('/module')])
        .then((response) => {
          const [studies, organizations, modules] = response;

          this.setState({
            studies: studies.data,
            organizations: organizations.data,
            moduleTypes: transformModulesForDropdowns(modules.data),
            loading: false,
          });
        })
        .catch((error) => {
          if (error.networkError) {
            return;
          }
          this.setError(error, 'Fehler beim Laden der Studiendaten.');
        });

      return;
    }

    Promise.all([Client.get('/study'), Client.get('/module')])
      .then((response) => {
        const [studies, modules] = response;
        this.setState({
          studies: studies.data,
          moduleTypes: transformModulesForDropdowns(modules.data),
          loading: false,
        });
      })
      .catch((error) => {
        if (error.networkError) {
          return;
        }
        this.setError(error, 'Fehler beim Laden der Studiendaten.');
      });
  }

  setError = (error, message = 'Bitte überprüfen Sie Ihre Eingabe.') => {
    if (error.code === 500) {
      store.dispatch(addMessage(`${message}: ${error.message}`, true, 'error'));
    } else {
      store.dispatch(addMessage(message, true, 'error'));
    }
    this.setState({ loading: false });
  };

  setOrganizationAndFilter(organizationId) {
    const { moduleType } = this.state;

    this.setState({ loading: true, organizationId: organizationId });

    this.filterStudies(organizationId, moduleType);
  }

  setModuleTypeAndFilter(moduleType) {
    const { organizationId } = this.state;

    this.setState({ loading: true, moduleType: moduleType });

    this.filterStudies(organizationId, moduleType);
  }

  updateStudy(updatedStudy) {
    this.setState((prevState) => {
      // eslint-disable-line arrow-body-style
      return {
        studies: prevState.studies.map((study) => {
          if (study.id === updatedStudy.id) {
            return updatedStudy;
          }

          return study;
        }),
      };
    });
  }

  addStudy(study) {
    const { studies } = this.state;
    this.setState({
      studies: [study, ...studies],
    });
  }

  filterStudies(organizationId, moduleType) {
    if (organizationId === 'all' && moduleType === 'all') {
      Client.get('/study')
        .then((response) => this.setState({ studies: response.data, loading: false }))
        .catch((error) => {
          if (error.networkError) {
            return;
          }
          this.setState({ loading: false });
        });

      return;
    }

    if (hasOneOfRoles([ROLE_ADMIN, ROLE_SERVICE_PROVIDER_PANEL, ROLE_SERVICE_PROVIDER_QUESTIONNAIRE, ROLE_STUDENT])) {
      Client.get('/study/filter', {
        params: {
          organizationId: organizationId === 'all' ? null : organizationId,
          moduleType: moduleType === 'all' ? null : moduleType,
        },
      })
        .then((response) => this.setState({ studies: response.data, loading: false }))
        .catch((error) => {
          if (error.networkError) {
            return;
          }
          this.setState({ loading: false });
        });

      return;
    }

    Client.get('/study/filter/by-module', {
      params: {
        moduleType: moduleType === 'all' ? null : moduleType,
      },
    })
      .then((response) => this.setState({ studies: response.data, loading: false }))
      .catch((error) => {
        if (error.networkError) {
          return;
        }
        this.setState({ loading: false });
      });
  }

  removeWave(studyId, waveId) {
    const { studies } = this.state;
    const study = studies.find((study) => study.id === studyId);

    if (study === undefined) {
      // Fail silently, should never occur
      return;
    }

    // Last wave, study needs to be removed as well
    if (study.waves.length === 1) {
      this.setState((prevState) => ({
        studies: prevState.studies.filter((study) => study.id !== studyId),
      }));

      return;
    }

    // Remove related wave
    const waves = study.waves.filter((wave) => wave.id !== waveId);

    this.setState((prevState) => ({
      studies: prevState.studies.map((study) => {
        if (study.id === studyId) {
          return {
            ...study,
            waves: waves,
          };
        }

        return study;
      }),
    }));
  }

  updateWaveName(updatedWave) {
    this.setState((prevState) => ({
      studies: prevState.studies.map((study) => {
        if (study.id === updatedWave.study.id) {
          return {
            ...(updatedWave.study ? updatedWave.study : study),
            waves: study.waves.map((wave) => {
              if (wave.id === updatedWave.id) {
                return {
                  ...wave,
                  name: updatedWave.name,
                  version: updatedWave.version,
                };
              }

              return wave;
            }),
          };
        }

        return study;
      }),
    }));
  }

  renderFilterForm = () => {
    const { organizations, organizationId, moduleTypes, moduleType, loading } = this.state;

    return (
      <Form className="d-flex w-100 mr-15">
        {hasOneOfRoles([
          ROLE_ADMIN,
          ROLE_SERVICE_PROVIDER_PANEL,
          ROLE_SERVICE_PROVIDER_QUESTIONNAIRE,
          ROLE_STUDENT,
        ]) && (
          <Form.Field
            className="w-100 mb-0 mr-15"
            data-doc="app_study_filter_by_organization"
            data-cy="study_filter_by_organization"
          >
            <label htmlFor="organizationId">Nach Organisation filtern</label>
            <Dropdown
              selection
              disabled={loading}
              loading={loading}
              name="organizationId"
              id="organizationId"
              placeholder="Filtern Sie nach Organisation"
              value={organizationId}
              onChange={(e, { value }) => this.setOrganizationAndFilter(value)}
              options={[
                { key: 'all', value: 'all', text: 'Alle' },
                ...organizations.map((organization) => ({
                  key: organization.id,
                  value: organization.id,
                  text: organization.name,
                })),
              ]}
            />
          </Form.Field>
        )}
        <Form.Field className="w-100 mb-0" data-doc="app_study_filter_by_module" data-cy="study_filter_by_module">
          <label htmlFor="moduleType">Nach Studientyp filtern</label>
          <Dropdown
            selection
            disabled={loading}
            loading={loading}
            name="moduleType"
            id="moduleType"
            placeholder="Filtern Sie nach Studientyp"
            value={moduleType}
            style={{ marginTop: 0 }}
            onChange={(e, { value }) => this.setModuleTypeAndFilter(value)}
            options={[{ key: 'all', value: 'all', text: 'Alle' }, ...moduleTypes]}
          />
        </Form.Field>
      </Form>
    );
  };

  renderButtonGroup = () => {
    const { view } = this.state;

    return (
      <Button.Group>
        <Button
          icon
          title="Listenansicht"
          active={view === VIEW_LIST}
          onClick={() => this.setState({ view: VIEW_LIST })}
          data-cy="dashboard-list-button"
        >
          <Icon name="list" />
        </Button>
        <Button
          icon
          title="Kalenderansicht"
          active={view === VIEW_CALENDAR}
          onClick={() => this.setState({ view: VIEW_CALENDAR })}
          data-cy="dashboard-calendar-button"
        >
          <Icon name="calendar alternate outline" />
        </Button>
      </Button.Group>
    );
  };

  renderStudies = () => {
    const { loading, studies, organizationId, view } = this.state;

    if (loading === false && studies.length === 0 && organizationId !== '' && view === VIEW_LIST) {
      return (
        <Message
          info
          icon="info"
          header="Keine Studien gefunden"
          content="Für die von Ihnen gewählte Organisation konnten keine Studien gefunden werden."
          data-cy="studies_message_container"
        />
      );
    }

    if (loading === false && studies.length === 0 && view === VIEW_LIST) {
      return (
        <Message
          info
          icon="info"
          header="Keine Studien verfügbar"
          content="Scheint so als wären für Ihre Organisation noch keine Studien verfügbar."
          data-cy="studies_message_container"
        />
      );
    }

    if (view === VIEW_CALENDAR) {
      return <StudyCalendar studies={studies} />;
    }

    return (
      <StudyList
        studies={studies}
        loading={loading}
        updateStudy={this.updateStudy}
        addStudy={this.addStudy}
        removeWave={this.removeWave}
        updateWaveName={this.updateWaveName}
      />
    );
  };

  render() {
    return (
      <Screen title="Studien | Media Monitor Rapid">
        <ScreenHeader className="justify-content-space-between" hasBackButton={false} title="Studien">
          {hasOneOfRoles([ROLE_ADMIN, ROLE_MARKET_RESEARCHER]) && (
            <Button
              primary
              icon
              as={Link}
              to="/study/create"
              labelPosition="right"
              className="mr-0"
              data-cy="button_create_study"
            >
              <Icon link name="plus" className="mr-0" /> Neue Studie erstellen
            </Button>
          )}
        </ScreenHeader>
        <div className="d-flex align-items-end mb-30">
          {this.renderFilterForm()}
          {this.renderButtonGroup()}
        </div>
        {this.renderStudies()}
      </Screen>
    );
  }
}

export default DashboardScreen;
