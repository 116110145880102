import React from 'react';
import { Button, Header, Modal } from 'semantic-ui-react';

class DeleteModal extends React.PureComponent {
  render() {
    const { open, loading, headline, message, abortLabel, confirmLabel, onConfirm, onClose } = this.props;

    return (
      <Modal data-cy="delete-modal" size="small" open={open} onClose={onClose}>
        <Header icon="delete" content={headline} />
        <Modal.Content>{message}</Modal.Content>
        <Modal.Actions>
          <Button content={abortLabel} onClick={onClose} data-cy="delete-modal-abort" />
          <Button
            color="red"
            data-cy="delete-modal-confirm"
            content={confirmLabel}
            onClick={onConfirm}
            loading={loading}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

export default DeleteModal;
