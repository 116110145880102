import React, { Fragment } from 'react';
import { Button, Header, Icon, Segment } from 'semantic-ui-react';
import { addMessage } from '../Actions/messageActions';
import { store } from '../App';
import Client from '../Client';

class FileUpload extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };

    this.input = React.createRef();
    this.onChange = this.onChange.bind(this);
  }

  onChange() {
    const { url, onUpload, fileKey } = this.props;
    const [file] = this.input.current.files;

    console.log('Appended file: ', file);

    this.setState({ loading: true });

    const data = new FormData();
    data.append('key', fileKey);
    data.append('file', file);

    Client.upload(url, data)
      .then((response) => {
        onUpload(response.data);
        this.setState({ loading: false });
      })
      .catch((error) => {
        this.setState({ loading: false });
        let message = [];
        if (error.data && error.data.length > 0) {
          message = error.data.map((m) => m.message);
        }
        store.dispatch(addMessage(`Die Datei konnte nicht hochgeladen werden: ${message.join(' ')}`, false, 'error'));
      });
  }

  isDisabled = () => {
    const { loading } = this.state;
    const { disabled } = this.props;

    return loading || disabled;
  };

  onClick = () => {
    if (this.isDisabled() === true) {
      return;
    }

    this.input.current.click();
  };

  render() {
    const { loading } = this.state;
    const { accept, buttonText, fileKey } = this.props;

    if (loading === true) {
      return <Segment placeholder loading={loading} />;
    }

    return (
      <Fragment>
        <Segment
          as="button"
          type="button"
          attached
          placeholder
          loading={loading}
          disabled={this.isDisabled()}
          onClick={this.onClick}
          style={{
            outline: 'none',
            transition: 'opacity 0.2s',
            opacity: this.isDisabled() === false ? 1 : 0.7,
            cursor: this.isDisabled() === false ? 'pointer' : 'not-allowed',
          }}
        >
          <Header icon>
            <Icon name="upload" />
          </Header>
          <input
            className="d-none"
            type="file"
            ref={this.input}
            data-cy="files-form-input-file"
            data-cy-key={fileKey}
            onChange={this.onChange}
            accept={accept}
          />
        </Segment>
        <Button.Group attached="bottom" size="small">
          <Button
            type="button"
            content={buttonText || 'Wählen Sie eine Datei'}
            icon="folder open outline"
            labelPosition="right"
            disabled={this.isDisabled()}
            onClick={this.onClick}
          />
        </Button.Group>
      </Fragment>
    );
  }
}

FileUpload.defaultProps = {
  disabled: false,
};

export default FileUpload;
