import React from 'react';
import { isMotiveTest, isRetargeting, Module, MODULE_FIELD_LENGTH_AND_SAMPLE } from '../../Modules';
import DefinitionList from '../../Ui/DefinitionList';
import ValidationError from '../../Ui/ValidationError';
import DownloadLink from '../DownloadLink';
import Date from './Date';
import Quote from './Quote';
import Region from './Region';
import Section from './Section';
import TargetGroup from './TargetGroup';

const FieldLengthAndSample = ({ configuration, values, module, errors, wave, regions, deepLink }) => {
  const {
    start,
    end,
    numberOfCases,
    gender,
    minAge,
    maxAge,
    quotaType,
    quote,
    quotaRequirement,
    whitelist,
    region,
    customRegion,
    customRegionName,
    dateSingle,
  } = values.values;

  const { dateLabel, dateType, regionLabel, hasAge, hasGender, hasNumberOfCases, hasQuotaTypes } = module;

  const showQuote =
    [
      'daily_sales',
      'daily_drive',
      'retargeting',
      'advertisement_copy_test',
      'supplement_copy_test',
      'motive_test_media_opal',
      'quick_poll',
    ].includes(wave.study.type.type) === false;
  const showQuotaType = quote > 0;
  const { isMediaOpal } = wave.study;

  return (
    <Section
      headline={Module.getComponentName(MODULE_FIELD_LENGTH_AND_SAMPLE, configuration.modules, wave.study.type.type)}
      deepLink={deepLink}
    >
      <DefinitionList>
        {dateType === 'dateRange' && (
          <React.Fragment>
            <DefinitionList.Definition>Start</DefinitionList.Definition>
            <DefinitionList.Description>
              <Date date={start} />
            </DefinitionList.Description>
            <ValidationError errors={errors} property="startDate" />

            <DefinitionList.Definition>Ende</DefinitionList.Definition>
            <DefinitionList.Description>
              <Date date={end} />
            </DefinitionList.Description>
            <ValidationError errors={errors} property="endDate" />
          </React.Fragment>
        )}

        {dateType === 'dateSingle' && (
          <React.Fragment>
            <DefinitionList.Definition>{dateLabel}</DefinitionList.Definition>
            <DefinitionList.Description>
              <Date date={dateSingle} />
            </DefinitionList.Description>
            <ValidationError errors={errors} property="singleDate" />
          </React.Fragment>
        )}

        {hasNumberOfCases && (
          <React.Fragment>
            <DefinitionList.Definition>Fallzahl</DefinitionList.Definition>
            <DefinitionList.Description>{numberOfCases}</DefinitionList.Description>
            <ValidationError errors={errors} property="numberOfCases" />
          </React.Fragment>
        )}

        {hasGender && (
          <React.Fragment>
            <DefinitionList.Definition>Geschlecht</DefinitionList.Definition>
            <DefinitionList.Description>
              <TargetGroup gender={gender} />
            </DefinitionList.Description>
            <ValidationError errors={errors} property="gender" />
          </React.Fragment>
        )}

        <DefinitionList.Definition>{regionLabel}</DefinitionList.Definition>
        <DefinitionList.Description>
          <Region
            regions={regions}
            regionId={region}
            customRegion={customRegion}
            customRegionName={customRegionName}
            wave={wave}
          />
        </DefinitionList.Description>
        <ValidationError errors={errors} property="customRegionName" />
        <ValidationError errors={errors} property="customRegion" />
        <ValidationError errors={errors} property="region" />

        {hasAge && (
          <React.Fragment>
            <DefinitionList.Definition>Altersbereich</DefinitionList.Definition>
            <DefinitionList.Description>
              {minAge} Jahre bis {maxAge} Jahre
            </DefinitionList.Description>
            <ValidationError errors={errors} property="minAge" />
            <ValidationError errors={errors} property="maxAge" />
          </React.Fragment>
        )}

        {
          // This is a dirty hack. This form should ideally be composed of modules like the "Image"-Tab or
          // the "Werbemittelbeurteilung"-Tab. This field should be another module, which can be plugged in/out.
          showQuote && (
            <React.Fragment>
              <DefinitionList.Definition>Anteil WLK TZ bzw. Vermarkter WLK TZ</DefinitionList.Definition>
              <DefinitionList.Description>
                <Quote quote={quote} />
              </DefinitionList.Description>
              <ValidationError errors={errors} property="quote" />
            </React.Fragment>
          )
        }

        {
          // This is a dirty hack. This form should ideally be composed of modules like the "Image"-Tab or
          // the "Werbemittelbeurteilung"-Tab. This field should be another module, which can be plugged in/out.
          hasQuotaTypes &&
            showQuotaType &&
            (wave.study.type.type === 'easy_success_measurement' || isMotiveTest(wave.study.type.type)) && (
              <React.Fragment>
                <DefinitionList.Definition>Quotierungstyp</DefinitionList.Definition>
                <DefinitionList.Description>{quotaType}</DefinitionList.Description>
                <ValidationError errors={errors} property="quotaType" />
              </React.Fragment>
            )
        }

        {!isMediaOpal && (
          <React.Fragment>
            <DefinitionList.Definition>Quotierungsvorgaben</DefinitionList.Definition>
            <DefinitionList.Description>
              {quotaRequirement === null ? (
                'Es wurden noch keine Quotierungsvorgaben hinterlegt'
              ) : (
                <DownloadLink
                  url={`/wave/${wave.id}/file/${quotaRequirement.fileName}`}
                  name={quotaRequirement.originalName}
                />
              )}
            </DefinitionList.Description>
            <ValidationError errors={errors} property="quotaRequirement" />
          </React.Fragment>
        )}

        {
          // This is a dirty hack. This form should ideally be composed of modules like the "Image"-Tab or
          // the "Werbemittelbeurteilung"-Tab. This field should be another module, which can be plugged in/out.
          isRetargeting(wave.study.type.type) && (
            <React.Fragment>
              <DefinitionList.Definition>Whitelist</DefinitionList.Definition>
              <DefinitionList.Description>
                {whitelist === null ? (
                  'Es wurden noch keine Whitelist hinterlegt'
                ) : (
                  <DownloadLink url={`/wave/${wave.id}/file/${whitelist.fileName}`} name={whitelist.originalName} />
                )}
              </DefinitionList.Description>
              <ValidationError errors={errors} property="whitelist" />
            </React.Fragment>
          )
        }
      </DefinitionList>
    </Section>
  );
};

export default FieldLengthAndSample;
