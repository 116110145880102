import React from 'react';
import { Card, Grid, Header } from 'semantic-ui-react';

const CenteredCard = ({ headline, children, renderHeader }) => (
  <Grid centered className="h-100 align-items-center">
    <Grid.Column widescreen={5} largeScreen={8} computer={8} mobile={14}>
      {renderHeader && (
        <Header textAlign="center" as="h1" color="grey" data-cy="headline_login">
          Media Monitor Rapid
        </Header>
      )}
      <Card fluid>
        <Card.Content>
          <Card.Header>{headline}</Card.Header>
        </Card.Content>
        <Card.Content>{children}</Card.Content>
      </Card>
    </Grid.Column>
  </Grid>
);

export default CenteredCard;
