import React from 'react';
import { Button, Dropdown, Form, Icon, Loader } from 'semantic-ui-react';
import { ErrorMessage, Formik } from 'formik';
import * as Yup from 'yup';
import { withHooks } from '@Classic/Hocs/withHooks';
import { addMessage } from '../Actions/messageActions';
import { store } from '../App';
import Client, { handleRequestError } from '../Client';
import MarkdownTextarea from '../Components/MarkdownTextarea';
import Screen from '../Components/Screen';
import ScreenHeader from '../Components/ScreenHeader';
import InputError from '../Ui/InputError';

const CreateNewsSchema = Yup.object().shape({
  title: Yup.string().required('Bitte geben Sie einen Titel an.'),
  text: Yup.string().required('Bitte geben Sie einen Text an.'),
  organizations: Yup.array().min(1, 'Bitte wählen Sie mindestens eine Organisation aus.'),
});

class CreateNewsScreen extends React.PureComponent {
  constructor(props) {
    super(props);

    this.input = React.createRef();

    this.state = {
      fetching: true,
      organizations: [],
      news: null,
    };
  }

  componentDidMount() {
    const { params } = this.props;
    const { uuid } = params;

    Promise.all([Client.get(`/news/${uuid}`), Client.get('/organization/list')])
      .then((response) => {
        const [news, organizations] = response;
        this.setState({ news: news.data, organizations: organizations.data, fetching: false });
      })
      .catch((error) => {
        if (error.networkError) {
          return;
        }
        this.setState({ fetching: false });
      });
  }

  submit = (values, actions) => {
    const { navigate, params } = this.props;
    const { uuid } = params;
    const { title, text, organizationIds } = values;

    Client.post(`/news/${uuid}/update`, {
      id: uuid,
      title: title,
      text: text,
      organizationIds: organizationIds,
    })
      .then(() => {
        store.dispatch(addMessage('Die Neuigkeit wurde erfolgreich bearbeitet.'));
        navigate('/news');
      })
      .catch((error) => {
        handleRequestError(error, actions);
      });
  };

  renderForm = () => {
    const { news, organizations, fetching } = this.state;

    return (
      <Formik
        initialValues={{
          title: news.title,
          text: news.text,
          organizationIds: news.organizations.map((organisation) => organisation.id),
        }}
        validationSchema={CreateNewsSchema}
        onSubmit={(values, actions) => this.submit(values, actions)}
        render={({
          errors,
          touched,
          values,
          isSubmitting,
          handleSubmit,
          handleChange,
          handleBlur,
          setFieldValue,
          setFieldTouched,
          isValid,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Field fluid error={errors.title && touched.title}>
              <label htmlFor="title">Titel</label>
              <input
                type="text"
                name="title"
                id="title"
                value={values.title}
                onChange={handleChange}
                onBlur={handleBlur}
                ref={this.input}
              />
              <InputError message={<ErrorMessage name="title" />} />
            </Form.Field>
            <Form.Field fluid error={errors.organizationIds && touched.organizationIds}>
              <label htmlFor="organizationIds">Organisation</label>
              <Dropdown
                selection
                multiple
                loading={fetching}
                disabled={fetching}
                name="organizationIds"
                id="organizationIds"
                placeholder="Wählen Sie mindestens eine Organisation"
                value={values.organizationIds}
                options={organizations.map((organization) => ({
                  key: organization.id,
                  value: organization.id,
                  text: organization.name,
                }))}
                onChange={(event, { value }) => setFieldValue('organizationIds', value)}
                onBlur={() => setFieldTouched('organizationIds', true)}
              />
              <InputError message={<ErrorMessage name="organizationIds" />} />
            </Form.Field>
            <Form.Field fluid>
              <MarkdownTextarea value={values.text} onChange={handleChange} onBlur={handleBlur} />
              <InputError message={<ErrorMessage name="text" />} />
            </Form.Field>
            <Button
              primary
              icon
              className="mt-15"
              type="submit"
              labelPosition="right"
              loading={isSubmitting}
              disabled={!isValid}
            >
              <Icon link name="save outline" />
              Änderungen speichern
            </Button>
          </Form>
        )}
      />
    );
  };

  render() {
    const { fetching } = this.state;

    return (
      <Screen title="Neuigkeit bearbeiten | Media Monitor Rapid">
        <ScreenHeader title="Neuigkeit bearbeiten" hasBackButton={false} />
        {fetching === true ? <Loader active>Neuigkeit wird geladen ...</Loader> : this.renderForm()}
      </Screen>
    );
  }
}

export default withHooks(CreateNewsScreen);
