import React from 'react';
import { Card, Grid, Header, Placeholder } from 'semantic-ui-react';
import { addMessage } from '../Actions/messageActions';
import { store } from '../App';
import Client from '../Client';
import download from '../Download';
import './Modules.scss';
import ModuleTypeIcon from './ModuleTypeIcon';

const Loading = () => (
  <Grid stackable className="Modules">
    <Grid.Row columns={3}>
      {[1, 2, 3].map((number) => (
        <Grid.Column key={number} style={{ marginBottom: '2rem' }}>
          <Card className="Modules__card">
            <Card.Content>
              <Placeholder fluid>
                <Placeholder.Header image>
                  <Placeholder.Line />
                </Placeholder.Header>
              </Placeholder>
            </Card.Content>
            <Card.Content>
              <Placeholder>
                <Placeholder.Paragraph>
                  <Placeholder.Line />
                  <Placeholder.Line />
                  <Placeholder.Line />
                  <Placeholder.Line />
                </Placeholder.Paragraph>
              </Placeholder>
            </Card.Content>
            <Card.Content extra>
              <Placeholder>
                <Placeholder.Paragraph>
                  <Placeholder.Line length="medium" />
                </Placeholder.Paragraph>
              </Placeholder>
            </Card.Content>
          </Card>
        </Grid.Column>
      ))}
    </Grid.Row>
  </Grid>
);

export const onDownloadPDF = (fileName) => {
  Client({
    method: 'GET',
    url: `/blueprint/questionnaire/${fileName}/download`,
    responseType: 'blob',
  })
    .then((response) => {
      let fileNameForDownload = fileName;
      try {
        const { request } = response;
        const contentDisposition = request.getResponseHeader('content-disposition');
        /* eslint prefer-destructuring: ["error", {VariableDeclarator: {object: true}}] */
        fileNameForDownload = contentDisposition.split('filename=')[1].split(';')[0];
        fileNameForDownload = fileNameForDownload.replace(/"/gi, '').replace(/\\"/gi, '');
      } catch (e) {
        console.log(e);
      }

      download(response.data, fileNameForDownload);
    })
    .catch(() => {
      store.dispatch(
        addMessage('Die Datei konnte nicht heruntergeladen werden. Bitte versuchen Sie es erneut.', false, 'error')
      );
    });
};

const Modules = ({ options, value, onChange, onBlur }) =>
  options.length === 0 ? (
    <Loading />
  ) : (
    <Grid stackable className="Modules">
      <Grid.Row columns={3}>
        {options.map((option, index) => (
          <Grid.Column key={`${option.type}-${index}`} style={{ marginBottom: '2rem' }}>
            <label htmlFor={option.type} data-cy="module_create">
              <input
                className="Modules__input"
                type="radio"
                name="type"
                checked={value === option.type}
                id={option.type}
                value={option.type}
                onChange={onChange}
                onBlur={onBlur}
                data-cy={option.type}
              />
              <Card className="Modules__card" raised={value === option.type}>
                <Card.Content>
                  <Header as="h4">
                    <ModuleTypeIcon type={option.type} name={option.name} />
                    <Header.Content>{option.name}</Header.Content>
                  </Header>
                </Card.Content>
                <Card.Content>{option.description}</Card.Content>
                <Card.Content extra>
                  {option.link && (
                    <span
                      className="Modules__link"
                      role="button"
                      tabIndex={index.toString()}
                      onClick={() => onDownloadPDF(option.link)}
                      onKeyPress={() => onDownloadPDF(option.link)}
                      title={option.link}
                    >
                      Fragebogen
                    </span>
                  )}
                  {!option.link && <span>&nbsp;</span>}
                </Card.Content>
              </Card>
            </label>
          </Grid.Column>
        ))}
      </Grid.Row>
    </Grid>
  );

export default Modules;
