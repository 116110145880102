import React from 'react';
import { Button, Dropdown, Form, Header, Icon, Modal } from 'semantic-ui-react';
import { ErrorMessage, Formik } from 'formik';
import * as Yup from 'yup';
import { withHooks } from '@Classic/Hocs/withHooks';
import { addMessage } from '../Actions/messageActions';
import { store } from '../App';
import Client, { handleRequestError } from '../Client';
import MarkdownTextarea from '../Components/MarkdownTextarea';
import Screen from '../Components/Screen';
import ScreenHeader from '../Components/ScreenHeader';
import InputError from '../Ui/InputError';

const CreateNewsSchema = Yup.object().shape({
  title: Yup.string().required('Bitte geben Sie einen Titel an.'),
  text: Yup.string().required('Bitte geben Sie einen Text an.'),
  organizations: Yup.array().min(1, 'Bitte wählen Sie mindestens eine Organisation aus.'),
});

class CreateNewsScreen extends React.PureComponent {
  constructor(props) {
    super(props);

    this.input = React.createRef();
    this.form = React.createRef();

    this.state = {
      isConfirmationOpen: false,
      fetching: true,
      organizations: [],
    };
  }

  componentDidMount() {
    this.input.current.focus();

    Client.get('/organization/list')
      .then((response) => this.setState({ organizations: response.data, fetching: false }))
      .catch((error) => {
        if (error.networkError) {
          return;
        }
        this.setState({ fetching: false });
      });
  }

  submit = (values, actions) => {
    const { navigate } = this.props;
    const { title, text, organizationIds } = values;

    Client.post('/news/create', {
      title: title,
      text: text,
      organizationIds: organizationIds,
    })
      .then(() => {
        store.dispatch(addMessage('Die Neuigkeit wurde erfolgreich erstellt.'));
        navigate('/news');
      })
      .catch((error) => {
        handleRequestError(error, actions);
      });
  };

  render() {
    const { isConfirmationOpen, fetching, organizations } = this.state;

    return (
      <Screen title="Neuigkeit schreiben | Media Monitor Rapid">
        <ScreenHeader title="Neuigkeit schreiben" hasBackButton={false} />
        <Formik
          initialValues={{
            title: '',
            text: '',
            organizationIds: [],
          }}
          validationSchema={CreateNewsSchema}
          onSubmit={(values, actions) => this.submit(values, actions)}
          render={({
            errors,
            touched,
            values,
            isSubmitting,
            handleSubmit,
            handleChange,
            handleBlur,
            setFieldValue,
            setFieldTouched,
            isValid,
          }) => (
            <form onSubmit={handleSubmit} ref={this.form} className="ui form">
              <Form.Field fluid error={errors.title && touched.title}>
                <label htmlFor="title">Titel</label>
                <input
                  type="text"
                  name="title"
                  id="title"
                  value={values.title}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  ref={this.input}
                />
                <InputError message={<ErrorMessage name="title" />} />
              </Form.Field>
              <Form.Field fluid error={errors.organizationIds && touched.organizationIds}>
                <label htmlFor="organizationIds">Organisation</label>
                <Dropdown
                  selection
                  multiple
                  loading={fetching}
                  disabled={fetching}
                  name="organizationIds"
                  id="organizationIds"
                  placeholder="Wählen Sie mindestens eine Organisation"
                  value={values.organizationIds}
                  options={organizations.map((organization) => ({
                    key: organization.id,
                    value: organization.id,
                    text: organization.name,
                  }))}
                  onChange={(event, { value }) => setFieldValue('organizationIds', value)}
                  onBlur={() => setFieldTouched('organizationIds', true)}
                />
                <InputError message={<ErrorMessage name="organizationIds" />} />
              </Form.Field>
              <Form.Field fluid>
                <MarkdownTextarea value={values.text} onChange={handleChange} onBlur={handleBlur} />
                <InputError message={<ErrorMessage name="text" />} />
              </Form.Field>
              <Button
                primary
                icon
                className="mt-15"
                type="button"
                labelPosition="right"
                loading={isSubmitting}
                disabled={!isValid}
                onClick={() => this.setState({ isConfirmationOpen: true })}
              >
                <Icon link name="save outline" />
                News veröffentlichen
              </Button>
              <Modal
                size="tiny"
                open={isConfirmationOpen}
                onClose={() => this.setState({ isConfirmationOpen: false })}
                mountNode={this.form.current}
              >
                <Header icon="save outline" content="Neuigkeit veröffentlichen" />
                <Modal.Content>
                  <p>Mit Veröffentlichung der Neuigkeit ist diese für alle gewählten Organisationen sichtbar.</p>
                  <p>Bitte bestätigen Sie, dass Sie die Neuigkeit veröffentlichen möchten.</p>
                </Modal.Content>
                <Modal.Actions>
                  <Button
                    content="Abbrechen"
                    type="button"
                    disabled={isSubmitting}
                    onClick={() => this.setState({ isConfirmationOpen: false })}
                  />
                  <Button
                    primary
                    content="Veröffentlichen"
                    type="submit"
                    loading={isSubmitting}
                    disabled={isSubmitting}
                  />
                </Modal.Actions>
              </Modal>
            </form>
          )}
        />
      </Screen>
    );
  }
}

export default withHooks(CreateNewsScreen);
