import Einfach from './assets/einfach.svg?react';
import Flexibel from './assets/flexibel.svg?react';
import Regional from './assets/regional.svg?react';
// icons
import Schnell from './assets/schnell.svg?react';
import React from 'react';
import { Helmet } from 'react-helmet';
import { InView } from 'react-intersection-observer';
import { createBrowserHistory } from 'history';
import Login from '@Organism/Login';
import ASLogo from './assets/axel-springer_logo.png';
import BDVZLogo from './assets/bdzv_logo.svg';
import erfolgsKontrolle from './assets/erfolgskontrolle.png';
import FMLogo from './assets/funke-mediengruppe-logo.jpg';
import GUVLogo from './assets/gruppe-unabhaengiger-verlage_logo.png';
import ChevronDown from './assets/icon_chevron-down.svg';
import studienUebersichtShare from './assets/media_monitor_rapid_studienuebersicht.png';
// logos
import RapidLogo from './assets/mm-rapid_logo.svg';
import motiveTest from './assets/motivtest.png';
import SMGLogo from './assets/score-media-group_logo.png';
// screenshots
import studienUebersicht from './assets/studienuebersicht.png';
import zmg from './assets/zmg_logo.svg';
import createHistoryHashObserver from './Components/createHashHistoryObserver';
import './Pages.scss';

export const history = createBrowserHistory();
createHistoryHashObserver(history);

class LandingPage extends React.Component {
  state = {
    showMobileNav: false,
  };

  componentDidMount() {}

  setShowMobileNav(value) {
    this.setState({
      showMobileNav: value,
    });
  }

  toggleMobileNav = () => {
    const { showMobileNav } = this.state;
    this.setShowMobileNav(!showMobileNav);
  };

  closeMobileNav = () => {
    this.setShowMobileNav(false);
  };

  toggleClass = (element, className, inView) => {
    if (inView) {
      element.classList.add(className);
    }
  };

  introInView = (inView, entry) => {
    this.toggleClass(entry.target, 'animate', inView);
  };

  partnerInView = (inView, entry) => {
    this.toggleClass(entry.target, 'animate', inView);
  };

  mehrwertInView = (inView, entry) => {
    this.toggleClass(entry.target, 'animate', inView);
  };

  staerkenInView = (inView, entry) => {
    this.toggleClass(entry.target, 'animate', inView);
    const svgs = entry.target.querySelectorAll('svg');
    if (inView) {
      svgs.forEach((element) => element.classList.add('animate-draw'));
    }
  };

  konzeptInView = (inView, entry) => {
    this.toggleClass(entry.target, 'animate', inView);
  };

  einblickInView = (inView, entry) => {
    this.toggleClass(entry.target, 'animate', inView);
    const cards = entry.target.querySelectorAll('.card');
    if (inView) {
      cards.forEach((element) => element.classList.add('animate-show'));
    } else {
      cards.forEach((element) => {
        element.classList.remove('animate-draw');
      });
    }
  };

  render() {
    return (
      <React.Fragment>
        <div id="landingpage">
          <Helmet
            title="Media Monitor Rapid"
            meta={[
              { property: 'og:site_name', content: 'Media Monitor Rapid' },
              { property: 'og:type', content: 'website' },
              { property: 'og:url', content: 'https://www.mm-rapid.de/' },
              {
                property: 'og:description',
                content:
                  'Hochwertige Marktforschung - schnell und einfach - mit dem Media Monitor Rapid. Beauftragen' +
                  ' Sie Ihre Befragung in Minuten und bewerten Sie Ihren Kampagnenerfolg aussagekräftig.',
              },
              { property: 'og:image', content: `https://www.mm-rapid.de${studienUebersichtShare}` },

              { name: 'viewport', content: 'width=device-width, maximum-scale=1' },
            ]}
          />
          <InView as="div" className="ui grid" onChange={this.navigationInView}>
            <div className="computer only row">
              <div className="column">
                <nav className="ui borderless stackable menu navbar">
                  <a className="item nav-link scroll-link" href="#intro">
                    <img className="logo" src={RapidLogo} alt="Media Monitor rapid Logo" />
                  </a>
                  <div className="right item">
                    <a className="item nav-link scroll-link" href="#mehrwert">
                      Mehrwert
                    </a>
                    <a className="item nav-link scroll-link" href="#staerken">
                      Stärken
                    </a>
                    <a className="item nav-link scroll-link" href="#konzept">
                      Konzept
                    </a>
                    <a className="item nav-link scroll-link" href="#einblick">
                      Einblick
                    </a>
                    <a className="item nav-link scroll-link" href="/login" data-cy="nav_btn_login">
                      <button type="button" className="scroll-link ui button btn btn-primary">
                        Login
                      </button>
                    </a>
                  </div>
                </nav>
              </div>
            </div>
            <div className="tablet mobile only row">
              <div className="column">
                <nav className="ui borderless stackable vertical menu navbar">
                  <a className="item nav-link scroll-link nav-link-logo" href="#intro" onClick={this.closeMobileNav}>
                    <img className="logo" src={RapidLogo} alt="Media Monitor rapid Logo" />
                  </a>
                  <div
                    className="item column centered-nav-items"
                    style={{ display: this.showMobileNav ? 'block' : 'none' }}
                  >
                    <a className="item nav-link scroll-link" href="#mehrwert" onClick={this.toggleMobileNav}>
                      Mehrwert
                    </a>
                    <a className="item nav-link scroll-link" href="#staerken" onClick={this.toggleMobileNav}>
                      Stärken
                    </a>
                    <a className="item nav-link scroll-link" href="#konzept" onClick={this.toggleMobileNav}>
                      Konzept
                    </a>
                    <a className="item nav-link scroll-link" href="#einblick" onClick={this.toggleMobileNav}>
                      Einblick
                    </a>
                    <a className="item nav-link scroll-link" href="/login" data-cy="nav_btn_login">
                      <button type="button" className="scroll-link ui button btn btn-primary">
                        Login
                      </button>
                    </a>
                  </div>
                  <button
                    className="tablet mobile only floated right navbar-toggler"
                    type="button"
                    onClick={this.toggleMobileNav}
                  >
                    <span className="navbar-toggler-icon" />
                  </button>
                </nav>
              </div>
            </div>
          </InView>

          <main className="main">
            <InView as="section" id="intro" name="intro" className="section bg-light" onChange={this.introInView}>
              <div className="ui two column stackable grid container">
                <div className="row">
                  <div className="column">
                    <h1 data-cy="headline_landingpage">
                      Willkommen beim Media Monitor <span>rapid</span>
                    </h1>
                    <p>
                      Hochwertige Marktforschung – schnell und einfach – auf einer Plattform für Sie zugänglich gemacht.
                      <br />
                      <br />
                      Bewerten Sie Ihren Kampagnenerfolg aussagekräftig!
                    </p>
                    <a href="#mehrwert" className="ui button btn btn-mehrwert">
                      <img src={ChevronDown} aria-hidden="true" alt="" />
                      Mehr Erfahren
                    </a>
                  </div>
                  <div className="right floated seven wide tablet six wide computer column">
                    <Login />
                  </div>
                </div>
              </div>
            </InView>

            <InView
              as="section"
              id="partner"
              name="partner"
              className="section"
              onChange={this.partnerInView}
              rootMargin="-200px"
            >
              <div className="ui container">
                <h5 className="ui left aligned header">Unsere Partner:</h5>
                <div className="logo-list d-flex align-items-center">
                  <img className="item img-fluid item-2" src={ASLogo} alt="Axel Springer Logo" />
                  <img
                    className="item img-fluid item-3"
                    src={BDVZLogo}
                    alt="Bundesverband Digitalpublisher und Zeitungsverleger Logo"
                  />
                  <img className="item img-fluid item-1" src={FMLogo} alt="Funke Mediengruppe Logo" />
                  <img className="item img-fluid item-4" src={GUVLogo} alt="Gruppe Unabhängiger Verlage Logo" />
                  <img className="item img-fluid item-5" src={SMGLogo} alt="Score Media Group Logo" />
                </div>
              </div>
            </InView>

            <InView
              as="section"
              id="mehrwert"
              name="mehrwert"
              className="section bg-light"
              onChange={this.mehrwertInView}
              rootMargin="-200px"
            >
              <div className="ui grid container">
                <h2 className="ui left aligned header">Mehrwert & USP</h2>
                <div className="row">
                  <div className="fourteen wide tablet twelve wide computer column">
                    <p>
                      Mit Marktforschung ist es wie mit Sonntagsbrötchen - mit jeder Stunde verlieren sie an Wert! Unser
                      Anspruch ist es, den geldwerten Vorteil Ihrer Marketingkampagne schon am nächsten Tag zum
                      Frühstück servieren zu können. Interaktiv und digital!
                    </p>
                    <p>
                      Über den Media Monitor Rapid erstellen und beauftragen Sie Ihre persönliche Werbewirkungsstudie so
                      komfortabel wie eine Pauschalreise, auch ohne „vom Fach“ zu sein. Gewohnte ZMG
                      Marktforschungsqualität inklusive.
                    </p>
                  </div>
                </div>
              </div>
            </InView>

            <InView
              as="section"
              id="staerken"
              name="staerken"
              className="section"
              onChange={this.staerkenInView}
              rootMargin="-250px"
            >
              <div className="ui grid container">
                <h2 className="ui left aligned header">Zentrale Stärken</h2>
                <div className="ui four doubling cards">
                  <div className="card">
                    <div className="img-con">
                      <Schnell />
                    </div>
                    <h3 className="card-title">Schnell</h3>
                    <p className="card-text">
                      Erhalten Sie Ihr interaktives Online-Reporting bereits ein Tag nach Feldende.
                    </p>
                  </div>
                  <div className="card">
                    <div className="img-con">
                      <Regional />
                    </div>
                    <h3 className="card-title">Regional</h3>
                    <p className="card-text">Erreichen Sie auch regional spitze Zielgruppen.</p>
                  </div>
                  <div className="card">
                    <div className="img-con">
                      <Einfach />
                    </div>
                    <h3 className="card-title">Einfach</h3>
                    <p className="card-text">
                      Automatisierte Prozesse, damit Sie sich auf das Wesentliche{' '}
                      <span className="text-nowrap">– Ihre Kampagne –</span> konzentrieren können.
                    </p>
                  </div>
                  <div className="card">
                    <div className="img-con">
                      <Flexibel />
                    </div>
                    <h3 className="card-title">Flexibel</h3>
                    <p className="card-text">Konfigurieren Sie Ihre individuelle Studie mit nur wenigen Klicks.</p>
                  </div>
                </div>
              </div>
            </InView>

            <InView
              as="section"
              id="konzept"
              name="konzept"
              className="section bg-light"
              onChange={this.konzeptInView}
              rootMargin="-200px"
            >
              <div className="ui grid container">
                <div className="row">
                  <div className="right floated fourteen wide tablet twelve wide computer column">
                    <h2 className="ui left aligned header">Das Konzept</h2>
                    <p>
                      Warum nicht Marktforschung wie einen Online-Shop denken? Erstellen, individualisieren und
                      bestellen Sie Ihr Produkt, wie Sie es im Web gewohnt sind.
                    </p>
                    <p>
                      Viele verschiedene Möglichkeiten stehen zur Individualisierung Ihrer Studie zur Verfügung: Die
                      Befragung regional einschränken? Einfach eine PLZ-Liste hochladen. Nur Leser der Ausgabe vom
                      01.06.2020 befragen? Zwei Klicks genügen. Durch automatisierte Prozesse werden die Vorlaufzeiten
                      dabei minimal gehalten.
                    </p>
                    <p>
                      Die Ergebnisse werden in jeder gewünschten Veredelungsstufe auf Ihre Bedürfnisse angepasst:
                      Rohdaten, ein übersichtlicher Tabellenband, ein interaktives Online-Reporting oder die klassische
                      PowerPoint-Präsentation stehen zur Auswahl.
                    </p>
                  </div>
                </div>
              </div>
            </InView>

            <InView
              as="section"
              id="einblick"
              name="einblick"
              className="section"
              onChange={this.einblickInView}
              rootMargin="-250px"
            >
              <div className="ui grid container">
                <h2 className="ui left aligned header">Einblick</h2>
                <div className="ui three stackable cards">
                  <div className="card">
                    <div className="image">
                      <img src={studienUebersicht} alt="Screenshot Studienübersicht" />
                    </div>
                    <h3 className="card-title">Studienübersicht</h3>
                    <p className="card-text">
                      In Ihrem exklusiven Bereich haben Sie jederzeit den aktuellen Status Ihrer Studien im Blick.
                    </p>
                  </div>
                  <div className="card">
                    <div className="image">
                      <img className="card-img-top p-2" src={erfolgsKontrolle} alt="Screenshot Erfolgskontrolle" />
                    </div>
                    <h3 className="card-title">Einfache Erfolgskontrolle</h3>
                    <p className="card-text">Messen Sie Kunden- und Wettbewerber-KPIs zur Werbeerfolgskontrolle.</p>
                  </div>
                  <div className="card">
                    <div className="image">
                      <img className="card-img-top p-2" src={motiveTest} alt="Screenshot Motivtest" />
                    </div>
                    <h3 className="card-title">Motivtest</h3>
                    <p className="card-text">
                      Bewerten Sie bis zu drei Anzeigenmotive in den Dimensionen Klarheit, Visuelle Exzellenz,
                      Markenfit, Aktivierung und Verständlichkeit.
                    </p>
                  </div>
                </div>
              </div>
            </InView>
          </main>

          <InView as="footer" id="footer" className="section bg-light" onChange={this.footerInView}>
            <div className="ui container">
              <div className="doubling four column ui grid container">
                <div className="three wide tablet three wide computer column">
                  <h3 className="ui left aligned header">Startseite</h3>
                  <nav className="ui vertical text menu">
                    <a className="item nav-link scroll-link" href="#mehrwert">
                      Mehrwert
                    </a>
                    <a className="item nav-link scroll-link" href="#staerken">
                      Stärken
                    </a>
                    <a className="item nav-link scroll-link" href="#konzept">
                      Konzept
                    </a>
                    <a className="item nav-link scroll-link" href="#einblick">
                      Einblick
                    </a>
                    <a className="item nav-link scroll-link" href="/login">
                      Login
                    </a>
                  </nav>
                </div>
                <div className="three wide tablet three wide computer column">
                  <h3>Sonstiges</h3>
                  <nav className="ui vertical text menu">
                    <a className="item nav-link" href="/recovery">
                      Passwort vergessen
                    </a>
                    <a
                      className="item nav-link"
                      href="https://www.zmg.de/datenschutz/"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      Datenschutz
                    </a>
                    <a
                      className="item nav-link"
                      href="https://www.zmg.de/impressum/"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      Impressum
                    </a>
                  </nav>
                </div>
                <div className="five wide tablet five wide computer column">
                  <h3>Kontakt</h3>
                  <p>
                    Zeitungsmarktforschung Gesellschaft
                    <br />
                    der deutschen Zeitungen (ZMG) mbH
                    <br />
                    <br />T +49 69 973822-0
                    <br />
                    <a href="mailto:sek@zmg.de">sek(at)zmg.de</a>
                  </p>
                </div>
                <div className="lfc right aligned four wide mobile two wide tablet three wide computer column">
                  <img
                    className="img-fluid logo-footer"
                    src={zmg}
                    alt="Zeitungsmarktforschung Gesellschaft der deutschen Zeitungen (ZMG) Logo"
                  />
                  <p className={'mt-15'}>Copyright {new Date().getFullYear()}</p>
                </div>
              </div>
            </div>
          </InView>
        </div>
      </React.Fragment>
    );
  }
}

export default LandingPage;
