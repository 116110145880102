import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface MessageInterface {
  type: string;
  text: string;
  closeable: boolean;
  id: string;
  visible: boolean;
}
interface MessageStoreState {
  data?: MessageInterface;
  addMessage: (msg: MessageInterface) => void;
  clear: () => void;
}

export const useMessageStore = create<MessageStoreState>()(
  persist(
    (set) => ({
      data: undefined,
      addMessage: async (msg: MessageInterface) => {
        set(() => {
          return { data: msg };
        });
      },
      clear: async () => {
        set((state) => {
          return {
            ...state,
            data: undefined,
          };
        });
      },
    }),
    {
      name: 'message-storage', // name of the item in the storage (must be unique)
    }
  )
);
