import React from 'react';
import { Header, Placeholder, Segment } from 'semantic-ui-react';
import { hasRole, ROLE_ADMIN, ROLE_SERVICE_PROVIDER_PANEL, ROLE_SERVICE_PROVIDER_QUESTIONNAIRE } from '../Permissions';
import Date from './Order/Date';
import Status from './Status';

const additional =
  hasRole(ROLE_ADMIN) || hasRole(ROLE_SERVICE_PROVIDER_PANEL) || hasRole(ROLE_SERVICE_PROVIDER_QUESTIONNAIRE);

const renderDate = (wave, start, end, dateSingle) =>
  wave.study.isMediaOpal ? (
    <Date date={dateSingle} />
  ) : (
    <React.Fragment>
      <Date date={start} /> - <Date date={end} />
    </React.Fragment>
  );

const renderTitle = (wave, start, end, dateSingle) =>
  start === null && end === null && dateSingle === null ? (
    <Placeholder style={{ marginBottom: '10px' }}>
      <Placeholder.Header>
        <Placeholder.Line />
      </Placeholder.Header>
    </Placeholder>
  ) : (
    <Header.Content style={{ width: '100%' }} data-cy="wave-title">
      {wave.study.isMediaOpal ? wave.study.name : wave.name}
      &nbsp;vom {renderDate(wave, start, end, dateSingle)}
      &nbsp; (#{wave.study.studyNumber})
      {additional && (
        <React.Fragment>
          <div className="text-muted" style={{ fontSize: '80%', fontWeight: 'normal' }}>
            ID: {wave.id}
          </div>
          <div
            style={{
              borderBottom: '1px dotted lightgrey',
              width: '100%',
              height: '1px',
              margin: '5px 0',
            }}
          />
        </React.Fragment>
      )}
    </Header.Content>
  );

const WaveHeader = ({ wave, start, end, dateSingle }) => (
  <Segment attached="top">
    <Header>
      <div className="d-flex justify-content-space-between">
        <div className="flex-grow">
          {renderTitle(wave, start, end, dateSingle)}
          <Header.Subheader style={additional ? { color: 'rgba(0, 0, 0, .87)' } : {}}>
            {wave.study.name} ({wave.study.type.name})
          </Header.Subheader>
          {additional && <Header.Subheader className="mt-5">Organisation: {wave.organization.name}</Header.Subheader>}
        </div>
        <div>
          <Status state={wave.state} />
        </div>
      </div>
    </Header>
  </Segment>
);

export default WaveHeader;
