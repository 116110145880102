import { Organization } from '@ApiGenerated/models/Organization.ts';
import { UuidInterface } from '@ApiGenerated/models/UuidInterface.ts';

export enum ROLE {
  ADMIN = 'ROLE_ADMIN',
}

export type UserModelInterface = {
  id: UuidInterface;
  email: string;
  authToken: string;
  firstName: string;
  lastName: string;
  organization: Organization;
  role: string;
  permissions: string[];
} | null;

export default class UserModel {
  constructor(
    public id: UuidInterface,
    public email: string,
    public authToken: string,
    public firstName: string,
    public lastName: string,
    public organization: Organization,
    public role: string,
    public permissions: string[]
  ) {
    this.id = id;
    this.email = email;
    this.authToken = authToken;
    this.firstName = firstName;
    this.lastName = lastName;
    this.organization = organization;
    this.role = role;
    this.permissions = permissions;
  }

  public static hydrateFromApi(userResponse: any): UserModel {
    return new UserModel(
      userResponse.id,
      userResponse.email,
      userResponse.token,
      userResponse.firstName,
      userResponse.lastName,
      userResponse.organization,
      userResponse.role,
      userResponse.permissions
    );
  }
}
