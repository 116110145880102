import React from 'react';
import { DateRangePicker as AirbnbPicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';
import './DateRangePicker.scss';

const isDaySelectable = (day, startDate, focusedInput) => {
  if (focusedInput === 'endDate') {
    return day.isBefore(startDate, 'days');
  }

  return day.isBefore(moment(), 'days');
};

class DateRangePicker extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      focusedInput: null,
    };
  }

  render() {
    const { focusedInput } = this.state;
    const { startDate, endDate, onChange, onBlur } = this.props;

    return (
      <AirbnbPicker
        noBorder
        hideKeyboardShortcutsPanel
        displayFormat="DD.MM.YYYY"
        startDatePlaceholderText="Von"
        endDatePlaceholderText="Bis"
        startDate={startDate}
        endDate={endDate}
        startDateId="startDate"
        endDateId="endDate"
        onDatesChange={({ startDate, endDate }) => onChange({ startDate, endDate })}
        focusedInput={focusedInput}
        onFocusChange={(focusedInput) => this.setState({ focusedInput })}
        firstDayOfWeek={1}
        minimumNights={0}
        onClose={onBlur}
        isOutsideRange={(day) => isDaySelectable(day, startDate, focusedInput)}
      />
    );
  }
}

export default DateRangePicker;
