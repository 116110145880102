import React from 'react';
import { Form, Icon, Message } from 'semantic-ui-react';
import FieldList from './FieldList';

class CustomQuestionsForm extends React.Component {
  renderInfoboxItems() {
    const { module } = this.props;

    return module.messages.map((message, idx) => {
      const key = `message-${idx}`;

      return <Message.Item key={key}>{message}</Message.Item>;
    });
  }

  renderInfobox() {
    const { module } = this.props;

    return (
      <Message>
        {module.infobox.headline && (
          <Message.Header style={{ fontSize: '1rem' }}>
            <Icon name={module.infobox.headlineIcon} /> {module.infobox.headline}
          </Message.Header>
        )}
        {module.messages.length > 1 && <Message.List>{this.renderInfoboxItems()}</Message.List>}
        {module.messages.length === 1 && module.messages[0]}
      </Message>
    );
  }

  render() {
    const { module, values, onChange } = this.props;
    const { key } = module;

    return (
      <form className="ui form">
        {module.messages.length > 0 && this.renderInfobox()}
        <Form.Field>
          <div className="as-label">Eigene Fragen</div>
          <FieldList
            name="questions"
            addButtonText="Eigene Frage hinzufügen"
            limit={module.questions.maxCount}
            values={values.questions}
            dataCyName="custom-question"
            onChange={(values) => onChange(key, { questions: values })}
          />
        </Form.Field>
      </form>
    );
  }
}

export default CustomQuestionsForm;
