import React from 'react';
import { Segment } from 'semantic-ui-react';

const Tab = ({ children, visible, ...props }) => {
  if (!visible) {
    return '';
  }

  return (
    <Segment
      padded
      attached="bottom"
      className={`mb-0 ${visible === true ? '' : 'd-none'} `}
      style={{
        width: 'calc(100% + 2px)',
        height: '100%',
        marginLeft: '-1px',
        borderTop: 'none',
        borderBottomLeftRadius: 0,
      }}
      {...props}
    >
      {children}
    </Segment>
  );
};

export default Tab;
