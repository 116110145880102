import React, { Fragment } from 'react';
import { Accordion, Checkbox, Icon, Label } from 'semantic-ui-react';
import FieldList from '../Forms/FieldList';

class CheckboxAccordion extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      active: props.active ?? false,
    };
  }

  toggleActive = () => {
    this.setState((prevState) => ({ active: !prevState.active }));
  };

  onChangeValue = (dimensionKey, values, value, onChange) => {
    if (values.includes(value) === true) {
      const newValues = [...values];

      newValues.splice(values.indexOf(value), 1);
      onChange(dimensionKey, newValues);

      return;
    }

    onChange(dimensionKey, [...values, value]);
  };

  renderContent = (
    dimensionKey,
    dimensionItems,
    values,
    checked,
    notChangeable,
    onChange,
    maxCustomItems,
    dataCyName
  ) => {
    const { dimension, active = false } = this.props;

    if (dimensionKey.startsWith('custom_items') || dimensionKey === 'additional_items') {
      return (
        <FieldList
          name={dimensionKey}
          addButtonText={dimension.placeholder}
          limit={maxCustomItems}
          values={values[dimensionKey]}
          dataCyName={dataCyName}
          onChange={(values) => onChange(dimensionKey, values)}
          active={active}
        />
      );
    }

    return dimensionItems.map((option, index) => (
      <Checkbox
        className="DimensionsForm__checkbox"
        key={option}
        label={option}
        name={dimensionKey}
        value={option}
        disabled={notChangeable.includes(option)}
        checked={values[dimensionKey].includes(option)}
        data-cy={`checkbox-${dataCyName}-${index}`}
        onChange={() => this.onChangeValue(dimensionKey, values[dimensionKey], option, onChange)}
      />
    ));
  };

  render() {
    const { active } = this.state;
    const { dimension, values, onChange, maxCustomItems, dataCyName } = this.props;
    const { key, items, checked, notChangeable, name } = dimension;

    return (
      <Fragment>
        <Accordion.Title
          className="DimensionsForm__header d-flex align-items-center justify-content-space-between"
          active={active}
          data-cy={`button-${dataCyName}`}
          onClick={this.toggleActive}
        >
          <h4 className="DimensionsForm__title mb-0">
            <Icon name="chevron right" /> {name}
          </h4>
          <Label horizontal circular basic={values[key].length === 0} content={values[key].length} />
        </Accordion.Title>
        <Accordion.Content active={active}>
          {this.renderContent(key, items, values, checked, notChangeable, onChange, maxCustomItems, dataCyName)}
        </Accordion.Content>
      </Fragment>
    );
  }
}

export default CheckboxAccordion;
