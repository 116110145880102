import React from 'react';
import {
  hasOneOfRoles,
  hasRole,
  ROLE_ADMIN,
  ROLE_MARKET_RESEARCHER,
  ROLE_REPORTING_INTERESTED_PERSON,
  ROLE_SERVICE_PROVIDER_PANEL,
  ROLE_SERVICE_PROVIDER_QUESTIONNAIRE,
  ROLE_STUDENT,
} from '../Permissions';
import InfoMessage from '../Ui/InfoMessage';

const FallbackMessage = ({ wave }) => {
  if (hasOneOfRoles([ROLE_REPORTING_INTERESTED_PERSON, ROLE_STUDENT])) {
    return <InfoMessage>Es stehen keine Aktionen zur Verfügung.</InfoMessage>;
  }

  if (hasOneOfRoles([ROLE_SERVICE_PROVIDER_PANEL, ROLE_SERVICE_PROVIDER_QUESTIONNAIRE])) {
    if (wave.state === 'aborted') {
      return <InfoMessage>Der Auftrag wurde abgebrochen. Es stehen keine weiteren Aktionen zur Verfügung.</InfoMessage>;
    }

    if (wave.state === 'completed') {
      return <InfoMessage>Der Auftrag wurde abgeschlossen. Es sind keine weiteren Aktionen vorgesehen.</InfoMessage>;
    }
  }

  if (hasRole(ROLE_ADMIN)) {
    if (wave.state === 'aborted') {
      return <InfoMessage>Der Auftrag wurde abgebrochen. Kontaktieren Sie den zuständigen Markforscher.</InfoMessage>;
    }

    if (wave.state === 'completed') {
      return (
        <InfoMessage>Der Auftrag wurde erfolgreich abgeschlossen. Es sind keine weiteren Aktionen möglich.</InfoMessage>
      );
    }
  }

  if (hasRole(ROLE_MARKET_RESEARCHER)) {
    if (wave.state === 'canceled') {
      return <InfoMessage>Der Auftrag wurde storniert. Es stehen keine weiteren Aktionen zur Verfügung.</InfoMessage>;
    }

    if (wave.state === 'aborted') {
      return <InfoMessage>Der Auftrag wurde abgebrochen. Es stehen keine weiteren Aktionen zur Verfügung.</InfoMessage>;
    }

    if (wave.state === 'approved') {
      return (
        <InfoMessage>
          Der Auftrag wurde freigegeben. Es stehen derzeit keine weiteren Aktionen zur Verfügung.
        </InfoMessage>
      );
    }

    if (wave.state === 'refused') {
      return <InfoMessage>Der Auftrag wurde verweigert. Es stehen keine weiteren Aktionen zur Verfügung.</InfoMessage>;
    }

    if (wave.state === 'in_field') {
      return (
        <InfoMessage>
          Der Auftrag ist bereit fürs Feld / im Feld. Es stehen keine weiteren Aktionen zur Verfügung.
        </InfoMessage>
      );
    }

    if (wave.state === 'completed') {
      return (
        <InfoMessage>Der Auftrag wurde abgeschlossen. Es stehen keine weiteren Aktionen zur Verfügung.</InfoMessage>
      );
    }
  }

  return null;
};

export default FallbackMessage;
