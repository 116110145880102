import React from 'react';
import { Button, Checkbox, Form, Header, Icon, Loader, Message, Modal } from 'semantic-ui-react';
import { ErrorMessage, Formik } from 'formik';
import * as Yup from 'yup';
import { useUserStore } from '@Store/useUsersStore';
import { withHooks } from '@Classic/Hocs/withHooks';
import { addMessage } from '../Actions/messageActions';
import { store } from '../App';
import Client, { handleRequestError } from '../Client';
import Screen from '../Components/Screen';
import ScreenHeader from '../Components/ScreenHeader';
import { getRoleName, hasRole, ROLE_ADMIN } from '../Permissions';
import InputError from '../Ui/InputError';

const UpdateUserSchema = Yup.object().shape({
  firstName: Yup.string().required('Bitte geben Sie einen Vornamen an.'),
  lastName: Yup.string().required('Bitte geben Sie einen Nachnamen an.'),
  email: Yup.string().email('Bitte geben Sie eine gültige E-Mail an.').required('Bitte geben Sie eine E-Mail an.'),
});

class UserScreen extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fetching: true,
      user: null,
      deleting: false,
      isDeleted: false,
      deleteModalOpen: false,
      deleteError: null,
    };
  }

  componentDidMount() {
    const { navigate, params } = this.props;
    const { uuid } = params;

    if (hasRole(ROLE_ADMIN) === false) {
      navigate('/');
      store.dispatch(addMessage('Sie haben keine Berechtigung diese Seite aufzurufen.', false, 'error'));
    }

    Client.get(`/user/${uuid}`)
      .then((response) => this.setState({ user: response.data, fetching: false }))
      .catch((error) => {
        if (error.networkError) {
          return;
        }
        this.setState({ fetching: false });
      });
  }

  openDeleteModal = (event) => {
    event.preventDefault();
    this.setState({
      deleteModalOpen: true,
    });
  };

  closeDeleteModal = () => {
    this.setState({
      deleteModalOpen: false,
    });
  };

  showUserList = () => {
    const { navigate } = this.props;
    navigate('/user');
  };

  deleteUser = (event) => {
    event.preventDefault();
    const { uuid } = this.props.params; // eslint-disable-line react/destructuring-assignment

    this.setState({
      deleting: true,
      deleteError: null,
    });

    Client.delete(`/user/${uuid}`)
      .then(() => {
        this.setState({
          deleting: false,
          isDeleted: true,
        });
      })
      .catch((error) => {
        this.setState({
          deleting: false,
          deleteError: error,
        });
      });
  };

  updateUser(values, actions) {
    // eslint-disable-line class-methods-use-this
    const { uuid } = this.props.params; // eslint-disable-line react/destructuring-assignment

    Client.post(`/user/${uuid}/update`, { id: uuid, ...values })
      .then(() => {
        actions.setSubmitting(false);
        store.dispatch(addMessage('Der Nutzer wurde erfolgreich bearbeitet.'));
      })
      .catch((error) => {
        handleRequestError(error, actions);
      });
  }

  renderDeleteModal = () => {
    const { deleteModalOpen, deleting, user, isDeleted, deleteError } = this.state;

    if (user === null) {
      return '';
    }

    return (
      <Modal
        size="small"
        open={deleteModalOpen}
        onClose={this.closeDeleteModal}
        closeOnEscape={deleting === false && isDeleted === false}
        closeOnDimmerClick={deleting === false && isDeleted === false}
      >
        <Header icon="delete" content="Benutzer löschen" />
        <Modal.Content style={{ height: '30vh' }}>
          {isDeleted && (
            <Message positive data-cy="user_delete_confirmation">
              <p>
                Der Benutzer{' '}
                <strong>
                  {user.firstName} {user.lastName}
                </strong>{' '}
                wurde erfolgreich gelöscht.
              </p>
            </Message>
          )}
          {isDeleted === false && (
            <Message negative>
              <p>
                Sind Sie sicher, dass Sie Benutzer{' '}
                <strong>
                  {user.firstName} {user.lastName}
                </strong>{' '}
                <br />
                der Organisation <strong>{user.organization.name}</strong> löschen möchten?
              </p>
            </Message>
          )}
          {deleteError && (
            <Message negative>
              <p>
                Beim Löschen ist ein Fehler aufgetreten:
                <br />
                <code>
                  Code {deleteError.code}: {deleteError.message}
                </code>
              </p>
            </Message>
          )}
        </Modal.Content>
        <Modal.Actions>
          {isDeleted && (
            <Button
              icon
              loading={deleting}
              disabled={deleting}
              labelPosition="right"
              onClick={this.showUserList}
              data-cy="user_close_delete_modal"
            >
              <Icon link name="cancel" />
              Zurück zur Übersicht
            </Button>
          )}
          {isDeleted === false && (
            <React.Fragment>
              <Button
                icon
                color="red"
                floated="left"
                loading={deleting}
                disabled={deleting}
                labelPosition="right"
                onClick={this.deleteUser}
                data-cy="user_confirm_delete"
              >
                <Icon link name="check" />
                Löschen bestätigen
              </Button>
              <Button icon loading={deleting} disabled={deleting} labelPosition="right" onClick={this.closeDeleteModal}>
                <Icon link name="cancel" />
                Abbrechen
              </Button>
            </React.Fragment>
          )}
        </Modal.Actions>
      </Modal>
    );
  };

  renderForm = () => {
    const { user } = this.state;
    const currentUser = useUserStore.getState().data;

    if (user === null) {
      return <Message negative>Nutzer nicht gefunden.</Message>;
    }

    let deleteBtnDisabled = false;
    if (currentUser && user && currentUser.id === user.id) {
      deleteBtnDisabled = true;
    }

    return (
      <Formik
        initialValues={{
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          active: user.active,
        }}
        validationSchema={UpdateUserSchema}
        onSubmit={(values, actions) => this.updateUser(values, actions)}
        render={(
          { errors, touched, values, isSubmitting, handleSubmit, handleChange, handleBlur, setFieldValue } // eslint-disable-line max-len
        ) => (
          <Form onSubmit={handleSubmit}>
            <Form.Field>
              <Checkbox
                toggle
                label={values.active === true ? 'aktiviert' : 'deaktiviert'}
                checked={values.active}
                onChange={() => setFieldValue('active', !values.active)}
              />
            </Form.Field>
            <Form.Field width={8} error={errors.firstName && touched.firstName}>
              <label htmlFor="firstName">Vorname</label>
              <input
                type="text"
                name="firstName"
                id="firstName"
                value={values.firstName}
                onChange={handleChange}
                onBlur={handleBlur}
                ref={this.input}
              />
              <InputError message={<ErrorMessage name="firstName" />} />
            </Form.Field>
            <Form.Field width={8} error={errors.lastName && touched.lastName}>
              <label htmlFor="lastName">Nachname</label>
              <input
                type="text"
                name="lastName"
                id="lastName"
                value={values.lastName}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <InputError message={<ErrorMessage name="lastName" />} />
            </Form.Field>
            <Form.Field width={8} error={errors.email && touched.email}>
              <label htmlFor="email">E-Mail</label>
              <input
                type="text"
                name="email"
                id="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <InputError message={<ErrorMessage name="email" />} />
            </Form.Field>
            <Form.Field width={8}>
              <label htmlFor="organization">Organisation</label>
              <input
                disabled
                readOnly
                type="text"
                name="organization"
                id="organization"
                value={user.organization.name}
              />
            </Form.Field>
            <Form.Field width={8}>
              <label htmlFor="role">Rolle</label>
              <input disabled readOnly type="text" name="role" id="role" value={getRoleName(user.role)} />
            </Form.Field>
            <Form.Field width={8}>
              <Button
                primary
                icon
                className="mt-15"
                type="submit"
                labelPosition="right"
                loading={isSubmitting}
                disabled={isSubmitting}
              >
                <Icon link name="save outline" />
                Änderungen speichern
              </Button>
              <Button
                icon
                onClick={this.openDeleteModal}
                floated="right"
                positive={false}
                color="red"
                className="mt-15"
                type="submit"
                labelPosition="right"
                loading={false}
                disabled={deleteBtnDisabled}
                data-cy="user_delete"
              >
                <Icon link name="delete" />
                Nutzer löschen
              </Button>
            </Form.Field>
          </Form>
        )}
      />
    );
  };

  render() {
    const { fetching } = this.state;

    return (
      <Screen title="Nutzer bearbeiten | Media Monitor Rapid">
        <ScreenHeader title="Nutzer bearbeiten" hasBackButton={false} />
        {fetching === true ? <Loader active>Nutzer wird geladen ...</Loader> : this.renderForm()}
        {this.renderDeleteModal()}
      </Screen>
    );
  }
}

export default withHooks(UserScreen);
