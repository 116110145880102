import React from 'react';
import { Button, Form, Icon, Message } from 'semantic-ui-react';
import { ErrorMessage, Formik } from 'formik';
import * as Yup from 'yup';
import { withHooks } from '@Classic/Hocs/withHooks';
import Client from '../Client';
import CenteredCard from '../Ui/CenteredCard';
import InputError from '../Ui/InputError';

const RecoverySchema = Yup.object().shape({
  email: Yup.string()
    .email('Bitte geben Sie eine gültige E-Mail Adresse an.')
    .required('Bitte geben Sie Ihre E-Mail Adresse an.')
    .trim(),
});

class RecoveryScreen extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      error: false,
      success: false,
    };

    this.email = React.createRef();
    this.request = this.request.bind(this);
  }

  componentDidMount() {
    this.email.current.focus();
    document.title = 'Password vergessen | Media Monitor Rapid';
  }

  request(values, actions) {
    Client.post('/passwordreset', values)
      .then(() => {
        this.setState({ success: true, error: false });
        actions.setSubmitting(false);
        actions.resetForm();
      })
      .catch(() => {
        this.setState({ error: true, success: false });
        actions.setSubmitting(false);
      });
  }

  render() {
    const { success, error } = this.state;

    return (
      <CenteredCard headline="Passwort zurücksetzen">
        <Formik
          initialValues={{
            email: '',
          }}
          validationSchema={RecoverySchema}
          onSubmit={(values, actions) => this.request(values, actions)}
          render={({ errors, touched, values, isSubmitting, handleSubmit, handleChange, handleBlur }) => (
            <Form onSubmit={handleSubmit}>
              <Form.Field fluid error={errors.email && touched.email}>
                <label htmlFor="email">E-Mail</label>
                <input
                  type="text"
                  name="email"
                  id="email"
                  autoComplete="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  ref={this.email}
                />
                <InputError message={<ErrorMessage name="email" />} />
              </Form.Field>
              {success && (
                <Message positive>
                  <Icon name="check" />
                  Bitte prüfen Sie Ihre E-Mails. Wir haben Ihnen einen Link zum Zurücksetzen Ihres Passwortes
                  zugeschickt.
                </Message>
              )}
              {error && (
                <Message negative>
                  <Icon name="warning sign" />
                  Beim Zurücksetzen Ihres Passwortes ist ein Fehler aufgetreten. Bitte versuchen Sie es noch einmal.
                </Message>
              )}
              <Button fluid secondary type="submit" loading={isSubmitting}>
                Passwort zurücksetzen
              </Button>
            </Form>
          )}
        />
      </CenteredCard>
    );
  }
}

export default withHooks(RecoveryScreen);
