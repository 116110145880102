import * as Sentry from '@sentry/browser';
import axios from 'axios';
import tokenStorage from '@Api/TokenStorage';
import { addMessage } from '@Classic/Actions/messageActions.js';
import { store } from '@Classic/App.jsx';

const Client = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  headers: {
    'Content-Type': 'application/json',
    'X-API-TOKEN': import.meta.env.VITE_API_TOKEN,
  },
});

Client.upload = (path, data) => {
  return Client.post(path, data, { headers: { 'Content-Type': 'multipart/form-data' } });
};

Client.interceptors.request.use(
  (config) => {
    // todo: temporary fix for dependency Cycle with user store
    const authToken = JSON.parse(localStorage.getItem('user-storage'))?.state?.data?.authToken ?? null;

    if (authToken) {
      config.headers['X-AUTH-TOKEN'] = authToken; // eslint-disable-line no-param-reassign
    }

    return config;
  },
  (error) => Promise.reject(error)
);

Client.interceptors.response.use(
  (response) => response,
  (error) => {
    // Network Error means we don't have a response
    if (!!error.isAxiosError && !error.response) {
      Sentry.captureException(error);
      Sentry.captureException({ error: JSON.stringify(error) });
      store.dispatch(
        addMessage(
          `Ein unerwarteter Fehler ist aufgetreten: [${error.message}]. 
			  Bitte aktualisieren Sie die Seite und versuchen Sie es erneut.`,
          false,
          'error'
        )
      );

      const networkError = new Error(error.message);
      networkError.code = error.code ? error.code : 0;
      networkError.networkError = true;
      networkError.previousError = error;
      console.warn('Error: ', error);
      return Promise.reject(networkError);
    }
    // Http 500
    if (!error.response || error.status === 500) {
      Sentry.captureException(error);
      store.dispatch(
        addMessage(
          'Ein unerwarteter Fehler ist aufgetreten. Bitte aktualisieren Sie die Seite und versuchen Sie es erneut.',
          false,
          'error'
        )
      );
    }

    // Unauthenticated
    if (error.response.status === 401) {
      // no redirect or logging out if the request was a login attempt
      if (error.config.url === '/login') {
        return Promise.reject(error.response.data);
      }
      tokenStorage.logout();
      setTimeout(() => {
        document.location.href = '/start';
      }, 1000);
    }

    if (error.response.status === 403) {
      Sentry.captureException(error);
      store.dispatch(addMessage('Sie haben keine Berechtigung diese Seite aufzurufen.', true, 'error'));
      // navigate('/');

      // document.location.href = '/';
      setTimeout(() => {
        document.location.href = '/start';
      }, 1000);
    }

    // Optimistic Lock
    if (error.response.status === 423) {
      store.dispatch(
        addMessage(
          'Die Daten haben sich geändert. Bitte aktualisieren Sie die Seite und versuchen Sie es erneut.',
          false,
          'warning'
        )
      );
    }

    // capture 400 BadRequest if it is not a validation error
    if (error.response.status === 400 && error.response.data?.code !== 450) {
      Sentry.captureException(error);
    }

    return Promise.reject(error.response.data);
  }
);

export const handleRequestError = (error, actions) => {
  Sentry.captureException(error);
  // validation error
  if (error.code === 450 && Array.isArray(error.data)) {
    error.data.forEach((error) => {
      actions.setFieldError(error.property, error.message);
    });
    store.dispatch(
      addMessage(
        'Beim Verarbeiten der Daten ist ein Fehler aufgetreten. Bitte überprüfen Sie Ihre Eingaben.',
        true,
        'warning'
      )
    );
  } else {
    store.dispatch(addMessage(`Es ist ein Server-Fehler aufgetreten: ${error.message}`, true, 'error'));
  }
  actions.setSubmitting(false);
};

export default Client;
