import React, { useEffect } from 'react';
import { Container } from 'semantic-ui-react';
import Header from './Header';
import MessageContainer from './MessageContainer';
import './Screen.scss';

const Screen = ({ title, children }) => {
  useEffect(() => {
    document.title = title;
  }, []);

  return (
    <main className="Screen">
      <Header />
      <Container className="Screen__content">
        <MessageContainer />
        {children}
      </Container>
    </main>
  );
};

Screen.defaultProps = {
  title: 'Media Monitor Rapid',
};

export default Screen;
