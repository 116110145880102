import React from 'react';
import * as Yup from 'yup';
import { withHooks } from '@Classic/Hocs/withHooks';
import { addMessage } from '../Actions/messageActions';
import { store } from '../App';
import Client, { handleRequestError } from '../Client';
import Screen from '../Components/Screen';
import ScreenHeader from '../Components/ScreenHeader';
import OrganizationForm from '../Forms/OrganizationForm';
import { hasRole, ROLE_ADMIN } from '../Permissions';

const CreateOrganizationSchema = Yup.object().shape({
  name: Yup.string().required('Bitte geben Sie einen Namen an.'),
  blueprints: Yup.array().required('Bitte wählen Sie die Studientypen aus.'),
});

class CreateOrganizationScreen extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      blueprints: [],
    };

    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    if (hasRole(ROLE_ADMIN) === false) {
      this.props.navigate('/');
      store.dispatch(addMessage('Sie haben keine Berechtigung diese Seite aufzurufen.', false, 'error'));
    }

    Client.get('/blueprint/list', { params: { type: 'ROOT' } })
      .then((response) => {
        this.setState({
          loading: false,
          blueprints: response.data,
        });
      })
      .catch(() => {
        this.setState({
          loading: false,
        });
      });
  }

  submit(values, actions) {
    // eslint-disable-line class-methods-use-this
    Client.post('/organization/create', values)
      .then((response) => {
        actions.setSubmitting(false);
        actions.resetForm();
        store.dispatch(addMessage('Die Organisation wurde erfolgreich angelegt.'));

        this.props.navigate(`/organization/${response.data.id}/update`);
      })
      .catch((error) => {
        handleRequestError(error, actions);
      });
  }

  render() {
    const { loading, blueprints } = this.state;
    return (
      <Screen title="Neue Organisation | Media Monitor Rapid">
        <ScreenHeader title="Neue Organisation" hasBackButton={false} />
        <OrganizationForm
          loading={loading}
          blueprints={blueprints}
          validationSchema={CreateOrganizationSchema}
          onSubmit={this.submit}
          submitLabel="Organisation anlegen"
          initialValues={{
            name: '',
            customerId: '',
            customerExportLabel: '',
            blueprints: [],
          }}
        />
      </Screen>
    );
  }
}

export default withHooks(CreateOrganizationScreen);
