import React from 'react';
import { Icon } from 'semantic-ui-react';

const InfoMessage = ({ children }) => (
  <span style={{ marginBottom: '5px', alignItems: 'baseline' }} className="d-flex text-muted" data-cy="info-message">
    <Icon name="info circle" />
    <span>{children}</span>
  </span>
);

export default InfoMessage;
