import React from 'react';
import {
  findModuleByKey,
  findValuesByKey,
  hasModule,
  Module,
  MODULE_ACTIVATION_DIMENSIONS,
  MODULE_ACTIVATION_TOGGLE,
} from '../../Modules';
import InfoMessage from '../../Ui/InfoMessage';
import ValidationError from '../../Ui/ValidationError';
import DimensionList from './DimensionList';
import Section from './Section';

const Activation = ({ configuration, values, errors, wave, deepLink }) => {
  const moduleName = Module.getComponentName(MODULE_ACTIVATION_DIMENSIONS, configuration.modules, wave.study.type.type);

  if (hasModule(MODULE_ACTIVATION_TOGGLE, configuration.modules)) {
    if (findValuesByKey(MODULE_ACTIVATION_TOGGLE, values).values.on === false) {
      return (
        <Section headline={moduleName} deepLink={deepLink}>
          <InfoMessage>Wird nicht abgefragt.</InfoMessage>
        </Section>
      );
    }
  }

  return (
    <Section headline={moduleName} deepLink={deepLink}>
      <DimensionList
        module={findModuleByKey(MODULE_ACTIVATION_DIMENSIONS, configuration.modules)}
        values={findValuesByKey(MODULE_ACTIVATION_DIMENSIONS, values)}
        errors={errors}
      />
      <ValidationError errors={errors} property="dimensions" />
    </Section>
  );
};

export default Activation;
