const download = (file, name) => {
  // https://gist.github.com/javilobo8/097c30a233786be52070986d8cdb1743
  const url = window.URL.createObjectURL(file);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', name);
  link.click();
  link.remove();
  window.URL.revokeObjectURL(url);
};

export default download;
