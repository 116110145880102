import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Button, Dropdown, Icon, Message } from 'semantic-ui-react';
import { withHooks } from '@Classic/Hocs/withHooks';
import Client from '../Client';
import NewsList from '../Components/NewsList';
import Screen from '../Components/Screen';
import ScreenHeader from '../Components/ScreenHeader';
import {
  hasOneOfRoles,
  ROLE_ADMIN,
  ROLE_SERVICE_PROVIDER_PANEL,
  ROLE_SERVICE_PROVIDER_QUESTIONNAIRE,
  ROLE_STUDENT,
} from '../Permissions';

class NewsListScreen extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      news: [],
      filteredNews: [],
      organizations: [],
      selectedOrganizationIds: [],
    };

    this.removeFromList = this.removeFromList.bind(this);
  }

  componentDidMount() {
    if (hasOneOfRoles([ROLE_ADMIN, ROLE_SERVICE_PROVIDER_QUESTIONNAIRE, ROLE_SERVICE_PROVIDER_PANEL, ROLE_STUDENT])) {
      this.getNewsAndOrganizations();
      return;
    }

    this.getNews();
  }

  getNews = () => {
    Promise.all([Client.get('/news')])
      .then((response) => {
        const [news] = response;
        this.setState({
          news: news.data,
          filteredNews: news.data,
          loading: false,
        });
      })
      .catch((error) => {
        if (error.networkError) {
          return;
        }
        this.setState({ loading: false });
      });
  };

  getNewsAndOrganizations = () => {
    Promise.all([Client.get('/news'), Client.get('/organization/list')])
      .then((response) => {
        const [news, organizations] = response;
        this.setState({
          news: news.data,
          filteredNews: news.data,
          organizations: organizations.data,
          loading: false,
        });
      })
      .catch((error) => {
        if (error.networkError) {
          return;
        }
        this.setState({ loading: false });
      });
  };

  filterNewsByOrganizations = (organizationIds) => {
    this.setState((prevState) => ({
      selectedOrganizationIds: organizationIds,
      filteredNews: prevState.news.filter((newsEntry) => {
        let count = 0;

        newsEntry.organizations.forEach((organisation) => {
          if (organizationIds.includes(organisation.id)) {
            count += 1;
          }
        });

        return count === organizationIds.length;
      }),
    }));
  };

  removeFromList = (newsEntryId) => {
    this.setState((prevState) => ({
      news: prevState.news.filter((newsEntry) => newsEntry.id !== newsEntryId),
      filteredNews: prevState.filteredNews.filter((newsEntry) => newsEntry.id !== newsEntryId),
    }));
  };

  renderNews = () => {
    const { loading, news, filteredNews, organizations, selectedOrganizationIds } = this.state;

    if (loading === false && news.length === 0) {
      return (
        <Message
          info
          icon="info"
          header="Keine Neuigkeiten"
          content={
            <Fragment>
              <span>Scheint so als gäbe es hier noch nichts zu sehen.</span>
              <br />
              <span>Sobald Neuigkeiten erscheinen, können Sie diese hier einsehen.</span>
            </Fragment>
          }
        />
      );
    }

    return (
      <Fragment>
        {hasOneOfRoles([ROLE_ADMIN, ROLE_STUDENT]) && (
          <Dropdown
            selection
            fluid
            multiple
            disabled={loading}
            loading={loading}
            name="organization"
            id="organization"
            data-doc="app_news_filter_by_organization"
            data-cy="news_filter_by_organization"
            placeholder="Filtern Sie nach Organisationen ..."
            value={selectedOrganizationIds}
            onChange={(e, { value }) => this.filterNewsByOrganizations(value)}
            options={organizations.map((organization) => ({
              key: organization.id,
              value: organization.id,
              text: organization.name,
            }))}
          />
        )}
        {loading === false && filteredNews.length === 0 && (
          <Message
            info
            icon="info"
            header="Keine Ergebnisse"
            content={
              <Fragment>
                <span>Für die ausgewählten Organisationen sind keine Neuigkeiten vorhanden.</span>
                <br />
                <span>Passen Sie die Filter an, um Neuigkeiten zu sehen</span>
              </Fragment>
            }
          />
        )}
        <NewsList news={filteredNews} loading={loading} removeFromList={this.removeFromList} />
      </Fragment>
    );
  };

  render() {
    return (
      <Screen title="Neuigkeiten | Media Monitor Rapid">
        <ScreenHeader className="justify-content-space-between" hasBackButton={false} title="Neuigkeiten">
          {hasOneOfRoles([ROLE_ADMIN, ROLE_STUDENT]) && (
            <Button
              primary
              icon
              as={Link}
              to="/news/create"
              labelPosition="right"
              className="mr-0"
              data-doc="app_news_create_news_post"
              data-cy="news_create_news_post"
            >
              <Icon link name="plus" className="mr-0" /> Neuigkeit schreiben
            </Button>
          )}
        </ScreenHeader>
        {this.renderNews()}
      </Screen>
    );
  }
}

export default withHooks(NewsListScreen);
