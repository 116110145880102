import React from 'react';
import { Checkbox, Form } from 'semantic-ui-react';
import { Module, MODULE_REPORTING_OPTIONS } from '../Modules';

const remove = (values, value) => {
  const newValues = [...values];
  newValues.splice(values.indexOf(value), 1);

  return newValues;
};

class ReportingOptionsForm extends React.Component {
  onChangeValue = (values, value) => {
    const { module, onChange } = this.props;
    const { key } = module;

    // remove one of exclusive tabellenband
    if (
      value === 'Tabellenband inkl. Indizes (1-2 Tage nach Feldende)' &&
      values.options.includes('Tabellenband (1-2 Tage nach Feldende)')
    ) {
      // eslint-disable-next-line no-param-reassign
      values.options = remove(values.options, 'Tabellenband (1-2 Tage nach Feldende)');
    }
    if (
      value === 'Tabellenband (1-2 Tage nach Feldende)' &&
      values.options.includes('Tabellenband inkl. Indizes (1-2 Tage nach Feldende)')
    ) {
      // eslint-disable-next-line no-param-reassign
      values.options = remove(values.options, 'Tabellenband inkl. Indizes (1-2 Tage nach Feldende)');
    }

    if (
      value === 'Tabellenband (1-2 Tage nach Feldende)' &&
      values.options.includes(value) &&
      values.options.includes('Interaktives Reporting (1-2 Tage nach Feldende)')
    ) {
      let options;
      options = remove(values.options, 'Interaktives Reporting (1-2 Tage nach Feldende)');
      options = remove(options, value);
      onChange(key, { options: options });

      return;
    }

    if (
      value === 'Tabellenband inkl. Indizes (1-2 Tage nach Feldende)' &&
      values.options.includes(value) &&
      values.options.includes('Interaktives Reporting (1-2 Tage nach Feldende)')
    ) {
      let options;
      options = remove(values.options, 'Tabellenband inkl. Indizes (1-2 Tage nach Feldende)');
      options = remove(options, value);
      onChange(key, { options: options });

      return;
    }

    if (values.options.includes(value) === false) {
      onChange(key, { options: [...values.options, value] });
    } else {
      onChange(key, { options: remove(values.options, value) });
    }
  };

  isDisabled = (values, value) => {
    if (value !== 'Interaktives Reporting (1-2 Tage nach Feldende)') {
      return false;
    }

    if (
      values.options.includes('Tabellenband (1-2 Tage nach Feldende)') ||
      values.options.includes('Tabellenband inkl. Indizes (1-2 Tage nach Feldende)')
    ) {
      return false;
    }

    return true;
  };

  renderCheckbox = (values, value, alwaysChecked) => (
    <Form.Field key={value}>
      <Checkbox
        name="options"
        label={value}
        value={value}
        disabled={alwaysChecked.includes(value) || this.isDisabled(values, value)}
        checked={values.options.includes(value)}
        onChange={() => this.onChangeValue(values, value)}
      />
    </Form.Field>
  );

  render() {
    const { module, values, wave, configuration } = this.props;
    const { options, alwaysChecked } = module;

    return (
      <form className="ui form">
        <Form.Field>
          <label htmlFor="options">
            {Module.getComponentName(MODULE_REPORTING_OPTIONS, configuration.modules, wave.study.type.type)}
          </label>
          {options.map((option) => this.renderCheckbox(values, option, alwaysChecked))}
        </Form.Field>
      </form>
    );
  }
}

export default ReportingOptionsForm;
