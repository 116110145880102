import { v4 as uuid } from 'uuid';

export const ADD_MESSAGE = 'ADD_MESSAGE';
export const DISMISS_MESSAGE = 'DISMISS_MESSAGE';
export const REMOVE_ALL = 'REMOVE_ALL';

export const addMessage = (text, closeable = true, type = 'info') => ({
  type: ADD_MESSAGE,
  message: {
    type,
    text,
    closeable,
    id: uuid(),
    visible: true,
  },
});

export const dismissMessage = (id) => ({
  type: DISMISS_MESSAGE,
  id,
});

export const removeAllMessages = () => ({
  type: REMOVE_ALL,
});
