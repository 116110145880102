import React from 'react';
import { Module, MODULE_CUSTOMER_AND_SPECIFICATION } from '../../Modules';
import DefinitionList from '../../Ui/DefinitionList';
import ValidationError from '../../Ui/ValidationError';
import FileList from './FileList';
import List from './List';
import Placeholder from './Placeholder';
import Section from './Section';

const CustomerAndSpecification = ({ configuration, values, errors, wave, deepLink, activeIndex }) => {
  const { customer, competitors, objectOfInvestigation, files } = values.values;

  return (
    <Section
      headline={Module.getComponentName(MODULE_CUSTOMER_AND_SPECIFICATION, configuration.modules, wave.study.type.type)}
      deepLink={deepLink}
    >
      <DefinitionList>
        <DefinitionList.Definition>Kunde</DefinitionList.Definition>
        <DefinitionList.Description>
          <Placeholder value={customer} />
        </DefinitionList.Description>
        <ValidationError errors={errors} property="customer" />
        <DefinitionList.Definition>Wettbewerber</DefinitionList.Definition>
        <DefinitionList.Description>
          <List items={competitors} />
        </DefinitionList.Description>
        <ValidationError errors={errors} property="competitors" />
        <DefinitionList.Definition>Untersuchungsgegenstand</DefinitionList.Definition>
        <DefinitionList.Description>
          <Placeholder value={objectOfInvestigation} />
        </DefinitionList.Description>
        <ValidationError errors={errors} property="objectOfInvestigation" />
      </DefinitionList>
      {(objectOfInvestigation === 'PKW-Modelle + Autohäuser' || objectOfInvestigation === 'PKW-Modelle') && (
        <>
          <FileList files={files} errors={errors} wave={wave} activeIndex={activeIndex} property={'files[]'} />
        </>
      )}
      <ValidationError errors={errors} property="files" />
    </Section>
  );
};

export default CustomerAndSpecification;
