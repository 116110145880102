import { Link } from 'react-router-dom';
import { Divider, Header, Icon, Responsive } from 'semantic-ui-react';
import './ScreenHeader.scss';

const renderBackButton = (hasBackButton) => {
  if (hasBackButton === false) {
    return null;
  }

  return (
    <>
      <Responsive
        minWidth={Responsive.onlyComputer.minWidth}
        as={Link}
        className="ui icon left labeled small button mr-15"
        to="/"
        data-cy="screenheader_back"
      >
        <Icon link name="chevron left" className="mr-0" /> Zurück
      </Responsive>
      <Responsive maxWidth={Responsive.onlyTablet.maxWidth} as={Link} className="ui icon small button mr-15" to="/">
        <Icon link name="chevron left" className="mr-0" />
      </Responsive>
    </>
  );
};

const ScreenHeader = ({ title, children, hasBackButton, className }) => (
  <Header as="h1" className="ScreenHeader mb-30">
    <div className={`d-flex align-items-center justify-content-space-between ${className}`}>
      <div className="d-flex align-items-center">
        {renderBackButton(hasBackButton)}
        <Header.Content as="h1" className="ScreenHeader__title my-0" data-cy="headline_mmrapid">
          {title}
        </Header.Content>
      </div>
      {children}
    </div>
    <Divider />
  </Header>
);

ScreenHeader.defaultProps = {
  hasBackButton: true,
  className: '',
};

export default ScreenHeader;
