import React from 'react';
import Date from './Date';

class Feasibility extends React.Component {
  constructor(props) {
    super(props);

    this.renderVisibility = this.renderVisibility.bind(this);
  }

  renderVisibility() {
    const { color, feasibility } = this.props;
    const { calculatedAt, expectedResponses } = feasibility;

    if (calculatedAt !== null && expectedResponses !== null && feasibility !== null) {
      return (
        <React.Fragment>
          <div className="step has-number">
            <div className="content ml-10">
              <div className="title">Verfügbare Fälle: {expectedResponses}</div>
              <div className="description">
                Zuletzt durchgeführt am <Date date={calculatedAt} />
              </div>
              {/*}
							<div className="text-muted mt-5" style={{ fontSize: '0.8em' }}>
								<i>{targetGroupId}</i>
							</div>
							*/}
            </div>
          </div>
          <div className={`step ${color === 'red' ? '' : 'disabled'}`}>
            <i className={`circle icon red ${color === 'red' ? '' : 'outline'}`} />
            <div className="content ml-10">
              <div className="title">Zu wenig Fälle</div>
              <div className={`description ${color === 'red' ? '' : 'd-none'}`}>
                Leider haben wir zu wenig Fälle,
                <br />
                um ausreichend Fälle mit
                <br />
                guten Quoten zu erreichen.
              </div>
            </div>
          </div>
          <div className={`step ${color === 'yellow' ? '' : 'disabled'}`}>
            <i className={`circle icon yellow ${color === 'yellow' ? '' : 'outline'}`} />
            <div className="content ml-10">
              <div className="title">Quoten gefährdet</div>
              <div className={`description ${color === 'yellow' ? '' : 'd-none'}`}>
                Die Befragung kann umgesetzt werden.
                <br />
                Eventuell können nicht alle
                <br />
                Quoten eingehalten werden.
              </div>
            </div>
          </div>
          <div className={`step ${color === 'green' ? '' : 'disabled'}`}>
            <i className={`circle icon green ${color === 'green' ? '' : 'outline'}`} />
            <div className="content ml-10">
              <div className="title">Studie umsetzbar</div>
              <div className={`description ${color === 'green' ? '' : 'd-none'}`}>
                Studie inkl. Quotierung auf Geschlecht,
                <br />
                Alter, Bildung kann umgesetzt werden.
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    }

    return (
      <div className="step">
        <i className="exclamation triangle icon grey" />
        <div className="content ml-10">
          <div className="title">Ungeprüfte Welle</div>
          <div className="description">
            Die Welle kann erst beauftragt werden, <br />
            wenn die möglichen Fallzahlen manuell <br />
            durch ZMG geprüft wurden.
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { checkingFeasibility, checkFeasibility, isCheckable } = this.props;

    return (
      <div className="status-screen">
        <div className="ui mini steps vertical fluid mb-0">{this.renderVisibility()}</div>
      </div>
    );
  }
}

export default Feasibility;
