import React from 'react';
import { Checkbox, Form } from 'semantic-ui-react';

const onChangeValue = (values, value, onChange) => {
  if (values.includes(value) === true) {
    const newValues = [...values];

    newValues.splice(values.indexOf(value), 1);
    onChange(newValues);

    return;
  }

  onChange([...values, value]);
};

const CheckboxList = ({ label, values, options, name, onChange }) => (
  <Form.Field>
    <label htmlFor={name}>{label}</label>
    {options.map((option) => (
      <Form.Field key={option.value}>
        <Checkbox
          name={name}
          label={option.text}
          value={option.value}
          checked={values.includes(option.value)}
          onChange={() => onChangeValue(values, option.value, onChange)}
        />
      </Form.Field>
    ))}
  </Form.Field>
);

export default CheckboxList;
