import React from 'react';
import * as Yup from 'yup';
import { withHooks } from '@Classic/Hocs/withHooks';
import { addMessage } from '../Actions/messageActions';
import { store } from '../App';
import Client, { handleRequestError } from '../Client';
import Screen from '../Components/Screen';
import ScreenHeader from '../Components/ScreenHeader';
import CreateBlueprintForm from '../Forms/CreateBlueprintForm';
import { hasRole, ROLE_ADMIN } from '../Permissions';

const CreateBlueprintSchema = Yup.object().shape({
  name: Yup.string().required('Bitte geben Sie einen Namen an.'),
  description: Yup.string().required('Bitte geben Sie eine Beschreibung ein.'),
  type: Yup.string().required('Bitte geben Sie einen Typ ein.'),
  organizationId: Yup.string().required('Bitte wählen Sie eine Organisation aus.'),
  sourceBlueprintId: Yup.string().required('Bitte wählen Sie eine Blaupause aus.'),
});

class CreateBlueprintScreen extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      blueprints: [],
      organizations: [],
    };

    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    const { navigate } = this.props;

    if (hasRole(ROLE_ADMIN) === false) {
      navigate('/');
      store.dispatch(addMessage('Sie haben keine Berechtigung diese Seite aufzurufen.', false, 'error'));
    }

    this.fetchData();
  }

  fetchData() {
    Promise.all([Client.get('/blueprint/list'), Client.get('/organization/list')])
      .then((response) => {
        const [blueprints, organizations] = response;

        this.setState({
          loading: false,
          blueprints: blueprints.data,
          organizations: organizations.data,
        });
      })
      .catch((error) => {
        if (error.networkError) {
          return;
        }
        this.setState({ loading: false });
      });
  }

  submit(values, actions) {
    // eslint-disable-line class-methods-use-this
    const { navigate } = this.props;
    Client.post('/blueprint/create', values)
      .then((response) => {
        actions.setSubmitting(false);
        actions.resetForm();
        store.dispatch(addMessage('Die Blaupause wurde erfolgreich angelegt.'));

        navigate(`/blueprint/${response.data.id}/update`);
      })
      .catch((error) => {
        handleRequestError(error, actions);
      });
  }

  render() {
    const { loading, blueprints, organizations } = this.state;

    return (
      <Screen title="Neue Blaupause | Media Monitor Rapid">
        <ScreenHeader title="Neue Blaupause" hasBackButton />
        <CreateBlueprintForm
          loading={loading}
          blueprints={blueprints}
          organizations={organizations}
          validationSchema={CreateBlueprintSchema}
          onSubmit={this.submit}
          submitLabel="Blaupause anlegen"
          initialValues={{
            name: '',
            description: '',
            type: 'ORGANIZATION_ROOT',
            organizationId: null,
            sourceBlueprintId: null,
          }}
        />
      </Screen>
    );
  }
}

export default withHooks(CreateBlueprintScreen);
