import React from 'react';
import { Form } from 'semantic-ui-react';
import { withHooks } from '@Classic/Hocs/withHooks';
import { addMessage } from '../Actions/messageActions';
import { store } from '../App';
import Client from '../Client';
import Modules from '../Components/Modules';
import Screen from '../Components/Screen';
import ScreenHeader from '../Components/ScreenHeader';
import { hasOneOfRoles, ROLE_ADMIN, ROLE_SERVICE_PROVIDER_QUESTIONNAIRE, ROLE_STUDENT } from '../Permissions';

class UpdateQuestionnaireScreen extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      type: '',
      modules: [],
    };
  }

  componentDidMount() {
    const { navigate } = this.props;

    // app_questionnaire
    if (hasOneOfRoles([ROLE_ADMIN, ROLE_SERVICE_PROVIDER_QUESTIONNAIRE, ROLE_STUDENT]) === false) {
      navigate('/');
      store.dispatch(addMessage('Sie haben keine Berechtigung diese Seite aufzurufen.', false, 'error'));
    }

    Client.get('/module')
      .then((response) => this.setState({ modules: response.data }))
      .catch(() => {
        store.dispatch(addMessage('Beim Laden der Daten ist ein Fehler aufgetreten.', false, 'error'));
      });
  }

  render() {
    const { modules, type } = this.state;

    return (
      <Screen title="Beispielfragebogen | Media Monitor Rapid">
        <ScreenHeader title="Beispielfragebogen" hasBackButton={false} />
        <Form.Field>
          <div className="as-label mb-15">Übersicht der Studientypen und Beispielfragebogen</div>
          <Modules
            value={type}
            options={modules}
            onChange={(event) => this.setState({ type: event.target.value })}
            onBlur={undefined}
          />
        </Form.Field>
      </Screen>
    );
  }
}

export default withHooks(UpdateQuestionnaireScreen);
