import React from 'react';
import PropTypes from 'prop-types';
import MarkdownRichTextEditor from '../Components/MarkdownRichTextEditor';

class CustomQuestionsRichTextForm extends React.Component {
  updateValue = (key, idx, value) => {
    const { values, onChange } = this.props;

    const newValues = { ...values };
    newValues.questions[idx] = value;

    onChange(key, {
      ...newValues,
    });
  };

  render() {
    const { values, module } = this.props;
    const { key, questions } = module;

    return (
      <form className="ui form">
        {questions.map((question, idx) => (
          <MarkdownRichTextEditor
            key={question.title}
            id={`question-${idx}`}
            label={question.title}
            placeholder={question.placeholder}
            value={values.questions[idx] ?? ''}
            onChange={(value) => this.updateValue(key, idx, value)}
          />
        ))}
      </form>
    );
  }
}

CustomQuestionsRichTextForm.propTypes = {
  module: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  values: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  onChange: PropTypes.func.isRequired,
};

export default CustomQuestionsRichTextForm;
