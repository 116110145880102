import React from 'react';
import { Button, Checkbox, Dropdown, Form, Icon } from 'semantic-ui-react';
import { ErrorMessage, Formik } from 'formik';
import * as Yup from 'yup';
import { withHooks } from '@Classic/Hocs/withHooks';
import { addMessage } from '../Actions/messageActions';
import { store } from '../App';
import Client, { handleRequestError } from '../Client';
import Screen from '../Components/Screen';
import ScreenHeader from '../Components/ScreenHeader';
import { getRoleName, hasRole, ROLE_ADMIN } from '../Permissions';
import InputError from '../Ui/InputError';

const CreateUserSchema = Yup.object().shape({
  firstName: Yup.string().required('Bitte geben Sie einen Vornamen an.'),
  lastName: Yup.string().required('Bitte geben Sie einen Nachnamen an.'),
  email: Yup.string().email('Bitte geben Sie eine gültige E-Mail an.').required('Bitte geben Sie eine E-Mail an.'),
  organization: Yup.string().required('Bitte wählen Sie eine Organisation.'),
  role: Yup.string().required('Bitte wählen Sie eine Rolle.'),
});

class UserScreen extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fetching: true,
      roles: [],
      organizations: [],
    };

    this.input = React.createRef();
    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    const { navigate } = this.props;

    if (hasRole(ROLE_ADMIN) === false) {
      navigate.push('/');
      store.dispatch(addMessage('Sie haben keine Berechtigung diese Seite aufzurufen.', false, 'error'));
    }

    this.input.current.focus();

    Promise.all([Client.get('/organization/list'), Client.get('/user/roles')])
      .then((response) => {
        const [organizations, roles] = response;

        this.setState({ organizations: organizations.data, roles: roles.data, fetching: false });
      })
      .catch((error) => {
        if (error.networkError) {
          return;
        }
        this.setState({ fetching: false });
      });
  }

  submit(values, actions) {
    // eslint-disable-line class-methods-use-this
    Client.post('/user/create', values)
      .then(() => {
        actions.setSubmitting(false);
        actions.resetForm();
        store.dispatch(addMessage('Der Nutzer wurde erfolgreich erstellt.'));
      })
      .catch((error) => {
        handleRequestError(error, actions);
      });
  }

  render() {
    const { fetching, roles, organizations } = this.state;

    return (
      <Screen title="Neuer Nutzer | Media Monitor Rapid">
        <ScreenHeader title="Neuer Nutzer" hasBackButton={false} />
        <Formik
          initialValues={{
            firstName: '',
            lastName: '',
            email: '',
            organization: '',
            role: '',
            active: true,
          }}
          validationSchema={CreateUserSchema}
          onSubmit={(values, actions) => this.submit(values, actions)}
          render={(
            {
              errors,
              touched,
              values,
              isSubmitting,
              handleSubmit,
              handleChange,
              handleBlur,
              setFieldValue,
              setFieldTouched,
            } // eslint-disable-line max-len
          ) => (
            <Form onSubmit={handleSubmit}>
              <Form.Field>
                <Checkbox
                  toggle
                  label={values.active === true ? 'aktiviert' : 'deaktiviert'}
                  checked={values.active}
                  onChange={() => setFieldValue('active', !values.active)}
                />
              </Form.Field>

              <Form.Field width={8} error={errors.firstName && touched.firstName}>
                <label htmlFor="firstName">Vorname</label>
                <input
                  type="text"
                  name="firstName"
                  id="firstName"
                  value={values.firstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  ref={this.input}
                />
                <InputError message={<ErrorMessage name="firstName" />} />
              </Form.Field>

              <Form.Field width={8} error={errors.lastName && touched.lastName}>
                <label htmlFor="lastName">Nachname</label>
                <input
                  type="text"
                  name="lastName"
                  id="lastName"
                  value={values.lastName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <InputError message={<ErrorMessage name="lastName" />} />
              </Form.Field>

              <Form.Field width={8} error={errors.email && touched.email}>
                <label htmlFor="email">E-Mail</label>
                <input
                  type="text"
                  name="email"
                  id="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <InputError message={<ErrorMessage name="email" />} />
              </Form.Field>

              <Form.Field width={8} error={errors.organization && touched.organization}>
                <label htmlFor="organization">Organisation</label>
                <Dropdown
                  selection
                  loading={fetching}
                  disabled={fetching}
                  name="organization"
                  id="organization"
                  placeholder="Wählen Sie eine Organisation"
                  value={values.organization}
                  onChange={(e, { value }) => setFieldValue('organization', value)}
                  onBlur={() => setFieldTouched('organization', true)}
                  options={organizations.map((organization) => ({
                    key: organization.id,
                    value: organization.id,
                    text: organization.name,
                  }))}
                />
                <InputError message={<ErrorMessage name="organization" />} />
              </Form.Field>

              <Form.Field width={8} error={errors.role && touched.role}>
                <label htmlFor="role">Rolle</label>
                <Dropdown
                  selection
                  loading={fetching}
                  disabled={fetching}
                  name="role"
                  id="role"
                  placeholder="Wählen Sie eine Rolle"
                  value={values.role}
                  onChange={(e, { value }) => setFieldValue('role', value)}
                  onBlur={() => setFieldTouched('role', true)}
                  options={roles.map((role) => ({ key: role, value: role, text: getRoleName(role) }))}
                />
                <InputError message={<ErrorMessage name="role" />} />
              </Form.Field>

              <Button
                primary
                icon
                className="mt-15"
                type="submit"
                labelPosition="right"
                loading={isSubmitting}
                disabled={isSubmitting}
              >
                <Icon link name="save outline" />
                Nutzer erstellen
              </Button>
            </Form>
          )}
        />
      </Screen>
    );
  }
}

export default withHooks(UserScreen);
