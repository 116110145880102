import { useEffect } from 'react';
import Login from '@Organism/Login';
import CenteredCard from '@Classic/Ui/CenteredCard.jsx';

const LoginPage = () => {
  useEffect(() => {
    document.title = 'Login | Media Monitor Rapid';
  }, []);

  return (
    <CenteredCard renderHeader headline="Login">
      <Login />
    </CenteredCard>
  );
};

export default LoginPage;
