import * as React from 'react';
import { useMessageStore } from '@Store/useMessageStore.ts';
import { useUserStore } from '@Store/useUsersStore.js';

export function withStores<ComponentProps>(Component: React.FunctionComponent<ComponentProps>) {
  function ComponentWithStore(props: ComponentProps) {
    const userStore = useUserStore();
    const messageStore = useMessageStore();

    return <Component {...props} userStore={userStore} messageStore={messageStore} />;
  }

  return ComponentWithStore;
}

export default withStores;
