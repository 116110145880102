import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { Dropdown, Icon, Menu, Responsive } from 'semantic-ui-react';
import { isConfigurable } from '../Permissions';

const renderItemsForDesktop = (wave) => {
  if (wave === null) {
    return (
      <Fragment>
        <Menu.Item disabled data-cy="wave_action_reporting">
          <Icon name="chart bar" />
          Reporting
        </Menu.Item>
        <Menu.Item disabled data-cy="wave_action_config">
          <Icon name="cogs" />
          Konfiguration
        </Menu.Item>
        <Menu.Item disabled data-cy="wave_action_order">
          <Icon name="shopping cart" />
          Auftrag
        </Menu.Item>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Menu.Item
        as={NavLink}
        to={`/study/${wave.study.id}/reporting`}
        data-doc="app_wave_open_report"
        data-cy="wave_action_reporting"
      >
        <Icon name="chart bar" />
        Reporting
      </Menu.Item>
      <Menu.Item
        as={NavLink}
        to={`/wave/${wave.id}/configuration`}
        disabled={isConfigurable(wave) === false}
        data-cy="wave_action_config"
      >
        <Icon name="cogs" />
        Konfiguration
      </Menu.Item>
      <Menu.Item as={NavLink} to={`/wave/${wave.id}/order`} data-cy="wave_action_order">
        <Icon name="shopping cart" />
        Auftrag
      </Menu.Item>
    </Fragment>
  );
};

const renderItemsForMobile = (wave) => {
  if (wave === null) {
    return null;
  }

  return (
    <Dropdown.Menu>
      <Dropdown.Item
        icon="chart bar"
        text="Reporting"
        as={NavLink}
        to={`/study/${wave.study.id}/reporting`}
        disabled={isConfigurable(wave) === false}
        data-doc="app_wave_open_report"
        data-cy="wave_action_reporting"
      />
      <Dropdown.Item
        icon="cogs"
        text="Konfiguration"
        as={NavLink}
        to={`/wave/${wave.id}/configuration`}
        disabled={isConfigurable(wave) === false}
        data-cy="wave_action_config"
      />
      <Dropdown.Item
        icon="shopping cart"
        text="Auftrag"
        as={NavLink}
        to={`/wave/${wave.id}/order`}
        data-cy="wave_action_order"
      />
    </Dropdown.Menu>
  );
};

const WaveActions = ({ wave }) => (
  <>
    <Responsive
      minWidth={Responsive.onlyComputer.minWidth}
      as={Menu}
      className="ui small compact labeled icon menu mt-0"
    >
      {renderItemsForDesktop(wave)}
    </Responsive>
    <Responsive
      maxWidth={Responsive.onlyTablet.maxWidth}
      as={Dropdown}
      button
      disabled={wave === null}
      icon="bars"
      direction="left"
      className="icon mr-0"
    >
      {renderItemsForMobile(wave)}
    </Responsive>
  </>
);

export default WaveActions;
