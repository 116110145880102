import { Container } from '@chakra-ui/react';
import { StyledRoot } from '@Template/DefaultTemplate/DefaultTemplate.styles.ts';
import { PropsWithChildren } from '@Types/PropsWithChildren.ts';
import { PropsWithClassName } from '@Types/PropsWithClassName.ts';

interface Props extends PropsWithChildren, PropsWithClassName {
  containerWidth: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
}

const DefaultTemplate = ({ containerWidth, children, className }: Props) => {
  return (
    <StyledRoot className={className} data-cy={'DefaultTemplate'}>
      <nav>Logo, Top Navigation</nav>
      <header>Header hier....</header>
      <Container data-testid={'DefaultTemplate'} maxW={`container.${containerWidth}`}>
        {children}
      </Container>
      <footer>Footer hier....</footer>
    </StyledRoot>
  );
};

export { DefaultTemplate };
