import React from 'react';
import {
  findModuleByKey,
  findValuesByKey,
  hasModule,
  Module,
  MODULE_IMAGE_DIMENSIONS,
  MODULE_IMAGE_TOGGLE,
} from '../../Modules';
import InfoMessage from '../../Ui/InfoMessage';
import ValidationError from '../../Ui/ValidationError';
import DimensionList from './DimensionList';
import Section from './Section';

const Image = ({ configuration, values, errors, wave, deepLink }) => {
  if (hasModule(MODULE_IMAGE_TOGGLE, configuration.modules)) {
    if (findValuesByKey(MODULE_IMAGE_TOGGLE, values).values.on === false) {
      return (
        <Section
          headline={Module.getComponentName(MODULE_IMAGE_TOGGLE, configuration.modules, wave.study.type.type)}
          deepLink={deepLink}
        >
          <InfoMessage>Wird nicht abgefragt.</InfoMessage>
        </Section>
      );
    }
  }

  return (
    <Section
      headline={Module.getComponentName(MODULE_IMAGE_DIMENSIONS, configuration.modules, wave.study.type.type)}
      deepLink={deepLink}
    >
      <DimensionList
        module={findModuleByKey(MODULE_IMAGE_DIMENSIONS, configuration.modules)}
        values={findModuleByKey(MODULE_IMAGE_DIMENSIONS, values)}
        errors={errors}
      />
      {errors.map((error) => (
        <ValidationError errors={[error]} property="dimensions" />
      ))}
    </Section>
  );
};

export default Image;
