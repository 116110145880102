import React from 'react';
import { Label } from 'semantic-ui-react';
import moment from 'moment';

moment.locale('de');

const CreatedBadge = ({ createdAt }) =>
  moment().isSame(moment(createdAt), 'day') === true ? <Label horizontal>Neu</Label> : null;

export default CreatedBadge;
