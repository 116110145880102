import React from 'react';
import { Icon } from 'semantic-ui-react';
import Popup from '../Ui/Popup';
import './ModuleTypeIcon.scss';

const renderIcon = (type) => {
  if (type === 'easy_success_measurement') {
    return <Icon name="chart line" />;
  }

  if (type === 'motive_test' || type === 'motive_test_brand_story' || type === 'motive_test_campaign_check') {
    return <Icon name="image outline" />;
  }

  if (type === 'daily_sales') {
    return <Icon name="euro" />;
  }

  if (type === 'daily_drive') {
    return <Icon name="car" />;
  }

  if (type === 'retargeting') {
    return <Icon name="redo alternate" />;
  }

  if (type === 'advertisement_copy_test') {
    return <Icon name="image" />;
  }

  if (type === 'supplement_copy_test') {
    return <Icon name="file image" />;
  }

  if (type === 'motive_test_media_opal') {
    return <Icon name="image outline" />;
  }

  if (type === 'quick_poll') {
    return <Icon name="stopwatch" />;
  }

  throw Error(`Module type ${type} not supported.`);
};

const ModuleTypeIcon = ({ type, name, popup = true }) => {
  if (popup === true) {
    return <Popup label={name} trigger={renderIcon(type)} />;
  }

  return renderIcon(type);
};

export default ModuleTypeIcon;
