/* eslint-disable */
import React from 'react';
import { useMessageStore } from '@Store/useMessageStore';
import { ADD_MESSAGE } from '@Classic/Actions/messageActions.js';

export const store = {
  dispatch: (msg) => {
    console.log('Dispatching message: ' + msg);
    switch (msg.type) {
      case ADD_MESSAGE:
        useMessageStore.getState().addMessage(msg.message);
        break;
      default:
        console.log('Unknown message type: ' + msg.type);
    }
  },
};

// Screens
{
  /*
const LandingPage = React.lazy(() => import('./Pages/LandingPage'));
const LoginScreen = React.lazy(() => import('../../app-v2/src/components-ui/Page/LoginScreen'));
const RecoveryScreen = React.lazy(() => import('./Screens/RecoveryScreen'));
const ResetScreen = React.lazy(() => import('./Screens/ResetScreen'));
const DashboardScreen = React.lazy(() => import('./Screens/DashboardScreen'));
const CreateStudyScreen = React.lazy(() => import('./Screens/CreateStudyScreen'));
const ConfigurationScreen = React.lazy(() => import('./Screens/ConfigurationScreen'));
const OrderScreen = React.lazy(() => import('./Screens/OrderScreen'));
const UserScreen = React.lazy(() => import('./Screens/UserScreen'));
const CreateUserScreen = React.lazy(() => import('./Screens/CreateUserScreen'));
const UpdateUserScreen = React.lazy(() => import('./Screens/UpdateUserScreen'));
const ReportingScreen = React.lazy(() => import('./Screens/ReportingScreen'));
const CreateNewsScreen = React.lazy(() => import('./Screens/CreateNewsScreen'));
const UpdateNewsScreen = React.lazy(() => import('./Screens/UpdateNewsScreen'));
const NewsListScreen = React.lazy(() => import('./Screens/NewsListScreen'));
const CostsScreen = React.lazy(() => import('./Screens/CostsScreen'));
const UpdateQuestionnaireScreen = React.lazy(() => import('./Screens/UpdateQuestionnaireScreen'));
const OrganizationScreen = React.lazy(() => import('./Screens/OrganizationScreen'));
const CreateOrganizationScreen = React.lazy(() => import('./Screens/CreateOrganizationScreen'));
const UpdateOrganizationScreen = React.lazy(() => import('./Screens/UpdateOrganizationScreen'));
const BlueprintScreen = React.lazy(() => import('./Screens/BlueprintScreen'));

import './semantic.min.css';
import './App.scss';
*/
}

// const rootReducer = combineReducers({
//   user: userReducer,
//   message: messageReducer, // blacklisted
// });

// const persistedReducer = persistReducer({ key: 'root', storage, blacklist: ['message'] }, rootReducer);
// export const store = (persistedReducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

// const persist0r = persistStore(store);

{
  /*
class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: false,
    };
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      scope.setExtra(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ error: true, eventId: eventId });
    });
  }

  render() {
    const { error } = this.state;
    const { user } = store.getState().user;

    if (process.env.REACT_APP_MAINTENANCE === 'true') {
      return (
        <MaintenanceScreen />
      );
    }

    if (error === true) {
      return (
        <ErrorScreen />
      );
    }

    return (
      <Provider store={store}>
        <PersistGate persistor={persist0r} loading={<ScreenLoader />}>
          <Router history={history}>
            <Suspense fallback={<ScreenLoader />}>
              <Switch>
                <Route path='/start' render={() => user === null ? <LandingPage /> : <Redirect to={'/'} />} />
                <Route path='/login' component={LoginScreen} />
                <Route path='/recovery' component={RecoveryScreen} />
                <Route path='/:hash/reset' component={ResetScreen} />
                <Route exact path='/' component={DashboardScreen} />
                <Route exact path='/study/create' component={CreateStudyScreen} />
                <Route path='/wave/:uuid/configuration/:tab?' component={ConfigurationScreen} />
                <Route exact path='/wave/:uuid/order' component={OrderScreen} />
                <Route exact path='/user' component={UserScreen} />
                <Route exact path='/user/create' component={CreateUserScreen} />
                <Route exact path='/user/:uuid/update' component={UpdateUserScreen} />
                <Route exact path='/organization' component={OrganizationScreen} />
                <Route exact path='/organization/create' component={CreateOrganizationScreen} />
                <Route exact path='/organization/:uuid/update' component={UpdateOrganizationScreen} />
                <Route exact path='/study/:uuid/reporting' component={ReportingScreen} />
                <Route exact path='/news' component={NewsListScreen} />
                <Route exact path='/news/create' component={CreateNewsScreen} />
                <Route exact path='/news/:uuid/update' component={UpdateNewsScreen} />
                <Route exact path='/costs' component={CostsScreen} />
                <Route exact path='/questionnaire' component={UpdateQuestionnaireScreen} />
                <Route exact path='/blueprint' component={BlueprintScreen} />
                <Route exact path='/blueprint/create' component={CreateBlueprintScreen} />
                <Route exact path='/blueprint/:uuid/update' component={UpdateBlueprintScreen} />
              </Switch>
            </Suspense>
          </Router>
        </PersistGate>
      </Provider>
    );
  }
}
export default App;
*/
}
