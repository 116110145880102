import React from 'react';
import { Button, Input } from 'semantic-ui-react';
import './RangeTextField.scss';

const RangeTextField = ({ value, onChange, onMinus, onPlus, onBlur, name, id }) => (
  <div className="RangeTextField">
    <Button
      className="RangeTextField__minus"
      fluid
      style={{ width: '40px' }}
      type="button"
      icon="minus"
      onClick={onMinus}
    />
    <Input
      className="RangeTextField__input"
      type="text"
      name={name}
      id={id}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
    />
    <Button
      className="RangeTextField__plus"
      fluid
      style={{ width: '40px' }}
      type="button"
      icon="plus"
      onClick={onPlus}
    />
    <div className="RangeTextField__info">Empfohlene Fallzahl: zwischen 250 und 1500.</div>
  </div>
);

export default RangeTextField;
