import React from 'react';
import { Button, Dropdown, Form, Icon, Message } from 'semantic-ui-react';
import { ErrorMessage, Formik } from 'formik';
import * as Yup from 'yup';
import { useUserStore } from '@Store/useUsersStore';
import { withHooks } from '@Classic/Hocs/withHooks';
import Client, { handleRequestError } from '../Client';
import Blueprints from '../Components/Blueprints';
import Screen from '../Components/Screen';
import ScreenHeader from '../Components/ScreenHeader';
import {
  ROLE_ADMIN,
  ROLE_MARKET_RESEARCHER,
  ROLE_SERVICE_PROVIDER_PANEL,
  ROLE_SERVICE_PROVIDER_QUESTIONNAIRE,
} from '../Permissions';
import InputError from '../Ui/InputError';

const CreateStudySchema = Yup.object().shape({
  name: Yup.string().required('Bitte geben Sie einen Namen für die Studie an.'),
  blueprintId: Yup.string().required('Bitte wählen Sie einen Studientyp aus.'),
});

class CreateStudyScreen extends React.PureComponent {
  constructor(props) {
    super(props);

    this.input = React.createRef();
    this.user = useUserStore.getState().data;

    this.state = {
      fetching: true,
      blueprints: [],
      organizations: [],
    };
  }

  componentDidMount = () => {
    this.input.current.focus();
    if (isAdminOrServiceProvider(this.user)) {
      Client.get('/organization/list')
        .then((response) => {
          this.setState({ organizations: response.data, fetching: false });
        })
        .catch((error) => {
          if (error.networkError) {
            return;
          }
          this.setState({ fetching: false });
        });
      return;
    }

    Client.get('/organization')
      .then((response) => {
        this.setState({
          blueprints: response.data.blueprints,
          organizations: [response.data],
          fetching: false,
        });
      })
      .catch((error) => {
        if (error.networkError) {
          return;
        }
        this.setState({ fetching: false });
      });
  };

  render() {
    const { fetching, organizations, blueprints } = this.state;
    const { navigate } = this.props;
    const user = this.user;
    return (
      <Screen title="Neue Studie anlegen | Media Monitor Rapid">
        <ScreenHeader title="Neue Studie anlegen" />
        <Formik
          initialValues={{
            name: '',
            organization: user.role === ROLE_MARKET_RESEARCHER ? user.organization.id : '',
            blueprintId: '',
          }}
          validationSchema={CreateStudySchema}
          onSubmit={(values, actions) => {
            Client.post('/study/create', {
              name: values.name,
              organizationId: values.organization,
              blueprintId: values.blueprintId,
            })
              .then((response) => {
                // todo: refactor to react router
                navigate(`/wave/${response.data.waves[0].id}/configuration`);
              })
              .catch((error) => {
                console.log('Error handling Request: ', error);

                handleRequestError(error, actions);
              });
          }}
          render={(
            {
              errors,
              touched,
              values,
              isSubmitting,
              handleSubmit,
              handleChange,
              handleBlur,
              setFieldValue,
              setFieldTouched,
              isValid,
            } // eslint-disable-line max-len
          ) => (
            <Form onSubmit={handleSubmit}>
              <Form.Field width={8} error={errors.name && touched.name}>
                <label htmlFor="name">Name der Studie</label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  data-cy="input_study_name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  ref={this.input}
                />
                <InputError message={<ErrorMessage name="name" />} />
              </Form.Field>
              {isAdminOrServiceProvider(this.user) && (
                <Form.Field
                  width={8}
                  error={errors.organization && touched.organization}
                  data-cy="dropdown_select_organization"
                >
                  <label htmlFor="organization">Organisation</label>
                  <Dropdown
                    selection
                    loading={fetching}
                    disabled={fetching}
                    name="organization"
                    id="organization"
                    placeholder="Wählen Sie eine Organisation"
                    value={values.organization}
                    onChange={(e, { value }) => {
                      const organization = organizations.find((organization) => organization.id === value);

                      if (blueprints === undefined) {
                        return;
                      }

                      const selectedBlueprint = blueprints.find((blueprint) => blueprint.id === values.blueprintId);

                      this.setState({ blueprints: [...organization.blueprints] });
                      setFieldValue('organization', value);

                      // when the newly selected organization also has a blueprint with the same study-type, select it
                      let blueprintIdUpdated = false;
                      organization.blueprints.forEach((blueprint) => {
                        if (selectedBlueprint && selectedBlueprint.studyType === blueprint.studyType) {
                          setFieldValue('blueprintId', blueprint.id);
                          blueprintIdUpdated = true;
                        }
                      });

                      if (blueprintIdUpdated === false) {
                        setFieldValue('blueprintId', '');
                      }
                    }}
                    onBlur={() => setFieldTouched('organization', true)}
                    options={organizations.map((organization) => ({
                      key: organization.id,
                      value: organization.id,
                      text: organization.name,
                    }))}
                  />
                  <InputError message={<ErrorMessage name="organization" />} />
                </Form.Field>
              )}

              <Form.Field error={errors.blueprintId && touched.blueprintId}>
                <div className="as-label">Studientyp</div>
                {values.organization === '' && (
                  <Message className="mt-0" style={{ width: '50%' }}>
                    <Icon name="warning sign" />
                    Bitte wählen Sie zunächst eine Organisation.
                  </Message>
                )}
                {values.organization !== '' && (
                  <Blueprints
                    value={values.blueprintId}
                    options={blueprints}
                    organizations={organizations}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                )}
                <InputError message={<ErrorMessage name="blueprintId" />} />
              </Form.Field>
              <Button
                primary
                icon
                className="mt-15 mb-15"
                type="submit"
                labelPosition="right"
                loading={isSubmitting}
                disabled={!isValid}
                data-cy="button-save_new_study"
              >
                <Icon link name="save outline" />
                Studie erstellen
              </Button>
            </Form>
          )}
        />
      </Screen>
    );
  }
}

const isAdminOrServiceProvider = (user) =>
  user.role === ROLE_ADMIN ||
  user.role === ROLE_SERVICE_PROVIDER_PANEL ||
  user.role === ROLE_SERVICE_PROVIDER_QUESTIONNAIRE;

export default withHooks(CreateStudyScreen);
