import React, { Suspense } from 'react';
import ScreenLoader from '../Components/ScreenLoader';

const ErrorInfo = React.lazy(() => import('../Components/ErrorInfo'));

const ErrorScreen = (props) => (
  <Suspense fallback={<ScreenLoader />}>
    <ErrorInfo {...props} />
  </Suspense>
);

export default ErrorScreen;
