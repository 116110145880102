import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Tab, TextArea } from 'semantic-ui-react';

const renderPreview = (text) => {
  if (text === '') {
    return (
      <p style={{ minHeight: '200px' }}>
        Es gibt noch nichts zum anzeigen. Schreiben Sie etwas, um eine Vorschau zu sehen.
      </p>
    );
  }

  return <ReactMarkdown children={text} style={{ minHeight: '200px' }} />;
};

const renderTextarea = (value, onChange, onBlur) => (
  <TextArea
    placeholder="Schreiben Sie einen Text ..."
    name="text"
    rows={16}
    value={value}
    onChange={onChange}
    onBlur={onBlur}
    style={{
      border: 'none',
      padding: 0,
    }}
  />
);

const MarkdownTextarea = ({ value, onChange, onBlur }) => (
  <Tab
    menu={{ secondary: true, pointing: true }}
    panes={[
      {
        menuItem: 'Text schreiben',
        render: () => <Tab.Pane>{renderTextarea(value, onChange, onBlur)}</Tab.Pane>,
      },
      {
        menuItem: 'Vorschau',
        render: () => <Tab.Pane>{renderPreview(value)}</Tab.Pane>,
      },
    ]}
  />
);

export default MarkdownTextarea;
