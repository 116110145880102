import React, { Fragment } from 'react';
import { Dropdown, Modal } from 'semantic-ui-react';
import { addMessage } from '../../Actions/messageActions';
import { store } from '../../App';
import Client from '../../Client';
import download from '../../Download';
import Map from '../Map';

class Region extends React.Component {
  state = {
    isMapOpen: false,
  };

  setMapOpen = (open) => {
    this.setState({
      isMapOpen: open,
    });
  };

  getRegion = () => {
    const { regions, regionId } = this.props;
    return regions.find((r) => r.id === regionId);
  };

  getZips = () => {
    const { customRegion } = this.props;
    const region = this.getRegion();

    if (region !== undefined) {
      return region.zips;
    }

    return customRegion;
  };

  getName = () => {
    const { customRegionName } = this.props;
    const region = this.getRegion();

    if (region !== undefined) {
      return region.name;
    }

    if (customRegionName === undefined || customRegionName === null) {
      return null;
    }

    // convert any file-ending to xlsx as we are providing that for download
    const fileName = customRegionName.split('.')[0];
    return `${fileName}.xlsx`;
  };

  downloadFile = () => {
    const { wave } = this.props;
    const name = this.getName();

    Client.get(`/wave/${wave.id}/regions-as-excel`, {
      responseType: 'arraybuffer',
    })
      .then((response) => {
        const blob = new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        download(blob, name);
      })
      .catch(() => {
        store.dispatch(addMessage('Beim Download der Daten ist ein Fehler aufgetreten', true, 'error'));
      });
  };

  render() {
    const { isMapOpen } = this.state;
    const { customRegion } = this.props;
    const region = this.getRegion();
    const zips = this.getZips();
    const name = this.getName();

    if (region === undefined && customRegion === null) {
      return 'Keine regionale Einschränkung';
    }

    return (
      <Fragment>
        <Dropdown text={name}>
          <Dropdown.Menu>
            <Dropdown.Item icon="download" text="Herunterladen" onClick={this.downloadFile} />
            <Dropdown.Item icon="map outline" text="Karte anzeigen" onClick={() => this.setMapOpen(true)} />
          </Dropdown.Menu>
        </Dropdown>
        <Modal closeIcon id="map-modal" size="fullscreen" open={isMapOpen} onClose={() => this.setMapOpen(false)}>
          <Map zips={zips} />
        </Modal>
      </Fragment>
    );
  }
}

export default Region;
