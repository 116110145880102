import React from 'react';
import { Button, Dropdown, Form, Icon } from 'semantic-ui-react';
import { ErrorMessage, Formik } from 'formik';
import InputError from '../Ui/InputError';

class CreateBlueprintForm extends React.PureComponent {
  constructor(props) {
    super(props);

    this.nameInput = React.createRef();
    this.descriptionInput = React.createRef();
    this.typeInput = React.createRef();
  }

  componentDidMount() {}

  render() {
    const { loading, blueprints, organizations, validationSchema, initialValues, submitLabel, onSubmit } = this.props;

    return (
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => onSubmit(values, actions)}
        render={(
          {
            errors,
            touched,
            values,
            isSubmitting,
            handleSubmit,
            handleChange,
            handleBlur,
            setFieldValue,
            setFieldTouched,
          } // eslint-disable-line max-len
        ) => (
          <Form onSubmit={handleSubmit}>
            <Form.Field
              width={8}
              error={errors.sourceBlueprintId && touched.sourceBlueprintId}
              data-cy="sourceBlueprintId"
            >
              <label htmlFor="sourceBlueprintId">Blaupause</label>
              <Dropdown
                selection
                loading={loading}
                disabled={loading}
                name="sourceBlueprintId"
                id="sourceBlueprintId"
                placeholder="Wählen Sie die Basis-Blaupause aus."
                value={values.modules}
                onChange={(e, { value, options }) => {
                  const { text, description } = options.find((option) => option.key === value);
                  setFieldValue('sourceBlueprintId', value);
                  setFieldValue('name', text);
                  setFieldValue('description', description);
                }}
                onBlur={() => setFieldTouched('sourceBlueprintId', true)}
                options={blueprints.map((blueprint) => ({
                  key: blueprint.id,
                  value: blueprint.id,
                  text: blueprint.name,
                  description: blueprint.description,
                }))}
              />
              <InputError message={<ErrorMessage name="organization" />} />
            </Form.Field>

            <Form.Field width={8} error={errors.name && touched.name}>
              <label htmlFor="name">Name</label>
              <input
                type="text"
                name="name"
                id="name"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                ref={this.nameInput}
              />
              <InputError message={<ErrorMessage name="name" />} />
            </Form.Field>

            <Form.Field width={8} error={errors.description && touched.description}>
              <label htmlFor="description">Beschreibung</label>
              <input
                type="text"
                name="description"
                id="description"
                value={values.description}
                onChange={handleChange}
                onBlur={handleBlur}
                ref={this.descriptionInput}
              />
              <InputError message={<ErrorMessage name="description" />} />
            </Form.Field>

            <Form.Field error={errors.type && touched.type}>
              <input
                type="hidden"
                name="type"
                id="type"
                value={values.type}
                onChange={handleChange}
                onBlur={handleBlur}
                ref={this.typeInput}
              />
            </Form.Field>

            <Form.Field width={8} error={errors.organizationId && touched.organizationId} data-cy="organizationId">
              <label htmlFor="organizationId">Organisation</label>
              <Dropdown
                selection
                loading={loading}
                disabled={loading}
                name="organizationId"
                id="organizationId"
                placeholder="Wählen Sie die Organisation aus, der diese Blaupause zugeordnet werden soll."
                value={values.modules}
                onChange={(e, { value }) => setFieldValue('organizationId', value)}
                onBlur={() => setFieldTouched('organizationId', true)}
                options={organizations.map((organization) => ({
                  key: organization.id,
                  value: organization.id,
                  text: organization.name,
                }))}
              />
              <InputError message={<ErrorMessage name="organizationId" />} />
            </Form.Field>

            <Button
              primary
              icon
              className="mt-15"
              type="submit"
              labelPosition="right"
              loading={isSubmitting}
              disabled={isSubmitting}
            >
              <Icon link name="save outline" />
              {submitLabel}
            </Button>
          </Form>
        )}
      />
    );
  }
}

export default CreateBlueprintForm;
