import React from 'react';
import { Card, Grid, Header, Placeholder } from 'semantic-ui-react';
import { onDownloadPDF } from './Modules';
import './Modules.scss';
import ModuleTypeIcon from './ModuleTypeIcon';

const Loading = () => (
  <Grid stackable className="Modules">
    <Grid.Row columns={3}>
      {[1, 2, 3].map((number) => (
        <Grid.Column key={number} style={{ marginBottom: '2rem' }}>
          <Card className="Modules__card">
            <Card.Content>
              <Placeholder fluid>
                <Placeholder.Header image>
                  <Placeholder.Line />
                </Placeholder.Header>
              </Placeholder>
            </Card.Content>
            <Card.Content>
              <Placeholder>
                <Placeholder.Paragraph>
                  <Placeholder.Line />
                  <Placeholder.Line />
                  <Placeholder.Line />
                  <Placeholder.Line />
                </Placeholder.Paragraph>
              </Placeholder>
            </Card.Content>
            <Card.Content extra>
              <Placeholder>
                <Placeholder.Paragraph>
                  <Placeholder.Line length="medium" />
                </Placeholder.Paragraph>
              </Placeholder>
            </Card.Content>
          </Card>
        </Grid.Column>
      ))}
    </Grid.Row>
  </Grid>
);

const Blueprints = ({ options, value, onChange, onBlur }) =>
  Object.keys(options).length === 0 ? (
    <Loading />
  ) : (
    <Grid stackable className="Modules">
      <Grid.Row columns={3}>
        {Object.values(options).map((option, index) => (
          <Grid.Column key={`${option.studyType}-${option.id}`} style={{ marginBottom: '2rem' }}>
            <label htmlFor={option.id} data-cy="module_create" data-id={option.id}>
              <input
                className="Modules__input"
                type="radio"
                name="blueprintId"
                checked={value === option.id}
                id={option.id}
                value={option.id}
                onChange={onChange}
                onBlur={onBlur}
                data-cy={option.studyType}
              />
              <Card className="Modules__card" raised={value === option.id}>
                <Card.Content>
                  <Header as="h4">
                    <ModuleTypeIcon type={option.studyType} name={option.name} />
                    <Header.Content>{option.name}</Header.Content>
                  </Header>
                </Card.Content>
                <Card.Content>{option.description}</Card.Content>
                <Card.Content extra>
                  {option.questionnaireUrl !== null && (
                    <span
                      className="Modules__link"
                      role="button"
                      tabIndex={index.toString()}
                      onClick={() => onDownloadPDF(option.questionnaireUrl)}
                      onKeyPress={() => onDownloadPDF(option.questionnaireUrl)}
                      title={option.link}
                    >
                      Fragebogen
                    </span>
                  )}
                </Card.Content>
              </Card>
            </label>
          </Grid.Column>
        ))}
      </Grid.Row>
    </Grid>
  );

export default Blueprints;
