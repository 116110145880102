import React from 'react';
import { Icon } from 'semantic-ui-react';

const findErrorByProperty = (errors, property) => {
  if (!Array.isArray(errors)) {
    return null;
  }

  const error = errors.find((error) => error.property === property);
  if (error === undefined) {
    return null;
  }

  return error.message;
};

const ValidationError = ({ errors, property }) =>
  findErrorByProperty(errors, property) && (
    <span style={{ marginBottom: '5px', color: '#9f3a38', alignItems: 'baseline' }} className="d-flex">
      <Icon name="warning sign" />
      <span>{findErrorByProperty(errors, property)}</span>
    </span>
  );

export default ValidationError;
