import React from 'react';
import { Header, Placeholder, Segment } from 'semantic-ui-react';
import {
  hasOneOfRoles,
  ROLE_ADMIN,
  ROLE_SERVICE_PROVIDER_PANEL,
  ROLE_SERVICE_PROVIDER_QUESTIONNAIRE,
} from '../Permissions';
import ModuleTypeIcon from './ModuleTypeIcon';
import './StudyHeader.scss';

const StudyHeader = ({ study, loading }) =>
  loading === true ? (
    <Segment attached="top" className="StudyHeader">
      <Placeholder fluid>
        <Placeholder.Header image>
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder.Header>
      </Placeholder>
    </Segment>
  ) : (
    <Segment attached="top" className="StudyHeader d-flex justify-content-space-between">
      <Header className="mb-0" style={{ position: 'relative', width: '100%' }}>
        <ModuleTypeIcon name={study.type.name} type={study.type.type} />
        <Header.Content>
          {study.name}
          {hasOneOfRoles([ROLE_ADMIN, ROLE_SERVICE_PROVIDER_PANEL, ROLE_SERVICE_PROVIDER_QUESTIONNAIRE]) && (
            <span className="text-muted"> ({study.organization.name})</span>
          )}
          <Header.Subheader className="mt-5">Erstellt am {study.createdAt}</Header.Subheader>
        </Header.Content>
      </Header>
    </Segment>
  );

export default StudyHeader;
