import React from 'react';
import {
  findModuleByKey,
  findValuesByKey,
  hasModule,
  Module,
  MODULE_ADVERTISING_EVALUATION_DIMENSIONS,
  MODULE_ADVERTISING_EVALUATION_FILES,
  MODULE_ADVERTISING_EVALUATION_TOGGLE,
} from '../../Modules';
import InfoMessage from '../../Ui/InfoMessage';
import ValidationError from '../../Ui/ValidationError';
import DimensionList from './DimensionList';
import FileList from './FileList';
import Section from './Section';

const Advertising = ({ configuration, values, errors, wave, deepLink, activeIndex }) => {
  const moduleName = Module.getComponentName(
    MODULE_ADVERTISING_EVALUATION_DIMENSIONS,
    configuration.modules,
    wave.study.type.type
  );

  if (hasModule(MODULE_ADVERTISING_EVALUATION_TOGGLE, configuration.modules)) {
    if (findValuesByKey(MODULE_ADVERTISING_EVALUATION_TOGGLE, values).values.on === false) {
      return (
        <Section headline={moduleName} deepLink={deepLink}>
          <InfoMessage>Wird nicht abgefragt.</InfoMessage>
        </Section>
      );
    }
  }

  return (
    <Section headline={moduleName} deepLink={deepLink}>
      <FileList
        files={findValuesByKey(MODULE_ADVERTISING_EVALUATION_FILES, values).values.files}
        errors={errors}
        wave={wave}
        activeIndex={activeIndex}
        property={'files[]'}
      />
      <ValidationError errors={errors} property="files" />
      <DimensionList
        module={findModuleByKey(MODULE_ADVERTISING_EVALUATION_DIMENSIONS, configuration.modules)}
        values={findValuesByKey(MODULE_ADVERTISING_EVALUATION_DIMENSIONS, values)}
        errors={errors}
      />
      <ValidationError errors={errors} property="dimensions" />
    </Section>
  );
};

export default Advertising;
