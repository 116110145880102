import React from 'react';
import ReactMarkdown from 'react-markdown';
import ReactMde from 'react-mde';
import 'react-mde/lib/styles/css/react-mde-all.css';
import { Form, Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';

class MarkdownRichTextEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tab: 'write',
    };
  }

  handleTabChange = (tab) => {
    this.setState({ tab });
  };

  render() {
    const { id, label, placeholder, value, onChange } = this.props;
    const { tab } = this.state;

    const customCommand = {
      name: 'line-break',
      icon: () => <Icon size="large">&crarr;</Icon>,
      execute: (opts) => {
        opts.textApi.replaceSelection(`${opts.initialState.selectedText}  \n`);
      },
    };

    return (
      <Form.Field>
        {/* eslint-disable-next-line react/no-danger, jsx-a11y/label-has-associated-control */}
        <label htmlFor={id} dangerouslySetInnerHTML={{ __html: label }} />
        <ReactMde
          name={id}
          id={id}
          commands={{ 'line-break': customCommand }}
          toolbarCommands={[['bold'], ['unordered-list', 'ordered-list'], ['line-break']]}
          l18n={{ write: 'Bearbeiten', preview: 'Vorschau' }}
          value={value}
          onChange={(value) => onChange(value)}
          rows={20}
          selectedTab={tab}
          onTabChange={this.handleTabChange}
          generateMarkdownPreview={(markdown) => Promise.resolve(<ReactMarkdown children={markdown} />)}
          childProps={{
            writeButton: {
              tabIndex: -1,
            },
            textArea: {
              placeholder: placeholder,
            },
          }}
        />
      </Form.Field>
    );
  }
}

MarkdownRichTextEditor.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default MarkdownRichTextEditor;
