import Cleave from 'cleave.js/react';
import React, { Fragment, useState } from 'react';
import {
  Button,
  Dropdown,
  Form,
  Header,
  Icon,
  Input,
  Label,
  Loader,
  Modal,
  Placeholder,
  Table,
  TextArea,
} from 'semantic-ui-react';
import { ErrorMessage, Formik } from 'formik';
import moment from 'moment';
import 'moment/locale/de';
import * as Yup from 'yup';
import { withHooks } from '@Classic/Hocs/withHooks';
import { addMessage } from '../Actions/messageActions';
import { store } from '../App';
import Client from '../Client';
import ModuleTypeIcon from '../Components/ModuleTypeIcon';
import Date from '../Components/Order/Date';
import Time from '../Components/Order/Time';
import Screen from '../Components/Screen';
import ScreenHeader from '../Components/ScreenHeader';
import download from '../Download';
import {
  hasOneOfRoles,
  hasRole,
  ROLE_ADMIN,
  ROLE_MARKET_RESEARCHER,
  ROLE_REPORTING_INTERESTED_PERSON,
  ROLE_SERVICE_PROVIDER_PANEL,
  ROLE_SERVICE_PROVIDER_QUESTIONNAIRE,
  ROLE_STUDENT,
} from '../Permissions';
import DefinitionList from '../Ui/DefinitionList';
import InputError from '../Ui/InputError';
import Popup from '../Ui/Popup';

moment.locale('de');

const parseCostStringToCent = (costString) => {
  if (costString === '') {
    return null;
  }

  let cost = costString.replace(/[\\.]/g, '');
  cost = cost.replace(',', '.');
  cost = parseFloat(cost);

  return parseInt((cost * 100).toFixed(), 10);
};

const parseCentToCostString = (cent) => {
  if (cent === null) {
    return '';
  }

  return (cent / 100).toLocaleString('de-DE', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

const parseDateStringToISOFormat = (dateString) => {
  if (dateString === '') {
    return null;
  }

  const date = moment(dateString, 'DD.MM.YYYY');

  return date.format('YYYY-MM-DD HH:mm:ss');
};

const parseISOFormatToString = (isoDateString) => {
  if (isoDateString === null) {
    return '';
  }

  const date = moment(isoDateString);

  return date.format('DD.MM.YYYY');
};

const formatPayload = (values) => ({
  fieldCosts: parseCostStringToCent(values.fieldCosts),
  setupCosts: parseCostStringToCent(values.setupCosts),
  tableCosts: parseCostStringToCent(values.tableCosts),
  dashboardCosts: parseCostStringToCent(values.dashboardCosts),
  powerpointCosts: parseCostStringToCent(values.powerpointCosts),
  otherCosts: parseCostStringToCent(values.otherCosts),
  gapfishBillDate: parseDateStringToISOFormat(values.gapfishBillDate),
  ergoDataBillDate: parseDateStringToISOFormat(values.ergoDataBillDate),
  comment: values.comment === '' ? null : values.comment,
});

const validate = (value) => {
  if (value === undefined) {
    return true;
  }

  const cent = parseCostStringToCent(value);

  if (Number.isNaN(cent)) {
    return false;
  }

  if (cent === null) {
    return true;
  }

  return cent >= 0;
};

const CostSchema = Yup.object().shape({
  fieldCosts: Yup.mixed().test('positive', 'Sie können keine negativen Kosten eintragen.', validate),
  setupCosts: Yup.mixed().test('positive', 'Sie können keine negativen Kosten eintragen.', validate),
  tableCosts: Yup.mixed().test('positive', 'Sie können keine negativen Kosten eintragen.', validate),
  dashboardCosts: Yup.mixed().test('positive', 'Sie können keine negativen Kosten eintragen.', validate),
  powerpointCosts: Yup.mixed().test('positive', 'Sie können keine negativen Kosten eintragen.', validate),
  otherCosts: Yup.mixed().test('positive', 'Sie können keine negativen Kosten eintragen.', validate),
});

const normalizeFilters = (originalFilter) => {
  const { organizationId, year } = originalFilter;
  let filter = {};

  if (organizationId !== 'all') {
    filter = { organizationId: organizationId };
  }

  if (year !== 'all') {
    filter = { ...filter, year: year };
  }

  return filter;
};

const getYearFilterOptions = () => {
  const options = [{ key: 'all', value: 'all', text: 'Keine Einschränkung' }];
  const oldestYear = 2019;

  for (let selectableYear = moment().format('YYYY'); selectableYear >= oldestYear; selectableYear -= 1) {
    options.push({ key: selectableYear, value: selectableYear, text: selectableYear });
  }

  return options;
};

class CostsScreen extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      updating: false,
      exporting: false,
      costs: [],
      organizations: [],
      sum: null,
      // Filters need to be synced for updates to the list
      filter: {
        organizationId: 'all',
        year: 'all',
      },
    };
  }

  componentDidMount() {
    const { navigate } = this.props;

    if (
      !hasOneOfRoles([
        ROLE_ADMIN,
        ROLE_REPORTING_INTERESTED_PERSON,
        ROLE_MARKET_RESEARCHER,
        ROLE_SERVICE_PROVIDER_QUESTIONNAIRE,
        ROLE_SERVICE_PROVIDER_PANEL,
        ROLE_STUDENT,
      ])
    ) {
      navigate('/');
      store.dispatch(addMessage('Sie haben keine Berechtigung diese Seite aufzurufen.', false, 'error'));

      return;
    }

    if (hasRole(ROLE_MARKET_RESEARCHER)) {
      this.getCostAccounting();
      return;
    }

    this.getCostAccountingAndOrganizations();
  }

  getCostAccounting = () => {
    Promise.all([Client.get('/cost-accounting/all')])
      .then((response) => {
        const [costAccounting] = response;
        const { costs, sum } = costAccounting.data;

        this.setState({ costs: costs, sum: sum, loading: false });
      })
      .catch((error) => {
        if (error.networkError) {
          return;
        }
        this.setState({ loading: false });
        this.setState({ loading: false });
        store.dispatch(addMessage('Beim Abruf der Daten ist ein Fehler aufgetreten.', false, 'error'));
      });
  };

  getCostAccountingAndOrganizations = () => {
    Promise.all([Client.get('/organization/list'), Client.get('/cost-accounting/all')])
      .then((response) => {
        const [organizations, costAccounting] = response;
        const { costs, sum } = costAccounting.data;

        this.setState({ organizations: organizations.data, costs: costs, sum: sum, loading: false });
      })
      .catch((error) => {
        if (error.networkError) {
          return;
        }
        this.setState({ loading: false });
        store.dispatch(addMessage('Beim Abruf der Daten ist ein Fehler aufgetreten.', false, 'error'));
      });
  };

  updateList = () => {
    const { filter } = this.state;
    const { organizationId, year } = filter;

    if (organizationId !== 'all' || year !== 'all') {
      this.filterCosts(filter);
      return;
    }

    this.setState({ updating: true });

    Client.get('/cost-accounting/all')
      .then((response) => {
        const { costs, sum } = response.data;
        this.setState({ costs: costs, sum: sum, updating: false });
      })
      .catch((error) => {
        if (error.networkError) {
          return;
        }
        this.setState({ updating: false });
        store.dispatch(addMessage('Bei der Aktualisierung der Kosten ist ein Fehler aufgetreten.', false, 'error'));
      });
  };

  filterCosts = (filter) => {
    console.log('Filtering...');
    this.setState({ updating: true });

    Client.get('/cost-accounting/filter', { params: normalizeFilters(filter) })
      .then((response) => {
        const { costs, sum } = response.data;
        this.setState({ costs: costs, sum: sum, updating: false });
      })
      .catch((error) => {
        if (error.networkError) {
          return;
        }
        this.setState({ updating: false });
        store.dispatch(addMessage('Bei der Aktualisierung der Kosten ist ein Fehler aufgetreten.', false, 'error'));
      });
  };

  exportCosts = (filter) => {
    this.setState({ exporting: true });

    Client({
      method: 'GET',
      url: '/cost-accounting/export',
      params: normalizeFilters(filter),
      responseType: 'blob',
    })
      .then((response) => {
        download(response.data, 'Kostenkalkulation.csv');
        this.setState({ exporting: false });
      })
      .catch(() => {
        this.setState({ exporting: false });
        store.dispatch(
          addMessage('Die Datei konnte nicht heruntergeladen werden. Bitte versuchen Sie es erneut.', false, 'error')
        );
      });
  };

  renderFilterForm = () => {
    const { loading, organizations, filter } = this.state;

    return (
      <Formik
        initialValues={filter}
        onSubmit={(values) => {
          this.filterCosts(values);
          this.setState({ filter: values });
        }}
        render={(formikProps) => (
          <Form onSubmit={formikProps.handleSubmit} className="mb-30">
            <Form.Group widths="equal" style={{ alignItems: 'flex-end' }}>
              {hasOneOfRoles([
                ROLE_ADMIN,
                ROLE_SERVICE_PROVIDER_PANEL,
                ROLE_SERVICE_PROVIDER_QUESTIONNAIRE,
                ROLE_STUDENT,
              ]) && (
                <Form.Field
                  error={formikProps.errors.organizationId && formikProps.touched.organizationId}
                  data-doc="app_costs_filter_by_organization"
                  data-cy="costs_filter_by_organization"
                >
                  <label htmlFor="organizationId">Organisation</label>
                  <Dropdown
                    selection
                    disabled={loading}
                    name="organizationId"
                    id="organizationId"
                    placeholder="Filtern Sie nach einer Organisation"
                    value={formikProps.values.organizationId}
                    onChange={(e, { value }) => formikProps.setFieldValue('organizationId', value)}
                    onBlur={() => formikProps.setFieldTouched('organizationId', true)}
                    options={[
                      { key: 'all', value: 'all', text: 'Alle' },
                      ...organizations.map((organization) => ({
                        key: organization.id,
                        value: organization.id,
                        text: organization.name,
                      })),
                    ]}
                  />
                </Form.Field>
              )}
              {hasOneOfRoles([
                ROLE_ADMIN,
                ROLE_MARKET_RESEARCHER,
                ROLE_SERVICE_PROVIDER_PANEL,
                ROLE_SERVICE_PROVIDER_QUESTIONNAIRE,
                ROLE_STUDENT,
              ]) && (
                <Fragment>
                  <Form.Field
                    error={formikProps.errors.year && formikProps.touched.year}
                    data-doc="app_costs_filter_by_year"
                    data-cy="costs_filter_by_year"
                  >
                    <label htmlFor="year">Jahr</label>
                    <Dropdown
                      selection
                      disabled={loading}
                      name="year"
                      id="year"
                      placeholder="Filtern Sie nach einem Jahr"
                      value={formikProps.values.year}
                      onChange={(e, { value }) => formikProps.setFieldValue('year', value)}
                      onBlur={() => formikProps.setFieldTouched('year', true)}
                      options={getYearFilterOptions()}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Button
                      primary
                      fluid
                      icon
                      className="mt-15"
                      type="submit"
                      labelPosition="right"
                      disabled={loading}
                      data-cy="costs_filter"
                    >
                      <Icon link name="sliders horizontal" />
                      Filter anwenden
                    </Button>
                  </Form.Field>
                </Fragment>
              )}
            </Form.Group>
          </Form>
        )}
      />
    );
  };

  renderTable = () => {
    const { loading, updating, exporting, filter } = this.state;

    return (
      <Table celled striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell textAlign="center" style={{ width: '5%' }}>
              <Loader active inline size="small" style={updating ? { opacity: 1 } : { opacity: 0 }} />
            </Table.HeaderCell>
            <Table.HeaderCell>Studie</Table.HeaderCell>
            <Table.HeaderCell>Welle</Table.HeaderCell>
            <Table.HeaderCell>Feldstart</Table.HeaderCell>
            <Table.HeaderCell>Kosten</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body data-cy="cost_entry_list">{this.renderCostEntries()}</Table.Body>
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan="4">
              <Button
                primary
                icon
                type="button"
                labelPosition="right"
                onClick={() => this.exportCosts(filter)}
                loading={exporting}
                disabled={loading || updating || exporting}
              >
                <Icon link name="download" />
                Exportieren
              </Button>
            </Table.HeaderCell>
            <Table.HeaderCell>
              <strong>{this.renderSum()}</strong>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    );
  };

  renderCostEntries = () => {
    const { loading, costs } = this.state;

    if (loading === true) {
      return <CostEntryPlaceholder />;
    }

    if (costs.length === 0) {
      return (
        <Table.Row>
          <Table.Cell colSpan={5} style={{ textAlign: 'center', height: '4rem' }}>
            Für die von Ihnen gewählten Filter liegen keine Daten vor.
          </Table.Cell>
        </Table.Row>
      );
    }

    return costs.map((cost) => <CostEntry key={cost.id} cost={cost} onUpdate={this.updateList} />);
  };

  renderSum = () => {
    const { loading, sum } = this.state;

    if (loading === true) {
      return (
        <Placeholder>
          <Placeholder.Header>
            <Placeholder.Line length="full" />
          </Placeholder.Header>
        </Placeholder>
      );
    }

    return <Price cent={sum} placeholder="Offen" />;
  };

  render() {
    return (
      <Screen title="Kostenkalkulation | Media Monitor Rapid">
        <ScreenHeader title="Kostenkalkulation" />
        {this.renderFilterForm()}
        {this.renderTable()}
      </Screen>
    );
  }
}

const CostEntry = ({ cost, onUpdate }) => {
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const invoiced = cost.gapfishBillDate !== null && cost.ergoDataBillDate !== null;

  return (
    <Fragment>
      <Table.Row negative={!invoiced && hasRole(ROLE_ADMIN)}>
        <Table.Cell style={{ position: 'relative', padding: 0 }}>
          <Button icon onClick={() => setOpen(!open)} style={{ background: 'transparent', width: '100%', margin: 0 }}>
            <Icon name={`chevron ${open === true ? 'up' : 'down'}`} />
          </Button>
        </Table.Cell>
        <Table.Cell>
          <div className="d-flex align-items-center">
            <ModuleTypeIcon type={cost.studyType.type} name={cost.studyType.name} />
            <div className="ml-5">
              <strong>{cost.studyName}</strong>
              <div>{cost.organizationName}</div>
            </div>
          </div>
        </Table.Cell>
        <Table.Cell>{cost.waveName}</Table.Cell>
        <Table.Cell>
          <Date date={cost.fieldStart} />
        </Table.Cell>
        <Table.Cell>
          <Price cent={cost.sumCosts} placeholder="Offen" />
          {!invoiced && hasRole(ROLE_ADMIN) && (
            <Popup label="Enthält offene Rechnungen" trigger={<Icon name="info circle" style={{ float: 'right' }} />} />
          )}
        </Table.Cell>
      </Table.Row>
      <Table.Row style={open === true ? { visibility: 'visible' } : { display: 'none' }}>
        <Table.Cell />
        <Table.Cell colSpan="4">
          {cost.updatedAt !== null && (
            <span className="text-muted" style={{ float: 'right' }}>
              Zuletzt aktualisiert am <Date date={cost.updatedAt} /> um <Time date={cost.updatedAt} /> Uhr.
            </span>
          )}
          <h3 className="mt-0">Kosten</h3>
          <DefinitionList style={{ width: '40%' }} inverted>
            <DefinitionList.Definition>Feldkosten</DefinitionList.Definition>
            <DefinitionList.Description style={{ textAlign: 'right' }}>
              <Price cent={cost.fieldCosts} placeholder="Offen" />
            </DefinitionList.Description>
            <DefinitionList.Definition>Setup & Datensatz</DefinitionList.Definition>
            <DefinitionList.Description style={{ textAlign: 'right' }}>
              <Price cent={cost.setupCosts} placeholder="Offen" />
            </DefinitionList.Description>
            <DefinitionList.Definition>Tabellenband</DefinitionList.Definition>
            <DefinitionList.Description style={{ textAlign: 'right' }}>
              <Price cent={cost.tableCosts} placeholder="Offen" />
            </DefinitionList.Description>
            <DefinitionList.Definition>Dashboard</DefinitionList.Definition>
            <DefinitionList.Description style={{ textAlign: 'right' }}>
              <Price cent={cost.dashboardCosts} placeholder="Offen" />
            </DefinitionList.Description>
            <DefinitionList.Definition>Powerpoint</DefinitionList.Definition>
            <DefinitionList.Description style={{ textAlign: 'right' }}>
              <Price cent={cost.powerpointCosts} placeholder="Offen" />
            </DefinitionList.Description>
            <DefinitionList.Definition>Sonstiges</DefinitionList.Definition>
            <DefinitionList.Description style={{ textAlign: 'right' }}>
              <Price cent={cost.otherCosts} placeholder="Offen" />
            </DefinitionList.Description>
            <DefinitionList.Definition
              style={{
                borderTop: '1px solid rgba(34, 36, 38, .1)',
                padding: '0.35rem 0',
                marginTop: '0.4rem',
              }}
            >
              Summe
            </DefinitionList.Definition>
            <DefinitionList.Description
              style={{
                borderTop: '1px solid rgba(34, 36, 38, .1)',
                padding: '0.35rem 0',
                marginTop: '0.4rem',
                textAlign: 'right',
              }}
            >
              <Price cent={cost.sumCosts} placeholder="Offen" />
            </DefinitionList.Description>
          </DefinitionList>
          {hasRole(ROLE_ADMIN) && (
            <Fragment>
              <h3>Rechnungen</h3>
              <DefinitionList style={{ width: '40%' }} inverted>
                <DefinitionList.Definition>Gapfish</DefinitionList.Definition>
                <DefinitionList.Description style={{ textAlign: 'right' }}>
                  <Date date={cost.gapfishBillDate} placeholder="Offen" />
                </DefinitionList.Description>
                <DefinitionList.Definition>Ergo-Data</DefinitionList.Definition>
                <DefinitionList.Description style={{ textAlign: 'right' }}>
                  <Date date={cost.ergoDataBillDate} placeholder="Offen" />
                </DefinitionList.Description>
              </DefinitionList>
            </Fragment>
          )}
          {cost.comment !== null && (
            <Fragment>
              <h3>Anmerkungen</h3>
              <p>{cost.comment}</p>
            </Fragment>
          )}
          {hasRole(ROLE_ADMIN) && (
            <Button
              primary
              icon
              labelPosition="right"
              className="my-15"
              onClick={() => setEdit(true)}
              data-doc="app_cost_accounting_update"
            >
              <Icon link name="edit" /> Kosten bearbeiten
            </Button>
          )}
        </Table.Cell>
      </Table.Row>
      {hasRole(ROLE_ADMIN) && (
        <Modal closeOnDimmerClick={false} size="tiny" open={edit} onClose={() => setEdit(false)}>
          <Header icon="write" content="Kosten aktualisieren" />
          <Formik
            validationSchema={CostSchema}
            initialValues={{
              fieldCosts: parseCentToCostString(cost.fieldCosts),
              setupCosts: parseCentToCostString(cost.setupCosts),
              tableCosts: parseCentToCostString(cost.tableCosts),
              dashboardCosts: parseCentToCostString(cost.dashboardCosts),
              powerpointCosts: parseCentToCostString(cost.powerpointCosts),
              otherCosts: parseCentToCostString(cost.otherCosts),
              gapfishBillDate: parseISOFormatToString(cost.gapfishBillDate),
              ergoDataBillDate: parseISOFormatToString(cost.ergoDataBillDate),
              comment: cost.comment === null ? '' : cost.comment,
            }}
            onSubmit={(values, actions) => {
              const payload = { id: cost.id, ...formatPayload(values) };

              Client.put(`/cost-accounting/${cost.id}/update`, payload)
                .then(() => {
                  onUpdate();
                  actions.setSubmitting(false);
                  setEdit(false);
                  store.dispatch(addMessage('Die Kosten wurden erfolgreich aktualisiert.'));
                })
                .catch((error) => {
                  if (error.networkError) {
                    return;
                  }
                  actions.setSubmitting(false);
                  setEdit(false);
                  store.dispatch(
                    addMessage('Bei der Aktualisierung der Kosten ist ein Fehler aufgetreten.', false, 'error')
                  );
                });
            }}
            render={(formikProps) => (
              <Form onSubmit={formikProps.handleSubmit}>
                <Modal.Content>
                  <CostInput formikProps={formikProps} name="fieldCosts" label="Feldkosten" />
                  <CostInput formikProps={formikProps} name="setupCosts" label="Setup & Datensatz" />
                  <CostInput formikProps={formikProps} name="tableCosts" label="Tabellenband" />
                  <CostInput formikProps={formikProps} name="dashboardCosts" label="Dashboard" />
                  <CostInput formikProps={formikProps} name="powerpointCosts" label="Powerpoint" />
                  <CostInput formikProps={formikProps} name="otherCosts" label="Sonstiges" />
                  <DateInput formikProps={formikProps} name="gapfishBillDate" label="Gapfish" />
                  <DateInput formikProps={formikProps} name="ergoDataBillDate" label="Ergo-Data" />
                  <Form.Field>
                    <label htmlFor="comment">Anmerkungen</label>
                    <TextArea
                      name="comment"
                      id="comment"
                      onChange={formikProps.handleChange}
                      value={formikProps.values.comment}
                      rows={3}
                    />
                  </Form.Field>
                </Modal.Content>
                <Modal.Actions>
                  <Button
                    type="button"
                    content="Abbrechen"
                    disabled={formikProps.isSubmitting}
                    onClick={() => setEdit(false)}
                  />
                  <Button
                    primary
                    type="submit"
                    content="Kosten aktualisieren"
                    disabled={formikProps.isSubmitting || !formikProps.isValid}
                    loading={formikProps.isSubmitting}
                    onClick={formikProps.handleSubmit}
                  />
                </Modal.Actions>
              </Form>
            )}
          />
        </Modal>
      )}
    </Fragment>
  );
};

const CostInput = ({ formikProps, name, label }) => (
  <Form.Field error={formikProps.errors[name] && formikProps.touched[name]} width={8}>
    <label htmlFor={name}>{label}</label>
    <Input labelPosition="right">
      <Cleave
        name={name}
        id={name}
        value={formikProps.values[name]}
        onChange={formikProps.handleChange}
        onBlur={formikProps.handleBlur}
        style={{ textAlign: 'right' }}
        options={{
          numeral: true,
          numeralDecimalMark: ',',
          delimiter: '.',
        }}
      />
      <Label>
        <Icon name="euro" style={{ margin: '0 0.25rem' }} />
      </Label>
    </Input>
    <InputError message={<ErrorMessage name={name} />} />
  </Form.Field>
);

const DateInput = ({ formikProps, name, label }) => (
  <Form.Field error={formikProps.errors[name] && formikProps.touched[name]} width={8}>
    <label htmlFor={name}>{label}</label>
    <Cleave
      options={{
        date: true,
        delimiter: '.',
        datePattern: ['d', 'm', 'Y'],
      }}
      placeholder="DD.MM.YYYY"
      name={name}
      id={name}
      value={formikProps.values[name]}
      onChange={formikProps.handleChange}
      onBlur={formikProps.handleBlur}
    />
    <InputError message={<ErrorMessage name={name} />} />
  </Form.Field>
);

const Price = ({ cent, placeholder = '-' }) =>
  cent === null ? placeholder : (cent / 100).toLocaleString('de-DE', { style: 'currency', currency: 'EUR' });

const CostEntryPlaceholder = () =>
  [1, 2, 3, 4, 5, 6, 7, 8].map((number) => (
    <Table.Row key={number}>
      <Table.Cell colSpan={5} style={{ height: '4rem' }}>
        <Placeholder fluid>
          <Placeholder.Header>
            <Placeholder.Line length="full" />
          </Placeholder.Header>
        </Placeholder>
      </Table.Cell>
    </Table.Row>
  ));

export default withHooks(CostsScreen);
