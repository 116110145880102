import React from 'react';
import { Label } from 'semantic-ui-react';
import Popup from '../Ui/Popup';

const states = {
  initial: {
    color: 'grey',
    translation: 'initial',
  },
  ordered: {
    color: 'teal',
    translation: 'beauftragt',
  },
  in_execution: {
    color: 'green',
    translation: 'in Umsetzung',
  },
  in_approval: {
    color: 'teal',
    translation: 'zur Abnahme',
  },
  approved: {
    color: 'green',
    translation: 'freigegeben',
  },
  in_field: {
    color: 'green',
    translation: 'bereit für Feld / im Feld',
  },
  completed: {
    color: 'blue',
    translation: 'abgeschlossen',
  },
  refused: {
    color: 'red',
    translation: 'verweigert',
  },
  aborted: {
    color: 'red',
    translation: 'abgebrochen',
  },
  canceled: {
    color: 'red',
    translation: 'storniert',
  },
  changed: {
    color: 'red',
    translation: 'Auftrag geändert',
  },
  last_minute: {
    color: 'teal',
    translation: 'last minute',
  },
};

const Status = ({ state, ...props }) => (
  <Popup
    label="Status"
    trigger={
      <Label {...props} horizontal color={states[state].color} data-cy="wave-status" data-state={state}>
        {states[state].translation}
      </Label>
    }
  />
);

export default Status;
