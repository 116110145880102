import React from 'react';
import { findModuleByKey, Module, MODULE_CUSTOMER_AND_INDUSTRY } from '../../Modules';
import DefinitionList from '../../Ui/DefinitionList';
import ValidationError from '../../Ui/ValidationError';
import List from './List';
import Placeholder from './Placeholder';
import Section from './Section';

const CustomerAndIndustry = ({ values, errors, wave, deepLink, configuration }) => {
  const { customer, topic, competitors, industry, purchaseProbability, industrySpecification, nestedIndustries } =
    values.values;
  const { isMediaOpal } = wave.study;

  const { nestedIndustries: nestedIndustriesConfiguration } = findModuleByKey(
    MODULE_CUSTOMER_AND_INDUSTRY,
    configuration.modules
  );
  let nestedIndustriesValues = [];
  if (nestedIndustriesConfiguration && nestedIndustries) {
    nestedIndustriesValues = nestedIndustries.map((value) => {
      const choiceSets = nestedIndustriesConfiguration.choiceSets.filter(
        (choiceSet) => choiceSet.key === value.parentValue
      );

      const choiceSet = choiceSets[0];

      return {
        ...value,
        parentLabel: choiceSet.label,
        childLabels: value.childValues.map((value) => {
          const choicesWithValue = choiceSet.choices.filter((choice) => choice.value === value);
          return choicesWithValue[0].label;
        }),
      };
    });
  }

  return (
    <Section
      headline={Module.getComponentName(MODULE_CUSTOMER_AND_INDUSTRY, configuration.modules, wave.study.type.type)}
      deepLink={deepLink}
    >
      <DefinitionList>
        <DefinitionList.Definition>Kunde</DefinitionList.Definition>
        <DefinitionList.Description>
          <Placeholder value={customer} />
        </DefinitionList.Description>
        <ValidationError errors={errors} property="customer" />

        {!isMediaOpal && (
          <>
            <DefinitionList.Definition>Wettbewerber</DefinitionList.Definition>
            <DefinitionList.Description>
              <List items={competitors} />
            </DefinitionList.Description>
            <ValidationError errors={errors} property="competitors" />

            <DefinitionList.Definition>
              {CustomerAndIndustry.getIndustryTitleByStudyType(wave.study.type.type)}
            </DefinitionList.Definition>
            <DefinitionList.Description>
              <Placeholder value={industry} />
            </DefinitionList.Description>
            <ValidationError errors={errors} property="industry" />

            {CustomerAndIndustry.showPurchaseProbabilityByStudyType(wave.study.type.type) && (
              <>
                <DefinitionList.Definition>Spezifikation des Zeitraumes</DefinitionList.Definition>
                <DefinitionList.Description>
                  <Placeholder value={purchaseProbability} />
                </DefinitionList.Description>
                <ValidationError errors={errors} property="purchaseProbability" />
              </>
            )}

            {CustomerAndIndustry.showTopicByStudyType(wave.study.type.type) && (
              <>
                <DefinitionList.Definition>
                  {CustomerAndIndustry.getTypeTitleByStudyType(wave.study.type.type)}
                </DefinitionList.Definition>
                <DefinitionList.Description>
                  <Placeholder value={topic} />
                </DefinitionList.Description>
                <ValidationError errors={errors} property="topic" />
              </>
            )}

            {CustomerAndIndustry.showIndustrySpecificationByStudyType(wave.study.type.type) && (
              <>
                <DefinitionList.Definition>
                  Spezifikation der Branche für Produktinteresse-Abfrage
                </DefinitionList.Definition>
                <DefinitionList.Description>
                  <Placeholder value={industrySpecification} />
                </DefinitionList.Description>
                <ValidationError errors={errors} property="industrySpecification" />
              </>
            )}
          </>
        )}
        {isMediaOpal && (
          <>
            <DefinitionList.Definition>{nestedIndustriesConfiguration.label}</DefinitionList.Definition>
            <DefinitionList.Description>
              {nestedIndustriesValues.map((value) => (
                <Placeholder key={value.parentValue} value={CustomerAndIndustry.getNestedIndustriesText(value)} />
              ))}
            </DefinitionList.Description>
            <ValidationError errors={errors} property="industry_specification" />
          </>
        )}
      </DefinitionList>
    </Section>
  );
};

CustomerAndIndustry.getNestedIndustriesText = (value) => {
  const childValues = value.childValues.map((val, idx) => `${value.childLabels[idx]} (${val})`).join(' + ');

  return `${value.parentLabel} (${value.parentValue}) ${childValues ? '>' : ''} ${childValues}`;
};

CustomerAndIndustry.getIndustryTitleByStudyType = (type) => {
  if (type === 'motive_test_brand_story') {
    return 'Spezifikation der Branche';
  }
  return 'Branche';
};

CustomerAndIndustry.getTypeTitleByStudyType = (type) => {
  if (
    type === 'easy_success_measurement' ||
    type === 'motive_test_campaign_check' ||
    type === 'motive_test_brand_story'
  ) {
    return 'Spezifikation der Branche für offene Nennungen';
  }
  return 'Thema der Befragung';
};

CustomerAndIndustry.showTopicByStudyType = (type) => {
  if (
    type === 'daily_sales' ||
    type === 'advertisement_copy_test' ||
    type === 'supplement_copy_test' ||
    type === 'motive_test_media_opal' ||
    type === 'quick_poll'
  ) {
    return false;
  }
  return true;
};

CustomerAndIndustry.showPurchaseProbabilityByStudyType = (type) => {
  if (type === 'motive_test_campaign_check' || type === 'motive_test_brand_story') {
    return true;
  }
  return false;
};

CustomerAndIndustry.showIndustrySpecificationByStudyType = (type) => {
  if (
    type === 'motive_test_campaign_check' ||
    type === 'motive_test_brand_story' ||
    type === 'daily_sales' ||
    type === 'advertisement_copy_test' ||
    type === 'supplement_copy_test' ||
    type === 'motive_test_media_opal' ||
    type === 'quick_poll'
  ) {
    return false;
  }
  return true;
};

export default CustomerAndIndustry;
