import React from 'react';
import InfoMessage from '../../Ui/InfoMessage';

const TestLink = ({ testLink, datacy, emptyMessage }) =>
  testLink === '' || testLink === null ? (
    <InfoMessage>{emptyMessage}</InfoMessage>
  ) : (
    <a
      href={testLink}
      target="_blank"
      rel="noopener noreferrer"
      style={{ overflowWrap: 'break-word' }}
      data-cy={datacy}
    >
      {testLink}
    </a>
  );

export default TestLink;
