import React from 'react';
import { Loader } from 'semantic-ui-react';

const ScreenLoader = () => (
  <div className="d-flex h-100 align-items-center">
    <Loader active inline="centered" size="large" />
  </div>
);

export default ScreenLoader;
