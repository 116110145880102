import React from 'react';
import { addMessage } from '../Actions/messageActions';
import { store } from '../App';
import Client from '../Client';
import download from '../Download';

class DownloadLink extends React.Component {
  onDownload = () => {
    const { url, name } = this.props;

    Client({
      method: 'GET',
      url: url,
      responseType: 'blob',
    })
      .then((response) => download(response.data, name))
      .catch(() => {
        store.dispatch(
          addMessage('Die Datei konnte nicht heruntergeladen werden. Bitte versuchen Sie es erneut.', false, 'error')
        );
      });
  };

  render() {
    const { name } = this.props;

    return (
      <button onClick={this.onDownload} onKeyPress={this.onDownload} className="as-link" type="button">
        {name}
      </button>
    );
  }
}

export default DownloadLink;
