import React from 'react';
import { Header, Segment } from 'semantic-ui-react';
import * as Sentry from '@sentry/browser';
import { Module, MODULE_CUSTOM_QUESTIONS_RICH_TEXT, MODULE_MEDIA_RETRIEVAL_DIMENSIONS } from '../Modules';
import DefinitionList from '../Ui/DefinitionList';
import InfoMessage from '../Ui/InfoMessage';
import CustomerAndIndustry from './Order/CustomerAndIndustry';
import Date from './Order/Date';
import Time from './Order/Time';

const FIELD_NAME_MAP = {
  start: 'Start',
  end: 'Ende',
  numberOfCases: 'Fallzahl',
  gender: 'Geschlecht',
  minAge: 'Mindestalter',
  maxAge: 'Maximalalter',
  quotaType: 'Quotierungstyp',
  quota: 'Quotierungsvorgabe',
  options: 'Optionen',
  questions: 'Fragen',
  targetGroupId: 'ID',
  expectedResponses: 'Verfügbare Fälle',
  calculatedAt: 'Geprüft am',
  on: 'Wird abgefragt',
  files: 'Dateien',
  media: 'Medien',
  targetGroup: 'Zielgruppenspezifikation',
  quotaRequirement: 'Quotierungsvorgaben',
  customRegion: 'Eigene Region',
  customRegionName: 'Eigene Region',
  pricing: 'Preis bzw. Preis-Leistung',
  trust: 'Trust',
  excellency: 'Exzellenz',
  emotion: 'Emotion',
  sustainability: 'Nachhaltigkeit / Social Responsibility',
  region: 'Region',
  custom_items: 'Eigene Items',
  custom_items_product_interest: 'Produktinteresse (bearbeitbar)',
  additional_items: 'Weitere Items',
  brand_match: 'Markenmatch',
  clarity: 'Klarheit',
  excellency_visuality: 'Visuelle Exzellenz',
  understandability: 'Verständlichkeit',
  activation: 'Aktivierung',
  notes: 'Anmerkungen',
  topic: CustomerAndIndustry.getTypeTitleByStudyType,
  competitors: 'Wettbewerber',
  industry: CustomerAndIndustry.getIndustryTitleByStudyType,
  purchaseProbability: 'Spezifikation des Zeitraumes',
  customer: 'Kunde',
  specification: 'Beilageoption',
  objectOfInvestigation: 'Untersuchungsgegenstand',
  industrySpecification: CustomerAndIndustry.getIndustrySpecificationByStudyType,
  mediaDimension: 'Titelabfrage',
  mediaEPaper: 'Zusätzliche Medien abfragen',
  dateSingle: 'Erscheinungstag',
  nestedIndustries: 'Branchenspezifizierung',
  creative_evaluation: 'Gestalterische Beurteilung',
  custom_items_activation: 'Aktivierung',
  defaults: 'Anzeigenart',
  colour: 'Farbe',
  department: 'Resort der Anzeige',
  format: 'Anzeigenformat',
  special: 'Sonderformat Anzeigen',
};

const groupUpdatesBySection = (updates, wave, configuration) => {
  const studyType = wave?.study?.type?.type;
  const sections = {};
  Module.getUniqueNamesByStudyType(studyType, configuration.modules).forEach((name) => {
    sections[name] = [];
  });

  updates.forEach((update) => {
    const sectionName = Module.getComponentName(update.itemKey, configuration.modules, studyType, 'Sonstige');
    const section = sections[sectionName];

    if (section === undefined) {
      Sentry.captureMessage(
        `Could not find a section by section name "${sectionName}" ("${update.itemKey}") in <ChangeHistory />.`,
        Sentry.Severity.Error
      );
      return;
    }

    let label = update.field;
    if (typeof FIELD_NAME_MAP[update.field] === 'string') {
      label = FIELD_NAME_MAP[update.field];
    } else if (typeof FIELD_NAME_MAP[update.field] === 'function') {
      label = FIELD_NAME_MAP[update.field](wave.study.type.type);
    }

    sections[sectionName] = [
      ...section,
      {
        ...update,
        label: label,
      },
    ];
  });

  for (const sectionName in sections) {
    if (Object.prototype.hasOwnProperty.call(sections, sectionName) === false) {
      continue;
    }

    sections[sectionName] = sections[sectionName].sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }

      if (a.label > b.label) {
        return 1;
      }

      return 0;
    });
  }

  return sections;
};

const HistorySection = ({ entry, regions, wave, configuration }) => (
  <div style={{ marginBottom: '45px' }}>
    <Header as="h3" className="mt-15" style={{ fontWeight: 100 }}>
      Am <Date date={entry.createdAt} /> wurden um <Time date={entry.createdAt} /> Uhr folgenden Änderung beauftragt:
    </Header>
    {entry.updates.length > 0 ? (
      <SectionUpdates updates={entry.updates} wave={wave} regions={regions} configuration={configuration} />
    ) : (
      <InfoMessage>
        Hinweis: Bei der letzten Konfiguration der Welle wurden keine Spezifikationen bzw. Inhalte geändert.
      </InfoMessage>
    )}
  </div>
);

const SectionUpdates = ({ updates, regions, wave, configuration }) => {
  const groupedUpdates = groupUpdatesBySection(updates, wave, configuration);

  return Object.keys(groupedUpdates).map((sectionName) => {
    if (groupedUpdates[sectionName].length === 0) {
      return null;
    }

    return (
      <div key={sectionName}>
        <Header dividing as="h3" className="border-bottom-dotted mt-30">
          {sectionName}
        </Header>
        {groupedUpdates[sectionName].map((update) => (
          <Change
            key={update.id}
            itemKey={update.itemKey}
            field={update.field}
            label={update.label}
            beforeValue={update.beforeValue}
            afterValue={update.afterValue}
            regions={regions}
            configuration={configuration}
          />
        ))}
      </div>
    );
  });
};

const formatValue = (field, value, regions, dimensions) => {
  if (field === 'on') {
    return value === '1' ? 'Ja' : 'Nein';
  }

  if (field === 'region' && value !== '') {
    const region = regions.find((region) => region.id === value);

    if (region === undefined) {
      return 'Unbekannte Region';
    }

    return region.name;
  }

  if (['start', 'end', 'calculatedAt', 'dateSingle'].includes(field)) {
    return <Date date={value} />;
  }

  if (field === 'gender' && value !== '') {
    const GENDER_MAP = {
      1: 'männlich',
      2: 'weiblich',
    };

    return value
      .split(',')
      .map((gender) => GENDER_MAP[parseInt(gender, 10)])
      .join(', ');
  }

  if (dimensions) {
    const dimension = dimensions.find((o) => o.key === field);

    const keyValue = [];
    dimension.items.forEach((item, idx) => {
      if (dimension.labels) {
        keyValue.push({ key: item, value: dimension.labels[idx] });
      }
    });

    if (keyValue.length > 0 && keyValue.find((o) => o.key === value)) {
      return `${keyValue.find((o) => o.key === value).value} (${value})`;
    }
  }

  return value;
};

const Change = ({ itemKey, field, label, beforeValue, afterValue, regions, configuration }) => {
  if (field === 'customRegionName') {
    return null;
  }

  // Custom display logic for file changes
  if (['customRegion', 'quotaRequirement', 'files'].includes(field)) {
    return (
      <DefinitionList>
        <DefinitionList.Definition>{label}</DefinitionList.Definition>
        <DefinitionList.Description>
          <code className="text-danger">Die Datei/en hat/haben sich geändert.</code>
        </DefinitionList.Description>
      </DefinitionList>
    );
  }

  if (itemKey === MODULE_MEDIA_RETRIEVAL_DIMENSIONS) {
    return (
      <DefinitionList>
        <DefinitionList.Definition>{label}</DefinitionList.Definition>
        <DefinitionList.Description>
          <span className="d-flex align-items-center">
            <code className="text-danger">{beforeValue === '' ? '-' : formatValue(field, beforeValue, regions)}</code>
            <code className="text-muted" style={{ margin: '0 10px' }}>
              =&gt;
            </code>
            <code className="text-success">{afterValue === '' ? '-' : formatValue(field, afterValue, regions)}</code>
          </span>
        </DefinitionList.Description>
      </DefinitionList>
    );
  }

  if (itemKey === MODULE_CUSTOM_QUESTIONS_RICH_TEXT) {
    return (
      <DefinitionList>
        <DefinitionList.Definition>{label}</DefinitionList.Definition>
        <DefinitionList.Description>
          <span className="d-flex align-items-center">
            <pre className="text-danger" style={{ whiteSpace: 'break-spaces' }}>
              {beforeValue === '' ? '-' : formatValue(field, beforeValue, regions)}
            </pre>
            <pre className="text-muted" style={{ margin: '0 10px', whiteSpace: 'break-spaces' }}>
              =&gt;
            </pre>
            <pre className="text-success" style={{ whiteSpace: 'break-spaces' }}>
              {afterValue === '' ? '-' : formatValue(field, afterValue, regions)}
            </pre>
          </span>
        </DefinitionList.Description>
      </DefinitionList>
    );
  }

  const { dimensions } = configuration.modules.find((o) => o.key === itemKey);
  return (
    <DefinitionList>
      <DefinitionList.Definition>{label}</DefinitionList.Definition>
      <DefinitionList.Description>
        <span className="d-flex align-items-center">
          <code className="text-danger">
            {beforeValue === '' ? '-' : formatValue(field, beforeValue, regions, dimensions)}
          </code>
          <code className="text-muted" style={{ margin: '0 10px' }}>
            =&gt;
          </code>
          <code className="text-success">
            {afterValue === '' ? '-' : formatValue(field, afterValue, regions, dimensions)}
          </code>
        </span>
      </DefinitionList.Description>
    </DefinitionList>
  );
};

const ChangeHistory = ({ history, wave, regions, configuration }) => (
  <Segment attached>
    {history.map((entry) => (
      <HistorySection key={entry.id} entry={entry} wave={wave} regions={regions} configuration={configuration} />
    ))}
  </Segment>
);

export default ChangeHistory;
