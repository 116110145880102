/* eslint-disable import/no-named-default */
import React, { Fragment } from 'react';
import { Dimmer, Header, Icon, Loader } from 'semantic-ui-react';
import axios from 'axios';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

const CITY_LIST = [
  { name: 'Berlin', latitude: 52.5170365, longitude: 13.3888599 },
  { name: 'Bremen', latitude: 53.0758196, longitude: 8.8071646 },
  { name: 'Dresden', latitude: 51.0493286, longitude: 13.7381437 },
  { name: 'Düsseldorf', latitude: 51.2254018, longitude: 6.7763137 },
  { name: 'Erfurt', latitude: 50.9777974, longitude: 11.0287364 },
  { name: 'Hamburg', latitude: 53.550341, longitude: 10.000654 },
  { name: 'Hannover', latitude: 52.3744779, longitude: 9.7385532 },
  { name: 'Kiel', latitude: 54.3227085, longitude: 10.135555 },
  { name: 'Magdeburg', latitude: 52.1315889, longitude: 11.6399609 },
  { name: 'Mainz', latitude: 50.0012314, longitude: 8.2762513 },
  { name: 'München', latitude: 48.1371079, longitude: 11.5753822 },
  { name: 'Potsdam', latitude: 52.4009309, longitude: 13.0591397 },
  { name: 'Saarbrücken', latitude: 49.234362, longitude: 6.996379 },
  { name: 'Schwerin', latitude: 53.6288297, longitude: 11.4148038 },
  { name: 'Stuttgart', latitude: 48.7784485, longitude: 9.1800132 },
  { name: 'Wiesbaden', latitude: 50.0820384, longitude: 8.2416556 },
];

const getLayerOptions = (zips) => ({
  onEachFeature: function (feature, layer) {
    if (Object.prototype.hasOwnProperty.call(feature.properties, 'note') === true) {
      layer.bindTooltip(feature.properties.note);
    }
  },
  style: (feature) => {
    const style = {
      color: '#000', // stroke
      weight: 0.5, // stroke-width
      // opacity: 0.9, // stroke-opacity
      fillColor: '#FFF', // fill
      fillOpacity: 1, // fill-opacity
    };

    const zip = feature.properties.plz;

    // 5 digit zips
    if (zips.includes(zip)) {
      return {
        ...style,
        color: '#000',
        fillColor: '#00a0de',
      };
    }

    return style;
  },
});

class Map extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      initialized: false,
      error: false,
    };
  }

  componentDidMount() {
    const { zips } = this.props;
    const PUBLIC_URL = import.meta.env.VITE_BASE_URL;

    Promise.all([
      axios(`${PUBLIC_URL}/geo/2_digit_zips.json`),
      axios(`${PUBLIC_URL}/geo/3_digit_zips.json`),
      axios(`${PUBLIC_URL}/geo/5_digit_zips.json`),
    ])
      .then((response) => {
        const [ZIPS_2, ZIPS_3, ZIPS_4] = response;
        this.initializeMap(zips, ZIPS_2.data, ZIPS_3.data, ZIPS_4.data);
        this.setState({ initialized: true });
      })
      .catch((error) => {
        if (error.networkError) {
          this.setState({ error: true });
        }
        // this.setState({ error: true });
      });
  }

  initializeMap = (zips, ZIPS_2, ZIPS_3, ZIPS_5) => {
    const relevant = [];

    // Disabled due to false positive for iteration variable "zip"
    // eslint-disable-next-line no-unused-vars
    for (const zip of zips) {
      const data = ZIPS_5.features.find((data) => data.properties.plz === zip);

      if (data === undefined) {
        continue;
      }

      relevant.push(data);
    }

    const ZIPS_2_MERGED = { ...ZIPS_2, features: [...ZIPS_2.features, ...relevant] };
    const ZIPS_3_MERGED = { ...ZIPS_3, features: [...ZIPS_3.features, ...relevant] };

    // Layers
    const LAYER_2 = L.geoJSON(ZIPS_2_MERGED, getLayerOptions(zips));
    const LAYER_3 = L.geoJSON(ZIPS_3_MERGED, getLayerOptions(zips));
    const LAYER_5 = L.geoJSON(ZIPS_5, getLayerOptions(zips));

    const map = L.map('leaflet-container', {
      center: [51.2, 10],
      zoom: 6.5,
      minZoom: 6.5,
      maxZoom: 12,
    });

    for (const city of CITY_LIST) {
      const cityIcon = L.divIcon({
        className: 'leaflet-city-name',
        html: `<div>${city.name}</div>`,
      });
      L.marker([city.latitude, city.longitude], { icon: cityIcon }).addTo(map);
    }

    LAYER_2.addTo(map);

    map.on('zoomend', () => {
      // Clear all layers before
      map.eachLayer((layer) => {
        if (layer instanceof L.GeoJSON) {
          map.removeLayer(layer);
        }
      });

      const zoom = map.getZoom();

      if (zoom <= 7) {
        LAYER_2.addTo(map);
      } else if (zoom > 7 && zoom < 10) {
        LAYER_3.addTo(map);
      } else {
        LAYER_5.addTo(map);
      }
    });
  };

  render() {
    const { initialized, error } = this.state;

    if (error === true) {
      return (
        <Header
          icon
          style={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <Icon name="warning sign" />
          Fehler beim Laden der Karte.
        </Header>
      );
    }

    return (
      <Fragment>
        <Dimmer inverted active={initialized === false}>
          <Loader inverted indeterminate size="large">
            Karte wird geladen ...
          </Loader>
        </Dimmer>
        <div
          id="leaflet-container"
          style={{
            height: '100%',
            width: '100%',
            background: '#FFF',
          }}
        />
      </Fragment>
    );
  }
}

Map.defaultProps = {
  zips: [],
};

export default Map;
