import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import UserModel from '@Api/models/UserModel.ts';
import tokenStorage from '@Api/TokenStorage.ts';
import Client from '@Classic/Client.js';

interface UserStoreState {
  data?: UserModel;
  isLoading?: boolean;
  error?: any;
  login: (email: string, password: string) => Promise<any>;
  logout: () => void;
  setLoading: (isLoading: boolean) => void;
}

export const useUserStore = create<UserStoreState>()(
  persist(
    (set) => ({
      data: undefined,
      isLoading: false,
      error: null,
      login: async (email: string, password: string) => {
        const response = await Client.post('/login', { email, password });

        console.log('Response: ', response);
        return new Promise((resolve, reject) => {
          try {
            const user = UserModel.hydrateFromApi(response.data);
            tokenStorage.setToken(user.authToken);

            set({ data: user, isLoading: false });
            resolve(user);
          } catch (err) {
            console.log('err', err);
            set({ error: err, isLoading: false });
            reject(err);
          }
        });
      },
      logout: async () => {
        try {
          await Client.post('/logout');
          set({ data: undefined, isLoading: false, error: null });
          tokenStorage.logout();
        } catch (err) {
          set({ error: err, isLoading: false });
        }
      },
      setLoading: (isLoading: boolean) => set({ isLoading }),
    }),
    {
      name: 'user-storage', // name of the item in the storage (must be unique)
    }
  )
);
