import AppProvider from '@Provider/AppProvider.tsx';
import '@Classic/App.scss';
import '@Classic/semantic.min.css';
import './App.css';

function App() {
  return <AppProvider />;
}

export default App;
