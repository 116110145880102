import React from 'react';
import { Button, Dropdown, Form, Icon } from 'semantic-ui-react';
import { ErrorMessage, Formik } from 'formik';
import InputError from '../Ui/InputError';

class OrganizationForm extends React.PureComponent {
  constructor(props) {
    super(props);

    this.input = React.createRef();
  }

  componentDidMount() {
    this.input.current.focus();
  }

  render() {
    const { loading, blueprints, validationSchema, initialValues, submitLabel, onSubmit } = this.props;

    return (
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => onSubmit(values, actions)}
        render={(
          {
            errors,
            touched,
            values,
            isSubmitting,
            handleSubmit,
            handleChange,
            handleBlur,
            setFieldValue,
            setFieldTouched,
          } // eslint-disable-line max-len
        ) => (
          <Form onSubmit={handleSubmit}>
            <Form.Field width={8} error={errors.name && touched.name}>
              <label htmlFor="name">Name</label>
              <input
                type="text"
                name="name"
                id="name"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                ref={this.input}
              />
              <InputError message={<ErrorMessage name="name" />} />
            </Form.Field>

            <Form.Field width={8} error={errors.customerId && touched.customerId}>
              <label htmlFor="name">Kundennummer</label>
              <input
                type="text"
                name="customerId"
                id="customerId"
                value={values.customerId}
                onChange={handleChange}
                onBlur={handleBlur}
                ref={this.input}
              />
              <InputError message={<ErrorMessage name="customerId" />} />
            </Form.Field>

            <Form.Field width={8} error={errors.customerExportLabel && touched.customerExportLabel}>
              <label htmlFor="name">Ausgaben</label>
              <input
                type="text"
                name="customerExportLabel"
                id="customerExportLabel"
                value={values.customerExportLabel}
                onChange={handleChange}
                onBlur={handleBlur}
                ref={this.input}
              />
              <InputError message={<ErrorMessage name="customerExportLabel" />} />
            </Form.Field>

            <Form.Field width={8} error={errors.blueprints && touched.blueprints} data-cy="blueprints">
              <label htmlFor="blueprints">Studientypen</label>
              <Dropdown
                selection
                multiple
                loading={loading}
                disabled={loading}
                name="blueprints"
                id="blueprints"
                placeholder="Wählen Sie die Studientypen für diese Organisation"
                value={values.blueprints}
                onChange={(e, { value }) => setFieldValue('blueprints', value)}
                onBlur={() => setFieldTouched('blueprints', true)}
                options={blueprints.map((blueprint) => ({
                  key: blueprint.studyType,
                  value: blueprint.id,
                  text: blueprint.name,
                }))}
              />
              <InputError message={<ErrorMessage name="modules" />} />
            </Form.Field>

            <Button
              primary
              icon
              className="mt-15"
              type="submit"
              labelPosition="right"
              loading={isSubmitting}
              disabled={isSubmitting}
            >
              <Icon link name="save outline" />
              {submitLabel}
            </Button>
          </Form>
        )}
      />
    );
  }
}

export default OrganizationForm;
