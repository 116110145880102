import React, { Fragment } from 'react';
import { Button, Form, Icon, Input } from 'semantic-ui-react';

const onAdd = (values, onChange) => {
  onChange([...values, '']);
};

const onRemove = (index, values, onChange) => {
  const newValues = [...values];
  newValues.splice(index, 1);

  onChange(newValues);
};

const onUpdate = (index, values, value, onChange) => {
  const newValues = [...values];
  newValues[index] = value;

  onChange(newValues);
};

const renderFieldList = (values, onChange, dataCyName) =>
  values.map((value, index) => (
    <Form.Field key={index} /* eslint-disable-line react/no-array-index-key */ style={{ marginBottom: '0.5rem' }}>
      <Input
        type="text"
        value={value}
        onChange={(event) => onUpdate(index, values, event.target.value, onChange)}
        data-cy={`input-${index}-${dataCyName}`}
        action={
          <Button icon type="button" color="red" onClick={() => onRemove(index, values, onChange)}>
            <Icon name="trash alternate outline" />
          </Button>
        }
      />
    </Form.Field>
  ));

const renderAddButton = (values, limit, addButtonText, onChange, dataCyName) => {
  if (values.length >= limit) {
    return null;
  }

  return (
    <Button
      fluid
      icon="plus"
      labelPosition="right"
      type="button"
      content={addButtonText}
      onClick={() => onAdd(values, onChange)}
      data-cy={`button-${dataCyName}`}
    />
  );
};

const FieldList = ({ values, limit, onChange, addButtonText = 'Hinzufügen', dataCyName }) => (
  <Fragment>
    {renderFieldList(values, onChange, dataCyName)}
    {renderAddButton(values, limit, addButtonText, onChange, dataCyName)}
  </Fragment>
);

export default FieldList;
