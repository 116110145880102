import React from 'react';
import { Loader } from 'semantic-ui-react';
import * as Yup from 'yup';
import { withHooks } from '@Classic/Hocs/withHooks';
import { addMessage } from '../Actions/messageActions';
import { store } from '../App';
import Client, { handleRequestError } from '../Client';
import Screen from '../Components/Screen';
import ScreenHeader from '../Components/ScreenHeader';
import UpdateBlueprintForm from '../Forms/UpdateBlueprintForm';
import { hasRole, ROLE_ADMIN } from '../Permissions';

const UpdateBlueprintSchema = Yup.object().shape({
  name: Yup.string().required('Bitte geben Sie einen Namen an.'),
  description: Yup.string().required('Bitte geben Sie eine Beschreibung ein.'),
});

class UpdateBlueprintScreen extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      blueprint: null,
      organizations: [],
    };

    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    const { params, navigate } = this.props;
    const { uuid } = params; // eslint-disable-line react/destructuring-assignment

    if (hasRole(ROLE_ADMIN) === false) {
      navigate('/');
      store.dispatch(addMessage('Sie haben keine Berechtigung diese Seite aufzurufen.', false, 'error'));
    }

    this.fetchData(uuid);
  }

  fetchData(id) {
    const { navigate } = this.props;

    Promise.all([Client.get(`/blueprint/detail/${id}`), Client.get('/organization/list')])
      .then((response) => {
        const [blueprint, organizations] = response;

        this.setState({
          loading: false,
          blueprint: blueprint.data,
          organizations: organizations.data,
        });
      })
      .catch((error) => {
        if (error.code === 404) {
          store.dispatch(addMessage('Die Blaupause existiert nicht.', true, 'error'));
          navigate('/blueprint');
        }
        this.setState({ loading: false });
      });
  }

  submit(values, actions) {
    // eslint-disable-line class-methods-use-this
    const { uuid } = this.props.params; // eslint-disable-line react/destructuring-assignment

    Client.post('/blueprint/update', { id: uuid, ...values })
      .then(() => {
        actions.setSubmitting(false);
        store.dispatch(addMessage('Die Blaupause wurde erfolgreich bearbeitet.'));
      })
      .catch((error) => {
        handleRequestError(error, actions);
      });
  }

  render() {
    const { loading, blueprint, organizations } = this.state;

    if (loading) {
      return (
        <Screen title="Blaupause bearbeiten | Media Monitor Rapid">
          <ScreenHeader title="Blaupause bearbeiten" hasBackButton />
          <Loading />
        </Screen>
      );
    }

    return (
      <Screen title="Blaupause bearbeiten | Media Monitor Rapid">
        <ScreenHeader title="Blaupause bearbeiten" hasBackButton={false} />
        <UpdateBlueprintForm
          loading={loading}
          organizations={organizations}
          validationSchema={UpdateBlueprintSchema}
          onSubmit={this.submit}
          submitLabel="Blaupause aktualisieren"
          initialValues={{
            name: blueprint.name,
            description: blueprint.description,
            type: blueprint.type,
            organizationId: blueprint.organizationId,
          }}
        />
      </Screen>
    );
  }
}

const Loading = () => <Loader active>Blaupause wird geladen ...</Loader>;

export default withHooks(UpdateBlueprintScreen);
