import React from 'react';
import { Placeholder, Segment } from 'semantic-ui-react';
import NewsListEntry from './NewsListEntry';

const Loading = () =>
  [1, 2, 3].map((index) => (
    <Segment key={index} className="my-15">
      <Placeholder fluid>
        <Placeholder.Header>
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder.Header>
        <Placeholder.Paragraph>
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder.Paragraph>
      </Placeholder>
    </Segment>
  ));

const NewsList = ({ news, loading, removeFromList }) =>
  loading ? (
    <Loading />
  ) : (
    <div role="list">
      {news.map((newsEntry, index) => (
        <NewsListEntry
          key={newsEntry.id}
          newsEntry={newsEntry}
          removeFromList={removeFromList}
          isInitialOpen={index === 0}
        />
      ))}
    </div>
  );

export default NewsList;
