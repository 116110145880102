import React from 'react';
import { Button, Header, Loader, Modal } from 'semantic-ui-react';
import Client from '../../Client';
import { hasRole, ROLE_ADMIN } from '../../Permissions';

class OpalInfoModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      copied: false,
      loading: false,
      opalInfo: '',
    };
  }

  componentDidMount() {
    const { wave } = this.props;

    if (wave.study.isMediaOpal && hasRole(ROLE_ADMIN) === true) {
      this.setState({
        loading: true,
      });
      Client.get(`/wave/${wave.id}/opal-info`, { id: wave.id })
        .then((response) => {
          this.setState({
            loading: false,
            opalInfo: response.data,
          });
        })
        .catch(() => {
          this.setState({
            loading: false,
            opalInfo: 'Entschuldigung, beim abrufen des Opal-Info-String ist leider ein Fehler aufgetreten.',
          });
        });
    }
  }

  copyContent = () => {
    const { opalInfo } = this.state;
    navigator.clipboard.writeText(opalInfo).then(
      () => {
        this.setState({ copied: true });
        setTimeout(() => {
          this.setState({ copied: false });
        }, 2500);
      },
      (err) => {
        console.error('Async: Could not copy text: ', err);
      }
    );
  };

  render = () => {
    const { loading, copied, opalInfo } = this.state;
    const { opalInfoModalOpenStatus } = this.props;

    return (
      <Modal size="small" open onClose={() => opalInfoModalOpenStatus(false)}>
        <Header icon="code" content="Opal-Info-String" />
        <Modal.Content style={{ minHeight: '30vh' }}>
          {loading && <Loader active>Lade...</Loader>}
          {!loading && (
            <pre>
              <code data-cy="code-opal-info-string">
                <div dangerouslySetInnerHTML={{ __html: opalInfo }} />
              </code>
            </pre>
          )}
        </Modal.Content>
        <Modal.Actions>
          {!loading && (
            <Button
              content={copied ? 'Kopiert' : 'Kopieren'}
              onClick={this.copyContent}
              icon={copied ? 'check' : undefined}
              data-cy="opal-info-modal-copy"
            />
          )}
          <Button content="Schliessen" onClick={() => opalInfoModalOpenStatus(false)} data-cy="opal-info-modal-close" />
        </Modal.Actions>
      </Modal>
    );
  };
}

export default OpalInfoModal;
