import React from 'react';
import { findValuesByKey, Module, MODULE_TITLE_SPECIFICATION_FILES } from '../../Modules';
import ValidationError from '../../Ui/ValidationError';
import FileList from './FileList';
import Section from './Section';

const TitleSpecification = ({ values, errors, wave, deepLink, activeIndex, configuration }) => (
  <Section
    headline={Module.getComponentName(MODULE_TITLE_SPECIFICATION_FILES, configuration.modules, wave.study.type.type)}
    deepLink={deepLink}
  >
    <FileList
      files={findValuesByKey(MODULE_TITLE_SPECIFICATION_FILES, values).values.files}
      errors={errors}
      wave={wave}
      activeIndex={activeIndex}
    />
    <ValidationError errors={errors} property="files" />
  </Section>
);

export default TitleSpecification;
