import { useEffect, useState } from 'react';
import CheckboxButtonForm from '@Classic/Forms/CheckboxButtonForm.jsx';
import { hasRole, ROLE_ADMIN } from '../../Permissions';
import Section from './Section';

const AllowedPanelProvider = ({
  wave,
  availablePanelProviders,
  selectedPanelProviders,
  isPanelProviderUpdating,
  onChangePanelProvider,
  updateAllowedPanelProvider,
}) => {
  if (!hasRole(ROLE_ADMIN)) {
    return <></>;
  }

  const [panelProviders, setPanelProviders] = useState(null);

  const options = [
    ...availablePanelProviders.map((panelProvider) => ({
      text: `(${panelProvider.organization.name}) ${panelProvider.lastName}, ${panelProvider.firstName} `,
      value: panelProvider.id,
    })),
  ];

  const filterSelectedProvider = (panelProvider) => {
    return selectedPanelProviders.includes(panelProvider.id);
  };

  useEffect(() => {
    const newPanelProviders = availablePanelProviders?.filter(filterSelectedProvider) ?? null;

    setPanelProviders(newPanelProviders);
  }, [availablePanelProviders, selectedPanelProviders, wave]);

  const renderPanelProviders = (panelProviders) => {
    if (Array.isArray(panelProviders) && panelProviders.length > 0) {
      return (
        <div className={'description'}>
          TEST
          <ul style={{ listStylePosition: 'inside', paddingLeft: '1rem' }}>
            {panelProviders.map((panelProvider) => (
              <li key={panelProvider.id}>
                {panelProvider.lastName}, {panelProvider.firstName} <small>({panelProvider.organization.name})</small>
              </li>
            ))}
          </ul>
        </div>
      );
    }
    return <div>Es wurde kein Panelprovider ausgewählt.</div>;
  };

  const allowedState = ['initial', 'ordered', 'changed'];

  return (
    <Section headline={'Panel Provider'}>
      {allowedState.includes(wave.state) ? (
        <CheckboxButtonForm
          name="panelProviderSelection"
          submitLabel="Panel Provider speichern"
          options={options}
          onChange={onChangePanelProvider}
          initialValue={selectedPanelProviders}
          isSubmitting={isPanelProviderUpdating}
          onSubmit={updateAllowedPanelProvider}
        />
      ) : (
        <div className={'description'}>{renderPanelProviders(panelProviders)}</div>
      )}
    </Section>
  );
};

export default AllowedPanelProvider;
