import React from 'react';
import { Grid } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import File from './File';

const FileList = ({ files, onRemove, wave, hasTitle, showAsNamedUploadHeadline, label, allFiles, onChange }) =>
  files.map((file) => {
    const placeholder = allFiles[file.key]?.placeholder || allFiles[0]?.placeholder || '';

    return (
      <Grid.Column key={file.fileName} style={{ marginTop: '1rem' }}>
        <File
          file={file}
          onRemove={onRemove}
          wave={wave}
          onChange={onChange}
          label={label}
          hasTitle={hasTitle}
          showAsNamedUploadHeadline={showAsNamedUploadHeadline}
          placeholder={placeholder}
        />
      </Grid.Column>
    );
  });

FileList.propTypes = {
  wave: PropTypes.shape({}).isRequired,
  onRemove: PropTypes.func.isRequired,
  files: PropTypes.arrayOf({
    fileName: PropTypes.string,
    type: PropTypes.string,
    originalName: PropTypes.string,
    title: PropTypes.string,
    mimeType: PropTypes.string,
  }),
  hasTitle: PropTypes.bool.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func,
};

export default FileList;
