import React, { Fragment } from 'react';
import { Button, Header, Icon, Message, Modal } from 'semantic-ui-react';
import {
  hasOneOfRoles,
  hasRole,
  ROLE_ADMIN,
  ROLE_MARKET_RESEARCHER,
  ROLE_SERVICE_PROVIDER_PANEL,
  ROLE_SERVICE_PROVIDER_QUESTIONNAIRE,
} from '../Permissions';
import FallbackMessage from './FallbackMessage';
import OpalInfoModal from './Order/OpalInfoModal';

const isAllowed = (transition, transitions) => transitions.includes(transition);
const isAlreadyOrdered = (transitions) => !transitions.includes('order');

class Transitions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      orderOpen: false,
      cancellationOpen: false,
      abortionOpen: false,
      changeOpen: false,
      opalInfoOpen: false,
      executeOpen: false,
    };
  }

  renderOrderModalText = () => {
    const { color, feasibility, wave } = this.props;

    const waveTypeName = wave.study.isMediaOpal ? 'Studie' : 'Welle';

    if (hasRole(ROLE_ADMIN) === true && feasibility !== null) {
      const { calculatedAt, expectedResponses } = feasibility;
      if (calculatedAt === null && expectedResponses === null) {
        return (
          <React.Fragment>
            <Message warning>
              <Icon name="warning sign" />
              Die {waveTypeName} befindet sich im Status <strong>ungeprüft</strong>.
              <br />
              <br />
              {`Soll die ${waveTypeName} dennoch beauftragt werden?`}
            </Message>
            <AllowedPanelProvider {...wave} />
          </React.Fragment>
        );
      }

      if (color === 'red') {
        return (
          <React.Fragment>
            <Message warning>
              <Icon name="warning sign" />
              Die Machbarkeitsprüfung hat eine <strong>zu geringe Fallzahl</strong> ergeben.
              <br />
              <br />
              Soll die Welle dennoch beauftragt werden?
            </Message>
            <AllowedPanelProvider {...wave} />
          </React.Fragment>
        );
      }
    }

    if (wave.study.isMediaOpal) {
      return (
        <Fragment>
          <p>
            Mit der Beauftragung wird die Studie verbindlich bestellt, im Falle einer nachträglichen Änderung oder
            Stornierung bitte <strong>direkt</strong> mit Ihrem ZMG-Ansprechpartner Kontakt aufnehmen.
          </p>
          <p>{`Bei der Beauftragung der ${waveTypeName} fallen Kosten an.`}</p>
          <AllowedPanelProvider {...wave} />
        </Fragment>
      );
    }

    return (
      <Fragment>
        <p>
          Bitte <strong>unbedingt</strong> vor Beauftragung der {waveTypeName} die Quotenvorgaben mit einem
          ZMG-Ansprechpartner abstimmen!
        </p>
        <p>{`Bei der Beauftragung der ${waveTypeName} fallen Kosten an.`}</p>
        <AllowedPanelProvider {...wave} />
      </Fragment>
    );
  };

  opalInfoModalOpenStatus = (status) => {
    this.setState({ opalInfoOpen: status });
  };

  render() {
    const { orderOpen, cancellationOpen, abortionOpen, changeOpen, opalInfoOpen, executeOpen } = this.state;
    const {
      order,
      execute,
      requestApproval,
      approve,
      lastMinute,
      start,
      complete,
      cancel,
      refuse,
      abort,
      change,
      wave,
    } = this.props;
    const waveTypeName = wave.study.isMediaOpal ? 'Studie' : 'Welle';
    const { transitions, allowedPanelProvider } = wave;
    const panelProviders = allowedPanelProvider && allowedPanelProvider.length > 0 ? allowedPanelProvider : null;

    return (
      <Fragment>
        <FallbackMessage wave={wave} data-cy="message" />
        {isAllowed('order', transitions) &&
          hasOneOfRoles([
            ROLE_ADMIN,
            ROLE_MARKET_RESEARCHER,
            ROLE_SERVICE_PROVIDER_QUESTIONNAIRE,
            ROLE_SERVICE_PROVIDER_PANEL,
          ]) && (
            <Button
              primary
              fluid
              size="tiny"
              icon="cart"
              content={`${waveTypeName} beauftragen`}
              onClick={() => this.setState({ orderOpen: true })}
              className="mt-5"
              data-doc="app_wave_transition_order"
              data-cy="transition_order"
            />
          )}
        {isAllowed('execute', transitions) &&
          hasOneOfRoles([ROLE_ADMIN, ROLE_SERVICE_PROVIDER_PANEL, ROLE_SERVICE_PROVIDER_QUESTIONNAIRE]) && (
            <Button
              primary
              fluid
              size="tiny"
              icon="play"
              content="Umsetzung starten"
              className="mt-5"
              onClick={() => this.setState({ executeOpen: true })}
              data-doc="app_wave_transition_execute"
              data-cy="transition_execute"
            />
          )}
        {isAllowed('request_approval', transitions) &&
          hasOneOfRoles([ROLE_ADMIN, ROLE_SERVICE_PROVIDER_PANEL, ROLE_SERVICE_PROVIDER_QUESTIONNAIRE]) && (
            <Button
              primary
              fluid
              size="tiny"
              icon="stop"
              content="Umsetzung beenden"
              className="mt-5"
              onClick={requestApproval}
              data-doc="app_wave_transition_request_approval"
              data-cy="transition_request_approval"
            />
          )}
        {isAllowed('abort', transitions) &&
          hasOneOfRoles([ROLE_ADMIN, ROLE_SERVICE_PROVIDER_QUESTIONNAIRE, ROLE_SERVICE_PROVIDER_PANEL]) && (
            <Button
              fluid
              color="red"
              size="tiny"
              icon="cancel"
              content={`${waveTypeName} abbrechen`}
              className="mt-5"
              onClick={() => this.setState({ abortionOpen: true })}
              data-doc="app_wave_transition_abort"
              data-cy="transition_abort"
            />
          )}
        {isAllowed('approve', transitions) &&
          hasOneOfRoles([
            ROLE_ADMIN,
            ROLE_MARKET_RESEARCHER,
            ROLE_SERVICE_PROVIDER_QUESTIONNAIRE,
            ROLE_SERVICE_PROVIDER_PANEL,
          ]) && (
            <Button
              primary
              fluid
              size="tiny"
              icon="unlock"
              content={`${waveTypeName} freigeben`}
              className="mt-5"
              onClick={approve}
              data-doc="app_wave_transition_approve"
              data-cy="transition_approve"
            />
          )}
        {isAllowed('refuse', transitions) &&
          hasOneOfRoles([
            ROLE_ADMIN,
            ROLE_MARKET_RESEARCHER,
            ROLE_SERVICE_PROVIDER_QUESTIONNAIRE,
            ROLE_SERVICE_PROVIDER_PANEL,
          ]) && (
            <Button
              primary
              fluid
              size="tiny"
              icon="ban"
              content="Fragebogen überarbeiten."
              className="mt-5"
              onClick={refuse}
              data-doc="app_wave_transition_refuse"
              data-cy="transition_refuse"
            />
          )}
        {isAllowed('to_in_field', transitions) &&
          hasOneOfRoles([ROLE_ADMIN, ROLE_SERVICE_PROVIDER_PANEL, ROLE_SERVICE_PROVIDER_QUESTIONNAIRE]) && (
            <Button
              primary
              fluid
              size="tiny"
              icon="play"
              content="Feldphase programmiert/Feldphase"
              className="mt-5"
              onClick={start}
              data-doc="app_wave_transition_toinfield"
              data-cy="transition_toinfield"
            />
          )}
        {isAllowed('complete', transitions) &&
          hasOneOfRoles([ROLE_ADMIN, ROLE_SERVICE_PROVIDER_PANEL, ROLE_SERVICE_PROVIDER_QUESTIONNAIRE]) && (
            <Button
              primary
              fluid
              size="tiny"
              icon="stop"
              content="Feldphase abschliessen"
              className="mt-5"
              onClick={complete}
              data-doc="app_wave_transition_complete"
              data-cy="transition_complete"
            />
          )}
        {isAllowed('cancel', transitions) &&
          hasOneOfRoles([
            ROLE_ADMIN,
            ROLE_MARKET_RESEARCHER,
            ROLE_SERVICE_PROVIDER_QUESTIONNAIRE,
            ROLE_SERVICE_PROVIDER_PANEL,
          ]) && (
            <Button
              fluid
              color="red"
              size="tiny"
              icon="cancel"
              content={`${waveTypeName} stornieren`}
              className="mt-5"
              onClick={() => this.setState({ cancellationOpen: true })}
              data-doc="app_wave_transition_cancel"
              data-cy="transition_cancel"
            />
          )}
        {isAllowed('change', transitions) &&
          hasOneOfRoles([
            ROLE_ADMIN,
            ROLE_MARKET_RESEARCHER,
            ROLE_SERVICE_PROVIDER_QUESTIONNAIRE,
            ROLE_SERVICE_PROVIDER_PANEL,
          ]) && (
            <Button
              fluid
              color="red"
              size="tiny"
              icon="edit outline"
              content="Auftrag ändern"
              className="mt-5"
              onClick={() => this.setState({ changeOpen: true })}
              data-doc="app_wave_transition_change"
              data-cy="transition_change"
            />
          )}
        {wave.study.isMediaOpal && isAllowed('to_last_minute', transitions) && hasOneOfRoles([ROLE_ADMIN]) && (
          <Button
            primary
            fluid
            size="tiny"
            icon="unlock"
            content={`${waveTypeName} 'last minute' bearbeiten`}
            className="mt-5"
            onClick={lastMinute}
            data-doc="app_wave_transition_last_minute"
            data-cy="transition_last_minute"
          />
        )}
        {wave.study.isMediaOpal && isAlreadyOrdered(transitions) && hasOneOfRoles([ROLE_ADMIN]) && (
          <Fragment>
            <Button
              fluid
              color="grey"
              size="tiny"
              icon="code"
              content="Opal-Info-String anzeigen"
              className="mt-5"
              onClick={() => this.opalInfoModalOpenStatus(true)}
              data-doc="app_wave_opal_info"
              data-cy="open-opal-info-modal"
            />
            {opalInfoOpen && <OpalInfoModal wave={wave} opalInfoModalOpenStatus={this.opalInfoModalOpenStatus} />}
          </Fragment>
        )}
        <Modal size="tiny" open={orderOpen} onClose={() => this.setState({ orderOpen: false })}>
          <Header icon="cart" content={`${waveTypeName} beauftragen`} />
          <Modal.Content>{this.renderOrderModalText()}</Modal.Content>
          <Modal.Actions>
            <Button content="Abbrechen" onClick={() => this.setState({ orderOpen: false })} />
            <Button
              primary
              content="Beauftragen"
              data-cy="button-modal-force-order-transition"
              onClick={() => {
                this.setState({ orderOpen: false });
                order();
              }}
            />
          </Modal.Actions>
        </Modal>
        <Modal size="tiny" open={cancellationOpen} onClose={() => this.setState({ cancellationOpen: false })}>
          <Header icon="cancel" content={`${waveTypeName} stornieren bestätigen`} />
          <Modal.Content>
            <p>
              {`Diese Aktion kann nicht rückgängig gemacht werden. 
                 Bitte bestätigen Sie die Stornierung der ${waveTypeName}`}
            </p>
          </Modal.Content>
          <Modal.Actions>
            <Button content="Abbrechen" onClick={() => this.setState({ cancellationOpen: false })} />
            <Button
              color="red"
              content="Stornieren"
              onClick={() => {
                this.setState({ cancellationOpen: false });
                cancel();
              }}
            />
          </Modal.Actions>
        </Modal>
        <Modal size="tiny" open={abortionOpen} onClose={() => this.setState({ abortionOpen: false })}>
          <Header icon="cancel" content={`${waveTypeName} abbrechen`} />
          <Modal.Content>
            <p>
              {`Diese Aktion kann nicht rückgängig gemacht werden.
              Bitte bestätigen Sie, dass Sie die Umsetzung der ${waveTypeName} abbrechen möchten.`}
            </p>
          </Modal.Content>
          <Modal.Actions>
            <Button content="Abbrechen" onClick={() => this.setState({ abortionOpen: false })} />
            <Button
              color="red"
              content="Bestätigen"
              onClick={() => {
                this.setState({ abortionOpen: false });
                abort();
              }}
            />
          </Modal.Actions>
        </Modal>
        <Modal size="tiny" open={changeOpen} onClose={() => this.setState({ changeOpen: false })}>
          <Header icon="edit outline" content="Auftrag ändern" />
          <Modal.Content>
            <p>Bitte bestätigen Sie, dass Sie den Auftrag ändern möchten.</p>
          </Modal.Content>
          <Modal.Actions>
            <Button content="Abbrechen" onClick={() => this.setState({ changeOpen: false })} />
            <Button
              color="red"
              content="Bestätigen"
              data-cy="button-modal-change-order"
              onClick={() => {
                this.setState({ changeOpen: false });
                change();
              }}
            />
          </Modal.Actions>
        </Modal>
        <Modal open={executeOpen} onClose={() => this.setState({ executeOpen: false })}>
          <Header icon="cart" content={`${waveTypeName} beauftragen`} />
          <Modal.Content>
            <AllowedPanelProvider {...wave} />
          </Modal.Content>
          <Modal.Actions>
            <Button content="Abbrechen" onClick={() => this.setState({ executeOpen: false })} />
            <Button
              primary
              content="Umsetzung starten"
              data-cy="button-modal-force-order-transition"
              disabled={panelProviders === null}
              onClick={() => {
                this.setState({ executeOpen: false });
                execute();
              }}
            />
          </Modal.Actions>
        </Modal>
      </Fragment>
    );
  }
}

function AllowedPanelProvider(wave) {
  if (hasRole(ROLE_ADMIN) === false) {
    return false;
  }

  const { allowedPanelProvider } = wave;

  const panelProviders = allowedPanelProvider && allowedPanelProvider.length > 0 ? allowedPanelProvider : null;

  if (panelProviders === null) {
    return (
      <Message warning>
        Sie haben <strong>keinen</strong> Panel-Dienstleister ausgewählt.
      </Message>
    );
  }

  return (
    <Message>
      <h4>Sie haben folgenden Panel-Dienstleister ausgewählt:</h4>
      <ul style={{ paddingLeft: '20px' }}>
        {panelProviders.map((panelProvider) => (
          <li key={panelProvider.id}>
            <strong>
              {panelProvider.firstName} {panelProvider.lastName}
            </strong>
            {` `}
            <a href={'mailto: {allowedPanelProvider.email}'}>{panelProvider.email}</a>
          </li>
        ))}
      </ul>
    </Message>
  );
}

export default Transitions;
