import React from 'react';
import { Button, Header, Icon, Image, Modal, Segment } from 'semantic-ui-react';
import Client from '../../Client';
import download from '../../Download';
import { ACTIVE_INDEX_ORDERED } from './active-index';

class File extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      url: null,
      binary: null,
      fetching: true,
      open: false,
      error: false,
    };

    this.renderFile = this.renderFile.bind(this);
  }

  componentDidMount() {
    const { file, wave, activeIndex } = this.props;

    let url = `/wave/${wave.id}/file/${file.fileName}?ordered=${0}`;

    if (wave.state === 'changed' && activeIndex === ACTIVE_INDEX_ORDERED) {
      url = `/wave/${wave.id}/file/${file.fileName}?ordered=${1}`;
    }

    Client({
      method: 'GET',
      url: url,
      responseType: 'blob',
    })
      .then((response) => {
        const file = response.data;

        this.setState({
          url: window.URL.createObjectURL(file),
          binary: file,
          fetching: false,
        });
      })
      .catch(() => this.setState({ error: true, fetching: false }));
  }

  renderFile() {
    const { url, binary, open, error } = this.state;
    const { file } = this.props;

    if (error === true) {
      return (
        <Segment placeholder>
          <Header icon>
            <Icon name="warning circle" />
            Fehler beim Laden der Datei.
          </Header>
        </Segment>
      );
    }

    if (file.mimeType === 'application/pdf') {
      return (
        <React.Fragment>
          <Segment placeholder attached>
            <Header icon>
              <Icon name="pdf file outline" />
            </Header>

            <Modal size="large" open={open} onClose={() => this.setState({ open: false })}>
              <Header icon="pdf file outline" content="Vorschau" />
              <Modal.Content style={{ height: '80vh' }}>
                <object
                  style={{ width: '100%', height: '100%' }}
                  data={url}
                  type={file.type}
                  name={file.originalName}
                  aria-label="PDF Vorschau"
                />
              </Modal.Content>
              <Modal.Actions>
                <Button content="Schließen" onClick={() => this.setState({ open: false })} />
              </Modal.Actions>
            </Modal>
          </Segment>
          <Button.Group attached="bottom" size="small">
            <Button
              labelPosition="right"
              icon="eye"
              content="Vorschau"
              data-cy="button-file-list-download-pdf"
              onClick={() => this.setState({ open: true })}
            />
          </Button.Group>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <Segment placeholder attached style={{ alignItems: 'center' }}>
          {file.mimeType === 'application/zip' ? (
            <Header icon>
              <Icon name="file outline" />
              <h6>{file.originalName}</h6>
            </Header>
          ) : (
            <Image fluid src={url} style={{ maxHeight: '200px', width: 'auto', flexShrink: 0 }} />
          )}
        </Segment>
        <Button.Group attached="bottom" size="small">
          <Button
            labelPosition="right"
            icon="download"
            content="Herunterladen"
            data-cy="button-file-list-download-image"
            onClick={() => download(binary, file.originalName)}
          />
        </Button.Group>
      </React.Fragment>
    );
  }

  render() {
    const { fetching } = this.state;

    return fetching === true ? <Segment placeholder loading style={{ margin: 0 }} /> : this.renderFile();
  }
}

export default File;
