import React from 'react';
import { Grid, Message, Segment, Loader as Spinner } from 'semantic-ui-react';
import { store } from '@Classic/App.jsx';
import AllowedPanelProvider from '@Classic/Components/Order/AllowedPanelProvider.jsx';
import Feasibility from '@Classic/Components/Order/Feasibility.jsx';
import ManualFeasibility from '@Classic/Components/Order/ManualFeasibility.jsx';
import { addMessage } from '../../Actions/messageActions';
import Client from '../../Client';
import { findModuleByKey, findValuesByKey, MODULE_FEASIBILITY } from '../../Modules';
import {
  hasOneOfRoles,
  hasRole,
  ROLE_ADMIN,
  ROLE_MARKET_RESEARCHER,
  ROLE_SERVICE_PROVIDER_QUESTIONNAIRE,
} from '../../Permissions';
import ValidationError from '../../Ui/ValidationError';
import ChangedOrderTransitions from '../ChangedOrderTransitions';
import { ACTIVE_INDEX_CONFIGURABLE } from './active-index';
import Section from './Section';
import Summary from './Summary';

class ChangedOrder extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: {},
      values: null,
      color: null,
      // Loading states
      fetching: true,
      updating: false,
      checkingFeasibility: false,
      hasFeasibilityComponent: false,
      selectedPanelProviders: [],
    };

    this.fetchValues = this.fetchValues.bind(this);
    this.abortChange = this.abortChange.bind(this);
    this.orderChanges = this.orderChanges.bind(this);
    this.cancel = this.cancel.bind(this);
    this.checkFeasibility = this.checkFeasibility.bind(this);
    this.setFeasibility = this.setFeasibility.bind(this);
    this.onChangePanelProvider = this.onChangePanelProvider.bind(this);
    this.updateAllowedPanelProvider = this.updateAllowedPanelProvider.bind(this);
    this.initSelectedPanelProvider = this.initSelectedPanelProvider.bind(this);
  }

  componentDidMount() {
    const { wave, configuration } = this.props;

    const hasFeasibilityComponent = findModuleByKey(MODULE_FEASIBILITY, configuration.modules) !== null;

    this.initSelectedPanelProvider();

    Promise.all([Client.get(`/wave/${wave.id}/values`)])
      .then((response) => {
        const [values] = response;

        this.updateFieldLength(values.data);

        const state = {
          values: values.data,
          hasFeasibilityComponent: hasFeasibilityComponent,
        };

        if (hasFeasibilityComponent) {
          this.setState(state);
          this.fetchFeasibility();
        } else {
          this.setState({
            ...state,
            fetching: false,
          });
        }
      })
      .catch((error) => {
        if (error.networkError) {
          return;
        }
        this.setState({ fetching: false });
      });
  }

  initSelectedPanelProvider = () => {
    if (!hasOneOfRoles([ROLE_ADMIN])) return;
    const { wave, availablePanelProviders } = this.props;

    let selectedPanelProviders = [];
    if (Array.isArray(wave.allowedPanelProvider) && wave.allowedPanelProvider.length > 0) {
      selectedPanelProviders = wave.allowedPanelProvider;
    }

    this.setState({ selectedPanelProviders: selectedPanelProviders.map((panelProvider) => panelProvider.id) });
  };

  onChangePanelProvider = (value) => {
    this.setState({ selectedPanelProviders: value });
  };

  updateAllowedPanelProvider = (values) => {
    const { wave, updateWave } = this.props;

    this.setState({ isPanelProviderUpdating: true });

    const userIds = values ?? [];
    Client.put(`/wave/${wave.id}/allowed-panel-provider`, { id: wave.id, version: wave.version, userIds: userIds })
      .then((response) => {
        updateWave(response.data);
        this.setState({ isPanelProviderUpdating: false });
        store.dispatch(addMessage('Der Panel Provider wurde erfolgreich gespeichert.'));
      })
      .catch((error) => {
        if (error.networkError) {
          return;
        }
        this.setState({ isPanelProviderUpdating: false });

        error.data.forEach((error) => {
          store.dispatch(addMessage(error.message, true, 'error'));
        });
      });
  };

  // Syncs field length up to parent component as the WaveHeader is in there and field length depends on selected Tab
  updateFieldLength = (values) => {
    const { syncFieldLength } = this.props;
    const { values: fieldLength } = findValuesByKey('field_length_and_sample', values);

    syncFieldLength(fieldLength.start, fieldLength.end, fieldLength.dateSingle);
  };

  setError = (error, message = 'Bitte überprüfen Sie Ihre Eingabe.') => {
    if (error.code === 500) {
      store.dispatch(addMessage(`${message}: ${error.message}`, true, 'error'));
      this.setState({ updating: false });
    } else {
      store.dispatch(addMessage(message, true, 'error'));
      this.setState({ errors: error.data, updating: false });
    }
  };

  fetchFeasibility() {
    const { wave } = this.props;

    Client.get(`/wave/${wave.id}/ordered-feasibility`).then((color) => {
      this.setState({
        color: color.data,
        fetching: false,
      });
    });
  }

  // Werte und Machbarkeit aktualisieren
  fetchValues() {
    const { wave } = this.props;

    Promise.all([Client.get(`/wave/${wave.id}/values`), Client.get(`/wave/${wave.id}/feasibility`)])
      .then((response) => {
        const [values, color] = response;

        this.updateFieldLength(values.data);
        this.setState({
          values: values.data,
          color: color.data,
          checkingFeasibility: false,
          updating: false,
        });
      })
      .catch((error) => {
        if (error.networkError) {
          return;
        }
        this.setState({ updating: false });
      });
  }

  // Änderung abbrechen
  abortChange() {
    const { wave, updateWave, resetTabIndex } = this.props;

    this.setState({ updating: true });

    Client.post(`/wave/${wave.id}/abortchange`, { id: wave.id, version: wave.version })
      .then((response) => {
        this.setState({ updating: false });
        updateWave(response.data);
        resetTabIndex();
        store.dispatch(
          addMessage('Die Änderungen wurden erfolgreich verworfen und der vorherige Status wiederhergestellt.')
        );
      })
      .catch((error) => {
        this.setError(error, 'Die Änderung konnte nicht abgebrochen werden.');
      });
  }

  // Änderung beauftragen
  orderChanges() {
    const { wave, updateWave, resetTabIndex, updateHistory } = this.props;

    this.setState({ updating: true });

    Client.post(`/wave/${wave.id}/orderchanges`, { id: wave.id, version: wave.version })
      .then((response) => {
        const wave = response.data;

        if (hasOneOfRoles([ROLE_ADMIN, ROLE_MARKET_RESEARCHER, ROLE_SERVICE_PROVIDER_QUESTIONNAIRE])) {
          Client.get(`/wave/${wave.id}/history`).then((response) => {
            const history = response.data;

            this.setState({ updating: false, errors: {} });
            updateWave(wave);
            updateHistory(history);
            resetTabIndex();
            store.dispatch(addMessage('Die Änderungen wurden erfolgreich beauftragt.'));
          });

          return;
        }

        this.setState({ updating: false });
        updateWave(wave);
        resetTabIndex();
        store.dispatch(addMessage('Die Änderungen wurden erfolgreich beauftragt.'));
      })
      .catch((error) => {
        this.setError(error, 'Die Änderung konnte nicht beauftrag werden');
      });
  }

  // Welle stornieren
  cancel() {
    const { wave, updateWave, resetTabIndex } = this.props;

    this.setState({ updating: true });

    Client.post(`/wave/${wave.id}/cancel`, { id: wave.id, version: wave.version })
      .then((response) => {
        this.setState({ updating: false });
        updateWave(response.data);
        resetTabIndex();
        store.dispatch(addMessage('Die Welle wurde erfolgreich storniert.'));
      })
      .catch((error) => {
        this.setError(error, 'Die Welle konnte nicht storniert werden.');
      });
  }

  // Machbarkeit einer Welle prüfen
  checkFeasibility() {
    const { wave, updateWave } = this.props;

    this.setState({ checkingFeasibility: true });

    Client.post(`/wave/${wave.id}/check-feasibility`, { id: wave.id, version: wave.version })
      .then((response) => {
        updateWave(response.data);
        this.fetchValues();

        // Removes feasibility validation error triggered by trying to order a wave without checked feasibility
        this.setState((prevState) => {
          const previousState = { ...prevState };
          if (previousState.errors && previousState.errors.feasibility) {
            delete previousState.errors.feasibility;
          }

          return {
            ...previousState,
          };
        });
      })
      .catch((error) => {
        // When the request did not fail due to validation errors
        if (error.code !== 450) {
          this.setState({ checkingFeasibility: false });
          store.dispatch(
            addMessage(
              'Bei der Prüfung der Machbarkeit ist ein unerwarteter Fehler aufgetreten. ' +
                'Bitte versuchen Sie es später erneut oder kontaktieren Sie einen Administrator.',
              false,
              'error'
            )
          );

          return;
        }

        this.setState({ errors: error.data, checkingFeasibility: false });
      });
  }

  // Machbarkeit einer Welle festlegen
  setFeasibility(feasibilityData) {
    const { wave, updateWave } = this.props;
    const { expectedResponses, state } = feasibilityData;

    this.setState({ checkingFeasibility: true });

    Client.post(`/wave/${wave.id}/set-feasibility`, {
      id: wave.id,
      version: wave.version,
      expectedResponses,
      state,
    })
      .then((response) => {
        updateWave(response.data);
        this.fetchValues();

        // Removes feasibility validation error triggered by trying to order a wave without checked feasibility
        this.setState((prevState) => {
          const previousState = { ...prevState };
          if (previousState.errors && previousState.errors.feasibility) {
            delete previousState.errors.feasibility;
          }

          return {
            ...previousState,
          };
        });
      })
      .catch((error) => {
        if (error.networkError) {
          return;
        }

        this.setState({ errors: error.data, checkingFeasibility: false });
      });
  }

  renderErrorMessage = () => {
    const { errors } = this.state;

    if (Object.keys(errors).length === 0) {
      return '';
    }
    return (
      <Message negative>
        <p>Bitte überprüfen Sie Ihre Eingabe.</p>
        {errors.feasibility && errors.feasibility.map((error) => <p>{error.message}</p>)}
      </Message>
    );
  };

  render() {
    const { regions, configuration, wave, availablePanelProviders } = this.props;
    const {
      errors,
      values,
      fetching,
      color,
      checkingFeasibility,
      updating,
      hasFeasibilityComponent,
      selectedPanelProviders,
      isPanelProviderUpdating,
    } = this.state;

    if (fetching) {
      return <Loader />;
    }

    let feasibility = null;
    if (hasFeasibilityComponent) {
      feasibility = findValuesByKey('feasibility', values).values;
    }

    return (
      <Segment attached loading={updating} data-cy="changedorder">
        <Grid stackable>
          <Grid.Row>
            <Grid.Column width={10}>
              {this.renderErrorMessage()}
              <Summary
                // props
                wave={wave}
                regions={regions}
                configuration={configuration}
                activeIndex={ACTIVE_INDEX_CONFIGURABLE}
                // state
                values={values}
                errors={errors}
              />
            </Grid.Column>
            <Grid.Column width={5} floated="right">
              <Section headline="Aktionen" padded={false}>
                <ChangedOrderTransitions
                  cancel={this.cancel}
                  abortChange={this.abortChange}
                  orderChanges={this.orderChanges}
                  wave={wave}
                  color={color}
                  feasibility={feasibility}
                />
              </Section>
              {hasFeasibilityComponent && (
                <Section headline="Machbarkeit">
                  <ValidationError errors={errors} property="feasibility" />
                  <ValidationError errors={errors} property="color" />
                  <Feasibility
                    wave={wave}
                    color={color}
                    feasibility={feasibility}
                    checkingFeasibility={checkingFeasibility}
                    data-doc="app_wave_feasibility_check"
                    isCheckable={wave.state === 'initial' && hasRole(ROLE_ADMIN)}
                  />
                  <ManualFeasibility
                    wave={wave}
                    color={color}
                    feasibility={feasibility}
                    checkingFeasibility={checkingFeasibility}
                    setFeasibility={this.setFeasibility}
                    data-doc="app_wave_feasibility_check"
                    isCheckable={wave.state === 'changed' && hasRole(ROLE_ADMIN)}
                  />
                </Section>
              )}
              <AllowedPanelProvider
                wave={wave}
                availablePanelProviders={availablePanelProviders}
                selectedPanelProviders={selectedPanelProviders}
                isPanelProviderUpdating={isPanelProviderUpdating}
                onChangePanelProvider={this.onChangePanelProvider}
                updateAllowedPanelProvider={this.updateAllowedPanelProvider}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}

const Loader = () => (
  <Segment attached style={{ minHeight: '300px' }}>
    <Spinner active>Konfiguration wird geladen ...</Spinner>
  </Segment>
);

export default ChangedOrder;
