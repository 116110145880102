import React from 'react';
import { Module, MODULE_MEDIA_RETRIEVAL } from '../../Modules';
import DefinitionList from '../../Ui/DefinitionList';
import ValidationError from '../../Ui/ValidationError';
import List from './List';
import Section from './Section';

const Media = ({ values, module, deepLink, wave, errors, configuration }) => {
  const { media, targetGroup } = values.values;
  const { label, customizeTargetGroup } = module;

  return (
    <Section
      headline={Module.getComponentName(MODULE_MEDIA_RETRIEVAL, configuration.modules, wave.study.type.type)}
      deepLink={deepLink}
    >
      <DefinitionList>
        <DefinitionList.Definition>{label}</DefinitionList.Definition>
        <DefinitionList.Description>
          <List items={media} />
        </DefinitionList.Description>
      </DefinitionList>
      <ValidationError errors={errors} property="choiceSetValues" />
      {customizeTargetGroup && (
        <DefinitionList>
          <DefinitionList.Definition>Zielgruppenspezifikation</DefinitionList.Definition>
          <DefinitionList.Description>{targetGroup}</DefinitionList.Description>
        </DefinitionList>
      )}
      <ValidationError errors={errors} property="targetGroup" />
    </Section>
  );
};

export default Media;
