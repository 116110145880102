import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Container, Dropdown, Header as Headline, Icon, Menu, Placeholder } from 'semantic-ui-react';
import { withHooks } from '@Classic/Hocs/withHooks';
import withStores from '@Classic/Hocs/withStores';
import {
  hasOneOfRoles,
  hasRole,
  ROLE_ADMIN,
  ROLE_MARKET_RESEARCHER,
  ROLE_SERVICE_PROVIDER_PANEL,
  ROLE_SERVICE_PROVIDER_QUESTIONNAIRE,
  ROLE_STUDENT,
} from '../Permissions';
import './Header.scss';

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };

    this.logout = this.logout.bind(this);
  }

  logout() {
    const { navigate, userStore } = this.props;
    userStore.logout();
    navigate('/start');
  }

  render() {
    const { loading } = this.state;
    const user = this.props.userStore.data;

    return (
      <Menu stackable borderless className="Header">
        <Container>
          <Menu.Item as={Link} to="/" className="Header__logo">
            <Headline as="h3">Media Monitor Rapid</Headline>
          </Menu.Item>
          <Menu.Menu position="right">
            {hasOneOfRoles([
              ROLE_ADMIN,
              ROLE_MARKET_RESEARCHER,
              ROLE_SERVICE_PROVIDER_PANEL,
              ROLE_SERVICE_PROVIDER_QUESTIONNAIRE,
              ROLE_STUDENT,
            ]) && (
              <Menu.Item
                as={NavLink}
                activeclassname="active"
                to="/costs"
                data-doc="app_cost_accounting_open"
                data-cy="cost_accounting_open"
              >
                Kostenkalkulation
              </Menu.Item>
            )}
            <Menu.Item as={NavLink} activeclassname="active" to="/news" data-doc="app_news" data-cy="button_news">
              Neuigkeiten
            </Menu.Item>
            <Dropdown
              item
              pointing
              data-cy="button_user_actions"
              trigger={
                <span className="d-flex align-items-center" data-cy="login_user_name">
                  <Icon name="user circle outline" size="big" />
                  {!user || loading ? (
                    <Placeholder fluid>
                      <Placeholder.Header>
                        <Placeholder.Line length="full" style={{ minWidth: '120px' }} />
                      </Placeholder.Header>
                    </Placeholder>
                  ) : (
                    `${user.firstName} ${user.lastName}`
                  )}
                </span>
              }
            >
              <Dropdown.Menu>
                <Dropdown.Header>Aktionen</Dropdown.Header>
                <Dropdown.Divider className="mb-0" />
                {hasOneOfRoles([ROLE_ADMIN, ROLE_SERVICE_PROVIDER_QUESTIONNAIRE, ROLE_STUDENT]) && (
                  <Dropdown.Item as={NavLink} to="/questionnaire" data-doc="app_questionnaire">
                    <Icon name="file pdf" /> Beispielfragebogen
                  </Dropdown.Item>
                )}
                {hasRole(ROLE_ADMIN) && (
                  <React.Fragment>
                    <Dropdown.Item as={NavLink} to="/user" data-doc="app_user_management" data-cy="user_management">
                      <Icon name="users" /> Nutzerverwaltung
                    </Dropdown.Item>
                    <Dropdown.Item
                      as={NavLink}
                      to="/organization"
                      data-doc="app_organization_management"
                      data-cy="organization_management"
                    >
                      <Icon name="building" /> Organisationsverwaltung
                    </Dropdown.Item>
                    <Dropdown.Item
                      as={NavLink}
                      to="/blueprint"
                      data-doc="app_blueprint_management"
                      data-cy="blueprint_management"
                    >
                      <Icon name="wrench" /> Modulverwaltung (Blaupausen)
                    </Dropdown.Item>
                  </React.Fragment>
                )}
                <Dropdown.Item onClick={this.logout} data-cy="logout">
                  <Icon name="log out" /> Abmelden
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Menu.Menu>
        </Container>
      </Menu>
    );
  }
}

export default withStores(withHooks(Header));
