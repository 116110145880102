import Arrow from '../assets/arrow.svg?react';
import React, { Fragment } from 'react';
import { Button, Dropdown, Form, Grid, Modal, Radio } from 'semantic-ui-react';
import { ErrorMessage } from 'formik';
import moment from 'moment';
import 'moment/locale/de';
import { addMessage } from '../Actions/messageActions';
import { store } from '../App';
import Client from '../Client';
import CheckboxList from '../Components/CheckboxList';
import DatePicker from '../Components/DatePicker';
import DateRangePicker from '../Components/DateRangePicker';
import DownloadLink from '../Components/DownloadLink';
import Map from '../Components/Map';
import RangeTextField from '../Components/RangeTextField';
import UploadButton from '../Components/UploadButton';
import { isMotiveTest } from '../Modules';
import InputError from '../Ui/InputError';

// Not optimal ... Should also be configured via API :)
const getQuotas = (wave) => {
  const { type } = wave.study.type;

  if (type === 'easy_success_measurement' || isMotiveTest(type)) {
    return [
      { key: 0, value: 0, text: 'Keine Quotierung' },
      { key: 50, value: 50, text: '50%' },
      { key: 60, value: 60, text: '60%' },
      { key: 70, value: 70, text: '70%' },
      { key: 80, value: 80, text: '80%' },
      { key: 90, value: 90, text: '90%' },
      { key: 100, value: 100, text: '100%' },
    ];
  }

  return [
    { key: 0, value: 0, text: 'Keine Quotierung' },
    { key: 30, value: 30, text: '30%' },
    { key: 40, value: 40, text: '40%' },
    { key: 50, value: 50, text: '50%' },
    { key: 60, value: 60, text: '60%' },
    { key: 70, value: 70, text: '70%' },
    { key: 80, value: 80, text: '80%' },
    { key: 90, value: 90, text: '90%' },
    { key: 100, value: 100, text: '100%' },
  ];
};

class FieldLengthAndSampleForm extends React.Component {
  regionMimeTypes = [
    '.csv',
    '.xlsx',
    '.xls',
    'application/vnd.ms-Excel',
    'application/msexcel',
    'application/x-msexcel',
    'application/x-ms-Excel',
    'zz-application/zz-winassoc-xls',

    'application/x-Excel',
    'application/x-dos_ms_Excel',
    'application/xls',
    'application/x-xls',

    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ];

  constructor(props) {
    super(props);
    moment.locale('de');

    this.state = {
      processingCsv: false,
      csvError: '',
      uploadingQuotaRequirement: false,
      uploadingWhitelist: false,
      isMapOpen: false,
    };

    this.onCsvSelect = this.onCsvSelect.bind(this);
    this.onQuotaRequirementUpload = this.onQuotaRequirementUpload.bind(this);
    this.onChangeMultipleValue = this.onChangeMultipleValue.bind(this);
  }

  onCsvSelect(file) {
    this.setState({ processingCsv: true });

    const data = new FormData();
    data.append('file', file);

    Client.upload('/region/upload', data)
      .then((response) => {
        const { zips, fileName } = response.data;
        this.onChangeMultipleValue({ customRegion: zips, customRegionName: fileName, region: null });
        this.setState({ csvError: '', processingCsv: false });
        store.dispatch(addMessage('Die Region wurde erfolgreich hochgeladen.'));
      })
      .catch((error) => {
        if (error.networkError) {
          return;
        }
        // Error while processing file in API
        if (error.code === 400) {
          this.setState({ csvError: error.message, processingCsv: false });

          return;
        }

        if (error.code === 450) {
          const messages = error.data.map((err) => err.message);
          this.setState({ csvError: messages.join(', '), processingCsv: false });

          return;
        }

        if (error.code === 500) {
          this.setState({ csvError: `${error.message}: ${error.data.message}`, processingCsv: false });

          return;
        }

        if (error instanceof TypeError) {
          this.setState({ csvError: 'Internal Server Error 500', processingCsv: false });

          return;
        }

        error.data.forEach((error) => {
          // Could not get it to work with Formik's setFieldError
          if (error.property === 'value') {
            this.setState({ csvError: error.message, processingCsv: false });
          }
        });
      });
  }

  onQuotaRequirementUpload(file) {
    const { wave } = this.props;
    this.setState({ uploadingQuotaRequirement: true });

    const data = new FormData();
    data.append('file', file);

    Client.upload(`/wave/${wave.id}/add-file/field_length_and_sample`, data)
      .then((response) => {
        this.setState({ uploadingQuotaRequirement: false });
        this.onChangeValue('quotaRequirement', response.data);
        store.dispatch(addMessage('Die Quotierungsvorgabe wurde erfolgreich hochgeladen.'));
      })
      .catch(() => {
        store.dispatch(addMessage('Die Quotierungsvorgabe konnten nicht hochgeladen werden.', false, 'error'));
      });
  }

  onWhitelistUpload(file) {
    const { wave } = this.props;
    this.setState({ uploadingWhitelist: true });

    const data = new FormData();
    data.append('file', file);

    Client.upload(`/wave/${wave.id}/add-file/field_length_and_sample`, data)
      .then((response) => {
        this.setState({ uploadingWhitelist: false });
        this.onChangeValue('whitelist', response.data);
        store.dispatch(addMessage('Die Whitelist wurde erfolgreich hochgeladen.'));
      })
      .catch(() => {
        store.dispatch(addMessage('Die Whitelist konnten nicht hochgeladen werden.', false, 'error'));
      });
  }

  renderMap = (regionId, customRegion) => {
    const { isMapOpen } = this.state;
    const { regions } = this.props;

    if (regionId === 'no_region' && customRegion === null) {
      return null;
    }

    const region = regions.find((region) => region.id === regionId);
    const zips = region !== undefined ? region.zips : customRegion;

    return (
      <Fragment>
        <Modal
          closeIcon
          id="map-modal"
          size="fullscreen"
          open={isMapOpen}
          onClose={() => this.setState({ isMapOpen: false })}
        >
          <Map zips={zips} />
        </Modal>
        <Button
          fluid
          style={{ textAlign: 'left' }}
          type="button"
          content="Karte anzeigen"
          icon="map outline"
          labelPosition="right"
          onClick={() => this.setState({ isMapOpen: true })}
        />
      </Fragment>
    );
  };

  onChangeMultiple = (names, settings) => {
    const { module, values, onChange } = this.props;
    const { key } = module;

    const data = {
      ...values,
    };

    names.forEach((name, idx) => {
      data[name] = settings[idx];
    });

    onChange(key, data);
  };

  onChangeValue = (name, value) => {
    const { module, values, onChange } = this.props;
    const { key } = module;

    const data = {
      ...values,
      [name]: value,
    };

    onChange(key, data);
  };

  onChangeMultipleValue = (updatedValues) => {
    const { module, values, onChange } = this.props;
    const { key } = module;

    const data = {
      ...values,
      ...updatedValues,
    };

    onChange(key, data);
  };

  render() {
    const { regions, wave, values, module } = this.props;
    const { processingCsv, csvError, uploadingQuotaRequirement, uploadingWhitelist } = this.state;

    const isDailySalesOrDailyDrive = ['daily_sales', 'daily_drive'].includes(wave.study.type.type);
    const isRetargeting = ['retargeting'].includes(wave.study.type.type);
    const { isMediaOpal } = wave.study;
    // one more thing that should be configurable, probably
    const step = 50;
    const min = 100;
    const max = 5000;

    return (
      <Grid>
        <Grid.Column
          widescreen={isDailySalesOrDailyDrive ? 10 : 16}
          largeScreen={isDailySalesOrDailyDrive ? 10 : 16}
          computer={isDailySalesOrDailyDrive ? 12 : 16}
          mobile={16}
        >
          <form className="ui form">
            <Form.Field>
              <div className="as-label" data-cy="label-date">
                {module.dateLabel}
              </div>
              {module.dateType === 'dateRange' && (
                <DateRangePicker
                  startDate={moment(values.start).utcOffset(0)}
                  endDate={moment(values.end).utcOffset(0)}
                  onChange={({ startDate, endDate }) => {
                    if (startDate === null) {
                      return;
                    }
                    const start = startDate.clone().utcOffset(0);
                    const end = endDate !== null ? endDate.clone().utcOffset(0) : null;

                    if (moment(values.start).utcOffset(0).isSame(start, 'days') === false) {
                      this.onChangeValue('start', start);
                    }

                    if (end !== null && moment(values.end).utcOffset(0).isSame(end, 'days') === false) {
                      this.onChangeValue('end', end);
                    }
                  }}
                />
              )}
              {module.dateType === 'dateSingle' && (
                <DatePicker
                  date={moment(values.dateSingle).utcOffset(0)}
                  onChange={({ date }) => {
                    if (date === null) {
                      return;
                    }
                    const single = date.clone().utcOffset(0);
                    if (moment(values.dateSingle).utcOffset(0).isSame(single, 'days') === false) {
                      this.onChangeValue('dateSingle', single);
                    }
                  }}
                />
              )}
            </Form.Field>

            {module.hasNumberOfCases && (
              <Form.Field>
                <label htmlFor="numberOfCases">Fallzahl</label>
                <RangeTextField
                  name="numberOfCases"
                  id="numberOfCases"
                  min={min}
                  max={max}
                  step={step}
                  value={values.numberOfCases}
                  onChange={(event) => this.onChangeValue(event.target.name, parseInt(event.target.value, 10))}
                  onMinus={() => {
                    if (parseInt(values.numberOfCases, 10) > min) {
                      this.onChangeValue(
                        'numberOfCases',
                        Math.round((parseInt(values.numberOfCases, 10) - step) / step) * step
                      );
                    }
                  }}
                  onPlus={() => {
                    if (parseInt(values.numberOfCases, 10) < max) {
                      this.onChangeValue(
                        'numberOfCases',
                        Math.round((parseInt(values.numberOfCases, 10) + step) / step) * step
                      );
                    }
                  }}
                />
              </Form.Field>
            )}

            {module.hasGender && (
              <CheckboxList
                label="Geschlecht"
                name="gender"
                options={[
                  { text: 'männlich', value: 1 },
                  { text: 'weiblich', value: 2 },
                ]}
                values={values.gender}
                onChange={(values) => this.onChangeValue('gender', values)}
              />
            )}

            <Form.Field>
              <label htmlFor="region">{module.regionLabel}</label>
              {wave.study.type.type !== 'motive_test' && values.customRegion === null && (
                <Dropdown
                  data-cy="select-region"
                  className="mb-5"
                  selection
                  name="region"
                  id="region"
                  value={values.region === null ? 'no_region' : values.region}
                  onChange={(e, { value }) => {
                    this.onChangeValue('region', value === 'no_region' ? null : value);
                  }}
                  options={[
                    ...regions.map((region) => ({ key: region.id, value: region.id, text: region.name })),
                    { key: 'no_region', value: 'no_region', text: 'Keine regionale Einschränkung' },
                  ]}
                />
              )}
              {module.hasGender && (
                <UploadButton
                  dataCyName="custom-region"
                  onUpload={(file) => this.onCsvSelect(file)}
                  onRemove={() => {
                    this.onChangeMultiple(['customRegion', 'customRegionName'], [null, null]);
                  }}
                  // Migration for regions without customRegionName
                  removeLabel={!values.customRegionName ? 'Region entfernen' : values.customRegionName}
                  uploadLabel="Region hochladen"
                  isUploading={processingCsv}
                  value={values.customRegion}
                  accept={this.regionMimeTypes.join(',')}
                  style={{ marginBottom: '5px' }}
                  fluid
                />
              )}
              {this.renderMap(values.region === null ? 'no_region' : values.region, values.customRegion)}
              <InputError message={csvError} />
            </Form.Field>

            {module.hasAge && (
              <Form.Field>
                <label htmlFor="age">Altersbereich</label>
                <div className="d-flex align-items-center">
                  <Dropdown
                    selection
                    name="minAge"
                    id="minAge"
                    className="flex-grow"
                    value={values.minAge}
                    onChange={(e, { value }) => this.onChangeValue('minAge', value)}
                    options={[module.minAge, 20, 30, 40, 50, 60].map((option) =>
                      /* todo-2022 clever dropdown generation for minAge -> maxAge from configuration */
                      ({ key: option, value: option, text: `${option} Jahre`, disabled: values.maxAge < option })
                    )}
                  />
                  <div style={{ margin: '0 10px' }}>
                    <Arrow width={24} height={24} />
                  </div>
                  <Dropdown
                    selection
                    name="maxAge"
                    id="maxAge"
                    className="flex-grow"
                    value={values.maxAge}
                    onChange={(e, { value }) => this.onChangeValue('maxAge', value)}
                    options={[19, 29, 39, 49, 59, module.maxAge].map((option) =>
                      /* todo-2022 clever dropdown generation for minAge -> maxAge from configuration */
                      ({ key: option, value: option, text: `${option} Jahre`, disabled: values.minAge > option })
                    )}
                  />
                </div>
              </Form.Field>
            )}

            {
              // This is still a dirty hack. This form should ideally be composed of modules like the "Image"-Tab or
              // the "Werbemittelbeurteilung"-Tab. This field should be another module, which can be plugged in/out.
              (wave.study.type.type === 'easy_success_measurement' || isMotiveTest(wave.study.type.type)) && (
                <Form.Field>
                  <label htmlFor="quotaType">
                    {wave.study.type.type === 'daily_sales'
                      ? 'Quotierungstyp wählen BILD-WLK Leser'
                      : 'Quotierungstyp wählen'}
                  </label>
                  <Form.Field>
                    <Radio
                      label={module.quotaTypes[0]}
                      name="quotaType"
                      value={module.quotaTypes[0]}
                      checked={values.quotaType === module.quotaTypes[0]}
                      onChange={() => {
                        this.onChangeValue('quotaType', module.quotaTypes[0]);
                      }}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Radio
                      label={module.quotaTypes[1]}
                      name="quotaType"
                      value={module.quotaTypes[1]}
                      checked={values.quotaType === module.quotaTypes[1]}
                      onChange={() => {
                        this.onChangeValue('quotaType', module.quotaTypes[1]);
                      }}
                    />
                  </Form.Field>
                  <InputError message={<ErrorMessage name="quotaType" />} />
                </Form.Field>
              )
            }

            {[
              'daily_sales',
              'daily_drive',
              'retargeting',
              'advertisement_copy_test',
              'supplement_copy_test',
              'motive_test_media_opal',
              'quick_poll',
            ].includes(wave.study.type.type) === false && (
              <Form.Field>
                <label htmlFor="quote">
                  {wave.study.type.type === 'daily_sales'
                    ? 'Mindestanteil BILD-WLK Leser'
                    : 'Anteil WLK TZ bzw. Vermarkter WLK TZ'}
                </label>
                <Dropdown
                  selection
                  name="quote"
                  id="quote"
                  value={values.quote}
                  onChange={(e, { value }) => this.onChangeValue('quote', value)}
                  options={getQuotas(wave)}
                />
                <InputError message={<ErrorMessage name="quote" />} />
              </Form.Field>
            )}

            {isMediaOpal === false && (
              <Form.Field>
                <div className="as-label">Quotierungsvorgaben</div>
                {values.quotaRequirement !== null && (
                  <div className="mb-5">
                    <DownloadLink
                      url={`/wave/${wave.id}/file/${values.quotaRequirement.fileName}`}
                      name={values.quotaRequirement.originalName}
                    />
                  </div>
                )}
                <UploadButton
                  onUpload={(file) => this.onQuotaRequirementUpload(file)}
                  onRemove={() => this.onChangeValue('quotaRequirement', null)}
                  removeLabel="Quotierungsvorgaben entfernen"
                  uploadLabel="Quotierungsvorgaben hochladen"
                  isUploading={uploadingQuotaRequirement}
                  value={values.quotaRequirement}
                  accept=".xlsx,.xls"
                />
              </Form.Field>
            )}

            {isRetargeting === true && (
              <Form.Field>
                <div className="as-label">Whitelist</div>
                {values.whitelist !== null && (
                  <div className="mb-5">
                    <DownloadLink
                      url={`/wave/${wave.id}/file/${values.whitelist.fileName}`}
                      name={values.whitelist.originalName}
                    />
                  </div>
                )}
                <UploadButton
                  onUpload={(file) => this.onWhitelistUpload(file)}
                  onRemove={() => this.onChangeValue('whitelist', null)}
                  removeLabel="Whitelist entfernen"
                  uploadLabel="Whitelist hochladen"
                  isUploading={uploadingWhitelist}
                  value={values.whitelist}
                  accept=".xlsx,.xls"
                />
              </Form.Field>
            )}
          </form>
        </Grid.Column>
      </Grid>
    );
  }
}

export default FieldLengthAndSampleForm;
