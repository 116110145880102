import React from 'react';
import { Label } from 'semantic-ui-react';

const OrganizationTagList = ({ organizations }) => (
  <div role="list">
    {organizations.map((organization) => (
      <Label
        key={organization.id}
        role="listitem"
        data-cy="news_organisation_info"
        size="small"
        style={{ margin: '.14285714em .14285714em' }}
      >
        {organization.name}
      </Label>
    ))}
  </div>
);

export default OrganizationTagList;
