export const styles = {
  styles: {
    global: {
      body: {},
      '::selection': {
        bg: 'accent',
        color: '#000',
      },
    },
  },
};
