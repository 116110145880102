import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Dropdown, Form, Header, Icon, List, Menu, Modal, Responsive } from 'semantic-ui-react';
import { ErrorMessage, Formik } from 'formik';
import * as Yup from 'yup';
import { addMessage } from '@Classic/Actions/messageActions.js';
import { store } from '@Classic/App.jsx';
import Client from '../Client';
import {
  hasOneOfRoles,
  hasRole,
  isConfigurable,
  isDeletable,
  ROLE_ADMIN,
  ROLE_MARKET_RESEARCHER,
  ROLE_SERVICE_PROVIDER_PANEL,
  ROLE_SERVICE_PROVIDER_QUESTIONNAIRE,
} from '../Permissions';
import InputError from '../Ui/InputError';
import CreatedBadge from './CreatedBadge';
import Date from './Order/Date';
import Time from './Order/Time';
import Status from './Status';
import './Wave.scss';

const UpdateWaveNameSchema = Yup.object().shape({
  name: Yup.string().required('Bitte geben Sie einen Namen für die Welle an.'),
});

class Wave extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isCopyModalOpen: false,
      isRemoveModalOpen: false,
      isEditNameModalOpen: false,
      updating: false,
    };
  }

  updateName(values) {
    const { wave, updateWaveName } = this.props;
    const { id: waveId, version } = wave;
    this.setState({ updating: true });

    Client.put(`/wave/${waveId}/name`, { id: waveId, version: version, name: values.name })
      .then((response) => {
        updateWaveName(response.data);
        this.setState({ updating: false, isEditNameModalOpen: false });
        store.dispatch(addMessage('Der Name der Studie wurde erfolgreich aktualisiert.'));
      })
      .catch((error) => {
        if (error.networkError) {
          return;
        }
        this.setState({ updating: false, isEditNameModalOpen: false });
        store.dispatch(addMessage('Beim Aktualisieren des Namens ist ein Fehler aufgetreten.', false, 'error'));
      });
  }

  renderHeader = () => {
    const { wave } = this.props;

    if (hasOneOfRoles([ROLE_MARKET_RESEARCHER, ROLE_ADMIN])) {
      return (
        <List.Header
          className="Wave__header Wave__header--editable"
          as="button"
          type="button"
          data-doc="app_wave_put_name"
          onClick={() => this.setState({ isEditNameModalOpen: true })}
          data-cy="studies_wave_name"
        >
          {wave.name}
          <Icon name="pencil alternate" />
        </List.Header>
      );
    }

    return <List.Header className="Wave__header">{wave.name}</List.Header>;
  };

  renderUpdateNameModal = () => {
    const { isEditNameModalOpen, updating } = this.state;
    const { wave } = this.props;

    if (hasRole(ROLE_MARKET_RESEARCHER) === false && hasRole(ROLE_ADMIN) === false) {
      return null;
    }

    return (
      <Modal
        data-cy="modal-wave-rename"
        closeOnDimmerClick={false}
        size="tiny"
        open={isEditNameModalOpen}
        onClose={() => this.setState({ isEditNameModalOpen: false })}
      >
        <Header icon="write" content="Welle umbenennen" />
        <Formik
          initialValues={{ name: wave.name }}
          validationSchema={UpdateWaveNameSchema}
          onSubmit={(values, actions) => this.updateName(values, actions)}
          render={({ errors, touched, values, handleSubmit, handleChange, handleBlur, isValid }) => (
            <Form onSubmit={handleSubmit}>
              <Modal.Content>
                <Form.Field error={errors.name && touched.name}>
                  <label htmlFor="name">Name</label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    data-cy="wave-name-input"
                  />
                  <InputError message={<ErrorMessage name="name" />} />
                </Form.Field>
              </Modal.Content>
              <Modal.Actions>
                <Button
                  type="button"
                  content="Abbrechen"
                  disabled={updating}
                  onClick={() => this.setState({ isEditNameModalOpen: false })}
                />
                <Button
                  primary
                  type="submit"
                  content="Namen aktualisieren"
                  disabled={updating || !isValid}
                  loading={updating}
                  onClick={handleSubmit}
                  data-cy="wave-name-submit"
                />
              </Modal.Actions>
            </Form>
          )}
        />
      </Modal>
    );
  };

  renderMenu = () => {
    const { wave } = this.props;

    return (
      <>
        <Responsive as={Menu} minWidth={Responsive.onlyComputer.minWidth} icon="labeled" size="small" className="mt-0">
          <Menu.Item
            as={Link}
            to={`/wave/${wave.id}/configuration`}
            data-doc="app_wave_open_configuration"
            disabled={isConfigurable(wave) === false}
            data-cy="studies_action_config"
            data-wave-id={wave.id}
          >
            <Icon name="cogs" />
            Konfiguration
          </Menu.Item>
          <Menu.Item
            as={Link}
            to={`/wave/${wave.id}/order`}
            data-doc="app_wave_open_order"
            data-cy="studies_action_order"
            data-wave-id={wave.id}
          >
            <Icon name="shopping cart" />
            Auftrag
          </Menu.Item>
          <Menu.Item
            name="Welle kopieren"
            as={Button}
            onClick={() => this.setState({ isCopyModalOpen: true })}
            data-doc="app_wave_copy"
            disabled={
              !hasOneOfRoles([
                ROLE_ADMIN,
                ROLE_MARKET_RESEARCHER,
                ROLE_SERVICE_PROVIDER_QUESTIONNAIRE,
                ROLE_SERVICE_PROVIDER_PANEL,
              ])
            }
            data-cy="studies_action_copy"
            data-wave-id={wave.id}
          >
            <Icon name="copy" />
            Kopieren
          </Menu.Item>
          <Menu.Item
            name="Welle löschen"
            as={Button}
            onClick={() => this.setState({ isRemoveModalOpen: true })}
            disabled={isDeletable(wave) === false}
            data-doc="app_wave_delete"
            data-cy="studies_action_delete"
            data-wave-id={wave.id}
          >
            <Icon name="trash alternate" />
            Löschen
          </Menu.Item>
        </Responsive>
        <Responsive
          as={Dropdown}
          maxWidth={Responsive.onlyTablet.maxWidth}
          button
          icon="bars"
          className="icon mr-0"
          direction="left"
          title="Menü"
        >
          <Dropdown.Menu>
            <Dropdown.Item
              icon="cogs"
              text="Konfiguration"
              as={Link}
              to={`/wave/${wave.id}/configuration`}
              disabled={isConfigurable(wave) === false}
            />
            <Dropdown.Item icon="shopping cart" text="Auftrag" as={Link} to={`/wave/${wave.id}/order`} />
            <Dropdown.Item
              icon="copy"
              text="Kopieren"
              onClick={() => this.setState({ isCopyModalOpen: true })}
              disabled={
                !hasOneOfRoles([
                  ROLE_ADMIN,
                  ROLE_MARKET_RESEARCHER,
                  ROLE_SERVICE_PROVIDER_QUESTIONNAIRE,
                  ROLE_SERVICE_PROVIDER_PANEL,
                ])
              }
            />
            <Dropdown.Item
              icon="trash alternate"
              text="Löschen"
              onClick={() => this.setState({ isRemoveModalOpen: true })}
              disabled={isDeletable(wave) === false}
            />
          </Dropdown.Menu>
        </Responsive>
      </>
    );
  };

  render() {
    const { isCopyModalOpen, isRemoveModalOpen } = this.state;
    const { wave, copyWave, removeWave, isMediaOpal, studyNumber } = this.props;

    return (
      <div
        className="Wave d-flex align-items-center justify-content-space-between"
        style={{ padding: '1rem 1rem' }}
        data-name={wave.name}
      >
        <List.Content>
          {this.renderHeader()}
          <List.Description>
            <div className="d-flex align-items-center">
              {isMediaOpal && <span>{`${wave.dateSingle}`}</span>}
              {!isMediaOpal && <span>{`${wave.start} - ${wave.end}`}</span>}
              <span>&nbsp;(#{studyNumber})</span>
              <Status className="Wave__status" state={wave.state} />
              <CreatedBadge createdAt={wave.createdAt} />
            </div>
            <div className="text-muted" style={{ marginTop: '5px' }} data-cy="wave_created_date">
              Erstellt am <Date date={wave.createdAt} /> um <Time date={wave.createdAt} /> Uhr
            </div>
          </List.Description>
        </List.Content>
        {this.renderMenu()}
        <Modal size="tiny" open={isCopyModalOpen} onClose={() => this.setState({ isCopyModalOpen: false })}>
          <Header icon="copy" content={isMediaOpal ? 'Studie kopieren' : 'Welle kopieren'} />
          <Modal.Content>
            <p>
              {isMediaOpal
                ? 'Möchten Sie die Studie kopieren? Das Kopieren der Studie dupliziert die gesamte Konfiguration.'
                : 'Möchten Sie diese Welle kopieren? Das Kopieren der Welle dupliziert die gesamte Konfiguration.'}
            </p>
          </Modal.Content>
          <Modal.Actions>
            <Button content="Abbrechen" onClick={() => this.setState({ isCopyModalOpen: false })} />
            <Button
              primary
              content="Kopieren"
              onClick={() => {
                this.setState({ isCopyModalOpen: false });
                copyWave(wave.id);
              }}
            />
          </Modal.Actions>
        </Modal>
        <Modal size="tiny" open={isRemoveModalOpen} onClose={() => this.setState({ isRemoveModalOpen: false })}>
          <Header icon="trash alternate" content="Welle löschen" />
          <Modal.Content>
            <p>
              Möchten Sie diese {isMediaOpal ? 'Studie' : 'Welle'} <strong>unwiderruflich</strong> löschen?
            </p>
          </Modal.Content>
          <Modal.Actions>
            <Button content="Abbrechen" onClick={() => this.setState({ isRemoveModalOpen: false })} />
            <Button
              color="red"
              content="Löschen"
              onClick={() => {
                removeWave(wave);
                this.setState({ isRemoveModalOpen: false });
              }}
            />
          </Modal.Actions>
        </Modal>
        {this.renderUpdateNameModal()}
      </div>
    );
  }
}

export default Wave;
