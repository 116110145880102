import React from 'react';
import { Checkbox, Form, Radio, TextArea } from 'semantic-ui-react';

class MediaForm extends React.Component {
  constructor(props) {
    super(props);

    const { targetGroup } = props;
    this.state = {
      targetGroupValue: targetGroup,
    };
  }

  renderInput = (option) => {
    const { module, values, targetGroup, onChange } = this.props;
    const { key: componentKey, notChangeable, single, items } = module;
    const key = module.choiceSetKey ?? module.key;

    if (single === true) {
      return (
        <Radio
          label={option}
          name="media"
          checked={values.includes(option)}
          onChange={() => onChange(componentKey, { [key]: [option], targetGroup: targetGroup ?? '' })}
        />
      );
    }

    return (
      <Checkbox
        disabled={notChangeable.includes(option) && items.length > 1}
        label={option}
        name="media"
        value={option}
        checked={values.includes(option)}
        onChange={() => {
          if (values.includes(option) === true) {
            const media = [...values];

            // remove the item from the state
            media.splice(values.indexOf(option), 1);
            onChange(componentKey, { [key]: media, targetGroup: targetGroup ?? '' });

            return;
          }

          onChange(componentKey, { [key]: [...values, option], targetGroup: targetGroup ?? '' });
        }}
      />
    );
  };

  renderTargetGroup = () => {
    const { targetGroupValue } = this.state;
    const { module, values, onChange } = this.props;
    const { key: componentKey } = module;

    return (
      <Form.Field>
        <label htmlFor="targetAudience">Zielgruppenspezifikation</label>
        <TextArea
          placeholder="z.B. Leser der BILD-Ausgabe vom 14.09.2021"
          value={targetGroupValue}
          onChange={(event) => {
            this.setState({ targetGroupValue: event.currentTarget.value });
          }}
          onBlur={(event) => {
            onChange(componentKey, { media: [...values], targetGroup: event.currentTarget.value });
          }}
        />
      </Form.Field>
    );
  };

  render() {
    const { module, values } = this.props;
    const { items, label, customizeTargetGroup } = module;

    if (!values) {
      return <b>Fehler: Keine Werte gefunden</b>;
    }

    return (
      <form className="ui form mb-30">
        <Form.Field>
          <label htmlFor="media">{label}</label>
          {items.map((option) => (
            <Form.Field key={option}>{this.renderInput(option)}</Form.Field>
          ))}
        </Form.Field>
        {customizeTargetGroup && this.renderTargetGroup()}
      </form>
    );
  }
}

export default MediaForm;
