import React from 'react';
import { Header } from 'semantic-ui-react';
import InfoMessage from '../../Ui/InfoMessage';

const hasValues = (dimensions) => Object.values(dimensions).every((items) => items.length === 0);

const DimensionList = ({ module, values }) => {
  const { dimensions } = module;

  if (hasValues(values.values) === true) {
    return <InfoMessage>Es wurde noch keine Auswahl getroffen.</InfoMessage>;
  }

  return dimensions.map((dimension) => {
    const dimensionValues = values.values[dimension.key];
    if (typeof dimensionValues === 'undefined') {
      // todo-2022 resolve this error :O
      console.warn('No DimensionValues with key %o in %o', dimension.key, values.values);
      return null;
    }

    const notConfiguredValues = dimensionValues.filter((item) => !dimension.items.includes(item));

    if (dimensionValues.length !== 0) {
      return (
        <div key={dimension.key} style={{ margin: '14px 0' }}>
          <Header as="h4" content={dimension.name} className="mb-0" />
          <ul className="mt-0">
            {dimension.items.map((item, idxItem) => {
              if (dimensionValues.includes(item)) {
                if (dimension.labels && item !== dimension.labels[idxItem]) {
                  return (
                    <li key={item}>
                      {dimension.labels[idxItem]} ({item})
                    </li>
                  );
                }
                return <li key={item}>{item}</li>;
              }
              return false;
            })}
            {notConfiguredValues.map((item) => (
              <li key={item}>{item}</li>
            ))}
          </ul>
        </div>
      );
    }

    return null;
  });
};

export default DimensionList;
