import AngleDoubleRight from '../../assets/angle-double-right-light.svg?react';
import React from 'react';
import { Link } from 'react-router-dom';
import { Header } from 'semantic-ui-react';
import './Section.scss';

const Section = ({ headline, children, padded, deepLink }) => (
  <div className="Section">
    <Header
      dividing
      as="h3"
      className="Section__headline border-bottom-dotted"
      style={padded ? { marginTop: '45px' } : { marginTop: '15px' }}
    >
      <span>{headline}</span>
      {deepLink && (
        <Link to={deepLink} className="Section__deep-link">
          <span>konfigurieren</span>
          <AngleDoubleRight />
        </Link>
      )}
    </Header>
    {children}
  </div>
);

Section.defaultProps = {
  padded: true,
};

export default Section;
