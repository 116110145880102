import React from 'react';
import { Grid, Icon, Message } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import FileList from '../Components/FileList';
import FileUpload from '../Components/FileUpload';

class FilesForm extends React.Component {
  static findImageByKey(values, title) {
    return values.find((file) => file.key === title);
  }

  renderMessage() {
    const { fileCount, acceptText, messages } = this.props;

    if (Array.isArray(messages) && messages.length > 0) {
      return messages.map((message, idx) => {
        const key = `message-${idx}`;
        return (
          <Message className="mt-0" key={key}>
            <Icon name="warning sign" />
            {message}
          </Message>
        );
      });
    }

    return (
      <Message className="mt-0">
        <Icon name="warning sign" />
        Sie dürfen maximal {fileCount} Dateien hochladen. {acceptText}.
        <ul>
          <li>Nach dem Dateiupload bitte Anzeigengröße angeben</li>
        </ul>
      </Message>
    );
  }

  renderNamedUpload() {
    const { module, values, onChange, wave, hasTitle, label, acceptMimeTypes, allFiles, showAsNamedUploadHeadline } =
      this.props;
    const { key } = module;
    let alreadyShowingUpload = false;

    const output = [];
    Object.keys(allFiles).map((fileKey) => {
      const files = [];
      files.push(
        FilesForm.findImageByKey(
          values.files.filter((file) => file.deleted === false),
          fileKey
        )
      );
      const currentFile = files[0];

      if (currentFile !== undefined) {
        output.push(
          <FileList
            key={fileKey}
            wave={wave}
            files={files}
            onRemove={() => {
              const index = values.files.indexOf(currentFile);

              if (index === -1) {
                return;
              }

              const files = [...values.files];
              files[index] = { ...files[index], deleted: true };
              onChange(key, { files: files });
            }}
            hasTitle={hasTitle}
            label={label}
            showAsNamedUploadHeadline={showAsNamedUploadHeadline}
            allFiles={allFiles}
            onChange={(file, event) => {
              const index = values.files.indexOf(currentFile);

              if (index === -1) {
                return;
              }

              const files = [...values.files];
              files[index] = {
                ...files[index],
                title: event.target.value,
                hasTitle: hasTitle,
              };
              onChange(key, { files: files });
            }}
          />
        );
      }
      if (currentFile === undefined && (alreadyShowingUpload === false || module.showAllUploads === true)) {
        const title = allFiles[fileKey].label;
        output.push(
          <Grid.Column style={{ marginTop: '1rem' }} key={fileKey}>
            <FileUpload
              url={`/wave/${wave.id}/add-file/${key}`}
              accept={acceptMimeTypes}
              buttonText={title}
              fileKey={fileKey}
              onUpload={(file) =>
                onChange(key, {
                  files: [
                    ...values.files,
                    {
                      ...file,
                      title: showAsNamedUploadHeadline ? title : '',
                      hasTitle: hasTitle,
                    },
                  ],
                })
              }
            />
          </Grid.Column>
        );
        alreadyShowingUpload = true;
      }
      return true;
    });

    return (
      <form className="ui form mb-15">
        {this.renderMessage()}
        <Grid doubling stackable columns={3}>
          <Grid.Row>{output}</Grid.Row>
        </Grid>
      </form>
    );
  }

  renderSingleUpload() {
    const { module, values, onChange, wave, hasTitle, label, fileCount, acceptMimeTypes, allFiles } = this.props;
    const { key } = module;

    const maxFilesNotReached = fileCount > values.files.filter((file) => file.deleted === false).length;

    return (
      <form className="ui form mb-15">
        {this.renderMessage()}
        <Grid doubling stackable columns={3}>
          <Grid.Row>
            <FileList
              wave={wave}
              files={values.files.filter((file) => file.deleted === false)}
              onRemove={(file) => {
                const index = values.files.indexOf(file);

                if (index === -1) {
                  return;
                }

                const files = [...values.files];
                files[index] = { ...files[index], deleted: true };
                onChange(key, { files: files });
              }}
              hasTitle={hasTitle}
              label={label}
              allFiles={allFiles}
              onChange={(file, event) => {
                const index = values.files.indexOf(file);

                if (index === -1) {
                  return;
                }

                const files = [...values.files];
                files[index] = {
                  ...files[index],
                  title: event.target.value,
                  hasTitle: hasTitle,
                };
                onChange(key, { files: files });
              }}
            />
            {maxFilesNotReached && (
              <Grid.Column style={{ marginTop: '1rem' }}>
                <FileUpload
                  url={`/wave/${wave.id}/add-file/${key}`}
                  accept={acceptMimeTypes}
                  buttonText={label}
                  onUpload={(file) =>
                    onChange(key, {
                      files: [
                        ...values.files,
                        {
                          ...file,
                          title: '',
                          hasTitle: hasTitle,
                        },
                      ],
                    })
                  }
                />
              </Grid.Column>
            )}
          </Grid.Row>
        </Grid>
      </form>
    );
  }

  render() {
    const { module } = this.props;

    if (!module.showAllUploads) {
      // todo - determine if new handling via named upload is okay? there will be uploads shown before uploaded files...
      // return this.renderSingleUpload();
    }
    return this.renderNamedUpload();
  }
}

FilesForm.defaultProps = {
  label: '',
};

FilesForm.defaultProps = {
  messages: [],
};

FilesForm.propTypes = {
  module: PropTypes.shape({
    key: PropTypes.string,
    label: PropTypes.string,
  }).isRequired,
  wave: PropTypes.shape({}).isRequired,
  values: PropTypes.shape({}).isRequired,
  onChange: PropTypes.func.isRequired,
  hasTitle: PropTypes.bool.isRequired,
  fileCount: PropTypes.number.isRequired,
  label: PropTypes.string,
  messages: PropTypes.arrayOf(PropTypes.string),
};

export default FilesForm;
