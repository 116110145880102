import { useToast } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { Button, Icon, Message } from 'semantic-ui-react';
import { useShallow } from 'zustand/react/shallow';
import { useMessageStore } from '@Store/useMessageStore';
import { addMessage } from '@Classic/Actions/messageActions.js';
import { store } from '@Classic/App';
import './MessageContainer.scss';

const MessageContainer = () => {
  const { data } = useMessageStore(useShallow((state) => ({ data: state.data })));

  const toast = useToast();
  const toastIdRef = React.useRef();

  useEffect(() => {
    useMessageStore.subscribe((state) => (toastIdRef.current = state.data));
  }, []);

  useEffect(() => {
    if (!data) return;

    toast({
      description: data.text,
      status: data.type,
      duration: 9000,
      isClosable: true,
      position: 'bottom-right',
    });

    useMessageStore.getState().clear();
  }, [toastIdRef.current]);

  return <></>;
};

export default MessageContainer;
