import React from 'react';
import { Placeholder, Segment } from 'semantic-ui-react';
import Study from './Study';
import StudyHeader from './StudyHeader';

const Loading = () =>
  [1, 2, 3].map((index) => (
    <div className="my-30" key={index}>
      <StudyHeader loading />
      <Segment attached>
        <Placeholder>
          <Placeholder.Paragraph>
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Paragraph>
        </Placeholder>
      </Segment>
    </div>
  ));

const StudyList = ({ studies, loading, updateStudy, addStudy, removeWave, updateWaveName }) =>
  loading ? (
    <Loading />
  ) : (
    studies.map((study) => (
      <Study
        key={study.id}
        study={study}
        updateStudy={updateStudy}
        addStudy={addStudy}
        removeWave={removeWave}
        updateWaveName={updateWaveName}
      />
    ))
  );

export default StudyList;
