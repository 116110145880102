import { UserModelInterface } from '@Api/models/UserModel.ts';
import { useUserStore } from '@Store/useUsersStore.ts';
import { ROLE } from '@Util/ROLE.ts';

export const ROLE_ADMIN = 'ROLE_ADMIN';
export const ROLE_SERVICE_PROVIDER_QUESTIONNAIRE = 'ROLE_SERVICE_PROVIDER_QUESTIONNAIRE';
export const ROLE_SERVICE_PROVIDER_PANEL = 'ROLE_SERVICE_PROVIDER_PANEL';
export const ROLE_REPORTING_INTERESTED_PERSON = 'ROLE_REPORTING_INTERESTED_PERSON';
export const ROLE_MARKET_RESEARCHER = 'ROLE_MARKET_RESEARCHER';
export const ROLE_STUDENT = 'ROLE_STUDENT';

const ROLES = [
  ROLE_SERVICE_PROVIDER_QUESTIONNAIRE,
  ROLE_SERVICE_PROVIDER_PANEL,
  ROLE_ADMIN,
  ROLE_REPORTING_INTERESTED_PERSON,
  ROLE_MARKET_RESEARCHER,
  ROLE_STUDENT,
];

const ROLE_NAMES: Record<string, string> = {
  ROLE_SERVICE_PROVIDER_QUESTIONNAIRE: 'Fragebogen-Dienstleister',
  ROLE_SERVICE_PROVIDER_PANEL: 'Panel-Dienstleister',
  ROLE_ADMIN: 'Administrator',
  ROLE_REPORTING_INTERESTED_PERSON: 'Reporting-Interessent',
  ROLE_MARKET_RESEARCHER: 'Marktforscher',
  ROLE_STUDENT: 'Hiwi',
};

export const getRoleName = (role: string): string => ROLE_NAMES[role];

export const getAllRoles = () => ROLES;

export const hasRole = (role: string) => {
  const user = useUserStore.getState().data;

  if (!user) {
    return false;
  }

  if (!ROLES.includes(role)) {
    throw new Error(`${role} is not a valid role.`);
  }

  return user.role === role;
};

export const hasOneOfRoles = (roles: string[] = []) => {
  const user = useUserStore.getState().data;
  if (!user) {
    return false;
  }

  roles.forEach((role) => {
    if (!ROLES.includes(role)) {
      throw new Error(`${role} is not a valid role.`);
    }
  });

  return roles.includes(user.role.valueOf());
};

export const hasAnyOfRoles = (user: UserModelInterface | undefined, roles: string[] = []) => {
  if (!user) {
    return false;
  }

  roles.forEach((role) => {
    if (!ROLES.includes(role)) {
      throw new Error(`${role} is not a valid role.`);
    }
  });

  return roles.includes(user.role.valueOf());
};

export const isConfigurable = (wave: any) => {
  if (
    wave.state === 'initial' &&
    hasOneOfRoles([
      ROLE.ROLE_ADMIN,
      ROLE.ROLE_MARKET_RESEARCHER,
      ROLE.ROLE_SERVICE_PROVIDER_QUESTIONNAIRE,
      ROLE.ROLE_SERVICE_PROVIDER_PANEL,
    ])
  ) {
    return true;
  }

  if (
    wave.state === 'changed' &&
    hasOneOfRoles([
      ROLE.ROLE_ADMIN,
      ROLE.ROLE_MARKET_RESEARCHER,
      ROLE.ROLE_SERVICE_PROVIDER_QUESTIONNAIRE,
      ROLE.ROLE_SERVICE_PROVIDER_PANEL,
    ])
  ) {
    return true;
  }

  if (wave.state === 'last_minute' && hasOneOfRoles([ROLE.ROLE_ADMIN])) {
    return true;
  }

  return false;
};

export const isDeletable = (wave: any) => {
  if (wave.state === 'initial' && hasOneOfRoles([ROLE.ROLE_ADMIN, ROLE.ROLE_MARKET_RESEARCHER])) {
    return true;
  }

  if (wave.state === 'aborted' && hasOneOfRoles([ROLE.ROLE_ADMIN, ROLE.ROLE_MARKET_RESEARCHER])) {
    return true;
  }

  return false;
};
