import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Icon, Loader, Table } from 'semantic-ui-react';
import { withHooks } from '@Classic/Hocs/withHooks';
import { addMessage } from '../Actions/messageActions';
import { store } from '../App';
import Client from '../Client';
import Screen from '../Components/Screen';
import ScreenHeader from '../Components/ScreenHeader';
import { hasRole, ROLE_ADMIN } from '../Permissions';

class OrganizationScreen extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      organizations: [],
    };
  }

  componentDidMount() {
    if (hasRole(ROLE_ADMIN) === false) {
      // todo: unreachable code by new react router
      this.props.navigate('/');
      store.dispatch(addMessage('Sie haben keine Berechtigung diese Seite aufzurufen.', false, 'error'));
    }

    Promise.all([Client.get('/user'), Client.get('/organization/list')])
      .then((response) => {
        const [users, organizations] = response;

        const organizationsWithUsers = organizations.data.map((organization) => ({
          ...organization,
          users: [...users.data.filter((user) => user.organization.id === organization.id)],
        }));

        this.setState({
          organizations: organizationsWithUsers,
          loading: false,
        });
      })
      .catch(() => {
        this.setState({ loading: false });
        store.dispatch(addMessage('Beim Abruf der Daten ist ein unerwarteter Fehler aufgetreten.', false, 'error'));
      });
  }

  render() {
    const { organizations, loading } = this.state;

    return (
      <Screen title="Organisationen | Media Monitor Rapid" data-doc="app_organization_management">
        <ScreenHeader title="Organisationen" hasBackButton={false} className="justify-content-space-between">
          <Button
            primary
            icon
            as={Link}
            to="/organization/create"
            labelPosition="right"
            className="mr-0"
            data-cy="organization_create"
            data-doc="app_create_organization"
          >
            <Icon link name="plus" className="mr-0" /> Neue Organisation erstellen
          </Button>
        </ScreenHeader>
        {loading === true ? (
          <Loading />
        ) : (
          <Table celled striped className="mb-5" data-cy="organization_list">
            <thead>
              <tr>
                <th>Organisation</th>
                <th>Studientypen</th>
                <th>Benutzer</th>
                <th>Bearbeiten</th>
              </tr>
            </thead>
            <tbody>
              {organizations.map((organization) => (
                <tr key={organization.id}>
                  <td className="top aligned" data-cy="organization_name">
                    {organization.name}
                  </td>
                  <td className="top aligned">
                    <ul className="m-0">
                      {organization.blueprints.map((b) => (
                        <li key={b.studyType}>{b.name}</li>
                      ))}
                    </ul>
                  </td>
                  <td className="top aligned">{organization.users.length}</td>
                  <td className="top aligned">
                    <Link
                      to={`/organization/${organization.id}/update`}
                      data-cy="organization_edit"
                      data-doc="app_organization_edit"
                    >
                      <Icon name="edit outline" className="mr-0" />
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </Screen>
    );
  }
}

const Loading = () => <Loader active>Organisationen werden geladen ...</Loader>;

export default withHooks(OrganizationScreen);
