const List = ({ items }) => {
  if (Array.isArray(items) === false) {
    return 'x';
  }

  const listItems = items.filter((item) => item !== '');

  return listItems.length === 0 ? '-' : listItems.join(', ');
};

export default List;
