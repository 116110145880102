import React from 'react';
import { Button, Form, Icon, Input } from 'semantic-ui-react';

class ManualFeasibility extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      expectedResponses: null,
      state: null,
    };
  }

  componentDidMount() {
    const { feasibility, color } = this.props;

    if (feasibility === null) {
      return;
    }

    this.setState({
      expectedResponses: feasibility.expectedResponses,
      state: color,
    });
  }

  handleStateChange = (e, { value }) => {
    this.setState({ state: value });
  };
  handleExpectedResponsesChange = (e, { value }) => {
    let number = parseInt(value);
    if (isNaN(number)) {
      number = null;
    }
    this.setState({ expectedResponses: number });
  };

  renderForm() {
    const { setFeasibility, checkingFeasibility } = this.props;
    const { expectedResponses, state } = this.state;

    return (
      <Form className={`mt-15`}>
        <h4>Machbarkeit festlegen</h4>
        {/*}
				<Form.Group grouped>
					<label>Status</label>
					<Form.Field control={Radio} label="Rot" value="red" checked={state === 'red'} onChange={this.handleStateChange} />
					<Form.Field
						control={Radio}
						label="Gelb"
						value="yellow"
						checked={state === 'yellow'}
						onChange={this.handleStateChange}
					/>
					<Form.Field
						control={Radio}
						label="Grün"
						value="green"
						checked={state === 'green'}
						onChange={this.handleStateChange}
					/>
				</Form.Group>
				*/}
        <Form.Input
          fluid
          control={Input}
          type="number"
          label="Fälle"
          placeholder="Fälle"
          value={expectedResponses}
          onChange={this.handleExpectedResponsesChange}
        />
        <Button
          primary
          icon
          type="submit"
          labelPosition="right"
          fluid
          onClick={() => setFeasibility({ expectedResponses, state })}
          loading={checkingFeasibility}
          data-doc="app_wave_feasibility_check"
        >
          <Icon link name="paper plane outline" />
          Machbarkeit festlegen
        </Button>
      </Form>
    );
  }

  render() {
    const { isCheckable } = this.props;

    return <div className="status-screen">{isCheckable === true && this.renderForm()}</div>;
  }
}

export default ManualFeasibility;
