import { Outlet, useLocation } from 'react-router';
import { Navigate } from 'react-router-dom';
import { addMessage } from '@Classic/Actions/messageActions.js';
import { store } from '@Classic/App.jsx';

interface PrivateRouteProps {
  hasPermission: boolean;
  redirectPath?: string;
}

const PrivateRoute = ({ hasPermission, redirectPath = '/' }: PrivateRouteProps) => {
  const location = useLocation();

  if (!hasPermission) {
    store.dispatch(addMessage('Sie haben keine Berechtigung diese Seite aufzurufen.', true, 'error'));
    return <Navigate to={redirectPath} state={{ from: location }} />;
  }

  return <Outlet />;
};

export default PrivateRoute;
