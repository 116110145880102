import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Dimmer, Header, Menu, Placeholder, Segment, Loader as Spinner } from 'semantic-ui-react';
import moment from 'moment';
import { withHooks } from '@Classic/Hocs/withHooks';
import { addMessage } from '../Actions/messageActions';
import { store } from '../App';
import Client from '../Client';
import CheckboxList from '../Components/CheckboxList';
import Screen from '../Components/Screen';
import ScreenHeader from '../Components/ScreenHeader';
import WaveActions from '../Components/WaveActions';
import WaveHeader from '../Components/WaveHeader';
import CustomerAndIndustryForm from '../Forms/CustomerAndIndustryForm';
import CustomerAndSpecificationForm from '../Forms/CustomerAndSpecificationForm';
import CustomQuestionsForm from '../Forms/CustomQuestionsForm';
import CustomQuestionsRichTextForm from '../Forms/CustomQuestionsRichTextForm';
import DimensionsForm from '../Forms/DimensionsForm';
import FieldLengthAndSampleForm from '../Forms/FieldLengthAndSampleForm';
import FilesForm from '../Forms/FilesForm';
import MediaForm from '../Forms/MediaForm';
import NotesForm from '../Forms/NotesForm';
import ReportingOptionsForm from '../Forms/ReportingOptionsForm';
import ToggleForm from '../Forms/ToggleForm';
import {
  findModuleByKey,
  findValuesByKey,
  hasModule,
  Module,
  MODULE_ACTIVATION_DIMENSIONS,
  MODULE_ACTIVATION_TOGGLE,
  MODULE_ADVERTISING_EVALUATION_DIMENSIONS,
  MODULE_ADVERTISING_EVALUATION_FILES,
  MODULE_ADVERTISING_EVALUATION_TOGGLE,
  MODULE_ADVERTISING_MATERIAL_FILES,
  MODULE_ADVERTISING_MATERIAL_TOGGLE,
  MODULE_CUSTOM_QUESTIONS,
  MODULE_CUSTOM_QUESTIONS_RICH_TEXT,
  MODULE_CUSTOMER_AND_INDUSTRY,
  MODULE_CUSTOMER_AND_SPECIFICATION,
  MODULE_EDITORIAL_SPECIFICATION_FILES,
  MODULE_FIELD_LENGTH_AND_SAMPLE,
  MODULE_IMAGE_DIMENSIONS,
  MODULE_IMAGE_TOGGLE,
  MODULE_MEDIA_RETRIEVAL,
  MODULE_MEDIA_RETRIEVAL_DIMENSIONS,
  MODULE_MOTIVE_SPECIFICATION,
  MODULE_NOTES,
  MODULE_REPORTING_OPTIONS,
  MODULE_TITLE_SPECIFICATION_FILES,
  showEditorialSpecification,
  showMediaRetrievalDimensionsOnly,
  showMediaRetrievalFromComponents,
  showTitleSpecification,
} from '../Modules';
import { isConfigurable } from '../Permissions';
import Tab from '../Ui/Tab';
import ErrorScreen from './ErrorScreen';

class ConfigurationScreen extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      wave: null,
      configuration: null,
      values: null,
      regions: [],
      loading: true,
      error: null,
      updating: false,
    };
  }

  componentDidMount() {
    const { navigate, params } = this.props;

    Promise.all([
      Client.get(`/wave/${params.uuid}`),
      Client.get(`/wave/${params.uuid}/configuration`),
      Client.get(`/wave/${params.uuid}/values`),
      Client.get(`/region/${params.uuid}`),
    ])
      .then((response) => {
        const [wave, configuration, values, regions] = response;

        if (isConfigurable(wave.data) === false) {
          navigate(`/wave/${params.uuid}/order`);

          store.dispatch(
            addMessage(
              wave?.study?.isMediaOpal
                ? 'Sie dürfen diese Studie nicht mehr bearbeiten.'
                : 'Sie dürfen diese Welle nicht mehr bearbeiten',
              false,
              'negative'
            )
          );

          return;
        }

        const waveState = this.manipulateFieldLength(wave.data, values.data);
        const moduleState = this.mapModulesToState(configuration.data);
        const valueState = this.mapValuesToState(values.data);

        this.setState({
          wave: waveState,
          configuration: configuration.data,
          modules: moduleState,
          values: valueState,
          regions: regions.data,
          loading: false,
          error: null,
        });
        console.log(valueState);
      })
      .catch((error) => {
        if (error.networkError) {
          return;
        }
        this.setState({ loading: false, error: error });
      });
  }

  onChange = (key, data) => {
    this.setState((prevState) => {
      const { values, modules } = prevState;
      const valuesWithDefaultValues = this.handleDefaultValues(key, values, modules);

      return {
        ...prevState,
        values: {
          ...prevState.values,
          ...valuesWithDefaultValues,
          [key]: data,
        },
      };
    });
  };

  handleDefaultValues = (key, values, modules) => {
    let valuesCopy = { ...values };

    if (key === MODULE_ADVERTISING_EVALUATION_TOGGLE) {
      const moduleConfig = modules[MODULE_ADVERTISING_EVALUATION_DIMENSIONS];
      const currentValues = { ...valuesCopy[MODULE_ADVERTISING_EVALUATION_DIMENSIONS] };

      /* eslint-disable no-unused-vars */
      for (const dimension of moduleConfig.dimensions) {
        for (const defaultValue of dimension.checked) {
          currentValues[dimension.key] = [...currentValues[dimension.key]];
          if (currentValues[dimension.key].includes(defaultValue) === false) {
            currentValues[dimension.key].push(defaultValue);
          }
        }
      }

      valuesCopy = { ...valuesCopy, [MODULE_ADVERTISING_EVALUATION_DIMENSIONS]: currentValues };
    }

    return valuesCopy;
  };

  mapValuesToState = (values) => {
    const flatValues = {};

    for (const value of values) {
      flatValues[value.key] = value.values;
    }

    return flatValues;
  };

  mapModulesToState = (configuration) => {
    const flatValues = {};
    const configurationCopy = { ...configuration };

    for (const module of configurationCopy.modules) {
      const moduleCopy = { ...module };
      const moduleKey = moduleCopy.key;
      delete moduleCopy.key;

      flatValues[moduleKey] = moduleCopy;
    }

    return flatValues;
  };

  mapValuesToPayload = (values) => {
    let payload = [];

    for (const key in values) {
      if (Object.prototype.hasOwnProperty.call(values, key) === false) {
        continue;
      }

      const modifiedValues = values[key];

      if (key === MODULE_FIELD_LENGTH_AND_SAMPLE) {
        modifiedValues.start = moment(modifiedValues.start).utcOffset(0).hour(0).minute(0).second(0).format();
        modifiedValues.end = moment(modifiedValues.end).utcOffset(0).hour(23).minute(59).second(59).format();
        modifiedValues.dateSingle = moment(modifiedValues.dateSingle)
          .utcOffset(0)
          .hour(23)
          .minute(59)
          .second(59)
          .format();
        modifiedValues.region = modifiedValues.region === 'no_region' ? null : modifiedValues.region;
      }

      if (key === MODULE_CUSTOMER_AND_INDUSTRY) {
        modifiedValues.competitors = modifiedValues.competitors.filter((value) => value !== '');
      }

      if (key === MODULE_IMAGE_DIMENSIONS) {
        for (const subKey in values[key]) {
          if (subKey.includes('custom_items')) {
            modifiedValues[subKey] = modifiedValues[subKey].filter((value) => value !== '');
          }
        }
      }

      if (key === MODULE_ADVERTISING_EVALUATION_DIMENSIONS) {
        if (modifiedValues.custom_items) {
          modifiedValues.custom_items = modifiedValues.custom_items.filter((value) => value !== '');
        }
        if (modifiedValues.additional_items) {
          modifiedValues.additional_items = modifiedValues.additional_items.filter((value) => value !== '');
        }
      }

      if (key === MODULE_ACTIVATION_DIMENSIONS) {
        modifiedValues.custom_items = modifiedValues.custom_items.filter((value) => value !== '');
      }

      if (key === MODULE_CUSTOM_QUESTIONS) {
        modifiedValues.questions = modifiedValues.questions.filter((value) => value !== '');
      }

      if (key === MODULE_CUSTOMER_AND_SPECIFICATION) {
        modifiedValues.competitors = modifiedValues.competitors.filter((value) => value !== '');
      }

      payload = [...payload, { key: key, data: modifiedValues }];
    }
    /* eslint-enable no-unused-vars */

    return payload;
  };

  isVisible = (key) => {
    const { values } = this.state;

    if (values[key] !== undefined) {
      return values[key].on;
    }

    return true;
  };

  // Manipulates the waves field length according to values as API always provides the ordered field length
  manipulateFieldLength = (wave, values) => {
    const { values: fieldLength } = findValuesByKey(MODULE_FIELD_LENGTH_AND_SAMPLE, values);

    return {
      ...wave,
      start: fieldLength.start,
      end: fieldLength.end,
      dateSingle: fieldLength.dateSingle,
    };
  };

  submit = () => {
    const { wave, values } = this.state;

    const payload = {
      id: wave.id,
      version: wave.version,
      items: this.mapValuesToPayload(values),
    };

    this.setState({ updating: true });

    Client.put(`/wave/${wave.id}/save`, payload)
      .then(() => {
        this.reload();
        store.dispatch(addMessage('Die Konfiguration wurde erfolgreich gespeichert.'));
      })
      .catch((error) => {
        if (error.networkError) {
          return;
        }
        store.dispatch(addMessage('Die Konfiguration konnten nicht gespeichert werden.', false, 'negative'));
        this.setState({ updating: false });
      });
  };

  reload = () => {
    const { params } = this.props;

    Promise.all([Client.get(`/wave/${params.uuid}`), Client.get(`/wave/${params.uuid}/values`)])
      .then((response) => {
        const [wave, values] = response;

        this.setState({
          wave: this.manipulateFieldLength(wave.data, values.data),
          values: this.mapValuesToState(values.data),
          updating: false,
        });
      })
      .catch((error) => {
        if (error.networkError) {
          return;
        }
        store.dispatch(addMessage('Fehler bei dem Aktualisieren der Konfiguration.', false, 'negative'));
        this.setState({ updating: false });
      });
  };

  renderMenu = () => {
    const { configuration, values, wave } = this.state;
    const { params } = this.props;

    return (
      <Menu attached vertical style={{ height: '100%', borderBottomWidth: '1px', borderTop: 0 }}>
        {hasModule(MODULE_FIELD_LENGTH_AND_SAMPLE, configuration.modules) && (
          <Menu.Item
            exact
            as={NavLink}
            to={`/wave/${params.uuid}/configuration`}
            data-cy="menu-item-field-length-and-sample"
          >
            {Module.getComponentName(MODULE_FIELD_LENGTH_AND_SAMPLE, configuration.modules, wave.study.type.type)}
          </Menu.Item>
        )}
        {hasModule(MODULE_MOTIVE_SPECIFICATION, configuration.modules) && (
          <Menu.Item
            exact
            as={NavLink}
            to={`/wave/${params.uuid}/configuration/specification`}
            data-cy="menu-item-motive-specification"
          >
            {Module.getComponentName(MODULE_MOTIVE_SPECIFICATION, configuration.modules, wave.study.type.type)}
          </Menu.Item>
        )}
        {hasModule(MODULE_CUSTOMER_AND_SPECIFICATION, configuration.modules) && (
          <Menu.Item
            exact
            as={NavLink}
            to={`/wave/${params.uuid}/configuration/customer-and-specification`}
            data-cy="menu-item-customer-and-specification"
          >
            {Module.getComponentName(MODULE_CUSTOMER_AND_SPECIFICATION, configuration.modules, wave.study.type.type)}
          </Menu.Item>
        )}
        {hasModule(MODULE_CUSTOMER_AND_INDUSTRY, configuration.modules) && (
          <Menu.Item
            exact
            as={NavLink}
            to={`/wave/${params.uuid}/configuration/customer-and-industry`}
            data-cy="menu-item-customer-and-industry"
          >
            {Module.getComponentName(MODULE_CUSTOMER_AND_INDUSTRY, configuration.modules, wave.study.type.type)}
          </Menu.Item>
        )}
        {hasModule(MODULE_IMAGE_DIMENSIONS, configuration.modules) && (
          <Menu.Item
            exact
            as={NavLink}
            to={`/wave/${params.uuid}/configuration/image`}
            data-cy="menu-item-image-dimension"
          >
            {Module.getComponentName(MODULE_IMAGE_DIMENSIONS, configuration.modules, wave.study.type.type)}
          </Menu.Item>
        )}
        {hasModule(MODULE_MEDIA_RETRIEVAL, configuration.modules) &&
          !showMediaRetrievalDimensionsOnly(configuration.modules, values) &&
          !showMediaRetrievalFromComponents(configuration.modules) && (
            <Menu.Item
              exact
              as={NavLink}
              to={`/wave/${params.uuid}/configuration/media`}
              data-cy="menu-item-media-retrieval"
            >
              {Module.getComponentName(MODULE_MEDIA_RETRIEVAL, configuration.modules, wave.study.type.type)}
            </Menu.Item>
          )}
        {hasModule(MODULE_MEDIA_RETRIEVAL_DIMENSIONS, configuration.modules) &&
          showMediaRetrievalDimensionsOnly(configuration.modules, values) && (
            <Menu.Item
              exact
              as={NavLink}
              to={`/wave/${params.uuid}/configuration/media-dimensions`}
              data-cy="menu-item-media-retrieval-dimensions"
            >
              {Module.getComponentName(MODULE_MEDIA_RETRIEVAL_DIMENSIONS, configuration.modules, wave.study.type.type)}
            </Menu.Item>
          )}
        {showMediaRetrievalFromComponents(configuration.modules) && (
          <Menu.Item
            exact
            as={NavLink}
            to={`/wave/${params.uuid}/configuration/media-dimensions`}
            data-cy="menu-item-media-retrieval-dimensions"
          >
            {Module.getComponentName(MODULE_MEDIA_RETRIEVAL, configuration.modules, wave.study.type.type)}
          </Menu.Item>
        )}
        {showTitleSpecification(configuration.modules, values) && (
          <Menu.Item
            exact
            as={NavLink}
            to={`/wave/${params.uuid}/configuration/title-specification-files`}
            data-cy="menu-item-title-specification"
          >
            {Module.getComponentName(MODULE_TITLE_SPECIFICATION_FILES, configuration.modules, wave.study.type.type)}
          </Menu.Item>
        )}
        {showEditorialSpecification(configuration.modules, values) && (
          <Menu.Item
            exact
            as={NavLink}
            to={`/wave/${params.uuid}/configuration/editorial-specification-files`}
            data-cy="menu-item-editorial-specification"
          >
            {Module.getName(MODULE_EDITORIAL_SPECIFICATION_FILES, wave.study.type.type)}
          </Menu.Item>
        )}
        {hasModule(MODULE_ADVERTISING_MATERIAL_FILES, configuration.modules) && (
          <Menu.Item
            exact
            as={NavLink}
            to={`/wave/${params.uuid}/configuration/advertising-material`}
            data-cy="menu-item-advertising-material"
          >
            {Module.getComponentName(MODULE_ADVERTISING_MATERIAL_TOGGLE, configuration.modules, wave.study.type.type)}
          </Menu.Item>
        )}
        {hasModule(MODULE_ADVERTISING_EVALUATION_DIMENSIONS, configuration.modules) && (
          <Menu.Item
            exact
            as={NavLink}
            to={`/wave/${params.uuid}/configuration/advertising-evaluation`}
            data-cy="menu-item-advertising-evaluation-dimensions"
          >
            {Module.getComponentName(
              MODULE_ADVERTISING_EVALUATION_DIMENSIONS,
              configuration.modules,
              wave.study.type.type
            )}
          </Menu.Item>
        )}
        {hasModule(MODULE_ACTIVATION_DIMENSIONS, configuration.modules) && (
          <Menu.Item
            exact
            as={NavLink}
            to={`/wave/${params.uuid}/configuration/activation`}
            data-cy="menu-item-activation-dimension"
          >
            {Module.getComponentName(MODULE_ACTIVATION_DIMENSIONS, configuration.modules, wave.study.type.type)}
          </Menu.Item>
        )}
        {hasModule(MODULE_CUSTOM_QUESTIONS, configuration.modules) && (
          <Menu.Item
            exact
            as={NavLink}
            to={`/wave/${params.uuid}/configuration/custom-questions`}
            data-cy="menu-item-custom-questions"
          >
            {Module.getComponentName(MODULE_CUSTOM_QUESTIONS, configuration.modules, wave.study.type.type)}
          </Menu.Item>
        )}
        {hasModule(MODULE_CUSTOM_QUESTIONS_RICH_TEXT, configuration.modules) && (
          <Menu.Item
            exact
            as={NavLink}
            to={`/wave/${params.uuid}/configuration/custom-questions-rich-text`}
            data-cy="menu-item-custom-questions"
          >
            {Module.getComponentName(MODULE_CUSTOM_QUESTIONS_RICH_TEXT, configuration.modules, wave.study.type.type)}
          </Menu.Item>
        )}
        {hasModule(MODULE_REPORTING_OPTIONS, configuration.modules) && (
          <Menu.Item
            exact
            as={NavLink}
            to={`/wave/${params.uuid}/configuration/reporting-options`}
            data-cy="menu-item-reporting-options"
          >
            {Module.getComponentName(MODULE_REPORTING_OPTIONS, configuration.modules, wave.study.type.type)}
          </Menu.Item>
        )}
        {hasModule(MODULE_NOTES, configuration.modules) && (
          <Menu.Item exact as={NavLink} to={`/wave/${params.uuid}/configuration/notes`} data-cy="menu-item-notes">
            {Module.getComponentName(MODULE_NOTES, configuration.modules, wave.study.type.type)}
          </Menu.Item>
        )}
      </Menu>
    );
  };

  renderTabs = () => {
    const { configuration, values, modules, regions, updating, wave } = this.state;
    const { params } = this.props;

    return (
      <Fragment>
        {hasModule(MODULE_FIELD_LENGTH_AND_SAMPLE, configuration.modules) && (
          <Tab loading={updating} visible={params.tab === undefined}>
            <FieldLengthAndSampleForm
              regions={regions}
              wave={wave}
              module={findModuleByKey(MODULE_FIELD_LENGTH_AND_SAMPLE, configuration.modules)}
              values={values[MODULE_FIELD_LENGTH_AND_SAMPLE]}
              onChange={this.onChange}
            />
          </Tab>
        )}
        {hasModule(MODULE_MOTIVE_SPECIFICATION, configuration.modules) && (
          <Tab loading={updating} visible={params.tab === 'specification'}>
            <form className="ui form">
              <CheckboxList
                label="Beilageoption"
                name="specification"
                options={modules[MODULE_MOTIVE_SPECIFICATION].items.map((item) => ({ value: item, text: item }))}
                onChange={(values) => this.onChange(MODULE_MOTIVE_SPECIFICATION, { specification: values })}
                values={values[MODULE_MOTIVE_SPECIFICATION].specification}
              />
            </form>
          </Tab>
        )}
        {showTitleSpecification(configuration.modules, values) && (
          <Tab loading={updating} visible={params.tab === 'title-specification-files'}>
            <FilesForm
              wave={wave}
              module={findModuleByKey(MODULE_TITLE_SPECIFICATION_FILES, configuration.modules)}
              values={values[MODULE_TITLE_SPECIFICATION_FILES]}
              onChange={this.onChange}
              hasTitle={findModuleByKey(MODULE_TITLE_SPECIFICATION_FILES, configuration.modules).hasTitle}
              fileCount={findModuleByKey(MODULE_TITLE_SPECIFICATION_FILES, configuration.modules).fileCount}
              showAllUploads={findModuleByKey(MODULE_TITLE_SPECIFICATION_FILES, configuration.modules).showAllUploads}
              allFiles={findModuleByKey(MODULE_TITLE_SPECIFICATION_FILES, configuration.modules).allFiles}
              label={findModuleByKey(MODULE_TITLE_SPECIFICATION_FILES, configuration.modules).label}
              acceptMimeTypes="image/png, image/jpeg, application/pdf"
              acceptText="Zulässige Dateiformate sind PDF, PNG oder JPEG"
              messages={findModuleByKey(MODULE_TITLE_SPECIFICATION_FILES, configuration.modules).messages}
              showAsNamedUploadHeadline={wave.study.isMediaOpal}
            />
          </Tab>
        )}
        {showEditorialSpecification(configuration.modules, values) && (
          <Tab loading={updating} visible={params.tab === 'editorial-specification-files'}>
            <FilesForm
              wave={wave}
              module={findModuleByKey(MODULE_EDITORIAL_SPECIFICATION_FILES, configuration.modules)}
              values={values[MODULE_EDITORIAL_SPECIFICATION_FILES]}
              onChange={this.onChange}
              hasTitle={findModuleByKey(MODULE_EDITORIAL_SPECIFICATION_FILES, configuration.modules).hasTitle}
              fileCount={findModuleByKey(MODULE_EDITORIAL_SPECIFICATION_FILES, configuration.modules).fileCount}
              showAllUploads={
                findModuleByKey(MODULE_EDITORIAL_SPECIFICATION_FILES, configuration.modules).showAllUploads
              }
              allFiles={findModuleByKey(MODULE_EDITORIAL_SPECIFICATION_FILES, configuration.modules).allFiles}
              label={findModuleByKey(MODULE_EDITORIAL_SPECIFICATION_FILES, configuration.modules).label}
              acceptMimeTypes="image/png, image/jpeg, application/pdf"
              acceptText="Zulässige Dateiformate sind PDF, PNG oder JPEG"
              messages={findModuleByKey(MODULE_EDITORIAL_SPECIFICATION_FILES, configuration.modules).messages}
              showAsNamedUploadHeadline={wave.study.isMediaOpal}
            />
          </Tab>
        )}
        {hasModule(MODULE_CUSTOMER_AND_SPECIFICATION, configuration.modules) && (
          <Tab loading={updating} visible={params.tab === 'customer-and-specification'}>
            <CustomerAndSpecificationForm
              wave={wave}
              configuration={configuration}
              module={findModuleByKey(MODULE_CUSTOMER_AND_SPECIFICATION, configuration.modules)}
              values={values[MODULE_CUSTOMER_AND_SPECIFICATION]}
              onChange={this.onChange}
            />
          </Tab>
        )}
        {hasModule(MODULE_CUSTOMER_AND_INDUSTRY, configuration.modules) && (
          <Tab loading={updating} visible={params.tab === 'customer-and-industry'}>
            <CustomerAndIndustryForm
              wave={wave}
              module={findModuleByKey(MODULE_CUSTOMER_AND_INDUSTRY, configuration.modules)}
              values={values[MODULE_CUSTOMER_AND_INDUSTRY]}
              onChange={this.onChange}
            />
          </Tab>
        )}
        <Tab loading={updating} visible={params.tab === 'image'}>
          {hasModule(MODULE_IMAGE_TOGGLE, configuration.modules) && (
            <ToggleForm
              module={findModuleByKey(MODULE_IMAGE_TOGGLE, configuration.modules)}
              values={values[MODULE_IMAGE_TOGGLE]}
              onChange={this.onChange}
            />
          )}
          <div
            style={
              this.isVisible(MODULE_IMAGE_TOGGLE) === false
                ? { opacity: 0, transition: 'opacity 0.2s' }
                : { opacity: 1, transition: 'opacity 0.2s' }
            }
          >
            {hasModule(MODULE_IMAGE_DIMENSIONS, configuration.modules) && (
              <DimensionsForm
                module={findModuleByKey(MODULE_IMAGE_DIMENSIONS, configuration.modules)}
                values={values[MODULE_IMAGE_DIMENSIONS]}
                onChange={this.onChange}
                studyType={wave.study.type.type}
                isMediaOpal={wave.study.isMediaOpal}
              />
            )}
          </div>
        </Tab>
        {hasModule(MODULE_MEDIA_RETRIEVAL, configuration.modules) &&
          showMediaRetrievalDimensionsOnly(configuration.modules, values) === false &&
          showMediaRetrievalFromComponents(configuration.modules) ===
            false /* backward compatibility for waves without the new media_retrieval_dimensions module */ && (
            /* todo-2022 remove this ? */
            <Tab loading={updating} visible={params.tab === 'media'}>
              <MediaForm
                module={findModuleByKey(MODULE_MEDIA_RETRIEVAL, configuration.modules)}
                values={values[MODULE_MEDIA_RETRIEVAL].media}
                targetGroup={values[MODULE_MEDIA_RETRIEVAL].targetGroup}
                onChange={this.onChange}
              />
            </Tab>
          )}
        {hasModule(MODULE_MEDIA_RETRIEVAL, configuration.modules) &&
          showMediaRetrievalFromComponents(configuration.modules) && (
            <Tab loading={updating} visible={params.tab === 'media-dimensions'}>
              {findModuleByKey(MODULE_MEDIA_RETRIEVAL, configuration.modules).mediaRetrievalDimensions.map(
                (module, idx) => (
                  /* eslint-disable */
                  <MediaForm
                    key={`media-retrieval-${idx}`}
                    module={module}
                    values={values[MODULE_MEDIA_RETRIEVAL][idx][module.key]}
                    onChange={(key, data) => {
                      const prevData = values[MODULE_MEDIA_RETRIEVAL];
                      prevData[idx] = { ...data };
                      const parentKey = findModuleByKey(MODULE_MEDIA_RETRIEVAL, configuration.modules).key;
                      this.onChange(parentKey, prevData);
                    }}
                  />
                  /* eslint-enable */
                )
              )}
            </Tab>
          )}
        {hasModule(MODULE_MEDIA_RETRIEVAL_DIMENSIONS, configuration.modules) &&
          showMediaRetrievalDimensionsOnly(configuration.modules, values) && (
            <Tab loading={updating} visible={params.tab === 'media-dimensions'}>
              {findModuleByKey(MODULE_MEDIA_RETRIEVAL_DIMENSIONS, configuration.modules).mediaRetrievalDimensions.map(
                (module, idx) => (
                  /* eslint-disable */
                  <MediaForm
                    key={`media-retrieval-${idx}`}
                    module={module}
                    values={values[MODULE_MEDIA_RETRIEVAL_DIMENSIONS][idx][module.key]}
                    onChange={(key, data) => {
                      const prevData = values[MODULE_MEDIA_RETRIEVAL_DIMENSIONS];
                      prevData[idx] = { ...data };
                      const parentKey = findModuleByKey(MODULE_MEDIA_RETRIEVAL_DIMENSIONS, configuration.modules).key;
                      this.onChange(parentKey, prevData);
                    }}
                  />
                  /* eslint-enable */
                )
              )}
            </Tab>
          )}
        <Tab loading={updating} visible={params.tab === 'advertising-material'}>
          {hasModule(MODULE_ADVERTISING_MATERIAL_TOGGLE, configuration.modules) && (
            <ToggleForm
              module={findModuleByKey(MODULE_ADVERTISING_MATERIAL_TOGGLE, configuration.modules)}
              values={values[MODULE_ADVERTISING_MATERIAL_TOGGLE]}
              onChange={this.onChange}
            />
          )}
          <div
            style={
              this.isVisible(MODULE_ADVERTISING_MATERIAL_TOGGLE) === false
                ? { opacity: 0, transition: 'opacity 0.2s' }
                : { opacity: 1, transition: 'opacity 0.2s' }
            }
          >
            {hasModule(MODULE_ADVERTISING_MATERIAL_FILES, configuration.modules) && (
              <FilesForm
                wave={wave}
                module={findModuleByKey(MODULE_ADVERTISING_MATERIAL_FILES, configuration.modules)}
                values={values[MODULE_ADVERTISING_MATERIAL_FILES]}
                onChange={this.onChange}
                hasTitle={findModuleByKey(MODULE_ADVERTISING_MATERIAL_FILES, configuration.modules).hasTitle}
                fileCount={findModuleByKey(MODULE_ADVERTISING_MATERIAL_FILES, configuration.modules).fileCount}
                label={findModuleByKey(MODULE_ADVERTISING_MATERIAL_FILES, configuration.modules).label}
                allFiles={findModuleByKey(MODULE_ADVERTISING_MATERIAL_FILES, configuration.modules).allFiles}
                acceptMimeTypes={findModuleByKey(MODULE_ADVERTISING_MATERIAL_FILES, configuration.modules).mimeTypes}
                acceptText="Bitte laden Sie ausschließlich ZIP Dateien mit HTML5"
                messages={findModuleByKey(MODULE_ADVERTISING_MATERIAL_FILES, configuration.modules).messages}
                showAsNamedUploadHeadline={false}
              />
            )}
          </div>
        </Tab>
        <Tab loading={updating} visible={params.tab === 'advertising-evaluation'}>
          {hasModule(MODULE_ADVERTISING_EVALUATION_TOGGLE, configuration.modules) && (
            <ToggleForm
              module={findModuleByKey(MODULE_ADVERTISING_EVALUATION_TOGGLE, configuration.modules)}
              values={values[MODULE_ADVERTISING_EVALUATION_TOGGLE]}
              onChange={this.onChange}
            />
          )}
          <div
            style={
              this.isVisible(MODULE_ADVERTISING_EVALUATION_TOGGLE) === false
                ? { opacity: 0, transition: 'opacity 0.2s' }
                : { opacity: 1, transition: 'opacity 0.2s' }
            }
          >
            {hasModule(MODULE_ADVERTISING_EVALUATION_FILES, configuration.modules) && (
              <FilesForm
                wave={wave}
                module={findModuleByKey(MODULE_ADVERTISING_EVALUATION_FILES, configuration.modules)}
                values={values[MODULE_ADVERTISING_EVALUATION_FILES]}
                onChange={this.onChange}
                acceptMimeTypes="image/png, image/jpeg, application/pdf"
                acceptText="Zulässige Dateiformate sind PDF, PNG oder JPEG"
                hasTitle={findModuleByKey(MODULE_ADVERTISING_EVALUATION_FILES, configuration.modules).hasTitle}
                fileCount={findModuleByKey(MODULE_ADVERTISING_EVALUATION_FILES, configuration.modules).fileCount}
                label={findModuleByKey(MODULE_ADVERTISING_EVALUATION_FILES, configuration.modules).label}
                allFiles={findModuleByKey(MODULE_ADVERTISING_EVALUATION_FILES, configuration.modules).allFiles}
                messages={findModuleByKey(MODULE_ADVERTISING_EVALUATION_FILES, configuration.modules).messages}
                showAsNamedUploadHeadline={false}
              />
            )}
            {hasModule(MODULE_ADVERTISING_EVALUATION_DIMENSIONS, configuration.modules) && (
              <DimensionsForm
                module={findModuleByKey(MODULE_ADVERTISING_EVALUATION_DIMENSIONS, configuration.modules)}
                values={values[MODULE_ADVERTISING_EVALUATION_DIMENSIONS]}
                onChange={this.onChange}
                studyType={wave.study.type.type}
                isMediaOpal={wave.study.isMediaOpal}
              />
            )}
          </div>
        </Tab>
        <Tab loading={updating} visible={params.tab === 'activation'}>
          {hasModule(MODULE_ACTIVATION_TOGGLE, configuration.modules) && (
            <ToggleForm
              module={findModuleByKey(MODULE_ACTIVATION_TOGGLE, configuration.modules)}
              values={values[MODULE_ACTIVATION_TOGGLE]}
              onChange={this.onChange}
            />
          )}
          <div
            style={
              this.isVisible(MODULE_ACTIVATION_TOGGLE) === false
                ? { opacity: 0, transition: 'opacity 0.2s' }
                : { opacity: 1, transition: 'opacity 0.2s' }
            }
          >
            {hasModule(MODULE_ACTIVATION_DIMENSIONS, configuration.modules) && (
              <DimensionsForm
                module={findModuleByKey(MODULE_ACTIVATION_DIMENSIONS, configuration.modules)}
                values={values[MODULE_ACTIVATION_DIMENSIONS]}
                onChange={this.onChange}
                studyType={wave.study.type.type}
                isMediaOpal={wave.study.isMediaOpal}
              />
            )}
          </div>
        </Tab>
        {hasModule(MODULE_CUSTOM_QUESTIONS, configuration.modules) && (
          <Tab loading={updating} visible={params.tab === 'custom-questions'}>
            <CustomQuestionsForm
              module={findModuleByKey(MODULE_CUSTOM_QUESTIONS, configuration.modules)}
              values={values[MODULE_CUSTOM_QUESTIONS]}
              onChange={this.onChange}
            />
          </Tab>
        )}
        {hasModule(MODULE_CUSTOM_QUESTIONS_RICH_TEXT, configuration.modules) && (
          <Tab loading={updating} visible={params.tab === 'custom-questions-rich-text'}>
            <CustomQuestionsRichTextForm
              module={findModuleByKey(MODULE_CUSTOM_QUESTIONS_RICH_TEXT, configuration.modules)}
              values={values[MODULE_CUSTOM_QUESTIONS_RICH_TEXT]}
              onChange={this.onChange}
            />
          </Tab>
        )}
        {hasModule(MODULE_REPORTING_OPTIONS, configuration.modules) && (
          <Tab loading={updating} visible={params.tab === 'reporting-options'}>
            <ReportingOptionsForm
              configuration={configuration}
              module={findModuleByKey(MODULE_REPORTING_OPTIONS, configuration.modules)}
              values={values[MODULE_REPORTING_OPTIONS]}
              onChange={this.onChange}
              wave={wave}
            />
          </Tab>
        )}
        {hasModule(MODULE_NOTES, configuration.modules) && (
          <Tab loading={updating} visible={params.tab === 'notes'}>
            <NotesForm
              configuration={configuration}
              module={findModuleByKey(MODULE_NOTES, configuration.modules)}
              values={values[MODULE_NOTES]}
              onChange={this.onChange}
              wave={wave}
            />
          </Tab>
        )}
      </Fragment>
    );
  };

  render() {
    const { loading, updating, wave, error } = this.state;
    const { isMediaOpal } = wave?.study ?? {};

    const titlePrefix = isMediaOpal ? 'Studie konfigurieren' : 'Welle konfigurieren';
    let title = `${titlePrefix} | Media Monitor Rapid`;

    if (!loading) {
      title = `${wave?.study.type.name} ${title}`;
    }

    return (
      <Screen title={title}>
        <ScreenHeader title={loading ? '' : titlePrefix}>
          <WaveActions wave={wave} />
        </ScreenHeader>
        {loading && <Loader />}
        {error && <ErrorScreen code={error.code} renderHeader={false} />}
        {!loading && !error && (
          <Fragment>
            <WaveHeader wave={wave} start={wave.start} end={wave.end} dateSingle={wave.dateSingle} />
            <div style={{ display: 'flex', flexDirection: 'row', minHeight: '400px' }} className="mb-30">
              <div>{this.renderMenu()}</div>
              <div style={{ flexGrow: 1 }}>{this.renderTabs()}</div>
            </div>
            <Button
              primary
              type="button"
              disabled={updating}
              onClick={this.submit}
              icon="save outline"
              labelPosition="right"
              content="Konfiguration speichern"
              data-doc="app_wave_save"
              data-cy="app_wave_save"
            />
          </Fragment>
        )}
      </Screen>
    );
  }
}

const Loader = () => (
  <Fragment>
    <Segment attached="top">
      <Header>
        <Placeholder style={{ marginBottom: '10px' }}>
          <Placeholder.Header>
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Header>
        </Placeholder>
        <div
          style={{
            borderBottom: '1px dotted lightgrey',
            width: '100%',
            height: '1px',
            margin: '5px 0',
          }}
        />
        <Placeholder>
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder>
      </Header>
    </Segment>
    <div style={{ display: 'flex' }}>
      <Menu attached vertical style={{ borderTop: 0 }}>
        <Menu.Item>
          <Placeholder style={{ width: '160px' }}>
            <Placeholder.Header>
              <Placeholder.Line length="full" />
            </Placeholder.Header>
          </Placeholder>
        </Menu.Item>
        <Menu.Item>
          <Placeholder style={{ width: '140px' }}>
            <Placeholder.Header>
              <Placeholder.Line length="full" />
            </Placeholder.Header>
          </Placeholder>
        </Menu.Item>
        <Menu.Item>
          <Placeholder style={{ width: '150px' }}>
            <Placeholder.Header>
              <Placeholder.Line length="full" />
            </Placeholder.Header>
          </Placeholder>
        </Menu.Item>
        <Menu.Item>
          <Placeholder style={{ width: '160px' }}>
            <Placeholder.Header>
              <Placeholder.Line length="full" />
            </Placeholder.Header>
          </Placeholder>
        </Menu.Item>
      </Menu>
      <Tab attached visible style={{ minHeight: '400px' }}>
        <Dimmer active inverted>
          <Spinner>Konfiguration wird geladen ...</Spinner>
        </Dimmer>
      </Tab>
    </div>
  </Fragment>
);

export default withHooks(ConfigurationScreen);
