import React from 'react';
import { Button, Header, Icon, Image, Modal, Segment } from 'semantic-ui-react';
import Client from '../Client';
import download from '../Download';
import './File.scss';

class File extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      url: null,
      binary: null,
      fetching: true,
      open: false,
      error: false,
    };

    this.renderFile = this.renderFile.bind(this);
    this.remove = this.remove.bind(this);
  }

  componentDidMount() {
    const { file, wave } = this.props;

    Client({
      method: 'GET',
      url: `/wave/${wave.id}/file/${file.fileName}`,
      responseType: 'blob',
    })
      .then((response) => {
        const fileResponse = response.data;

        this.setState({
          url: window.URL.createObjectURL(fileResponse),
          binary: fileResponse,
          fetching: false,
          error: false,
        });
      })
      .catch(() => {
        this.setState({ error: true, fetching: false });
      });
  }

  remove() {
    const { file, onRemove } = this.props;

    onRemove(file);
  }

  renderPDF() {
    const { url, open } = this.state;
    const { file, placeholder, showAsNamedUploadHeadline } = this.props;

    return (
      <React.Fragment>
        {showAsNamedUploadHeadline && (
          <div className="ui tiny header" data-cy="file-label-named-upload" data-cy-key={file.key}>
            <strong>{placeholder}</strong>
          </div>
        )}
        <Segment placeholder attached data-cy="app_file_wrapper">
          <Header icon>
            <Icon name="pdf file outline" />
          </Header>
          <Modal size="large" open={open} onClose={() => this.setState({ open: false })}>
            <Header icon="pdf file outline" content="Vorschau" />
            <Modal.Content style={{ height: '80vh' }}>
              <object
                style={{ width: '100%', height: '100%' }}
                data={url}
                type={file.type}
                name={file.originalName}
                aria-label="PDF Vorschau"
              />
            </Modal.Content>
            <Modal.Actions>
              <Button content="Schließen" onClick={() => this.setState({ open: false })} />
            </Modal.Actions>
          </Modal>
        </Segment>
        {this.renderTitleAndButtons('Vorschau', () => this.setState({ open: true }))}
      </React.Fragment>
    );
  }

  renderImage() {
    const { url } = this.state;
    const { file, placeholder, showAsNamedUploadHeadline } = this.props;

    return (
      <React.Fragment>
        {showAsNamedUploadHeadline && (
          <div className="ui tiny header" data-cy="file-label-named-upload" data-cy-key={file.key}>
            <strong>{placeholder}</strong>
          </div>
        )}
        <Segment placeholder attached style={{ alignItems: 'center' }} data-cy="app_file_wrapper">
          <Image fluid src={url} style={{ maxHeight: '250px', width: 'auto', flexShrink: 0 }} />
        </Segment>
        {this.renderTitleAndButtons('Herunterladen', (binary, file) => download(binary, file.originalName))}
      </React.Fragment>
    );
  }

  renderTitleAndButtons = (downloadLabel, onDownload) => {
    const { binary } = this.state;
    const { file, hasTitle, onChange, placeholder, showAsNamedUploadHeadline } = this.props;

    return (
      <React.Fragment>
        {hasTitle && !showAsNamedUploadHeadline && (
          <input
            type="text"
            className="file-place-holder"
            data-cy="file-label"
            placeholder={placeholder}
            value={file.title ? file.title : ''}
            onChange={(event) => {
              if (event.target.value !== file.title) {
                onChange(file, event);
              }
            }}
          />
        )}
        <Button.Group attached="bottom" size="small" className="file-list-buttons">
          <Button
            type="button"
            color="red"
            content="Löschen"
            labelPosition="left"
            icon="trash alternate outline"
            data-cy="button-delete-file"
            onClick={this.remove}
          />
          {binary !== null && (
            <Button
              type="button"
              labelPosition="right"
              icon="download"
              content={downloadLabel}
              onClick={() => onDownload(binary, file)}
            />
          )}
        </Button.Group>
      </React.Fragment>
    );
  };

  renderDefaultFile() {
    const { file } = this.props;

    return (
      <React.Fragment>
        <Segment placeholder attached data-cy="app_file_wrapper">
          <Header icon>
            <Icon name="file outline" />
            <h4>{file.originalName}</h4>
          </Header>
        </Segment>
        {this.renderTitleAndButtons('Herunterladen', (binary, file) => download(binary, file.originalName))}
      </React.Fragment>
    );
  }

  renderFile() {
    const { error } = this.state;
    const { file } = this.props;

    if (error === true) {
      return (
        <React.Fragment>
          <Segment placeholder attached data-cy="app_file_wrapper">
            <Header icon>
              <Icon name="warning circle" />
              Fehler beim Laden der Datei [{file.originalName}].
            </Header>
          </Segment>
          {this.renderTitleAndButtons('Herunterladen', (binary, file) => download(binary, file.originalName))}
        </React.Fragment>
      );
    }

    if (file.mimeType === 'application/pdf') {
      return this.renderPDF();
    }

    if (file.mimeType === 'image/png' || file.mimeType === 'image/jpg' || file.mimeType === 'image/jpeg') {
      return this.renderImage();
    }

    return this.renderDefaultFile();
  }

  render() {
    const { fetching } = this.state;

    return fetching === true ? <Segment placeholder loading attached style={{ marginTop: 0 }} /> : this.renderFile();
  }
}

export default File;
