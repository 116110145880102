import React from 'react';
import { Button, Icon, Placeholder } from 'semantic-ui-react';
import {
  hasOneOfRoles,
  ROLE_ADMIN,
  ROLE_SERVICE_PROVIDER_PANEL,
  ROLE_SERVICE_PROVIDER_QUESTIONNAIRE,
} from '../Permissions';
import DownloadLink from './DownloadLink';
import Date from './Order/Date';

class ReportingFileList extends React.Component {
  constructor(props) {
    super(props);

    this.fileInput = React.createRef();
    this.upload = this.upload.bind(this);
  }

  upload() {
    const { uploadFile, study } = this.props;
    const [file] = this.fileInput.current.files;
    console.log('Adding file to formdata: ', file);
    const data = new FormData();
    data.append('file', file);
    data.append('id', study.id);

    uploadFile(data);
    this.fileInput.current.value = null;
  }

  renderButtons() {
    const { uploading, notifying, sendReportingNotification } = this.props;

    return (
      <div className="mt-15">
        <input
          type="file"
          className="d-none"
          ref={this.fileInput}
          onChange={this.upload}
          data-cy="study_upload_reporting_file"
        />
        <Button
          primary
          icon
          disabled={uploading}
          labelPosition="right"
          data-doc="app_study_add_reporting_file"
          data-cy="study_add_reporting_file"
          onClick={() => this.fileInput.current.click()}
        >
          <Icon name="folder open outline" />
          Datei hochladen
        </Button>
        {hasOneOfRoles([ROLE_ADMIN, ROLE_SERVICE_PROVIDER_QUESTIONNAIRE]) && (
          <Button
            icon
            color="green"
            labelPosition="right"
            disabled={notifying}
            loading={notifying}
            onClick={sendReportingNotification}
            data-doc="app_study_send_reporting_notification"
            data-cy="study_send_reporting_notification"
          >
            <Icon name="bell outline" />
            Marktforscher informieren
          </Button>
        )}
      </div>
    );
  }

  render() {
    const { study, uploading, removeFile } = this.props;

    return (
      <table className="ui very basic table" data-cy="study_reporting_file_list">
        <tbody>
          {uploading === true && (
            <tr style={{ height: '60px' }}>
              <td>
                <Placeholder fluid>
                  <Placeholder.Header>
                    <Placeholder.Line length="full" />
                  </Placeholder.Header>
                </Placeholder>
              </td>
              <td>
                <Placeholder fluid>
                  <Placeholder.Header>
                    <Placeholder.Line length="long" />
                  </Placeholder.Header>
                </Placeholder>
              </td>
              <td className="right aligned">
                <Button icon disabled labelPosition="right">
                  <Icon name="trash alternate outline" />
                  Datei löschen
                </Button>
              </td>
            </tr>
          )}
          {study.files.length === 0
            ? uploading === false && (
                <tr className="text-muted">
                  <td colSpan={3}>Es wurden noch keine Dateien hochgeladen.</td>
                </tr>
              )
            : study.files.map((file) => <File key={file.id} file={file} study={study} remove={removeFile} />)}
        </tbody>
        {hasOneOfRoles([ROLE_ADMIN, ROLE_SERVICE_PROVIDER_PANEL, ROLE_SERVICE_PROVIDER_QUESTIONNAIRE]) && (
          <tfoot data-doc="app_study_reporting_actions">
            <tr>
              <th colSpan={3}>{this.renderButtons()}</th>
            </tr>
          </tfoot>
        )}
      </table>
    );
  }
}

export default ReportingFileList;

class File extends React.Component {
  // eslint-disable-line react/no-multi-comp
  constructor(props) {
    super(props);

    this.state = {
      deleting: false,
    };
  }

  render() {
    const { deleting } = this.state;
    const { file, remove, study } = this.props;

    return (
      <tr className={`${deleting ? 'disabled' : ''}`}>
        <td className="collapsing">
          <Date date={file.createdAt} />
        </td>
        <td>
          <DownloadLink url={`/study/${study.id}/reporting-file/${file.id}`} name={file.name} />
        </td>
        {hasOneOfRoles([ROLE_ADMIN, ROLE_SERVICE_PROVIDER_PANEL, ROLE_SERVICE_PROVIDER_QUESTIONNAIRE]) && (
          <td className="right aligned">
            <Button
              icon
              labelPosition="right"
              loading={deleting}
              onClick={() => {
                this.setState({ deleting: true });
                remove(file.id);
              }}
              data-doc="app_study_remove_reporting_file"
              data-cy="study_remove_reporting_file"
            >
              <Icon name="trash alternate outline" />
              Datei löschen
            </Button>
          </td>
        )}
      </tr>
    );
  }
}
