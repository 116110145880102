import AngleDoubleRight from '../assets/angle-double-right-light.svg?react';
import React, { Fragment } from 'react';
import ReactMarkdown from 'react-markdown';
import { Link } from 'react-router-dom';
import { Accordion, Button, Header, Icon, Modal, Segment } from 'semantic-ui-react';
import { addMessage } from '../Actions/messageActions';
import { store } from '../App';
import Client from '../Client';
import { hasOneOfRoles, ROLE_ADMIN, ROLE_STUDENT } from '../Permissions';
import OrganizationTagList from './OrganizationTagList';

class NewsListEntry extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isDeleteModalOpen: false,
      loading: false,
      isOpen: props.isInitialOpen,
    };
  }

  remove = () => {
    const { newsEntry, removeFromList } = this.props;
    this.setState({ loading: true });

    Client.delete(`/news/${newsEntry.id}/remove`)
      .then(() => {
        this.setState({ isDeleteModalOpen: false, loading: false });
        removeFromList(newsEntry.id);
        store.dispatch(addMessage('Die Neuigkeit wurde erfolgreich entfernt.'));
      })
      .catch(() => {
        this.setState({ isDeleteModalOpen: false, loading: false });
        store.dispatch(
          addMessage(
            'Beim Löschen der Neuigkeit ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.',
            false,
            'error'
          )
        );
      });
  };

  handleClick = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };

  render() {
    const { isDeleteModalOpen, loading, isOpen } = this.state;
    const { newsEntry } = this.props;

    return (
      <Fragment>
        <Segment role="listitem" className="my-15">
          <Accordion>
            <Accordion.Title style={{ cursor: 'default' }}>
              <div className="mb-15 d-flex justify-content-space-between">
                <div className="mb-5">
                  <span className="text-muted">Veröffentlicht am {newsEntry.createdAt}</span>
                  <h2 className="mb-5" data-cy="news_title">
                    {newsEntry.title}
                  </h2>
                  {hasOneOfRoles([ROLE_ADMIN, ROLE_STUDENT]) && (
                    <OrganizationTagList organizations={newsEntry.organizations} />
                  )}
                </div>
                {hasOneOfRoles([ROLE_ADMIN, ROLE_STUDENT]) && (
                  <div>
                    <Button
                      compact
                      icon
                      labelPosition="right"
                      size="small"
                      as={Link}
                      to={`/news/${newsEntry.id}/update`}
                      data-doc="app_news_update_news_post"
                    >
                      Bearbeiten
                      <Icon name="edit" />
                    </Button>
                    <Button
                      compact
                      icon
                      labelPosition="right"
                      size="small"
                      color="red"
                      onClick={() => this.setState({ isDeleteModalOpen: true })}
                      data-doc="app_news_delete_news_post"
                    >
                      Löschen
                      <Icon name="trash alternate outline" />
                    </Button>
                  </div>
                )}
              </div>
            </Accordion.Title>
            <Accordion.Content active={isOpen}>
              <ReactMarkdown children={newsEntry.text} />
            </Accordion.Content>
            <button type="button" className={`as-link ${isOpen === true ? 'mt-15' : ''}`} onClick={this.handleClick}>
              {isOpen === true && (
                <AngleDoubleRight height={16} style={{ marginRight: '0.25rem', transform: 'rotate(180deg)' }} />
              )}
              <span>{isOpen ? 'Weniger anzeigen' : 'Mehr anzeigen'}</span>
              {isOpen === false && <AngleDoubleRight height={16} style={{ marginLeft: '0.25rem' }} />}
            </button>
          </Accordion>
        </Segment>
        <Modal
          size="tiny"
          open={isDeleteModalOpen}
          onClose={() => this.setState({ isDeleteModalOpen: false })}
          data-doc="app_news_delete_news_post"
        >
          <Header icon="trash alternate outline" content="Neuigkeit löschen" />
          <Modal.Content>
            <p>
              Bitte bestätigen Sie, dass Sie die Neuigkeit <strong>unwiderruflich</strong> löschen möchten.
            </p>
          </Modal.Content>
          <Modal.Actions>
            <Button
              content="Abbrechen"
              disabled={loading}
              onClick={() => this.setState({ isDeleteModalOpen: false })}
            />
            <Button color="red" content="Löschen" loading={loading} disabled={loading} onClick={this.remove} />
          </Modal.Actions>
        </Modal>
      </Fragment>
    );
  }
}

export default NewsListEntry;
