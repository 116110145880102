import React, { Fragment } from 'react';
import { Button } from 'semantic-ui-react';

class UploadButton extends React.Component {
  maxFileSize = 100000000; // 10MByte

  constructor(props) {
    super(props);

    this.input = React.createRef();
    this.onChange = this.onChange.bind(this);
  }

  onChange() {
    const { onUpload } = this.props;
    const [file] = this.input.current.files;

    onUpload(file);
    // reset input manually so we can re-upload the same file again if we want because there is no 'reset' button
    this.input.current.value = '';
  }

  render() {
    const { onRemove, removeLabel, uploadLabel, isUploading, value, accept, style, dataCyName, fluid } = this.props;

    return value !== null ? (
      <Button
        fluid={fluid}
        style={{ textAlign: 'left', ...style }}
        type="button"
        content={removeLabel}
        icon="trash alternate outline"
        labelPosition="right"
        color="red"
        onClick={onRemove}
        data-cy="upload-button"
      />
    ) : (
      <Fragment>
        <Button
          fluid={fluid}
          style={{ textAlign: 'left', ...style }}
          type="button"
          content={uploadLabel}
          icon="folder open outline"
          labelPosition="right"
          loading={isUploading}
          disabled={isUploading}
          onClick={() => this.input.current.click()}
        />
        <input
          className="d-none"
          type="file"
          accept={accept}
          data-cy={`input-${dataCyName}`}
          ref={this.input}
          onChange={this.onChange}
          size={this.maxFileSize}
        />
      </Fragment>
    );
  }
}

export default UploadButton;
