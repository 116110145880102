// Lookup Props: https://github.com/chakra-ui/chakra-ui/tree/main/packages/components/theme/src/foundations
import { extendTheme } from '@chakra-ui/react';
import { colors } from './Colors.ts';
import { components } from './Components.ts';
import { styles } from './GlobalStyles.ts';
import { radii, sizes } from './Sizes.ts';
import { space } from './Spacing.ts';
import { typography } from './Typography.ts';

export const Theme = extendTheme({
  sizes,
  colors,
  components,
  space,
  radii,
  ...typography,
  ...styles,
});
