import React from 'react';
import { Form, Grid, Radio } from 'semantic-ui-react';
import { findModuleByKey, MODULE_CUSTOMER_AND_SPECIFICATION } from '../Modules';
import FieldList from './FieldList';
import FilesForm from './FilesForm';

class CustomerAndSpecificationForm extends React.PureComponent {
  onChangeValue = (name, value) => {
    const { module, values, onChange } = this.props;
    const { key } = module;

    const data = {
      ...values,
      [name]: value,
    };

    onChange(key, data);
  };

  render() {
    const { module, values, wave, configuration } = this.props;
    const { objectsOfInvestigation } = module;
    const withFiles = ['PKW-Modelle + Autohäuser', 'PKW-Modelle'].includes(values.objectOfInvestigation);

    return (
      <form className="ui form">
        <Grid>
          <Grid.Column widescreen={10} largeScreen={10} computer={12} mobile={16}>
            <Form.Field>
              <label htmlFor="customer">Kunde</label>
              <input
                type="text"
                name="customer"
                id="customer"
                value={values.customer}
                onChange={(event) => this.onChangeValue(event.target.name, event.target.value)}
              />
            </Form.Field>
            <Form.Field>
              <div className="as-label">Wettbewerber</div>
              <FieldList
                name="competitors"
                addButtonText="Wettbewerber hinzufügen"
                values={values.competitors}
                onChange={(values) => this.onChangeValue('competitors', values)}
                limit={4}
              />
            </Form.Field>
            <Form.Field>
              <div className="as-label">Untersuchungsgegenstand</div>
              {objectsOfInvestigation.map((option) => (
                <Form.Field key={option}>
                  <Radio
                    label={option}
                    name="objectOfInvestigation"
                    value={option}
                    checked={values.objectOfInvestigation === option}
                    onChange={() => this.onChangeValue('objectOfInvestigation', option)}
                  />
                </Form.Field>
              ))}
            </Form.Field>
          </Grid.Column>
        </Grid>
        <div style={withFiles === true ? { opacity: 1, height: 'auto' } : { opacity: 0, height: 0 }}>
          <FilesForm
            module={module}
            wave={wave}
            values={values}
            onChange={(key, files) => this.onChangeValue('files', files.files)}
            fileCount={5}
            allFiles={findModuleByKey(MODULE_CUSTOMER_AND_SPECIFICATION, configuration.modules).allFiles}
            hasTitle
            messages={[
              'Sie dürfen maximal 5 Dateien hochladen.',
              'Zulässige Dateiformate sind PDF, PNG oder JPEG',
              'Nach dem Upload bitte den Modellnamen angeben',
            ]}
            label="Modellname eingeben ..."
          />
        </div>
      </form>
    );
  }
}

export default CustomerAndSpecificationForm;
