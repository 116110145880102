import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Dropdown, Form, Icon, Label, Loader, Table } from 'semantic-ui-react';
import { withHooks } from '@Classic/Hocs/withHooks';
import { addMessage } from '../Actions/messageActions';
import { store } from '../App';
import Client from '../Client';
import Screen from '../Components/Screen';
import ScreenHeader from '../Components/ScreenHeader';
import { getRoleName, hasRole, ROLE_ADMIN } from '../Permissions';
import Popup from '../Ui/Popup';

class UserScreen extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      users: [],
      loading: true,
      organizations: [],
      organizationId: 'all',
    };

    this.filterUsers = this.filterUsers.bind(this);
  }

  componentDidMount() {
    const { navigate } = this.props;
    if (hasRole(ROLE_ADMIN) === false) {
      navigate('/');
      store.dispatch(addMessage('Sie haben keine Berechtigung diese Seite aufzurufen.', false, 'error'));
    }

    Promise.all([Client.get('/user'), Client.get('/organization/list')])
      .then((response) => {
        const [users, organizations] = response;
        this.setState({ users: users.data, organizations: organizations.data, loading: false });
      })
      .catch((error) => {
        if (error.networkError) {
          return;
        }
        this.setState({ loading: false });
        store.dispatch(addMessage('Beim Abruf der Daten ist ein unerwarteter Fehler aufgetreten.', false, 'error'));
      });
  }

  filterUsers(organizationId) {
    this.setState({ loading: true, organizationId: organizationId });

    if (organizationId === 'all') {
      Client.get('/user')
        .then((response) => this.setState({ users: response.data, loading: false }))
        .catch((error) => {
          if (error.networkError) {
            return;
          }
          this.setState({ loading: false });
          store.dispatch(addMessage('Beim Abruf der Daten ist ein unerwarteter Fehler aufgetreten.', false, 'error'));
        });

      return;
    }

    Client.get('/user/filter', { params: { organizationId: organizationId } })
      .then((response) => this.setState({ users: response.data, loading: false }))
      .catch((error) => {
        if (error.networkError) {
          return;
        }
        this.setState({ loading: false });
        store.dispatch(addMessage('Beim Abruf der Daten ist ein unerwarteter Fehler aufgetreten.', false, 'error'));
      });
  }

  renderFilterForm = () => {
    const { organizations, organizationId, loading } = this.state;

    return (
      <Form className="w-100">
        <Form.Field>
          <label htmlFor="organizationId">Nach Organisation filtern</label>
          <Dropdown
            selection
            disabled={loading}
            loading={loading}
            name="organizationId"
            id="organizationId"
            placeholder="Filtern Sie nach Organisation"
            value={organizationId}
            onChange={(e, { value }) => this.filterUsers(value)}
            options={[
              { key: 'all', value: 'all', text: 'Alle' },
              ...organizations.map((organization) => ({
                key: organization.id,
                value: organization.id,
                text: organization.name,
              })),
            ]}
          />
        </Form.Field>
      </Form>
    );
  };

  render() {
    const { users, loading } = this.state;

    return (
      <Screen title="Nutzer | Media Monitor Rapid">
        <ScreenHeader title="Nutzer" hasBackButton={false} className="justify-content-space-between">
          <Button primary icon as={Link} to="/user/create" labelPosition="right" className="mr-0">
            <Icon link name="plus" className="mr-0" /> Neuen Nutzer erstellen
          </Button>
        </ScreenHeader>
        {this.renderFilterForm()}
        {loading === true ? (
          <Loading />
        ) : (
          <Table celled striped>
            <thead>
              <tr>
                <th />
                <th>Name</th>
                <th>E-Mail</th>
                <th>Organisation</th>
                <th>Rolle</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <tr key={user.id}>
                  <td>
                    <Active active={user.active} />
                  </td>
                  <td data-cy="user_name">
                    {user.firstName} {user.lastName}
                  </td>
                  <td>{user.email}</td>
                  <td>{user.organization.name}</td>
                  <td>{getRoleName(user.role)}</td>
                  <td>
                    <Link to={`/user/${user.id}/update`} data-cy="user_edit">
                      <Icon name="edit outline" className="mr-0" />
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </Screen>
    );
  }
}

const Loading = () => <Loader active>Nutzer werden geladen ...</Loader>;

export const Active = ({ active }) => (
  <Popup
    label={active === true ? 'aktiviert' : 'deaktiviert'}
    trigger={<Label circular empty horizontal color={active === true ? 'green' : 'red'} style={{ marginRight: 0 }} />}
  />
);

export default withHooks(UserScreen);
