import React, { Fragment } from 'react';
import { Placeholder, Segment, Tab } from 'semantic-ui-react';
import { withHooks } from '@Classic/Hocs/withHooks';
import Client from '../Client';
import ChangeHistory from '../Components/ChangeHistory';
import { ACTIVE_INDEX_CONFIGURABLE, ACTIVE_INDEX_ORDERED } from '../Components/Order/active-index';
import ChangedOrder from '../Components/Order/ChangedOrder';
import Order from '../Components/Order/Order';
import Screen from '../Components/Screen';
import ScreenHeader from '../Components/ScreenHeader';
import WaveActions from '../Components/WaveActions';
import WaveHeader from '../Components/WaveHeader';
import {
  hasOneOfRoles,
  ROLE_ADMIN,
  ROLE_MARKET_RESEARCHER,
  ROLE_SERVICE_PROVIDER_PANEL,
  ROLE_SERVICE_PROVIDER_QUESTIONNAIRE,
} from '../Permissions';
import ErrorScreen from './ErrorScreen';

class OrderScreen extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      regions: [],
      history: [],
      wave: null,
      configuration: null,
      loading: true,
      error: null,
      // We need to manage the active tab index as Semantic UIs Tab Component does not update the activeIndex properly
      // when the count of the Tabs changes :(
      activeIndex: 0,
      // Holds the correct dates. Needs to update when Tabs change but is synced via child components.
      start: null,
      end: null,
      dateSingle: null,
      availablePanelProviders: [],
    };
  }

  componentDidMount() {
    const { params } = this.props; // eslint-disable-line react/destructuring-assignment

    let requests = [
      Client.get(`/region/${params.uuid}`),
      Client.get(`/wave/${params.uuid}`),
      Client.get(`/wave/${params.uuid}/configuration`),
    ];

    if (hasOneOfRoles([ROLE_ADMIN, ROLE_SERVICE_PROVIDER_QUESTIONNAIRE, ROLE_MARKET_RESEARCHER])) {
      requests = [...requests, Client.get(`/wave/${params.uuid}/history`)];
    }

    if (hasOneOfRoles([ROLE_ADMIN])) {
      requests = [...requests, Client.get('/user', { params: { roles: [ROLE_SERVICE_PROVIDER_PANEL] } })];
    }

    Promise.all(requests)
      .then(async (response) => {
        const [regions, wave, configuration, history, availablePanelProviders] = response;

        this.setState({
          regions: regions.data,
          history: history === undefined ? [] : history.data,
          wave: wave.data,
          configuration: configuration.data,
          loading: false,
          error: null,
          activeIndex: wave.data.state === 'changed' ? ACTIVE_INDEX_CONFIGURABLE : ACTIVE_INDEX_ORDERED,
          availablePanelProviders: availablePanelProviders ? availablePanelProviders.data : [],
        });
      })
      .catch((error) => {
        if (error.networkError) {
          return;
        }
        this.setState({ loading: false, error: error });
      });
  }

  resetTabIndex = () => {
    this.setState({ activeIndex: 0 });
  };

  updateHistory = (history) => {
    this.setState({ history: history });
  };

  updateWave = (wave) => {
    this.setState({
      wave: wave,
      activeIndex: wave.state === 'changed' ? ACTIVE_INDEX_CONFIGURABLE : ACTIVE_INDEX_ORDERED,
    });
  };

  syncFieldLength = (start, end, dateSingle) => {
    this.setState({
      start: start,
      end: end,
      dateSingle: dateSingle,
    });
  };

  renderTabs() {
    const { wave, history, regions, configuration, activeIndex, start, end, dateSingle, availablePanelProviders } =
      this.state;

    let tabs = [
      {
        menuItem: 'Aktuell beauftragte Konfiguration',
        render: () => (
          <Tab.Pane as={Segment.Group} className="mt-0" style={{ borderRadius: 0, borderTop: 'none' }}>
            <Order
              wave={wave}
              regions={regions}
              configuration={configuration}
              updateWave={this.updateWave}
              syncFieldLength={this.syncFieldLength}
              resetTabIndex={this.resetTabIndex}
              availablePanelProviders={availablePanelProviders}
            />
          </Tab.Pane>
        ),
      },
    ];

    if (wave && wave.state === 'changed') {
      tabs = [
        ...tabs,
        {
          menuItem: 'Konfiguration in Bearbeitung',
          render: () => (
            <Tab.Pane as={Segment.Group} className="mt-0" style={{ borderRadius: 0, borderTop: 'none' }}>
              <ChangedOrder
                wave={wave}
                regions={regions}
                configuration={configuration}
                updateWave={this.updateWave}
                updateHistory={this.updateHistory}
                syncFieldLength={this.syncFieldLength}
                resetTabIndex={this.resetTabIndex}
                availablePanelProviders={availablePanelProviders}
              />
            </Tab.Pane>
          ),
        },
      ];
    }

    if (history.length !== 0) {
      tabs = [
        ...tabs,
        {
          menuItem: 'Änderungshistorie',
          render: () => (
            <Tab.Pane as={Segment.Group} className="mt-0" style={{ borderRadius: 0, borderTop: 'none' }}>
              <ChangeHistory history={history} wave={wave} regions={regions} configuration={configuration} />
            </Tab.Pane>
          ),
        },
      ];
    }

    return (
      <Fragment>
        <WaveHeader wave={wave} start={start} end={end} dateSingle={dateSingle} />
        <Tab
          activeIndex={activeIndex}
          panes={tabs}
          menu={{ secondary: true, pointing: true, attached: true, tabular: true }}
          onTabChange={(event, { activeIndex }) => this.setState({ activeIndex })}
        />
      </Fragment>
    );
  }

  render() {
    const { wave, loading, error } = this.state;

    return (
      <Screen title="Auftragszusammenfassung | Media Monitor Rapid">
        <ScreenHeader title="Auftragszusammenfassung">
          <WaveActions wave={wave} />
        </ScreenHeader>
        {loading && <Loading />}
        {error && <ErrorScreen code={error.code} renderHeader={false} />}
        {!loading && !error && this.renderTabs()}
      </Screen>
    );
  }
}

const Loading = () => (
  <React.Fragment>
    <Segment attached="top">
      <Placeholder>
        <Placeholder.Header>
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder.Header>
      </Placeholder>
    </Segment>
    <Segment attached className="mb-15">
      <Placeholder>
        <Placeholder.Paragraph>
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder.Paragraph>
      </Placeholder>
    </Segment>
  </React.Fragment>
);

export default withHooks(OrderScreen);
