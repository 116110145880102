import { useState } from 'react';
import { Button, Checkbox, Form, Icon } from 'semantic-ui-react';

const CheckboxButtonForm = ({
  options,
  submitLabel,
  groupName,
  onChange,
  onSubmit,
  initialValue = null,
  disabled = false,
  isSubmitting = false,
}) => {
  const [values, setValues] = useState(initialValue ?? null);

  const onChangeValue = (e, { value }) => {
    let statelessValues;
    if (values.includes(value)) {
      const newValues = values.filter((v) => v !== value);
      statelessValues = newValues;
      setValues(newValues);
    } else {
      const newValues = [...values, value];
      statelessValues = newValues;
      setValues(newValues);
    }

    if (onChange) {
      onChange(statelessValues);
    }
  };

  const onSubmitForm = (e) => {
    e.preventDefault();
    onSubmit(values);
  };

  return (
    <form className="ui form" onSubmit={onSubmitForm}>
      <Form.Field>
        {options.map((option) => (
          <Checkbox
            name={groupName}
            label={option.text}
            value={option.value}
            checked={values?.includes(option.value)}
            onChange={onChangeValue}
            key={`${groupName}-${option.value}`}
            style={{
              display: 'block',
              marginBottom: '0.5rem',
              fontWeight: values?.includes(option.value) ? 'bold' : 'normal',
            }}
            disabled={disabled}
          />
        ))}
        <Button icon primary type="submit" loading={isSubmitting} style={{ marginTop: '1rem', width: '100%' }}>
          <Icon name="save outline" />
          {submitLabel}
        </Button>
      </Form.Field>
    </form>
  );
};

export default CheckboxButtonForm;
