import React from 'react';
import { Dropdown, Form, Grid, Icon, Message, TextArea } from 'semantic-ui-react';
import CustomerAndIndustry from '../Components/Order/CustomerAndIndustry';
import getOptionsFromChoiceSet from '../helper/choiceSetHelper';
import './CustomerAndIndustryForm.scss';
import FieldList from './FieldList';

class CustomerAndIndustryForm extends React.PureComponent {
  onChangeValue = (name, value) => {
    const { module, values, onChange } = this.props;
    const { key } = module;

    const data = {
      ...values,
      [name]: value,
    };

    onChange(key, data);
  };

  onChangeNestedIndustriesParent = (value) => {
    // reset child-values if parent-value changes
    this.onChangeValue('nestedIndustries', [
      {
        parentValue: value,
        childValues: [],
      },
    ]);
  };

  onChangeNestedIndustriesChild = (value) => {
    const { values } = this.props;
    const nestedIndustries = values.nestedIndustries[0];

    this.onChangeValue('nestedIndustries', [
      {
        ...nestedIndustries,
        childValues: [value],
      },
    ]);
  };

  loadDefaultCompetitors = (ev) => {
    ev.preventDefault();
    const { module, values } = this.props;
    const { competitors: competitorsFromConfiguration, competitorsCount } = module;

    const competitorsFromValues = [...values.competitors];
    competitorsFromConfiguration.forEach((competitor) => {
      if (competitorsFromValues.indexOf(competitor) < 0 && competitorsFromValues.length < competitorsCount) {
        competitorsFromValues.push(competitor);
      }
    });

    this.onChangeValue('competitors', competitorsFromValues);
  };

  render() {
    const { module, values, wave } = this.props;
    const {
      industries,
      purchaseProbability,
      competitorsCount,
      competitors: competitorsFromConfiguration,
      nestedIndustries,
    } = module;

    const isMediaOpal = [
      'advertisement_copy_test',
      'supplement_copy_test',
      'motive_test_media_opal',
      'quick_poll',
    ].includes(wave.study.type.type);
    const hasNestedIndustries = Object.keys(nestedIndustries).length > 0;

    let nestedIndustryChildren = [];
    if (hasNestedIndustries && typeof values.nestedIndustries[0]?.parentValue === 'string') {
      const selectedParentChoiceSets = nestedIndustries.choiceSets.filter(
        (choiceSet) => choiceSet.key === values.nestedIndustries[0].parentValue
      );
      nestedIndustryChildren = getOptionsFromChoiceSet(selectedParentChoiceSets[0]);
    }

    return (
      <Grid className="CustomerAndIndustryForm">
        <Grid.Column widescreen={10} largeScreen={10} computer={12} mobile={16}>
          <form className="ui form">
            <Message className="mt-0">
              <Icon name="warning sign" />
              Die Eingaben werden 1:1 im Fragebogen angezeigt.
            </Message>
            <Form.Field>
              <label htmlFor="customer">Kunde</label>
              <input
                type="text"
                name="customer"
                id="customer"
                value={values.customer}
                onChange={(event) => this.onChangeValue(event.target.name, event.target.value)}
                data-cy="input-customer-and-industry-customer"
              />
            </Form.Field>
            {!isMediaOpal && (
              <Form.Field>
                <label htmlFor="competitors" className="competitors">
                  <span>Wettbewerber</span>
                  {competitorsFromConfiguration.length > 0 && (
                    <button
                      type="button"
                      className="load-defaults"
                      onClick={this.loadDefaultCompetitors}
                      onKeyDown={this.loadDefaultCompetitors}
                    >
                      Lade Vorauswahl
                    </button>
                  )}
                </label>
                <FieldList
                  name="competitors"
                  addButtonText="Wettbewerber hinzufügen"
                  values={values.competitors}
                  onChange={(values) => this.onChangeValue('competitors', values)}
                  limit={competitorsCount}
                  dataCyName="customer-and-industry-competitors"
                />
              </Form.Field>
            )}
            {industries.length > 0 && (
              <Form.Field>
                <label htmlFor="industry">
                  {CustomerAndIndustry.getIndustryTitleByStudyType(wave.study.type.type)}
                </label>
                <Dropdown
                  selection
                  name="industry"
                  id="industry"
                  placeholder="Wählen Sie eine Branche"
                  value={values.industry}
                  data-cy="dropdown-customer-and-industry-industry"
                  onChange={(e, { value }) => this.onChangeValue('industry', value)}
                  options={industries.map((industry) => ({
                    key: industry.name,
                    value: industry.name,
                    text: industry.name,
                  }))}
                />
              </Form.Field>
            )}
            {purchaseProbability.length > 0 && (
              <Form.Field>
                <label htmlFor="purchaseProbability">
                  Spezifikation des Zeitraumes für Kaufwahrscheinlichkeit bzw. Informationswahrscheinlichkeit
                </label>
                <Dropdown
                  selection
                  name="purchaseProbability"
                  id="purchaseProbability"
                  placeholder="Wählen Sie einen Zeitraum"
                  value={values.purchaseProbability}
                  data-cy="dropdown-customer-and-industry-purchaseProbability"
                  onChange={(e, { value }) => this.onChangeValue('purchaseProbability', value)}
                  options={purchaseProbability.map((purchaseProbability) => ({
                    key: purchaseProbability.name,
                    value: purchaseProbability.name,
                    text: purchaseProbability.name,
                  }))}
                />
              </Form.Field>
            )}
            {CustomerAndIndustry.showTopicByStudyType(wave.study.type.type) && (
              <Form.Field>
                <label htmlFor="topic">{CustomerAndIndustry.getTypeTitleByStudyType(wave.study.type.type)}</label>
                <TextArea
                  type="text"
                  name="topic"
                  id="topic"
                  placeholder="z.B. Anbieter von Versicherungen, Snackmarken, Hersteller von Staubsaugern"
                  rows={2}
                  value={values.topic}
                  data-cy="textarea-customer-and-industry-topic"
                  onChange={(event) => this.onChangeValue(event.target.name, event.target.value)}
                />
              </Form.Field>
            )}
            {CustomerAndIndustry.showIndustrySpecificationByStudyType(wave.study.type.type) && (
              <Form.Field>
                <label htmlFor="industrySpecification">Spezifikation der Branche für Produktinteresse-Abfrage</label>
                <TextArea
                  type="text"
                  name="industrySpecification"
                  id="industrySpecification"
                  rows={2}
                  value={values.industrySpecification}
                  data-cy="textarea-customer-and-industry-industry-specification"
                  onChange={(event) => this.onChangeValue(event.target.name, event.target.value)}
                />
              </Form.Field>
            )}
            {isMediaOpal && hasNestedIndustries && (
              <Form.Field>
                <label htmlFor="industry">{nestedIndustries.label}</label>
                <Dropdown
                  selection
                  name="industry"
                  id="industry"
                  className="mb-5"
                  placeholder={nestedIndustries.placeholderParent}
                  value={values.nestedIndustries[0]?.parentValue}
                  data-cy="dropdown-customer-and-industry-nested-industries-parent"
                  onChange={(e, { value }) => this.onChangeNestedIndustriesParent(value)}
                  options={nestedIndustries.choiceSets.map((choiceSet) => ({
                    key: choiceSet.key,
                    value: choiceSet.key,
                    text: choiceSet.label,
                  }))}
                />
                {nestedIndustryChildren.length > 0 && (
                  <Dropdown
                    selection
                    name="industry"
                    id="industry"
                    placeholder={nestedIndustries.placeholderChild}
                    value={values.nestedIndustries[0]?.childValues[0]}
                    data-cy="dropdown-customer-and-industry-nested-industries-child"
                    onChange={(e, { value }) => this.onChangeNestedIndustriesChild(value)}
                    options={nestedIndustryChildren}
                  />
                )}
              </Form.Field>
            )}
          </form>
        </Grid.Column>
      </Grid>
    );
  }
}

export default CustomerAndIndustryForm;
