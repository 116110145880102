import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Icon, Loader, Table } from 'semantic-ui-react';
import { store } from '@Classic/App.jsx';
import { withHooks } from '@Classic/Hocs/withHooks';
import { addMessage } from '../Actions/messageActions';
import Client from '../Client';
import Screen from '../Components/Screen';
import ScreenHeader from '../Components/ScreenHeader';
import download from '../Download';
import { hasRole, ROLE_ADMIN } from '../Permissions';

const onDownloadPDF = (fileName, url) => {
  Client({
    method: 'GET',
    url: `/blueprint/questionnaire/${url}/download`,
    responseType: 'blob',
  })
    .then((response) => download(response.data, fileName))
    .catch(() => {
      store.dispatch(
        addMessage('Die Datei konnte nicht heruntergeladen werden. Bitte versuchen Sie es erneut.', false, 'error')
      );
    });
};

class BlueprintScreen extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      blueprints: [],
    };
  }

  componentDidMount() {
    const { navigate } = this.props;
    if (hasRole(ROLE_ADMIN) === false) {
      navigate('/');
      store.dispatch(addMessage('Sie haben keine Berechtigung diese Seite aufzurufen.', false, 'error'));
    }

    Promise.all([Client.get('/blueprint/list')])
      .then((response) => {
        const [blueprints] = response;

        // const blueprintsWithUsers = blueprints.data.map(blueprint => ({
        //   ...blueprint,
        //   users: [...users.data.filter(user => user.blueprint.id === blueprint.id)],
        // }));

        this.setState({
          blueprints: [...blueprints.data],
          loading: false,
        });
      })
      .catch(() => {
        this.setState({ loading: false });
        store.dispatch(addMessage('Beim Abruf der Daten ist ein unerwarteter Fehler aufgetreten.', false, 'error'));
      });
  }

  render() {
    const { blueprints, loading } = this.state;

    return (
      <Screen title="Studien Blaupausen | Media Monitor Rapid" data-doc="app_blueprint_management">
        <ScreenHeader title="Studien Blaupausen" hasBackButton className="justify-content-space-between">
          <Button
            primary
            icon
            as={Link}
            to="/blueprint/create"
            labelPosition="right"
            className="mr-0"
            data-cy="blueprint_create"
            data-doc="app_create_blueprint"
          >
            <Icon link name="plus" className="mr-0" /> Neue Blaupause erstellen
          </Button>
        </ScreenHeader>
        {loading === true ? (
          <Loading />
        ) : (
          <Table celled striped className="mb-5" data-cy="blueprint_list">
            <thead>
              <tr>
                <th>Blaupause</th>
                <th>Komponenten</th>
                <th>Beschreibung</th>
                <th>Typ</th>
                <th>Fragebogen</th>
                <th>Bearbeiten</th>
              </tr>
            </thead>
            <tbody>
              {blueprints.map((blueprint, index) => (
                <tr key={blueprint.id}>
                  <td className="top aligned" data-cy="blueprint_name">
                    {blueprint.name}
                  </td>
                  <td className="top aligned">
                    <ul className="m-0">
                      {blueprint.components.map((component) => (
                        <li key={component.key}>{component.configuration.label}</li>
                      ))}
                    </ul>
                  </td>
                  <td className="top aligned">{blueprint.description}</td>
                  <td className="top aligned">{blueprint.typeLabel}</td>
                  <td className="top aligned">
                    <span
                      className="as-link"
                      role="button"
                      tabIndex={index}
                      onClick={() => onDownloadPDF(blueprint.questionnaire, blueprint.questionnaireUrl)}
                      onKeyPress={() => onDownloadPDF(blueprint.questionnaire, blueprint.questionnaireUrl)}
                    >
                      {blueprint.questionnaire}
                    </span>
                  </td>
                  <td className="top aligned">
                    <Link
                      to={`/blueprint/${blueprint.id}/update`}
                      data-cy="blueprint_edit"
                      data-doc="app_blueprint_edit"
                    >
                      <Icon name="edit outline" className="mr-0" />
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </Screen>
    );
  }
}

const Loading = () => <Loader active>Blaupausen werden geladen ...</Loader>;

export default withHooks(BlueprintScreen);
