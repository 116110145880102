import { useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Form, Icon, Message } from 'semantic-ui-react';
import { ErrorMessage, Formik } from 'formik';
import * as Yup from 'yup';
import { useMessageStore } from '@Store/useMessageStore.ts';
import { useUserStore } from '@Store/useUsersStore';
import InputError from '@Classic/Ui/InputError.jsx';

const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Bitte geben Sie eine gültige E-Mail Adresse an.')
    .required('Bitte geben Sie Ihre E-Mail Adresse an.')
    .trim(),
  password: Yup.string().required('Bitte geben Sie Ihr Passwort an.'),
});

const Login = () => {
  const emailRef = useRef(null);
  const [state, setState] = useState({ error: false });
  const navigate = useNavigate();

  const {
    isLoading,
    setLoading,
    login: executeLogin,
  } = useUserStore((state) => ({
    user: state.data,
    login: state.login,
    isLoading: state.isLoading,
    setLoading: state.setLoading,
  }));
  const { clear } = useMessageStore((state) => ({ clear: state.clear }));

  const redirectOnSuccess = () => {
    const redirect = window.sessionStorage.getItem('redirect');
    console.log('Redirect set to: ', redirect);
    if (redirect !== null) {
      window.sessionStorage.removeItem('redirect');
      navigate(redirect);
      return;
    }
    navigate('/');
  };

  const login = async (credentials: { email: string; password: string }) => {
    const { email, password } = credentials;
    setLoading(true);

    executeLogin(email, password)
      .then(() => {
        clear();
        redirectOnSuccess();
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
        setState({ error: true });
      });
  };

  return (
    <>
      <Formik
        initialValues={{
          email: '',
          password: '',
        }}
        validationSchema={LoginSchema}
        onSubmit={(values) => login(values)}
        render={({ errors, touched, values, handleSubmit, handleChange, handleBlur }) => (
          <form onSubmit={handleSubmit} className="loginbox ui form">
            <Form.Field className="form-group" fluid="true" error={errors.email && touched.email}>
              <label htmlFor="email">E-Mail</label>
              <input
                type="text"
                name="email"
                id="email"
                autoComplete="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                ref={emailRef}
                className="form-control"
              />
              <InputError message={<ErrorMessage name="email" />} />
            </Form.Field>
            <Form.Field className="form-group" fluid="true" error={errors.password && touched.password}>
              <label htmlFor="password">Passwort</label>
              <input
                type="password"
                name="password"
                id="password"
                autoComplete="current-password"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                className="form-control"
              />
              <InputError message={<ErrorMessage name="password" />} />
            </Form.Field>
            {/*
								// Awkward check as state is not set by default in the location object
								this.props.location.state &&
									this.props.location.state.fromReset && ( // eslint-disable-line react/destructuring-assignment, max-len
										<Message positive>
											<Icon name="check" />
											Ihr Passwort wurde erfolgreich geändert. Sie können sich jetzt mit Ihrem neuen Passwort anmelden.
										</Message>
									)
							*/}
            {state.error && (
              <Message negative>
                <Icon name="warning" />
                Falsche E-Mail oder falsches Passwort. Bitte überprüfen Sie ihre Eingabe und versuchen Sie es erneut.
              </Message>
            )}
            <div className="mt-4">
              <Button className="btn btn-primary btn-block mt-3" fluid secondary type="submit" loading={isLoading}>
                Login
              </Button>
              <Link to="/recovery" className="mt-2 d-inline-block">
                Passwort vergessen?
              </Link>
            </div>
          </form>
        )}
      />
    </>
  );
};
export default Login;
