import React from 'react';
import { Tab } from 'semantic-ui-react';
import { withHooks } from '@Classic/Hocs/withHooks';
import { addMessage } from '../Actions/messageActions';
import { store } from '../App';
import Client from '../Client';
import ReportingFileList from '../Components/ReportingFileList';
import Screen from '../Components/Screen';
import ScreenHeader from '../Components/ScreenHeader';
import StudyHeader from '../Components/StudyHeader';
import LinkForm from '../Forms/LinkForm';
import {
  hasOneOfRoles,
  ROLE_ADMIN,
  ROLE_SERVICE_PROVIDER_PANEL,
  ROLE_SERVICE_PROVIDER_QUESTIONNAIRE,
} from '../Permissions';

class ReportingScreen extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      study: null,
      fetching: true,
      uploading: false,
      notifying: false,
      activeIndex: 1,
    };

    this.uploadFile = this.uploadFile.bind(this);
    this.removeFile = this.removeFile.bind(this);
    this.updateReportingLink = this.updateReportingLink.bind(this);
    this.sendReportingNotification = this.sendReportingNotification.bind(this);
    this.renderTabs = this.renderTabs.bind(this);
  }

  componentDidMount() {
    const { params } = this.props;
    const { uuid } = params;

    Client.get(`/study/${uuid}`)
      .then((response) => this.setState({ study: response.data, fetching: false }))
      .catch((error) => {
        if (error.networkError) {
          return;
        }
        this.setState({ fetching: false });
      });
  }

  uploadFile(formData) {
    const { params } = this.props;
    const { uuid } = params;

    this.setState({ uploading: true });

    Client.upload(`/study/${uuid}/add-file`, formData)
      .then((response) => {
        this.setState({ study: response.data, uploading: false });
        store.dispatch(addMessage('Die Datei wurde erfolgreich hochgeladen.'));
      })
      .catch((error) => {
        console.log('Error hier: ', error);
        if (error.networkError) {
          return;
        }
        this.setState({ uploading: false });

        if (error.code === 450) {
          store.dispatch(
            addMessage(
              'Fehler beim Hochladen der Datei. Eine Datei mit diesem Namen existiert bereits.',
              false,
              'error'
            )
          );

          return;
        }

        store.dispatch(addMessage('Fehler beim Hochladen der Datei. Bitte versuchen Sie es erneut.', false, 'error'));
      });
  }

  removeFile(fileId) {
    const { params } = this.props;
    const { uuid } = params;

    Client.post(`/study/${uuid}/remove-file`, { studyId: uuid, fileId })
      .then((response) => {
        this.setState({ study: response.data });
        store.dispatch(addMessage('Die Datei wurde erfolgreich entfernt.'));
      })
      .catch(() => {
        store.dispatch(
          addMessage('Die Datei konnte nicht gelöscht werden. Bitte versuchen Sie es erneut.', false, 'error')
        );
      });
  }

  updateReportingLink(link, formikActions) {
    const { params } = this.props;
    const { uuid } = params;

    Client.post(`/study/${uuid}/update-reporting-link`, { link, studyId: uuid })
      .then((response) => {
        this.setState({ study: response.data });
        formikActions.setSubmitting(false);
        store.dispatch(addMessage('Der Link wurde erfolgreich aktualisiert.'));
      })
      .catch((error) => {
        if (error.networkError) {
          return;
        }
        formikActions.setSubmitting(false);

        error.data.forEach((error) => {
          formikActions.setFieldError(error.property, error.message);
        });
      });
  }

  sendReportingNotification() {
    const { params } = this.props;
    const { uuid } = params;

    this.setState({ notifying: true });

    Client.post(`/study/${uuid}/notify-reporting`)
      .then(() => {
        this.setState({ notifying: false });
        store.dispatch(addMessage('Die Marktforscher wurden per E-Mail informiert.'));
      })
      .catch((error) => {
        if (error.networkError) {
          return;
        }
        this.setState({ notifying: false });
      });
  }

  renderTabs() {
    const { study, fetching, uploading, notifying, activeIndex } = this.state;

    let tabs = [
      { menuItem: 'Report', render: () => <Tab.Pane loading /> },
      { menuItem: 'Dateien', render: () => <Tab.Pane loading /> },
    ];

    if (fetching === false) {
      const { reportingLink } = study;

      tabs = [
        {
          menuItem: 'Report',
          render: () => (
            <Tab.Pane style={{ padding: 0 }}>
              {hasOneOfRoles([ROLE_ADMIN, ROLE_SERVICE_PROVIDER_PANEL, ROLE_SERVICE_PROVIDER_QUESTIONNAIRE]) && (
                <div style={{ width: '50%', padding: '1rem' }}>
                  <LinkForm
                    link={reportingLink}
                    submit={this.updateReportingLink}
                    data-doc="app_study_update_reporting_link"
                    datacy="study_update_reporting_link"
                  />
                </div>
              )}
              {reportingLink !== null && reportingLink !== '' ? (
                <iframe
                  title="Reporting"
                  src={reportingLink}
                  frameBorder="0"
                  style={{
                    width: '100%',
                    height: '80vh',
                    borderTop: '1px solid rgba(34, 36, 38, 0.15)',
                    marginTop: '-1px',
                  }}
                />
              ) : (
                <p className="text-muted" style={{ padding: '1.78571429rem 1rem' }}>
                  Für diese Studie ist noch kein Reporting verfügbar.
                </p>
              )}
            </Tab.Pane>
          ),
        },
        {
          menuItem: 'Dateien',
          render: () => (
            <Tab.Pane>
              <ReportingFileList
                study={study}
                uploading={uploading}
                notifying={notifying}
                uploadFile={this.uploadFile}
                removeFile={this.removeFile}
                sendReportingNotification={this.sendReportingNotification}
              />
            </Tab.Pane>
          ),
        },
      ];
    }

    return (
      <Tab
        activeIndex={activeIndex}
        menu={{ secondary: true, pointing: true, attached: true, tabular: true }}
        panes={tabs}
        onTabChange={(event, { activeIndex }) => this.setState({ activeIndex })}
      />
    );
  }

  render() {
    const { study, fetching } = this.state;

    return (
      <Screen title="Reporting | Media Monitor Rapid">
        <ScreenHeader title="Reporting" />
        <StudyHeader study={study} loading={fetching} />
        {this.renderTabs()}
      </Screen>
    );
  }
}

export default withHooks(ReportingScreen);
