import React from 'react';
import InfoMessage from '../../Ui/InfoMessage';
import ValidationError from '../../Ui/ValidationError';
import File from './File';

const FileList = ({ files, wave, activeIndex, errors, property }) => {
  if (files.length === 0) {
    return <InfoMessage>Es wurden noch keine Dateien hochgeladen.</InfoMessage>;
  }

  const renderTitle = (file) => {
    if (!file.hasTitle) {
      return '';
    }

    if (file.title.length > 0) {
      return (
        <h5 className="mb-5" data-cy="file-title">
          {file.title}
        </h5>
      );
    }

    return <h5 className="mb-5">&nbsp;</h5>;
  };

  const buildPropertyPathWithKey = (key, suffix) => {
    if (errors.length === 0) {
      return null;
    }
    if (typeof property === 'undefined') {
      return `files[${key}].${suffix}`;
    }

    // if the property-path for validation includes brackets []
    // add the file-key to it so we can access the correct one
    if (property.match(/\[]/)) {
      return `${property.replace('[]', `[${key}]`)}.${suffix}`;
    }

    return property;
  };

  return (
    <div className="ui stackable three column grid mb-15">
      <div className="row">
        {files.map((file) => (
          <div className="column mb-15" key={file.fileName} data-cy="file-container">
            {renderTitle(file)}
            <File file={file} wave={wave} activeIndex={activeIndex} />
            <ValidationError errors={errors} property={buildPropertyPathWithKey(file.key, 'title')} />
            <ValidationError errors={errors} property={buildPropertyPathWithKey(file.key, 'fileType')} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default FileList;
