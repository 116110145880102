import RouterProvider from '@Provider/RouterProvider.tsx';
import ChakraProvider from './ChakraProvider.tsx';

const AppProvider = () => {
  return (
    <ChakraProvider>
      <RouterProvider />
    </ChakraProvider>
  );
};

export default AppProvider;
