import React from 'react';
import { Checkbox, Form } from 'semantic-ui-react';

const ToggleForm = ({ module, values, onChange }) => {
  const { key } = module;

  return (
    <form className="ui form mb-15">
      <Form.Field style={{ margin: '0 0 1em' }}>
        <Checkbox
          toggle
          checked={values.on}
          data-cy="button-toggle-form"
          onChange={() => {
            const on = !values.on;

            onChange(key, { on: on });
          }}
          label={values.on ? 'Wird abgefragt' : 'Wird nicht abgefragt'}
        />
      </Form.Field>
    </form>
  );
};

export default ToggleForm;
