import React, { Fragment } from 'react';
import { Button, Header, Icon, Message, Modal } from 'semantic-ui-react';
import {
  hasOneOfRoles,
  hasRole,
  ROLE_ADMIN,
  ROLE_MARKET_RESEARCHER,
  ROLE_SERVICE_PROVIDER_PANEL,
  ROLE_SERVICE_PROVIDER_QUESTIONNAIRE,
} from '../Permissions';
import FallbackMessage from './FallbackMessage';

const isAllowed = (transition, transitions) => transitions.includes(transition);

class ChangedOrderTransitions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      cancellationOpen: false,
      orderChangesOpen: false,
      abortOpen: false,
    };
  }

  renderOrderModalText = () => {
    const { color, feasibility } = this.props;

    if (hasRole(ROLE_ADMIN) === true && feasibility !== null) {
      const { calculatedAt, expectedResponses } = feasibility;
      if (calculatedAt === null && expectedResponses === null) {
        return (
          <Message warning>
            <Icon name="warning sign" />
            Die Welle befindet sich im Status <strong>ungeprüft</strong>.
            <br />
            <br />
            Soll die Welle dennoch beauftragt werden?
          </Message>
        );
      }

      if (color === 'red') {
        return (
          <Message warning>
            <Icon name="warning sign" />
            Die Machbarkeitsprüfung hat eine <strong>zu geringe Fallzahl</strong> ergeben.
            <br />
            <br />
            Soll die Welle dennoch beauftragt werden?
          </Message>
        );
      }
    }

    return (
      <Fragment>
        <p>
          Bitte <strong>unbedingt</strong> vor Beauftragung der Welle die Quotenvorgaben mit einem ZMG-Ansprechpartner
          abstimmen!
        </p>
        <p>Bei der Beauftragung der Welle fallen Kosten an.</p>
      </Fragment>
    );
  };

  render() {
    const { cancellationOpen, orderChangesOpen, abortOpen } = this.state;
    const { cancel, abortChange, orderChanges, wave } = this.props;
    const { transitions } = wave;

    return (
      <Fragment>
        <FallbackMessage wave={wave} />
        {isAllowed('cancel', transitions) &&
          hasOneOfRoles([
            ROLE_ADMIN,
            ROLE_MARKET_RESEARCHER,
            ROLE_SERVICE_PROVIDER_QUESTIONNAIRE,
            ROLE_SERVICE_PROVIDER_PANEL,
          ]) && (
            <Button
              fluid
              color="red"
              size="tiny"
              icon="cancel"
              content="Welle stornieren"
              className="mt-5"
              onClick={() => this.setState({ cancellationOpen: true })}
              data-doc="app_wave_transition_cancel"
              data-cy="transition_cancel"
            />
          )}
        {isAllowed('abort_change', transitions) &&
          hasOneOfRoles([
            ROLE_ADMIN,
            ROLE_MARKET_RESEARCHER,
            ROLE_SERVICE_PROVIDER_QUESTIONNAIRE,
            ROLE_SERVICE_PROVIDER_PANEL,
          ]) && (
            <Button
              fluid
              color="red"
              size="tiny"
              icon="trash alternate outline"
              content="Änderung verwerfen"
              className="mt-5"
              onClick={() => this.setState({ abortOpen: true })}
              data-doc="app_wave_transition_abort_change"
              data-cy="transition_abort_change"
            />
          )}
        {isAllowed('order_changes', transitions) &&
          hasOneOfRoles([
            ROLE_ADMIN,
            ROLE_MARKET_RESEARCHER,
            ROLE_SERVICE_PROVIDER_QUESTIONNAIRE,
            ROLE_SERVICE_PROVIDER_PANEL,
          ]) && (
            <Button
              fluid
              primary
              size="tiny"
              icon="shopping cart"
              content="Änderung beauftragen"
              className="mt-5"
              onClick={() => this.setState({ orderChangesOpen: true })}
              data-doc="app_wave_transition_order_change"
              data-cy="transition_order_change"
            />
          )}
        <Modal
          size="tiny"
          open={cancellationOpen}
          onClose={() => this.setState({ cancellationOpen: false })}
          data-doc="app_wave_transition_cancel"
        >
          <Header icon="cancel" content="Welle stornieren bestätigen" />
          <Modal.Content>
            <p>Diese Aktion kann nicht rückgängig gemacht werden. Bitte bestätigen Sie die Stornierung der Welle.</p>
          </Modal.Content>
          <Modal.Actions>
            <Button content="Abbrechen" onClick={() => this.setState({ cancellationOpen: false })} />
            <Button
              color="red"
              content="Stornieren"
              onClick={() => {
                this.setState({ cancellationOpen: false });
                cancel();
              }}
            />
          </Modal.Actions>
        </Modal>
        <Modal
          size="tiny"
          open={orderChangesOpen}
          onClose={() => this.setState({ orderChangesOpen: false })}
          data-doc="app_wave_transition_order_change"
        >
          <Header icon="shopping cart" content="Änderung beauftragen" />
          <Modal.Content>{this.renderOrderModalText()}</Modal.Content>
          <Modal.Actions>
            <Button content="Abbrechen" onClick={() => this.setState({ orderChangesOpen: false })} />
            <Button
              primary
              content="Änderungen beauftragen"
              data-cy="modal-transition_order_change"
              onClick={() => {
                this.setState({ orderChangesOpen: false });
                orderChanges();
              }}
            />
          </Modal.Actions>
        </Modal>
        <Modal
          size="tiny"
          open={abortOpen}
          onClose={() => this.setState({ abortOpen: false })}
          data-doc="app_wave_transition_abort_change"
        >
          <Header icon="trash alternate outline" content="Änderung verwerfen" />
          <Modal.Content>
            <p>
              Es werden alle Änderungen der Beauftragung verworfen. Bitte bestätigen Sie, dass Sie die Änderungen
              verwerfen möchten.
            </p>
          </Modal.Content>
          <Modal.Actions>
            <Button content="Abbrechen" onClick={() => this.setState({ abortOpen: false })} />
            <Button
              color="red"
              content="Änderungen verwerfen"
              onClick={() => {
                this.setState({ abortOpen: false });
                abortChange();
              }}
            />
          </Modal.Actions>
        </Modal>
      </Fragment>
    );
  }
}

export default ChangedOrderTransitions;
