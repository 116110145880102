import React from 'react';
import { SingleDatePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './DatePicker.scss';

class DatePicker extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      focused: false,
    };
  }

  render() {
    const { focused } = this.state;
    const { date, onChange, onBlur } = this.props;

    return (
      <SingleDatePicker
        noBorder
        hideKeyboardShortcutsPanel
        displayFormat="DD.MM.YYYY"
        date={date}
        focused={focused}
        onDateChange={(date) => {
          onChange({ date });
        }}
        onFocusChange={({ focused }) => {
          this.setState({ focused });
        }}
        firstDayOfWeek={1}
        onClose={onBlur}
      />
    );
  }
}

export default DatePicker;
