import * as React from 'react';
import { useLocation } from 'react-router';
import { useNavigate, useParams } from 'react-router-dom';

export function withHooks<ComponentProps>(Component: React.FunctionComponent<ComponentProps>) {
  function ComponentWithRouterProp(props: ComponentProps) {
    const navigate = useNavigate();
    const params = useParams();
    const location = useLocation();

    return <Component {...props} navigate={navigate} params={params} location={location} />;
  }

  return ComponentWithRouterProp;
}
