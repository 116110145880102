import React from 'react';
import { Button, Input } from 'semantic-ui-react';

class InputWithSave extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      value: '',
    };

    this.input = React.createRef();
  }

  componentDidMount() {
    const { value } = this.props;
    this.setState({
      value: value,
    });
  }

  componentDidUpdate(prevProps) {
    const { value } = this.props;
    if (value !== prevProps.value) {
      this.setState({
        // eslint-disable-line react/no-did-update-set-state
        loading: false,
      });
    }
  }

  onSubmit = (event) => {
    const { value } = this.state;
    const { onSubmit } = this.props;

    event.currentTarget.blur();
    onSubmit(value);
    this.setState({
      loading: true,
    });
  };

  reset = (event) => {
    event.currentTarget.blur();
    const { value } = this.props;
    this.setState({
      value: value,
    });
  };

  onKeydown = (event) => {
    if (event.keyCode === 13) {
      // enter
      this.onSubmit(event);
      return;
    }
    if (event.keyCode === 27) {
      // esc
      this.reset(event);
    }
  };

  setValue = (event) => {
    this.setState({
      value: event.currentTarget.value,
    });
  };

  render() {
    const { value, loading } = this.state;
    const { value: defaultValue, className } = this.props;

    return (
      <Input
        className={className}
        ref={this.input}
        type="text"
        value={value}
        onKeyDown={this.onKeydown}
        onChange={this.setValue}
        action
      >
        <input />
        <Button
          icon="redo"
          onClick={this.reset}
          disabled={value === defaultValue || loading}
          title="Zurücksetzen (ESC)"
          data-cy="input-reset"
        />
        <Button
          type="submit"
          loading={loading}
          icon="save"
          onClick={this.onSubmit}
          disabled={value === defaultValue}
          title="Speichern (Enter)"
          data-cy="input-submit"
        />
      </Input>
    );
  }
}

export default InputWithSave;
