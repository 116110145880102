import React from 'react';
import './DefinitionList.scss';

const DefinitionList = ({ children, inverted = false, ...props }) => (
  <dl className={`DefinitionList ${inverted === true ? 'DefinitionList--inverted' : ''}`} {...props}>
    {children}
  </dl>
);

const Definition = ({ children, ...props }) => (
  <dt className="DefinitionList__label" {...props}>
    {children}
  </dt>
);
const Description = ({ children, ...props }) => (
  <dd style={{ width: '100%' }} className="DefinitionList__value" {...props}>
    {children}
  </dd>
);

DefinitionList.Definition = Definition;
DefinitionList.Description = Description;

export default DefinitionList;
