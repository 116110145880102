import React from 'react';
import { Button, Form, Icon, Input } from 'semantic-ui-react';
import { ErrorMessage, Formik } from 'formik';
import InputError from '../Ui/InputError';

const LinkForm = ({ link, datacy, submit }) => (
  <Formik
    initialValues={{
      link: link === null ? '' : link,
    }}
    onSubmit={(values, actions) => {
      const link = values.link === '' ? null : values.link;

      submit(link, actions);
    }}
    render={({ errors, touched, values, handleSubmit, handleChange, handleBlur, isSubmitting }) => (
      <form className="ui form" onSubmit={handleSubmit}>
        <Form.Field error={errors.link && touched.link}>
          <Input
            type="text"
            name="link"
            id="link"
            value={values.link}
            data-cy={datacy}
            onChange={handleChange}
            onBlur={handleBlur}
            action={
              <Button icon primary type="submit" loading={isSubmitting}>
                <Icon name="save outline" />
              </Button>
            }
          />
          <InputError message={<ErrorMessage name="link" />} />
        </Form.Field>
      </form>
    )}
  />
);

export default LinkForm;
