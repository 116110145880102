import React from 'react';
import 'react-mde/lib/styles/css/react-mde-all.css';
import MarkdownRichTextEditor from '../Components/MarkdownRichTextEditor';
import { Module, MODULE_NOTES } from '../Modules';

class NotesForm extends React.Component {
  updateValue = (key, value) => {
    const { onChange } = this.props;

    onChange(key, { notes: value });
  };

  render() {
    const { module, values, wave, configuration } = this.props;
    const { key } = module;

    return (
      <MarkdownRichTextEditor
        id="notes"
        label={Module.getComponentName(MODULE_NOTES, configuration.modules, wave.study.type.type)}
        value={values.notes}
        onChange={(value) => this.updateValue(key, value)}
      />
    );
  }
}

export default NotesForm;
