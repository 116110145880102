import React from 'react';
import { Grid, Message, Segment, Loader as Spinner } from 'semantic-ui-react';
import { store } from '@Classic/App.jsx';
import Feasibility from '@Classic/Components/Order/Feasibility.jsx';
import ManualFeasibility from '@Classic/Components/Order/ManualFeasibility.jsx';
import { addMessage } from '../../Actions/messageActions';
import Client from '../../Client';
import LinkForm from '../../Forms/LinkForm';
import { findModuleByKey, findValuesByKey, MODULE_FEASIBILITY } from '../../Modules';
import {
  hasOneOfRoles,
  hasRole,
  ROLE_ADMIN,
  ROLE_SERVICE_PROVIDER_PANEL,
  ROLE_SERVICE_PROVIDER_QUESTIONNAIRE,
  ROLE_STUDENT,
} from '../../Permissions';
import ValidationError from '../../Ui/ValidationError';
import Transitions from '../Transitions';
import AllowedPanelProvider from './AllowedPanelProvider';
import Section from './Section';
import Summary from './Summary';
import TestLink from './TestLink';

class Order extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: {},
      values: null,
      color: null,
      // Loading states
      fetching: true,
      updating: false,
      checkingFeasibility: false,
      hasFeasibilityComponent: false,
      opalInfo: '',
      selectedPanelProviders: [],
    };

    this.fetchValues = this.fetchValues.bind(this);
    this.order = this.order.bind(this);
    this.execute = this.execute.bind(this);
    this.requestApproval = this.requestApproval.bind(this);
    this.approve = this.approve.bind(this);
    this.start = this.start.bind(this);
    this.complete = this.complete.bind(this);
    this.cancel = this.cancel.bind(this);
    this.refuse = this.refuse.bind(this);
    this.abort = this.abort.bind(this);
    this.change = this.change.bind(this);
    this.lastMinute = this.lastMinute.bind(this);
    this.fetchValues = this.fetchValues.bind(this);
    this.checkFeasibility = this.checkFeasibility.bind(this);
    this.setFeasibility = this.setFeasibility.bind(this);
    this.updateTestLink = this.updateTestLink.bind(this);
    this.updateEntryLink = this.updateEntryLink.bind(this);
    this.updateMonitoringLink = this.updateMonitoringLink.bind(this);
    this.onChangePanelProvider = this.onChangePanelProvider.bind(this);
    this.updateAllowedPanelProvider = this.updateAllowedPanelProvider.bind(this);
    this.initSelectedPanelProvider = this.initSelectedPanelProvider.bind(this);
  }

  componentDidMount() {
    const { wave, configuration } = this.props;

    const hasFeasibilityComponent = findModuleByKey(MODULE_FEASIBILITY, configuration.modules) !== null;

    this.initSelectedPanelProvider();

    Promise.all([Client.get(`/wave/${wave.id}/orderedvalues`)])
      .then((response) => {
        const [values] = response;

        this.updateFieldLength(values.data);

        const state = {
          values: values.data,
          hasFeasibilityComponent: hasFeasibilityComponent,
        };

        if (hasFeasibilityComponent) {
          this.setState(state);
          this.fetchFeasibility();
        } else {
          this.setState({
            ...state,
            fetching: false,
          });
        }
      })
      .catch((error) => {
        if (error.networkError) {
          return;
        }
        this.setState({ fetching: false });
      });
  }

  initSelectedPanelProvider = () => {
    if (!hasOneOfRoles([ROLE_ADMIN])) return;
    const { wave, availablePanelProviders } = this.props;

    let selectedPanelProviders = [];
    if (Array.isArray(wave.allowedPanelProvider) && wave.allowedPanelProvider.length > 0) {
      selectedPanelProviders = wave.allowedPanelProvider;
    }

    this.setState({ selectedPanelProviders: selectedPanelProviders.map((panelProvider) => panelProvider.id) });
  };

  onChangePanelProvider = (value) => {
    this.setState({ selectedPanelProviders: value });
  };

  updateAllowedPanelProvider = (values) => {
    const { wave, updateWave } = this.props;

    this.setState({ isPanelProviderUpdating: true });

    const userIds = values ?? [];
    Client.put(`/wave/${wave.id}/allowed-panel-provider`, { id: wave.id, version: wave.version, userIds: userIds })
      .then((response) => {
        updateWave(response.data);
        this.setState({ isPanelProviderUpdating: false });
        store.dispatch(addMessage('Der Panel Provider wurde erfolgreich gespeichert.'));
      })
      .catch((error) => {
        if (error.networkError) {
          return;
        }
        this.setState({ isPanelProviderUpdating: false });

        error.data.forEach((error) => {
          store.dispatch(addMessage(error.message, true, 'error'));
        });
      });
  };

  // Syncs field length up to parent component as the WaveHeader is in there and field length depends on selected Tab
  updateFieldLength = (values) => {
    const { syncFieldLength } = this.props;
    const { values: fieldLength } = findValuesByKey('field_length_and_sample', values);

    syncFieldLength(fieldLength.start, fieldLength.end, fieldLength.dateSingle);
  };

  setError = (error, message = 'Bitte überprüfen Sie Ihre Eingabe.') => {
    if (error.networkError) {
      return;
    }
    if (error.code === 500) {
      store.dispatch(addMessage(`${message}: ${error.message}`, true, 'error'));
      this.setState({ updating: false });
    } else {
      store.dispatch(addMessage(message, true, 'error'));
      console.log('Setting error: ', error);
      this.setState({ errors: error.data, updating: false });
    }
  };

  fetchFeasibility() {
    const { wave } = this.props;

    Client.get(`/wave/${wave.id}/ordered-feasibility`).then((color) => {
      this.setState({
        color: color.data,
        fetching: false,
      });
    });
  }

  // Werte und Machbarkeit aktualisieren
  fetchValues() {
    const { wave } = this.props;

    Promise.all([Client.get(`/wave/${wave.id}/orderedvalues`), Client.get(`/wave/${wave.id}/ordered-feasibility`)])
      .then((response) => {
        const [values, color] = response;

        this.updateFieldLength(values.data);
        this.setState({
          values: values.data,
          color: color.data,
          checkingFeasibility: false,
        });
      })
      .catch((error) => {
        if (error.networkError) {
          return;
        }
        this.setState({ updating: false });
      });
  }

  // Welle beauftragen
  order() {
    const { wave, updateWave } = this.props;

    this.setState({ updating: true });

    Client.post(`/wave/${wave.id}/order`, { id: wave.id, version: wave.version })
      .then((response) => {
        const waveTypeName = wave.study.isMediaOpal ? 'Studie' : 'Welle';
        this.setState({ updating: false, errors: {} });
        updateWave(response.data);
        store.dispatch(addMessage(`Die ${waveTypeName} wurde erfolgreich beauftragt.`));
      })
      .catch((error) => {
        // store.dispatch(addMessage(`Die Welle wurde nicht erfolgreich beauftragt.`));
        this.setError(error);
      });
  }

  // Umsetzung starten
  execute() {
    const { wave, updateWave } = this.props;

    this.setState({ updating: true });

    Client.post(`/wave/${wave.id}/execute`, { id: wave.id, version: wave.version })
      .then((response) => {
        const waveTypeName = wave.study.isMediaOpal ? 'Studie' : 'Welle';
        this.setState({ updating: false });
        updateWave(response.data);
        store.dispatch(addMessage(`Die Umsetzung der ${waveTypeName} wurde erfolgreich gestartet.`));
      })
      .catch((error) => {
        this.setError(error, 'Die Umsetzung konnte nicht gestartet werden.');
      });
  }

  // Umsetzung beenden
  requestApproval() {
    const { wave, updateWave } = this.props;

    this.setState({ updating: true });

    Client.post(`/wave/${wave.id}/requestapproval`, { id: wave.id, version: wave.version })
      .then((response) => {
        const waveTypeName = wave.study.isMediaOpal ? 'Studie' : 'Welle';
        this.setState({ updating: false });
        updateWave(response.data);
        store.dispatch(addMessage(`Die Umsetzung der ${waveTypeName} wurde erfolgreich beendet.`));
      })
      .catch((error) => {
        const waveTypeName = wave.study.isMediaOpal ? 'Studie' : 'Welle';
        this.setError(error, `Die Umsetzung der ${waveTypeName} konnte nicht beendet werden.`);
      });
  }

  // Umsetzung abbrechen
  abort() {
    const { wave, updateWave } = this.props;

    this.setState({ updating: true });

    Client.post(`/wave/${wave.id}/abort`, { id: wave.id, version: wave.version })
      .then((response) => {
        const waveTypeName = wave.study.isMediaOpal ? 'Studie' : 'Welle';
        this.setState({ updating: false });
        updateWave(response.data);
        store.dispatch(addMessage(`Die ${waveTypeName} wurde erfolgreich verweigert.`));
      })
      .catch((error) => {
        const waveTypeName = wave.study.isMediaOpal ? 'Studie' : 'Welle';
        this.setError(error, `Die ${waveTypeName} konnte nicht verweigert werden`);
      });
  }

  // Abnahme durchführen
  approve() {
    const { wave, updateWave } = this.props;

    this.setState({ updating: true });

    Client.post(`/wave/${wave.id}/approve`, { id: wave.id, version: wave.version })
      .then((response) => {
        const waveTypeName = wave.study.isMediaOpal ? 'Studie' : 'Welle';
        this.setState({ updating: false });
        updateWave(response.data);
        store.dispatch(addMessage(`Die ${waveTypeName} wurde erfolgreich freigegeben.`));
      })
      .catch((error) => {
        const waveTypeName = wave.study.isMediaOpal ? 'Studie' : 'Welle';
        this.setError(error, `Die ${waveTypeName} konnte nicht freigegeben werden`);
      });
  }

  // Druchführung starten
  start() {
    const { wave, updateWave } = this.props;

    this.setState({ updating: true });

    Client.post(`/wave/${wave.id}/toinfield`, { id: wave.id, version: wave.version })
      .then((response) => {
        this.setState({ updating: false });
        updateWave(response.data);
        store.dispatch(addMessage('Die Durchführung wurde erfolgreich gestartet.'));
      })
      .catch((error) => {
        this.setError(error, 'Die Durchführung konnte nicht gestartet werden.');
      });
  }

  // Durchführung beenden
  complete() {
    const { wave, updateWave } = this.props;

    this.setState({ updating: true });

    Client.post(`/wave/${wave.id}/complete`, { id: wave.id, version: wave.version })
      .then((response) => {
        this.setState({ updating: false });
        updateWave(response.data);
        store.dispatch(addMessage('Die Durchführung wurde erfolgreich beendet.'));
      })
      .catch((error) => {
        this.setError(error, 'Die Durchführung konnte nicht beendet werden.');
      });
  }

  // Welle stornieren
  cancel() {
    const { wave, updateWave } = this.props;

    this.setState({ updating: true });

    Client.post(`/wave/${wave.id}/cancel`, { id: wave.id, version: wave.version })
      .then((response) => {
        const waveTypeName = wave.study.isMediaOpal ? 'Studie' : 'Welle';
        this.setState({ updating: false });
        updateWave(response.data);
        store.dispatch(addMessage(`Die ${waveTypeName} wurde erfolgreich storniert.`));
      })
      .catch((error) => {
        const waveTypeName = wave.study.isMediaOpal ? 'Studie' : 'Welle';
        this.setError(error, `Die ${waveTypeName} konnte nicht storniert werden.`);
      });
  }

  // Welle verweigern
  refuse() {
    const { wave, updateWave } = this.props;

    this.setState({ updating: true });

    Client.post(`/wave/${wave.id}/refuse`, { id: wave.id, version: wave.version })
      .then((response) => {
        const waveTypeName = wave.study.isMediaOpal ? 'Studie' : 'Welle';
        this.setState({ updating: false });
        updateWave(response.data);
        store.dispatch(addMessage(`Die ${waveTypeName} wurde erfolgreich verweigert.`));
      })
      .catch((error) => {
        const waveTypeName = wave.study.isMediaOpal ? 'Studie' : 'Welle';
        this.setError(error, `Die ${waveTypeName} konnte nicht verweigert werden.`);
      });
  }

  // Auftrag ändern
  change() {
    const { wave, updateWave } = this.props;

    this.setState({ updating: true });

    Client.post(`/wave/${wave.id}/change`, { id: wave.id, version: wave.version })
      .then((response) => {
        const waveTypeName = wave.study.isMediaOpal ? 'Studie' : 'Welle';
        this.setState({ updating: false });
        updateWave(response.data);
        store.dispatch(
          addMessage(
            `Der Status der ${waveTypeName} wurde erfolgreich geändert. ` +
              'Sie können jetzt Änderungen am Bearbeitsstand vornehmen.'
          )
        );
      })
      .catch((error) => {
        const waveTypeName = wave.study.isMediaOpal ? 'Studie' : 'Welle';
        this.setError(error, `Der Status der ${waveTypeName} konnte nicht geändert werden.`);
      });
  }

  // last minute ändern
  lastMinute() {
    const { wave, updateWave } = this.props;

    this.setState({ updating: true });

    Client.post(`/wave/${wave.id}/last_minute`, { id: wave.id, version: wave.version })
      .then((response) => {
        const waveTypeName = wave.study.isMediaOpal ? 'Studie' : 'Welle';
        this.setState({ updating: false });
        updateWave(response.data);
        store.dispatch(
          addMessage(
            `Der Status der ${waveTypeName} wurde erfolgreich geändert. ` + 'Sie können jetzt Änderungen vornehmen.'
          )
        );
      })
      .catch((error) => {
        const waveTypeName = wave.study.isMediaOpal ? 'Studie' : 'Welle';
        this.setError(error, `Der Status der ${waveTypeName} konnte nicht geändert werden.`);
      });
  }

  // Machbarkeit einer Welle prüfen
  checkFeasibility() {
    const { wave, updateWave } = this.props;

    this.setState({ checkingFeasibility: true });

    Client.post(`/wave/${wave.id}/check-ordered-feasibility`, { id: wave.id, version: wave.version })
      .then((response) => {
        updateWave(response.data);
        this.fetchValues();

        // Removes feasibility validation error triggered by trying to order a wave without checked feasibility
        this.setState((prevState) => {
          const previousState = { ...prevState };
          if (previousState.errors && previousState.errors.feasibility) {
            delete previousState.errors.feasibility;
          }

          return {
            ...previousState,
          };
        });
      })
      .catch((error) => {
        if (error.networkError) {
          return;
        }
        // When the request did not fail due to validation errors
        if (error.code !== 450) {
          this.setState({ checkingFeasibility: false });
          store.dispatch(
            addMessage(
              'Bei der Prüfung der Machbarkeit ist ein unerwarteter Fehler aufgetreten. ' +
                'Bitte versuchen Sie es später erneut oder kontaktieren Sie einen Administrator.',
              false,
              'error'
            )
          );

          return;
        }

        this.setState({ errors: error.data, checkingFeasibility: false });
      });
  }

  // Machbarkeit einer Welle festlegen
  setFeasibility(feasibilityData) {
    const { wave, updateWave } = this.props;
    const { expectedResponses, state } = feasibilityData;

    this.setState({ checkingFeasibility: true });

    Client.post(`/wave/${wave.id}/set-feasibility`, {
      id: wave.id,
      version: wave.version,
      expectedResponses,
      state,
    })
      .then((response) => {
        updateWave(response.data);
        this.fetchValues();

        // Removes feasibility validation error triggered by trying to order a wave without checked feasibility
        this.setState((prevState) => {
          const previousState = { ...prevState };
          if (previousState.errors && previousState.errors.feasibility) {
            delete previousState.errors.feasibility;
          }

          return {
            ...previousState,
          };
        });
      })
      .catch((error) => {
        if (error.networkError) {
          return;
        }

        this.setState({ errors: error.data, checkingFeasibility: false });
      });
  }

  updateTestLink(testLink, formikActions) {
    const { wave, updateWave } = this.props;

    Client.post(`/wave/${wave.id}/update-test-link`, { id: wave.id, version: wave.version, link: testLink })
      .then((response) => {
        updateWave(response.data);
        formikActions.setSubmitting(false);
        store.dispatch(addMessage('Der Test-Link wurde erfolgreich gespeichert.'));
      })
      .catch((error) => {
        if (error.networkError) {
          return;
        }
        formikActions.setSubmitting(false);

        error.data.forEach((error) => {
          formikActions.setFieldError(error.property, error.message);
        });
      });
  }

  updateEntryLink(entryLink, formikActions) {
    const { wave, updateWave } = this.props;

    Client.post(`/wave/${wave.id}/update-entry-link`, { id: wave.id, version: wave.version, link: entryLink })
      .then((response) => {
        updateWave(response.data);
        formikActions.setSubmitting(false);
        store.dispatch(addMessage('Der Produktiv-Link wurde erfolgreich gespeichert.'));
      })
      .catch((error) => {
        if (error.networkError) {
          return;
        }
        formikActions.setSubmitting(false);

        error.data.forEach((error) => {
          formikActions.setFieldError(error.property, error.message);
        });
      });
  }

  updateMonitoringLink(monitoringLink, formikActions) {
    const { wave, updateWave } = this.props;

    Client.post(`/wave/${wave.id}/update-monitoring-link`, {
      id: wave.id,
      version: wave.version,
      link: monitoringLink,
    }) // eslint-disable-line max-len
      .then((response) => {
        updateWave(response.data);
        formikActions.setSubmitting(false);
        store.dispatch(addMessage('Der Monitoring-Link wurde erfolgreich gespeichert.'));
      })
      .catch((error) => {
        if (error.networkError) {
          return;
        }
        formikActions.setSubmitting(false);

        error.data.forEach((error) => {
          formikActions.setFieldError(error.property, error.message);
        });
      });
  }

  renderErrorMessage = () => {
    const { errors } = this.state;

    if (Object.keys(errors).length === 0) {
      return '';
    }

    console.log('Render Error Message errors: ', errors);
    return (
      <Message negative>
        <p>Bitte überprüfen Sie Ihre Eingabe.</p>
        {errors.feasibility && errors.feasibility.map((error) => <p>{error.message}</p>)}
      </Message>
    );
  };

  render() {
    const { regions, configuration, wave, availablePanelProviders } = this.props;
    const {
      errors,
      values,
      fetching,
      color,
      checkingFeasibility,
      updating,
      hasFeasibilityComponent,
      selectedPanelProviders,
      isPanelProviderUpdating,
    } = this.state;

    if (fetching) {
      return <Loader />;
    }

    let feasibility = null;
    if (hasFeasibilityComponent) {
      feasibility = findValuesByKey('feasibility', values).values;
    }

    return (
      <Segment attached loading={updating} data-cy="order">
        <Grid stackable>
          <Grid.Row>
            <Grid.Column widescreen={10} computer={10} tablet={16}>
              {this.renderErrorMessage()}
              <Summary
                // props
                wave={wave}
                regions={regions}
                configuration={configuration}
                activeIndex={0}
                // state
                values={values}
                errors={errors}
              />
            </Grid.Column>
            <Grid.Column widescreen={5} computer={6} tablet={16} floated="right">
              <Section headline="Aktionen" padded={false}>
                <Transitions
                  order={this.order}
                  execute={this.execute}
                  requestApproval={this.requestApproval}
                  approve={this.approve}
                  lastMinute={this.lastMinute}
                  start={this.start}
                  complete={this.complete}
                  cancel={this.cancel}
                  refuse={this.refuse}
                  abort={this.abort}
                  change={this.change}
                  opalInfoString={this.opalInfoString}
                  wave={wave}
                  color={color}
                  feasibility={feasibility}
                />
              </Section>
              {hasFeasibilityComponent && (
                <Section headline="Machbarkeit">
                  <ValidationError errors={errors} property="feasibility" />
                  <ValidationError errors={errors} property="color" />
                  <Feasibility
                    wave={wave}
                    color={color}
                    feasibility={feasibility}
                    checkingFeasibility={checkingFeasibility}
                    data-doc="app_wave_feasibility_check"
                    isCheckable={wave.state === 'initial' && hasRole(ROLE_ADMIN)}
                  />
                  <ManualFeasibility
                    wave={wave}
                    color={color}
                    feasibility={feasibility}
                    checkingFeasibility={checkingFeasibility}
                    setFeasibility={this.setFeasibility}
                    data-doc="app_wave_feasibility_check"
                    isCheckable={wave.state === 'initial' && hasRole(ROLE_ADMIN)}
                  />
                </Section>
              )}

              <AllowedPanelProvider
                wave={wave}
                availablePanelProviders={availablePanelProviders}
                selectedPanelProviders={selectedPanelProviders}
                isPanelProviderUpdating={isPanelProviderUpdating}
                onChangePanelProvider={this.onChangePanelProvider}
                updateAllowedPanelProvider={this.updateAllowedPanelProvider}
              />

              <Section headline="Test-Link">
                {hasOneOfRoles([ROLE_ADMIN, ROLE_SERVICE_PROVIDER_PANEL, ROLE_SERVICE_PROVIDER_QUESTIONNAIRE]) ? (
                  <LinkForm
                    link={wave.testLink}
                    submit={this.updateTestLink}
                    data-doc="app_wave_test_link_edit"
                    datacy="wave_test_link_edit"
                  />
                ) : (
                  <TestLink
                    testLink={wave.testLink}
                    emptyMessage="Es wurde noch kein Test-Link hinterlegt."
                    data-doc="app_wave_test_link_show"
                    datacy="wave_test_link_show"
                  />
                )}
              </Section>
              {hasOneOfRoles([
                ROLE_ADMIN,
                ROLE_SERVICE_PROVIDER_PANEL,
                ROLE_SERVICE_PROVIDER_QUESTIONNAIRE,
                ROLE_STUDENT,
              ]) && (
                <Section headline="Produktiv-Link">
                  {hasOneOfRoles([ROLE_ADMIN, ROLE_SERVICE_PROVIDER_PANEL, ROLE_SERVICE_PROVIDER_QUESTIONNAIRE]) ? (
                    <LinkForm
                      link={wave.entryLink}
                      submit={this.updateEntryLink}
                      data-doc="app_wave_entry_link_edit"
                      datacy="wave_entry_link_edit"
                    />
                  ) : (
                    <TestLink
                      testLink={wave.entryLink}
                      emptyMessage="Es wurde noch kein Produktiv-Link hinterlegt."
                      data-doc="app_wave_entry_link_show"
                      datacy="wave_entry_link_show"
                    />
                  )}
                </Section>
              )}
              {hasOneOfRoles([
                ROLE_ADMIN,
                ROLE_SERVICE_PROVIDER_PANEL,
                ROLE_SERVICE_PROVIDER_QUESTIONNAIRE,
                ROLE_STUDENT,
              ]) && (
                <Section headline="Monitoring-Link">
                  {hasOneOfRoles([ROLE_ADMIN, ROLE_SERVICE_PROVIDER_PANEL, ROLE_SERVICE_PROVIDER_QUESTIONNAIRE]) ? (
                    <LinkForm
                      link={wave.monitoringLink}
                      submit={this.updateMonitoringLink}
                      data-doc="app_wave_monitoring_link_edit"
                      datacy="wave_monitoring_link_edit"
                    />
                  ) : (
                    <TestLink
                      testLink={wave.monitoringLink}
                      emptyMessage="Es wurde noch kein Monitoring-Link hinterlegt."
                      data-doc="app_wave_monitoring_link_show"
                      datacy="wave_monitoring_link_show"
                    />
                  )}
                </Section>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}

const Loader = () => (
  <Segment attached style={{ minHeight: '300px' }}>
    <Spinner active>Konfiguration wird geladen ...</Spinner>
  </Segment>
);

export default Order;
