import React, { useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import { NavLink } from 'react-router-dom';
import { Button, Header, Icon, Modal } from 'semantic-ui-react';
import moment from 'moment';
import 'moment/locale/de';
import DefinitionList from '../Ui/DefinitionList';
import ModuleTypeIcon from './ModuleTypeIcon';
import './StudyCalendar.scss';

moment.locale('de');

const normalizeWaves = (studies) => {
  const events = [];

  studies.forEach((study) => {
    study.waves.forEach((wave) => {
      const start = moment(wave.start, 'DD.MM.YYYY').utc(true).toDate();
      const end = moment(wave.end, 'DD.MM.YYYY').utc(true).toDate();

      events.push({
        ...wave,
        study: study,
        title: <EventTitle study={study} wave={wave} />,
        start: start,
        end: end,
      });
    });
  });

  return events;
};

const EventTitle = ({ study, wave }) => (
  <span style={{ display: 'flex', alignItems: 'baseline' }}>
    <ModuleTypeIcon type={study.type.type} popup={false} />
    <span>
      {study.name}, {wave.name} ({study.organization.name})
    </span>
  </span>
);

const Toolbar = ({ onNavigate, label }) => (
  <div style={{ display: 'flex', alignItems: 'center', padding: '15px 0' }}>
    <Button.Group>
      <Button onClick={() => onNavigate('TODAY')}>Heute</Button>
      <Button icon="chevron left" onClick={() => onNavigate('PREV')} />
      <Button icon="chevron right" onClick={() => onNavigate('NEXT')} />
    </Button.Group>
    <span style={{ marginLeft: '1rem', fontSize: '1.2rem' }}>{label}</span>
  </div>
);

const DetailModal = ({ wave = null, setWaveId }) => {
  if (wave === null) {
    return null;
  }

  return (
    <Modal open size="tiny" onClose={() => setWaveId(null)}>
      <div
        className="d-flex align-items-center justify-content-space-between"
        style={{ padding: '1rem', borderBottom: '1px solid rgba(34, 36, 38, .15)' }}
      >
        <Header className="mb-0">{wave.name}</Header>
        <Button icon labelPosition="right" className="mr-0" as={NavLink} to={`/wave/${wave.id}/order`}>
          Auftragszusammenfassung
          <Icon name="shopping cart" />
        </Button>
      </div>
      <Modal.Content>
        <DefinitionList>
          <DefinitionList.Definition>Studie</DefinitionList.Definition>
          <DefinitionList.Description>{wave.study.name}</DefinitionList.Description>
          <DefinitionList.Definition>Typ</DefinitionList.Definition>
          <DefinitionList.Description>{wave.study.type.name}</DefinitionList.Description>
          <DefinitionList.Definition>Organisation</DefinitionList.Definition>
          <DefinitionList.Description>{wave.study.organization.name}</DefinitionList.Description>
        </DefinitionList>
      </Modal.Content>
      <Modal.Actions>
        <Button content="Schließen" onClick={() => setWaveId(null)} />
      </Modal.Actions>
    </Modal>
  );
};

const StudyCalendar = ({ studies }) => {
  const [waveId, setWaveId] = useState(null);
  const waves = normalizeWaves(studies);

  return (
    <div style={{ height: '90vh' }} data-cy="dashboard-calendar">
      <Calendar
        popup
        components={{ toolbar: Toolbar }}
        onSelectEvent={(event) => setWaveId(event.id)}
        localizer={momentLocalizer(moment)}
        events={waves}
        startAccessor="start"
        endAccessor="end"
        views={['month']}
        messages={{
          previous: 'Zurück',
          next: 'Vor',
          today: 'Heute',
          month: 'Monat',
          week: 'Woche',
          day: 'Tag',
          showMore: (total) => `+${total} weitere`,
        }}
        style={{ height: '90%' }}
      />
      <DetailModal wave={waves.find((wave) => wave.id === waveId)} waveId={waveId} setWaveId={setWaveId} />
    </div>
  );
};

export default StudyCalendar;
