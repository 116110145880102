import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Form, Header, Icon, List, Modal, Responsive, Segment } from 'semantic-ui-react';
import { ErrorMessage, Formik } from 'formik';
import * as Yup from 'yup';
import { addMessage } from '@Classic/Actions/messageActions.js';
import { store } from '@Classic/App.jsx';
import Client from '../Client';
import {
  hasOneOfRoles,
  hasRole,
  ROLE_ADMIN,
  ROLE_MARKET_RESEARCHER,
  ROLE_REPORTING_INTERESTED_PERSON,
} from '../Permissions';
import InputError from '../Ui/InputError';
import ModuleTypeIcon from './ModuleTypeIcon';
import './Study.scss';
import Wave from './Wave';

const UpdateStudyNameSchema = Yup.object().shape({
  name: Yup.string().required('Bitte geben Sie einen Namen für die Studie an.'),
});

class Study extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      isEditNameModalOpen: false,
      updating: false,
    };

    this.copyStudy = this.copyStudy.bind(this);
    this.copyWave = this.copyWave.bind(this);
    this.removeWave = this.removeWave.bind(this);
  }

  updateName(values) {
    const { study, updateStudy } = this.props;
    const { id: studyId } = study;
    this.setState({ updating: true });

    Client.put(`/study/${studyId}/name`, { id: studyId, name: values.name })
      .then((response) => {
        updateStudy(response.data);
        this.setState({ updating: false, isEditNameModalOpen: false });
        store.dispatch(addMessage('Der Name der Studie wurde erfolgreich aktualisiert.'));
      })
      .catch((error) => {
        console.log('network error', error);
        if (error.networkError) {
          return;
        }
        this.setState({ updating: false, isEditNameModalOpen: false });
        store.dispatch(addMessage('Beim Aktualisieren des Namens ist ein Fehler aufgetreten.', false, 'error'));
      });
  }

  copyStudy() {
    const { study, addStudy } = this.props;

    this.setState({ loading: true });

    Client.post(`/study/${study.id}/copy`, { id: study.id })
      .then((response) => {
        addStudy(response.data);
        this.setState({ loading: false });
        store.dispatch(addMessage('Die Studie wurde erfolgreich kopiert.'));
      })
      .catch((error) => {
        store.dispatch(
          addMessage(
            'Beim Kopieren der Studie ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.',
            false,
            'error'
          )
        );
        if (error.networkError) {
          return;
        }
        this.setState({ loading: false });
      });
  }

  copyWave(waveId) {
    const { updateStudy } = this.props;

    this.setState({ loading: true });

    Client.post(`/wave/${waveId}/copy`, { id: waveId })
      .then((response) => {
        updateStudy(response.data);
        this.setState({ loading: false });
        store.dispatch(addMessage('Die Welle wurde erfolgreich kopiert.'));
      })
      .catch((error) => {
        store.dispatch(
          addMessage(
            'Beim Kopieren der Welle ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.',
            false,
            'error'
          )
        );
        if (error.networkError) {
          return;
        }
        this.setState({ loading: false });
      });
  }

  removeWave(wave) {
    const { removeWave, study } = this.props;
    const { id: studyId } = study;
    const { id: waveId, version } = wave;

    this.setState({ loading: true });

    Client.request({
      method: 'DELETE',
      url: `/wave/${waveId}/delete`,
      data: { id: waveId, version: version },
    })
      .then(() => {
        removeWave(studyId, waveId);
        this.setState({ loading: false });
        store.dispatch(addMessage('Die Welle wurde erfolgreich entfernt.'));
      })
      .catch(() => {
        store.dispatch(
          addMessage(
            'Beim Löschen der Welle ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.',
            false,
            'error'
          )
        );
        this.setState({ loading: false });
      });
  }

  renderOrganization = () => {
    const { study } = this.props;

    if (hasRole(ROLE_REPORTING_INTERESTED_PERSON) || hasRole(ROLE_MARKET_RESEARCHER)) {
      return null;
    }

    return (
      <Responsive as="span" className="text-muted" minWidth={Responsive.onlyComputer.minWidth}>
        ({study.organization.name})
      </Responsive>
    );
  };

  renderReportingButton = () => {
    const { study } = this.props;

    return (
      <>
        <Responsive
          minWidth={Responsive.onlyComputer.minWidth}
          as={Link}
          className="ui big basic icon right labeled button mr-0"
          to={`/study/${study.id}/reporting`}
          data-doc="app_wave_open_report"
          data-cy="wave_open_report"
        >
          Reporting
          <Icon name="chart bar" />
        </Responsive>
        <Responsive
          maxWidth={Responsive.onlyTablet.maxWidth}
          as={Link}
          className="ui basic icon button mr-0"
          title="Reporting"
          to={`/study/${study.id}/reporting`}
          data-doc="app_wave_open_report"
        >
          <Icon name="chart bar" />
        </Responsive>
      </>
    );
  };

  renderNameEdit = () => {
    const { study } = this.props;

    if (hasOneOfRoles([ROLE_MARKET_RESEARCHER, ROLE_ADMIN])) {
      return (
        <List.Header
          className="Study__header Study__header--editable"
          as="button"
          type="button"
          data-doc="app_study_put_name"
          onClick={() => this.setState({ isEditNameModalOpen: true })}
          data-cy="study_name_edit"
        >
          {study.name}
          <Icon name="pencil alternate" />
        </List.Header>
      );
    }

    return <List.Header className="Study__header">{study.name}</List.Header>;
  };

  renderUpdateNameModal = () => {
    const { isEditNameModalOpen, updating } = this.state;
    const { study } = this.props;

    if (hasRole(ROLE_MARKET_RESEARCHER) === false && hasRole(ROLE_ADMIN) === false) {
      return null;
    }

    return (
      <Modal
        data-cy="modal_study_rename"
        closeOnDimmerClick={false}
        size="tiny"
        open={isEditNameModalOpen}
        onClose={() => this.setState({ isEditNameModalOpen: false })}
      >
        <Header icon="write" content="Studie umbenennen" />
        <Formik
          initialValues={{ name: study.name }}
          validationSchema={UpdateStudyNameSchema}
          onSubmit={(values, actions) => this.updateName(values, actions)}
          render={({ errors, touched, values, handleSubmit, handleChange, handleBlur, isValid }) => (
            <Form onSubmit={handleSubmit}>
              <Modal.Content>
                <Form.Field error={errors.name && touched.name}>
                  <label htmlFor="name">Name</label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    data-cy="study_name_input"
                  />
                  <InputError message={<ErrorMessage name="name" />} />
                </Form.Field>
              </Modal.Content>
              <Modal.Actions>
                <Button
                  type="button"
                  content="Abbrechen"
                  disabled={updating}
                  onClick={() => this.setState({ isEditNameModalOpen: false })}
                />
                <Button
                  primary
                  type="submit"
                  content="Namen aktualisieren"
                  disabled={updating || !isValid}
                  loading={updating}
                  onClick={handleSubmit}
                  data-cy="study_name_submit"
                />
              </Modal.Actions>
            </Form>
          )}
        />
      </Modal>
    );
  };

  render() {
    const { loading } = this.state;
    const { study, updateWaveName } = this.props;

    return (
      <div className="my-30" data-cy="study_overview" data-study-id={study.id}>
        <Segment
          attached="top"
          className="d-flex justify-content-space-between"
          style={{
            background: study.isMediaOpal ? 'rgba(155, 195, 70, 0.25)' : 'rgba(0, 0, 0, 0.03)',
            alignItems: 'flex-start',
            padding: '1rem 1rem',
          }}
        >
          <Header className="mb-0" style={{ position: 'relative', width: '100%' }}>
            <ModuleTypeIcon name={study.type.name} type={study.type.type} />
            <Header.Content style={{ paddingRight: '0.75rem' }} data-cy="studies_headline">
              {this.renderNameEdit()} {this.renderOrganization()}
              <Header.Subheader className="mt-5" data-cy="studies_created_date">
                Erstellt am {study.createdAt}
              </Header.Subheader>
            </Header.Content>
          </Header>
          {this.renderReportingButton()}
        </Segment>
        <Segment attached loading={loading} className="p-0">
          <List divided relaxed className="mt-0">
            {study.waves.map((wave) => (
              <Wave
                key={wave.id}
                wave={wave}
                isMediaOpal={study.isMediaOpal}
                updateWaveName={updateWaveName}
                copyWave={study.isMediaOpal ? this.copyStudy : this.copyWave}
                removeWave={this.removeWave}
                updateName={this.updateName}
                studyNumber={study.studyNumber}
              />
            ))}
          </List>
        </Segment>
        {this.renderUpdateNameModal()}
      </div>
    );
  }
}

export default Study;
