import { Route, Routes } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';
import LoginPage from '@Page/LoginPage.jsx';
import PermissionDenied from '@Page/PermissionDenied.tsx';
import { useUserStore } from '@Store/useUsersStore.ts';
import PrivateRoute from '@Util/PrivateRoute.tsx';
import { ROLE } from '@Util/ROLE.ts';
import LandingPage from '@Classic/Pages/LandingPage.jsx';
import { getAllRoles, hasAnyOfRoles, hasOneOfRoles } from '@Classic/Permissions.ts';
import BlueprintScreen from '@Classic/Screens/BlueprintScreen.jsx';
import ConfigurationScreen from '@Classic/Screens/ConfigurationScreen.jsx';
import CostsScreen from '@Classic/Screens/CostsScreen.jsx';
import CreateBlueprintScreen from '@Classic/Screens/CreateBlueprintScreen.jsx';
import CreateNewsScreen from '@Classic/Screens/CreateNewsScreen.jsx';
import CreateOrganizationScreen from '@Classic/Screens/CreateOrganizationScreen.jsx';
import CreateStudyScreen from '@Classic/Screens/CreateStudyScreen.jsx';
import CreateUserScreen from '@Classic/Screens/CreateUserScreen.jsx';
import DashboardScreen from '@Classic/Screens/DashboardScreen';
import NewsListScreen from '@Classic/Screens/NewsListScreen.jsx';
import OrderScreen from '@Classic/Screens/OrderScreen.jsx';
import OrganizationScreen from '@Classic/Screens/OrganizationScreen.jsx';
import RecoveryScreen from '@Classic/Screens/RecoveryScreen.jsx';
import ReportingScreen from '@Classic/Screens/ReportingScreen.jsx';
import ResetScreen from '@Classic/Screens/ResetScreen.jsx';
import UpdateBlueprintScreen from '@Classic/Screens/UpdateBlueprintScreen.jsx';
import UpdateNewsScreen from '@Classic/Screens/UpdateNewsScreen.jsx';
import UpdateOrganizationScreen from '@Classic/Screens/UpdateOrganizationScreen.jsx';
import UpdateQuestionnaireScreen from '@Classic/Screens/UpdateQuestionnaireScreen.jsx';
import UpdateUserScreen from '@Classic/Screens/UpdateUserScreen.jsx';
import UserScreen from '@Classic/Screens/UserScreen.jsx';

const RouterProvider = () => {
  const { data } = useUserStore(useShallow((state) => ({ data: state.data }))) ?? { data: null };

  return (
    <BrowserRouter>
      <Routes>
        <Route path={'/login'} element={<LoginPage />} />

        <Route element={<PrivateRoute hasPermission={hasAnyOfRoles(data, getAllRoles())} redirectPath={'/start'} />}>
          <Route path={'/'} element={<DashboardScreen />} />

          <Route path="/wave/:uuid/order" element={<OrderScreen />} />
          <Route path={'/wave/:uuid/configuration/:tab?'} element={<ConfigurationScreen />} />

          <Route path={'/study/create'} element={<CreateStudyScreen />} />
          <Route path="/study/:uuid/reporting" element={<ReportingScreen />} />

          <Route path="/news" element={<NewsListScreen />} />
          <Route path="/news/create" element={<CreateNewsScreen />} />
          <Route path="/news/:uuid/update" element={<UpdateNewsScreen />} />
        </Route>

        <Route element={<PrivateRoute hasPermission={hasAnyOfRoles(data, [ROLE.ROLE_ADMIN])} redirectPath={'/'} />}>
          <Route path="/user" element={<UserScreen />} />
          <Route path="/user/create" element={<CreateUserScreen />} />
          <Route path="/user/:uuid/update" element={<UpdateUserScreen />} />

          <Route path="/organization" element={<OrganizationScreen />} />
          <Route path="/organization/create" element={<CreateOrganizationScreen />} />
          <Route path="/organization/:uuid/update" element={<UpdateOrganizationScreen />} />

          <Route path="/blueprint" element={<BlueprintScreen />} />
          <Route path="/blueprint/create" element={<CreateBlueprintScreen />} />
          <Route path="/blueprint/:uuid/update" element={<UpdateBlueprintScreen />} />
        </Route>

        <Route
          element={
            <PrivateRoute
              hasPermission={hasOneOfRoles([
                ROLE.ROLE_ADMIN,
                ROLE.ROLE_MARKET_RESEARCHER,
                ROLE.ROLE_SERVICE_PROVIDER_PANEL,
                ROLE.ROLE_STUDENT,
              ])}
              redirectPath={'/'}
            />
          }
        >
          <Route path="/costs" element={<CostsScreen />} />
        </Route>

        <Route
          element={
            <PrivateRoute
              hasPermission={hasOneOfRoles([
                ROLE.ROLE_ADMIN,
                ROLE.ROLE_REPORTING_INTERESTED_PERSON,
                ROLE.ROLE_MARKET_RESEARCHER,
                ROLE.ROLE_SERVICE_PROVIDER_QUESTIONNAIRE,
                ROLE.ROLE_SERVICE_PROVIDER_PANEL,
              ])}
              redirectPath={'/'}
            />
          }
        >
          <Route path="/questionnaire" element={<UpdateQuestionnaireScreen />} />
        </Route>

        <Route path="/start" element={<LandingPage />} />
        <Route path="/recovery" element={<RecoveryScreen />} />
        <Route path="/:hash/reset" element={<ResetScreen />} />

        {/* Routes for testing */}
        <Route path={'/permission-denied'} element={<PermissionDenied />} />
      </Routes>
    </BrowserRouter>
  );
};

export default RouterProvider;
