import React from 'react';
import { Module, MODULE_MEDIA_RETRIEVAL_DIMENSIONS } from '../../Modules';
import DefinitionList from '../../Ui/DefinitionList';
import ValidationError from '../../Ui/ValidationError';
import List from './List';
import Section from './Section';

const MediaDimension = ({ values, module, deepLink, wave, errors, configuration }) => {
  const { values: mediaRetrieval } = values;
  const { mediaRetrievalDimensions: labels } = module;

  /* eslint-disable */
  return (
    <Section
      headline={Module.getComponentName(MODULE_MEDIA_RETRIEVAL_DIMENSIONS, configuration.modules, wave.study.type.type)}
      deepLink={deepLink}
    >
      {mediaRetrieval.map((media, idx) => {
        const key = module.mediaRetrievalDimensions[idx].key;
        return (
          <DefinitionList key={`media-${idx}`}>
            <DefinitionList.Definition>{labels[idx].label}</DefinitionList.Definition>
            <DefinitionList.Description>
              <List items={media[key]} />
            </DefinitionList.Description>
          </DefinitionList>
        );
      })}
      <ValidationError errors={errors} property="choiceSetValues" />
    </Section>
  );
  /* eslint-enable */
};

export default MediaDimension;
