import { ChakraBaseProvider, extendBaseTheme } from '@chakra-ui/react';
import * as React from 'react';
import { Theme } from '../assets/styles/Theme.ts';

type Props = {
  children: React.ReactNode;
};

const theme = extendBaseTheme({
  ...Theme,
  // Override chakra components theme styling here...
});

const ChakraProvider = ({ children }: Props) => {
  return <ChakraBaseProvider theme={extendBaseTheme(theme)}>{children}</ChakraBaseProvider>;
};

export default ChakraProvider;
