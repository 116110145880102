export const EVENT_LOGGED_IN = 'LOGGED_IN';
export const EVENT_LOGGED_OUT = 'LOGGED_OUT';
export const EVENT_EXPIRED = 'EXPIRED';

class TokenStorage {
  private handlers: ((event: string) => void)[];

  constructor() {
    this.handlers = [];

    const token = this.getToken();

    if (!token) {
      return;
    }
  }

  getToken(): string | undefined {
    const token = localStorage.getItem('token');

    if (!token) {
      return undefined;
    }

    return token;
  }

  logout(): void {
    localStorage.removeItem('token');

    this.fire(EVENT_LOGGED_OUT);
  }

  setToken(token: string): void {
    localStorage.setItem('token', token);

    this.fire(EVENT_LOGGED_IN);
  }

  subscribe(handler: (event: string) => void): void {
    this.handlers.push(handler);
  }

  unsubscribe(handlerToBeRemoved: (event: string) => void): void {
    const currentHandlers = this.handlers;

    this.handlers = currentHandlers.filter((handler) => {
      return handler !== handlerToBeRemoved;
    });
  }

  private fire(event: string): void {
    for (const handler of this.handlers) {
      handler(event);
    }
  }
}

const tokenStorage = new TokenStorage();

export default tokenStorage;
